import { useState } from 'react';

import { useFormik } from 'formik';

import { EButtonColor } from '../../constants/enums';
import { EToasterStatus, showToast } from '../../utils/showToast';
import DialogModal from '../Dialog/Dialog';

import { IMPORT_CSV_WRONG_SIZE_MESSAGE } from './constants/constants';
import { csvUploadSchema } from './constants/validationSchema';
import ImportCSVContent from './ImportCSVContent';
import { ImportCSVModalProps } from './types';

import './style.scss';

const ImportCSVModal = ({
  isOpen,
  onImportFile,
  onCloseModal,
  fileRef,
  fileKeyName
}: ImportCSVModalProps) => {
  const [isUploading, setIsUploading] = useState(false);

  const { submitForm, resetForm, setFieldValue } = useFormik({
    validateOnMount: true,
    validationSchema: csvUploadSchema,
    enableReinitialize: true,
    initialValues: {
      file: null
    },
    onSubmit: async (values, { resetForm }) => {
      const file = fileRef?.current?.files?.[0];
      const formData = new FormData();
      if (!file) return;
      if (file && file.size > 2000000) {
        showToast({
          message: IMPORT_CSV_WRONG_SIZE_MESSAGE,
          status: EToasterStatus.ERROR
        });
        return;
      }
      setIsUploading(true);

      formData.append(fileKeyName, file as any);

      onImportFile(formData);
      resetForm();
      setIsUploading(false);
    }
  });

  return (
    <DialogModal
      className="importCSVModal"
      isOpen={isOpen}
      withDivider={false}
      closeButton={true}
      headline="Import CSV"
      width={'392px'}
      text={''}
      content={
        <ImportCSVContent
          isUploading={isUploading}
          fileRef={fileRef}
          setFieldValue={setFieldValue}
        />
      }
      buttons={[
        {
          text: 'Import',
          color: EButtonColor.PRIMARY,
          variant: 'contained',
          func: () => submitForm(),
          disabled: !fileRef?.current?.files?.length,
          fullWidth: true
        }
      ]}
      closeDialog={() => {
        resetForm();
        onCloseModal();
      }}
    />
  );
};
export default ImportCSVModal;
