import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { useProduct } from 'api/useProduct';

import ProductsForm from '../ProductsForm/ProductsForm';
import { Product } from '../ProductsForm/ProductsForm.types';

import { ProductFormEditWrapperProps } from './ProductFormEditWrapper.types';

const ProductFormEditWrapper = ({
  dup = false,
  updateProduct,
  postProduct
}: ProductFormEditWrapperProps) => {
  const { id } = useParams();
  const { publisherId } = useParams();
  const product = useProduct(publisherId, id).getProduct;
  const updateMiddleware = (data: Product, onMutationComplete: () => void) => {
    if (!updateProduct) return;
    const form = {
      id,
      data
    };

    updateProduct(form as { id: string; data: Product }, onMutationComplete);
  };

  useEffect(() => {
    if (id && publisherId) {
      product.refetch();
    }
  }, [id, publisherId]);

  return (
    <>
      {product.isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: '100px',
            width: '100px'
          }}
        />
      ) : product.isError ? (
        <span>An error has occurred, {JSON.stringify(product.error)}</span>
      ) : (
        product.data && (
          <ProductsForm
            data={product.data.result}
            isLoading={product.isRefetching}
            emptyCache={product.remove}
            postProduct={dup ? postProduct : updateMiddleware}
            headline={dup ? 'New Product' : 'Edit Product'}
            buttonText={`${dup ? 'Save' : 'Update'} Product`}
            edit={dup ? false : true}
            dup={dup}
          />
        )
      )}
    </>
  );
};

export default ProductFormEditWrapper;
