import { ChangeEvent, useEffect } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import { EIntervalsRepeat, EWeekDay } from 'constants/enums';
import { permissionsUtil } from 'utils/permissionsUtil';
import { timeUtils } from 'utils/scheduleUtils';
import {
  dayInMonthOptions,
  intervalsRepeatOptions,
  roundedHoursOptions
} from 'utils/selectOptionsUtils';

import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import type { PermanentOfferProps } from './PermanentOffer';

const IntervalOffer: React.FC<PermanentOfferProps> = ({
  formikProps,
  repeat,
  setRepeat,
  weekDay,
  setWeekDay,
  dayInMonth,
  setDayInMonth,
  publisherId,
  startHour,
  setStartHour,
  intervalCron,
  setIntervalCron
}) => {
  const { values, setFieldValue, errors, touched, handleBlur } = formikProps;

  useEffect(() => {
    if (repeat === EIntervalsRepeat.NONE) {
      setIntervalCron('');
      return;
    }

    const newCron = timeUtils.generateCronString(
      repeat,
      startHour,
      weekDay as number,
      dayInMonth as number
    );

    if (newCron !== intervalCron) {
      setIntervalCron(newCron || '');
    }
  }, [repeat, startHour, weekDay, dayInMonth]);

  useEffect(() => {
    if (values?.schedule?.permanent) {
      setFieldValue('schedule', {
        permanent: values?.schedule?.permanent,
        timeFrames: values?.schedule?.timeFrames,
        ...(repeat !== EIntervalsRepeat.NONE &&
          intervalCron && { interval: intervalCron })
      });
      setFieldValue(
        'startHour',
        timeUtils.getTimeFromCron(intervalCron || '')?.hourFromCron
      );
    }
  }, [intervalCron]);

  useEffect(() => {
    const interval = values?.schedule?.interval;

    if (!interval) {
      return;
    }

    const { dayInMonthFromCron, hourFromCron, weekDayFromCron } =
      timeUtils.getTimeFromCron(interval);

    const newRepeat = dayInMonthFromCron
      ? EIntervalsRepeat.MONTHLY
      : weekDayFromCron !== undefined
        ? EIntervalsRepeat.WEEKLY
        : hourFromCron
          ? EIntervalsRepeat.DAILY
          : EIntervalsRepeat.NONE;

    if (newRepeat !== repeat) setRepeat(newRepeat);
    if (dayInMonthFromCron && dayInMonthFromCron !== dayInMonth)
      setDayInMonth(dayInMonthFromCron);
    if (weekDayFromCron !== undefined && weekDayFromCron !== weekDay)
      setWeekDay(weekDayFromCron);
    if (hourFromCron && hourFromCron !== startHour) setStartHour(hourFromCron);
  }, [values?.schedule?.interval]);

  const weekDaysOptions = Object.entries(EWeekDay)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      content: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
      value
    }));

  const isSuperAdmin =
    publisherId && permissionsUtil.isSuperAdminByProject(publisherId);

  return (
    <>
      <Grid item xs={3}>
        <AcSelect
          header="Repeat"
          name="repeat"
          value={repeat}
          items={intervalsRepeatOptions}
          onChange={(e) => setRepeat(e.target.value as EIntervalsRepeat)}
        />
      </Grid>

      {repeat === EIntervalsRepeat.WEEKLY && (
        <Grid item xs={3}>
          <AcSelect
            header="Week Day"
            name="weekDay"
            value={weekDay}
            items={weekDaysOptions}
            onChange={(e) => setWeekDay(e.target.value as number)}
          />
        </Grid>
      )}

      {repeat === EIntervalsRepeat.MONTHLY && (
        <Grid item xs={3}>
          <AcSelect
            header="Day in Month"
            name="dayInMonth"
            value={dayInMonth || 1}
            items={dayInMonthOptions}
            onChange={(e) => setDayInMonth(Number(e.target.value))}
          />
        </Grid>
      )}

      {repeat !== EIntervalsRepeat.NONE && (
        <Grid item xs={isSuperAdmin ? 1 : 3}>
          {isSuperAdmin ? (
            <AcInput
              header="Hour (UTC)"
              name="startHour"
              type="time"
              value={startHour}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setStartHour(e.target.value);
                setFieldValue('startHour', e.target.value);
              }}
              sx={{ minWidth: '100px' }}
              onBlur={handleBlur}
              required
              error={touched?.startHour && Boolean(errors?.startHour)}
              helperText={
                touched?.startHour ? errors?.startHour?.toString() : ''
              }
            />
          ) : (
            <AcSelect
              header="Hour (UTC)"
              name="startHour"
              value={startHour}
              items={roundedHoursOptions}
              onChange={(e: SelectChangeEvent<unknown>) =>
                setStartHour(e.target.value as string)
              }
              onBlur={handleBlur}
              required
              error={touched?.startHour && Boolean(errors?.startHour)}
              helperText={
                touched?.startHour ? errors?.startHour?.toString() : ''
              }
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default IntervalOffer;
