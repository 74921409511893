import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { DateRange } from '@mui/x-date-pickers-pro';
import type {
  ExtendedDateRange,
  FormikProps,
  ScheduleTimeFrame
} from 'common/contracts';
import { EIntervalsRepeatRequired, EScheduleTabs } from 'constants/enums';
import type { Dayjs } from 'dayjs';

import IntervalOffer from 'components/Schedule/IntervalOffer';
import PermanentOffer from 'components/Schedule/PermanentOffer';
import ScheduledOffer from 'components/Schedule/ScheduledOffer';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector';

import RichText from '../../../components/RichText/RichText';

interface ScheduleTabProps {
  formikProps: FormikProps;
  startHour?: string;
  setStartHour: (startHour?: string) => void;
  intervalCron: string;
  setIntervalCron: (intervalCron: string) => void;
}

const ScheduleTab: React.FC<ScheduleTabProps> = ({
  formikProps,
  startHour,
  setStartHour,
  intervalCron,
  setIntervalCron
}) => {
  const { setFieldValue, values } = formikProps;

  const [repeat, setRepeat] = useState(EIntervalsRepeatRequired.DAILY);
  const [weekDay, setWeekDay] = useState<number>(0);
  const [dayInMonth, setDayInMonth] = useState<number>(1);

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [appearancesTableData, setAppearancesTableData] = useState<
    ScheduleTimeFrame[]
  >([]);
  const [editRangeDatesValues, setEditRangeDatesValues] =
    useState<ExtendedDateRange | null>(null);
  const [notes, setNotes] = useState('');
  const [tabTitle, setTabTitle] = useState(
    values?.schedule?.permanent
      ? values?.schedule?.interval
        ? EScheduleTabs.INTERVALS
        : EScheduleTabs.PERMANENT
      : EScheduleTabs.SCHEDULE
  );
  const { publisherId } = useParams();

  useEffect(() => {
    const timeFramesData: ScheduleTimeFrame[] =
      values.schedule?.timeFrames?.map(
        (date: ScheduleTimeFrame, index: number) => ({
          id: date.id || `id-${index}`,
          startTime: date.startTime,
          endTime: date.endTime,
          notes: date.notes
        })
      );

    setAppearancesTableData(timeFramesData);
  }, [values?.schedule?.timeFrames]);

  const resetScheduleValues = () => {
    setDateRange([null, null]);
    setEditRangeDatesValues(null);
    setNotes('');
    setStartHour('');
    setIntervalCron('');
    setRepeat(EIntervalsRepeatRequired.DAILY);
  };

  const onClickSwitch = (value: string) => {
    setTabTitle(value as EScheduleTabs);
    const isPermanent = value !== EScheduleTabs.SCHEDULE;
    setFieldValue('schedule', {
      timeFrames: values?.schedule.timeFrames,
      permanent: isPermanent,
      ...(isPermanent && intervalCron && { interval: intervalCron })
    });
    resetScheduleValues();
  };

  const switchOptions = [
    { value: EScheduleTabs.PERMANENT },
    { value: EScheduleTabs.INTERVALS },
    { value: EScheduleTabs.SCHEDULE }
  ];

  return (
    <div className='w-[456px] mx-auto'>
      <RichText
        title="Scheduled Offer"
        description="Set specific dates & hours to your offer"
        className="mb-6"
      />
      <SwitchSelector
        options={switchOptions}
        onClick={onClickSwitch}
        value={tabTitle}
      />
      {tabTitle === EScheduleTabs.SCHEDULE ? (
        <ScheduledOffer
          formikProps={formikProps}
          editRangeDatesValues={editRangeDatesValues}
          setEditRangeDatesValues={setEditRangeDatesValues}
          intervalCron={intervalCron}
          appearancesTableData={appearancesTableData}
          notes={notes}
          setNotes={setNotes}
          dateRange={dateRange}
          setDateRange={setDateRange}
          resetScheduleValues={resetScheduleValues}
        />
      ) : tabTitle === EScheduleTabs.INTERVALS ? (
        <IntervalOffer
          formikProps={formikProps}
          repeat={repeat}
          setRepeat={setRepeat}
          weekDay={weekDay}
          setWeekDay={setWeekDay}
          dayInMonth={dayInMonth}
          setDayInMonth={setDayInMonth}
          publisherId={publisherId}
          startHour={startHour || ''}
          setStartHour={setStartHour}
          intervalCron={intervalCron}
          setIntervalCron={setIntervalCron}
        />
      ) : (
        <PermanentOffer formikProps={formikProps} />
      )}
    </div>
  );
};

export default ScheduleTab;
