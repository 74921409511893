import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import useOffersUI from 'api/useOffersUI';
import useSegments from 'api/useSegments';
import useTags from 'api/useTags';
import useUsers from 'api/useUsers';
import { OfferUI } from 'common/contracts';
import { MIN_USD_PRICE } from 'constants/constants';
import {
  EFeatureFlag,
  EPopupSubType,
  ESaleDiscountDisplayType
} from 'constants/enums';
import { OfferDesignTypes } from 'views/OffersUI/OffersUIForm/types';

import AcAutoComplete from 'components/AcAutoComplete/AcAutoComplete';
import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import DisplayTypeSelect from 'components/DisplayTypeSelect/DisplayTypeSelect';

const SettingsTab = (props: any) => {
  const {
    formikProps,
    values,
    getOffers,
    edit,
    currentOfferUI,
    setCurrentOfferUI,
    currentSubType,
    setCurrentSubType,
    chosenSegment,
    setChosenSegment,
    chosenTags,
    setChosenTags,
    hasFeatureFlagTags,
    dup
  } = props;
  const { handleChange, handleBlur, touched, errors, setFieldValue } =
    formikProps;

  const [isNameExists, setIsNameExists] = useState<boolean>(false);
  const [priceInputValue, setPriceInputValue] = useState<number>(0);
  const [segmentError, setSegmentError] = useState('');
  const [tagsError, setTagsError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { publisherId } = useParams();

  const { getOffersUI } = useOffersUI(publisherId, currentOfferUI);
  const { getSegments } = useSegments(publisherId);
  const { getTags } = useTags(publisherId, hasFeatureFlagTags);

  const { fetchFeatureFlags } = useUsers({ publisherId });

  useEffect(() => {
    setIsNameExists(
      getOffers.data?.offers.find((o: any) => {
        return o.name === values.name;
      }) && !edit
    );
  }, [values.name]);

  useEffect(() => {
    if (values.subType === EPopupSubType.POST_PURCHASE) {
      values.setAsFree
        ? setPriceInputValue(0)
        : setPriceInputValue(values.price);
    } else {
      setPriceInputValue(0);
    }
  }, [values.subType, values.setAsFree, currentSubType]);

  const handlePriceChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === 'Free' || inputValue === 0) {
      setPriceInputValue(0);
      setFieldValue('price', 0);
    } else {
      const numericValue = parseFloat(inputValue);
      setPriceInputValue(isNaN(numericValue) ? 0 : numericValue);
      setFieldValue('price', isNaN(numericValue) ? 0 : numericValue);
    }
  };

  const hasFeatureFlagPostPurchase =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_POST_PURCHASE
    ];

  return (
    <Stack gap={2}>
      <AcCard stackContainer={false} title="General">
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <AcInput
              header="Name"
              name="name"
              required
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(touched.name && Boolean(errors.name)) || isNameExists}
              helperText={
                (touched.name ? errors.name?.toString() : '') ||
                (isNameExists ? 'Name already exists' : '')
              }
              dataTestValidationId="popupNameValidationTxt"
            />
          </Grid>
          <Grid xs={6} item>
            <AcInput
              header="Display Name"
              name="displayName"
              variant="outlined"
              value={values.displayName}
              onChange={(data) => {
                handleChange(data);
              }}
              onBlur={handleBlur}
              error={touched.displayName && Boolean(errors.displayName)}
              tooltip={'Add your display name for this popup'}
              dataTestValidationId="popupDisplayNameValidationTxt"
            />
          </Grid>
          <Grid xs={12} item>
            <AcInput
              header="Description"
              name="description"
              variant="outlined"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && Boolean(errors.description)}
              tooltip={'Add your description for this offer'}
              helperText={
                touched.description ? errors.description?.toString() : ''
              }
              dataTestValidationId="popupDescriptionValidationTxt"
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="External ID"
              name="publisherOfferId"
              required
              value={values.publisherOfferId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                Boolean(errors.publisherOfferId) &&
                (touched.publisherOfferId || dup)
              }
              helperText={
                touched.publisherOfferId || dup
                  ? errors.publisherOfferId?.toString()
                  : ''
              }
              dataTestValidationId="popupSkuValidationTxt"
            />
          </Grid>
          <Grid item xs={6}>
            <AcSelect
              header="Offer design"
              name="offerUiId"
              required
              value={values.offerUiId}
              onChange={(e) => {
                setCurrentOfferUI(e.target.value as string);
                handleChange(e);
              }}
              onBlur={handleBlur}
              items={getOffersUI.data?.result
                .filter(
                  (oi: OfferUI) => oi.offerUiType === OfferDesignTypes.PopUp
                )
                .map((offerUI: OfferUI) => {
                  return {
                    content: offerUI.name,
                    key: offerUI._id,
                    value: offerUI._id
                  };
                })}
              error={touched.offerUiId && Boolean(errors.offerUiId)}
              helperText={touched.offerUiId ? errors.offerUiId?.toString() : ''}
              dataTestValidationId="popupDesignValidationTxt"
              dataTestListId="dashPopupDesignList"
              dataTestBoxId="popupDesignBox"
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="Priority"
              name="priority"
              required
              tooltip="Priority decides which popups show first, based on their importance. The lower the number, the higher the priority."
              value={values.priority}
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              error={touched.priority && Boolean(errors.priority)}
              helperText={touched.priority ? errors.priority?.toString() : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <AcSelect
              header="Type"
              name="subType"
              value={currentSubType || values.subType}
              onChange={(e) => {
                const newSubType: any = e.target.value;
                setFieldValue('subType', newSubType);
                setCurrentSubType(newSubType);
                if (newSubType === EPopupSubType.DAILY_BONUS) {
                  setFieldValue('price', 0);
                  setPriceInputValue(0);
                  setFieldValue('setAsFree', false);
                } else {
                  setFieldValue('price', 0.8);
                  setPriceInputValue(0.8);
                  setFieldValue('setAsFree', false);
                }
              }}
              onBlur={handleBlur}
              items={
                hasFeatureFlagPostPurchase
                  ? [
                      {
                        content: 'Bonus',
                        key: EPopupSubType.DAILY_BONUS,
                        value: EPopupSubType.DAILY_BONUS
                      },
                      {
                        content: 'Post Purchase',
                        key: EPopupSubType.POST_PURCHASE,
                        value: EPopupSubType.POST_PURCHASE
                      }
                    ]
                  : [
                      {
                        content: 'Bonus',
                        key: EPopupSubType.DAILY_BONUS,
                        value: EPopupSubType.DAILY_BONUS
                      }
                    ]
              }
              error={touched.subType && Boolean(errors.subType)}
              helperText={touched.subType ? errors.subType?.toString() : ''}
              dataTestValidationId="popupSubTypeValidationTxt"
              dataTestListId="dashPopupSubTypeList"
              dataTestBoxId="popupSubTypeBox"
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="Price"
              name="price"
              value={priceInputValue === 0 ? 'Free' : priceInputValue}
              onChange={handlePriceChange}
              onBlur={handleBlur}
              type={
                values.subType === EPopupSubType.POST_PURCHASE &&
                !values.setAsFree
                  ? 'number'
                  : 'text'
              }
              disabled={
                values.subType === EPopupSubType.DAILY_BONUS || values.setAsFree
              }
              slotProps={{
                htmlInput: {
                  min:
                    currentSubType === EPopupSubType.POST_PURCHASE &&
                    !values.setAsFree
                      ? MIN_USD_PRICE
                      : 0,
                  readOnly: !(
                    currentSubType === EPopupSubType.POST_PURCHASE &&
                    !values.setAsFree
                  )
                },
                input: {
                  startAdornment:
                    values.subType === EPopupSubType.POST_PURCHASE &&
                    !values.setAsFree ? (
                      <InputAdornment position="start">$</InputAdornment>
                    ) : undefined
                }
              }}
              error={touched.price && Boolean(errors.price)}
              helperText={touched.price ? errors.price?.toString() : ''}
            />
            {values.subType === EPopupSubType.POST_PURCHASE && (
              <FormControlLabel
                control={
                  <Checkbox
                    name="setAsFree"
                    checked={values.setAsFree}
                    onBlur={handleBlur}
                    onChange={async (e) => {
                      const isChecked = e.target.checked;
                      await setFieldValue('setAsFree', isChecked);
                      await setFieldValue('price', isChecked ? 0 : 0.8);
                      setPriceInputValue(isChecked ? 0 : 0.8);
                    }}
                  />
                }
                label="Set as Free"
              />
            )}
          </Grid>
        </Grid>
      </AcCard>
      <Divider sx={{ width: '100%' }} />
      <AcCard stackContainer={false} title="Segments">
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={12} item>
            <AcAutoComplete
              ref={inputRef}
              value={inputValue}
              onEnter={(val) => {
                setChosenSegment((prev: string[]) => {
                  const newSegments = [...prev, val];
                  setFieldValue('segments', newSegments);
                  return newSegments;
                });
                setInputValue('');
                inputRef?.current?.blur();
              }}
              onChangeEvent={(val) => {
                if (chosenSegment.includes(val)) {
                  setSegmentError("You can't add the same segment twice");
                } else {
                  setSegmentError('');
                }
              }}
              options={
                (!getSegments?.isLoading &&
                  getSegments?.data.filter(
                    (s: string) => !chosenSegment.includes(s)
                  )) ||
                []
              }
              additionalOptions={chosenSegment}
              startAdornment={
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              }
              onChange={(e) => {
                const selected = (e.target as HTMLElement).textContent;
                if (selected) {
                  setChosenSegment((prev: string[]) => {
                    const newSegments = [...prev, selected];
                    setFieldValue('segments', newSegments);
                    return newSegments;
                  });
                }
              }}
              helperText={'Press ‘Enter’ to add a new segment'}
              error={segmentError}
            />
            {chosenSegment && (
              <Stack
                marginTop={1.5}
                gap={1}
                direction="row"
                flexWrap="wrap"
                data-testid="popupSegmentsList"
              >
                {chosenSegment.map((val: any) => (
                  <Chip
                    className="segment-chip"
                    key={val}
                    onClick={() => {
                      setChosenSegment((oldSegments: any) => {
                        const newSegments = oldSegments.filter(
                          (existingVal: string) => existingVal !== val
                        );
                        setFieldValue('segments', newSegments);
                        return newSegments;
                      });
                    }}
                    label={
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={0.5}
                      >
                        <span>{val}</span>
                        <CloseIcon sx={{ width: 14, height: 14 }} />
                      </Stack>
                    }
                  />
                ))}
              </Stack>
            )}
          </Grid>
        </Grid>
      </AcCard>
      <Divider sx={{ width: '100%' }} />
      {hasFeatureFlagTags && (
        <AcCard stackContainer={false} title="Tags">
          <Grid
            container
            rowSpacing={4}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12} item>
              <AcAutoComplete
                ref={inputRef}
                value={inputValue}
                onEnter={(val) => {
                  setChosenTags((prev: string[]) => {
                    const newTags = [...prev, val];
                    setFieldValue('tags', newTags);
                    return newTags;
                  });
                  setInputValue('');
                  inputRef?.current?.blur();
                }}
                onChangeEvent={(val) => {
                  if (chosenTags.includes(val)) {
                    setTagsError("You can't add the same tag twice");
                  } else {
                    setTagsError('');
                  }
                }}
                options={
                  (!getTags?.isLoading &&
                    getTags?.data?.filter(
                      (s: string) => !chosenTags.includes(s)
                    )) ||
                  []
                }
                additionalOptions={chosenTags}
                startAdornment={
                  <InputAdornment position="start">
                    <GridSearchIcon />
                  </InputAdornment>
                }
                onChange={(e) => {
                  const selected = (e.target as HTMLElement).textContent;
                  if (selected) {
                    setChosenTags((prev: string[]) => {
                      const newTags = [...prev, selected];
                      setFieldValue('tags', newTags);
                      return newTags;
                    });
                  }
                }}
                helperText={'Press ‘Enter’ to add a new tag'}
                error={tagsError}
              />
              {chosenTags && (
                <Stack marginTop={1.5} gap={1} direction="row" flexWrap="wrap">
                  {chosenTags.map((val: any) => (
                    <Chip
                      className="tag-chip"
                      key={val}
                      onClick={() => {
                        setChosenTags((oldTags: any) => {
                          const newTags = oldTags.filter(
                            (existingVal: string) => existingVal !== val
                          );
                          setFieldValue('tags', newTags);
                          return newTags;
                        });
                      }}
                      label={
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          gap={0.5}
                        >
                          <span>{val}</span>
                          <CloseIcon sx={{ width: 14, height: 14 }} />
                        </Stack>
                      }
                    />
                  ))}
                </Stack>
              )}
            </Grid>
          </Grid>
        </AcCard>
      )}
      {values.price > 0 && (
        <AcCard stackContainer={false} title="Sale & Discounts">
          <Grid container columnSpacing={1.5}>
            <Grid item xs={6}>
              <AcInput
                header="Price Discount"
                name="discount"
                value={values.discount}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                slotProps={{
                  htmlInput: {
                    min: 0,
                    step: 0.1
                  },
                  input: {
                    startAdornment:
                      values?.priceDiscountDisplayType ===
                      ESaleDiscountDisplayType.PERCENTAGE ? (
                        <InputAdornment position="start">%</InputAdornment>
                      ) : undefined
                  }
                }}
                error={touched.discount && Boolean(errors.discount)}
                helperText={touched.discount ? errors.discount?.toString() : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <DisplayTypeSelect
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values?.priceDiscountDisplayType}
                disabled={true}
              />
            </Grid>
          </Grid>
        </AcCard>
      )}
    </Stack>
  );
};

export default SettingsTab;
