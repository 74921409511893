import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';

export default function useShortcutsItems(isUtc: boolean) {
  const getDate = () => (isUtc ? dayjs().utc() : dayjs());

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: 'Today',
      getValue: () => {
        const today = getDate().startOf('day');
        const endOfDay = getDate().endOf('day');
        return [today, endOfDay];
      }
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = getDate();
        return [today.subtract(7, 'days'), today];
      }
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        const today = getDate();
        return [today.subtract(30, 'days'), today];
      }
    },
    { label: 'Reset', getValue: () => [null, null] }
  ];

  return { shortcutsItems };
}
