import React, { useMemo } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';
import ActionButton from 'components/ActionButton/ActionButton';
import DataTable from 'components/DataTable/DataTable';
import { Product } from 'components/ProductsForm/ProductsForm.types';

const DailyBonusProducts = ({
  values,
  handleChange,
  handleBlur,
  currentProductId,
  setCurrentProductId,
  handleQuantityChange,
  productQuantity,
  isProductSequenceEditMode,
  updateProductSequence,
  createNewProductSequence,
  resetEditState,
  productSequence,
  flattenedSequence,
  handleSectionsOrderChange,
  handleRowMouseEnter,
  isLoadingProducts,
  productsData,
  editProduct,
  duplicateProductSequence,
  removeProductSequence
}: any) => {
  const getProductOptions = useMemo(() => {
    if (isLoadingProducts || !productsData?.result) return [];
    return productsData.result.map((product: Product) => ({
      url: product.images?.[0]?.url,
      name: product.name,
      key: product.name,
      value: product.productId
    }));
  }, [productsData?.result, isLoadingProducts]);

  return (
    <AcCard stackContainer={false} title="Products">
      <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Tooltip
            arrow
            title={
              'After the last product was collected, the products list will start over from the first product at the first day.'
            }
            placement="top"
          >
            <FormControlLabel
              label="Start over"
              style={{
                marginLeft: '-2px'
              }}
              control={
                <Checkbox
                  name="startOver"
                  style={{
                    padding: '0'
                  }}
                  checked={values.startOver}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  data-testid="dashPopupStartOver"
                />
              }
              componentsProps={{
                typography: {
                  fontSize: 14,
                  sx: {
                    marginLeft: '2px'
                  },
                  color: '#717188'
                }
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <AcSelect
            header="Name"
            name="name"
            value={currentProductId}
            onChange={(e) => setCurrentProductId(e.target?.value || '')}
            onBlur={handleBlur}
            renderType={EAcSelectItemRenderType.IMAGE}
            items={getProductOptions}
            dataTestValidationId="popupProductNameValidationTxt"
            dataTestListId="dashPopupProductsList"
            dataTestBoxId="popupProductsBox"
          />
        </Grid>

        <Grid item xs={6}>
          <AcInput
            header="Quantity"
            name=""
            value={productQuantity}
            onChange={handleQuantityChange}
            type="number"
            slotProps={{
              htmlInput: { min: 1 }
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Stack gap={2} direction={'row'} alignItems={'center'}>
            <ActionButton
              text={isProductSequenceEditMode ? 'Update' : 'Add'}
              style={{ padding: '8px 16px' }}
              variant="outlined"
              onClick={
                isProductSequenceEditMode
                  ? updateProductSequence
                  : createNewProductSequence
              }
              disabled={
                (isProductSequenceEditMode
                  ? false
                  : productSequence.length === 100) ||
                parseInt(productQuantity.toString()) <= 0 ||
                !currentProductId
              }
            />
            {isProductSequenceEditMode && (
              <ActionButton
                text={'Cancel'}
                style={{ padding: '8px 16px' }}
                variant="contained"
                onClick={resetEditState}
              />
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} mt={-3}>
          <DataTable
            allowEmptyRows
            className="products-table"
            columns={[
              {
                field: 'name',
                headerName: 'Product',
                flex: 1,
                renderCell: (params) => {
                  return (
                    <>
                      <img
                        style={{
                          maxHeight: '50%',
                          objectFit: 'contain',
                          marginRight: '5px'
                        }}
                        src={params.row.image}
                        alt="productImage"
                      />
                      <span
                        className="ellipsis"
                        data-testid={`popupProduct${params.row.sequenceIndex}`}
                      >
                        {params.row.name}
                      </span>
                    </>
                  );
                }
              },
              {
                field: 'amount',
                headerName: 'Amount',
                flex: 1
              },
              {
                field: 'sequenceIndex',
                headerName: 'Day',
                flex: 0.5
              },
              {
                field: 'actions',
                type: 'actions',
                width: 80,
                align: 'right',
                getActions: (params) => [
                  <GridActionsCellItem
                    icon={<EditTwoToneIcon />}
                    label="Edit"
                    onClick={() => editProduct(params.row._id)}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    icon={<ContentCopyOutlinedIcon />}
                    label="Duplicate"
                    onClick={() => duplicateProductSequence(params.row._id)}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    className="danger"
                    icon={<DeleteOutlineTwoToneIcon className="danger" />}
                    label="Delete"
                    onClick={() => removeProductSequence(params.row._id)}
                    showInMenu
                  />
                ],
                disableReorder: true
              }
            ]}
            rows={flattenedSequence}
            rowReordering={true}
            onRowOrderChange={(params) =>
              handleSectionsOrderChange(params.oldIndex, params.targetIndex)
            }
            onRowMouseEnter={(rowId) => handleRowMouseEnter(rowId)}
            loading={false}
            headerHeight={productSequence.length > 0 ? 56 : 0}
          />
        </Grid>
      </Grid>
    </AcCard>
  );
};

export default DailyBonusProducts;
