import { CloseOutlined, ViewListOutlined } from '@mui/icons-material';
import { CircularProgress, Stack, Typography } from '@mui/material';

import { EToasterStatus, showToast } from '../../utils/showToast';

import {
  IMPORT_CSV_FILE_DESCRIPTION,
  IMPORT_CSV_FILE_MESSAGE
} from './constants/constants';
import { ImportCSVContentProps } from './types';

import './style.scss';

const ImportCSVContent = ({
  isUploading,
  fileRef,
  setFieldValue
}: ImportCSVContentProps) => {
  const handleFileChange = (event: any) => {
    const file = event.currentTarget.files[0];
    if (file.type !== 'text/csv') {
      showToast({
        message: IMPORT_CSV_FILE_MESSAGE,
        status: EToasterStatus.ERROR
      });
      return;
    }
    setFieldValue('file', file);
  };

  const calculateFileSizeDisplay = (sizeInBytes: number) => {
    const sizeInKB = sizeInBytes / 1024;
    if (sizeInKB < 1024) {
      return sizeInKB.toFixed(2) + ' KB';
    } else {
      const sizeInMB = sizeInKB / 1024;
      return sizeInMB.toFixed(2) + ' MB';
    }
  };

  return (
    <div style={{ fontFamily: `'Montserrat', sans-serif` }}>
      <div>
        <input
          style={{ display: 'none' }}
          type="file"
          ref={fileRef}
          accept=".csv"
          onChange={handleFileChange}
        />
        <Stack
          height="144px"
          bgcolor="rgba(202, 203, 212, 0.2)"
          border="1px solid rgba(202, 203, 212, 1)"
          justifyContent="center"
          alignItems="center"
          borderRadius={1}
          gap={1}
          onClick={() => {
            fileRef?.current?.click();
          }}
          sx={{
            cursor: isUploading ? 'unset' : 'pointer'
          }}
        >
          {isUploading ? (
            <CircularProgress />
          ) : !!fileRef?.current?.files?.length ? (
            <>
              <div
                style={{
                  width: '64px',
                  height: '64px',
                  background: '#717188',
                  position: 'relative'
                }}
              >
                <CloseOutlined
                  className="close-icon"
                  style={{
                    width: '16px',
                    height: '16px'
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (fileRef.current) {
                      fileRef.current.value = '';
                    }
                    setFieldValue('file', null);
                  }}
                />
              </div>
              <div className="file-name">{fileRef?.current?.files[0].name}</div>
              <div className="file-size">
                {calculateFileSizeDisplay(fileRef.current.files[0].size)}
              </div>
            </>
          ) : (
            <>
              <ViewListOutlined
                sx={{
                  fontSize: '64px',
                  color: 'rgba(168, 169, 178, 1)'
                }}
              />
              <Typography
                fontFamily="'Montserrat', sans-serif"
                variant="body1"
                sx={{
                  color: 'rgb(67, 56, 202)',
                  textDecoration: 'underline'
                }}
                color="textSecondary"
              >
                {IMPORT_CSV_FILE_DESCRIPTION}
              </Typography>
            </>
          )}
        </Stack>
      </div>
    </div>
  );
};
export default ImportCSVContent;
