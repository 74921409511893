import { Route, Routes } from 'react-router-dom';
import ContentForm from './ContentForm/ContentForm';
import ContentTable from './ContentTable/ContentTable';

const ContentView = () => {
  return (
    <Routes>
      <Route path="/" element={<ContentTable />} />
      <Route path="/form" element={<ContentForm />} />
      <Route path="/form/:contentId" element={<ContentForm />} />
      <Route path="/dup/:contentId" element={<ContentForm dup={true} />} />
    </Routes>
  );
};

export default ContentView;
