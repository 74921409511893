import React, { FC, ReactNode } from 'react';

import { cn } from '../../../components/lib/utils';
import { UISidebarMenuButton } from '../../../components/ui';

type SidebarMenuButtonProps = React.ComponentProps<
  typeof UISidebarMenuButton
> & {
  children?: ReactNode;
  className?: string;
  isDisabled?: boolean;
};

const SidebarMenuButton: FC<SidebarMenuButtonProps> = ({
  children,
  className,
  isDisabled = false,
  ...rest
}) => (
  <UISidebarMenuButton
    className={cn(
      'text-base-sidebarForeground text-p-s flex items-center hover:bg-indigo-50 hover:text-base-sidebarAccentForeground data-[active=true]:font-medium data-[active=true]:text-indigo-700',
      isDisabled && 'cursor-not-allowed opacity-50',
      className
    )}
    {...rest}
  >
    {children}
  </UISidebarMenuButton>
);

export default SidebarMenuButton;
