import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import useOffers from 'api/useOffers';
import {
  ExtendedDateRange,
  FormikProps,
  ScheduleTimeFrame
} from 'common/contracts';
import { EScheduleType, OfferType } from 'constants/enums';
import { Dayjs } from 'dayjs';
import ScheduledOffer from 'views/Promotions/Tabs/ScheduleOptions/ScheduledOffer';

import AcSwitchSelector from 'components/AcSwitchSelector/AcSwitchSelector';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import { EToasterStatus, showToast } from '../../../../utils/showToast';

interface ScheduleTabProps {
  formikProps: FormikProps;
  rollingOfferId?: string;
  edit: boolean;
}

const ScheduleTab: React.FC<ScheduleTabProps> = ({
  formikProps,
  rollingOfferId,
  edit
}) => {
  const { values, setFieldValue } = formikProps;

  const { updateOffer } = useOffers({
    offerId: rollingOfferId,
    offerType: OfferType.ROLLING_OFFER
  });

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [appearancesTableData, setAppearancesTableData] = useState<
    ScheduleTimeFrame[]
  >([]);
  const [editRangeDatesValues, setEditRangeDatesValues] =
    useState<ExtendedDateRange | null>(null);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const timeFramesData: ScheduleTimeFrame[] =
      values.schedule?.timeFrames?.map(
        (date: ScheduleTimeFrame, index: number) => ({
          id: date.id || `id-${index}`,
          startTime: date.startTime,
          endTime: date.endTime,
          notes: date.notes
        })
      );

    setAppearancesTableData(timeFramesData);
  }, [values?.schedule?.timeFrames]);

  const resetScheduleValues = () => {
    setDateRange([null, null]);
    setEditRangeDatesValues(null);
    setNotes('');
  };

  const updateOfferStatus = async (active: boolean) => {
    if (edit) {
      try {
        await updateOffer.mutateAsync({
          offerId: String(rollingOfferId),
          form: { active: active }
        });
        setFieldValue('active', active);
      } catch (error) {
        showToast({
          message: 'Error editing rolling offer',
          status: EToasterStatus.ERROR
        });
      }
    } else setFieldValue('active', active);
  };

  const switchOptions = [
    { value: EScheduleType.PERMANENT },
    { value: EScheduleType.SCHEDULED }
  ];

  const onClickSwitch = (value: string) => {
    const isPermanent = value === EScheduleType.PERMANENT;
    setFieldValue('schedule', {
      timeFrames: values?.schedule.timeFrames,
      permanent: isPermanent
    });
    resetScheduleValues();
  };

  return (
    <>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <AcSwitchSelector
            options={switchOptions}
            onClick={onClickSwitch}
            value={
              values?.schedule?.permanent
                ? EScheduleType.PERMANENT
                : EScheduleType.SCHEDULED
            }
          />
          <CustomizedSwitch
            status={values.active}
            texts={['Is Active', 'Is Active']}
            functions={[
              () => updateOfferStatus(false),
              () => updateOfferStatus(true)
            ]}
            color={'#000000'}
            dataTestId="rollingOfferActiveSwitchBtn"
          />
        </Stack>
        {!values?.schedule?.permanent && (
          <ScheduledOffer
            formikProps={formikProps}
            editRangeDatesValues={editRangeDatesValues}
            setEditRangeDatesValues={setEditRangeDatesValues}
            appearancesTableData={appearancesTableData}
            notes={notes}
            setNotes={setNotes}
            dateRange={dateRange}
            setDateRange={setDateRange}
            resetScheduleValues={resetScheduleValues}
          />
        )}
      </Stack>
    </>
  );
};

export default ScheduleTab;
