import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import {
  BundlesLayout,
  EBundlesViewModel,
  ELayout
} from '@appcharge/shared-ui';
import useTheme from 'api/useTheme';

import { EBundlesInternalViewModel } from '../../../../constants/enums';
import { mapBundleComponents } from '../../../../utils/mapBundleComponent';
import { StoreThemeFormValues } from '../storeTheme.types';

export const StoreThemeBundles: React.FC<{
  bundleBorderColor: StoreThemeFormValues['bundleBorderColor'];
  buttonTextColor: StoreThemeFormValues['buttonTextColor'];
  buttonColor: StoreThemeFormValues['buttonColor'];
}> = ({ bundleBorderColor, buttonTextColor, buttonColor }) => {
  const { publisherId } = useParams();

  const { getGeneralTheme: generalTheme } = useTheme(publisherId);

  const bundlesViewModel = generalTheme.data?.bundlesViewModel as ELayout;
  const BundlesInternalLayout =
    mapBundleComponents[
      generalTheme.data?.bundlesInternalViewModel as EBundlesInternalViewModel
    ];

  const bundlesAmount = useMemo(() => {
    const viewModel = generalTheme?.data?.bundlesViewModel;
    if (viewModel === EBundlesViewModel.TWO_IN_ONE) return 2;
    if (viewModel === EBundlesViewModel.THREE_IN_ONE) return 3;
    if (viewModel === EBundlesViewModel.SIX_IN_ONE) return 6;
    return 1;
  }, [generalTheme?.data?.bundlesViewModel]);

  const isLoading = useMemo(
    () => generalTheme.isLoading || !generalTheme.data,
    [generalTheme]
  );

  if (isLoading) return <></>;

  return (
    <BundlesLayout bundlesViewModel={bundlesViewModel} isPreview={true}>
      {Array.from(
        {
          length: bundlesAmount
        },
        () => null
      ).map((i, index) => (
        <div className="bundle" key={index}>
          <BundlesInternalLayout
            bundleBorderColor={bundleBorderColor}
            productList={[]}
            bundleButtonColor={buttonColor}
            backgroundSrc={
              generalTheme?.data?.bundlesInternalViewModel !==
              EBundlesInternalViewModel.PLAY_SIMPLE
                ? 'https://media.appcharge.com/defaults/background.png'
                : ''
            }
            bundleButtonTextColor={buttonTextColor}
            fontFamily={generalTheme?.data?.font}
            prices={{ priceDisplay: '$0.00', priceInUsdCents: 1 }}
            inMobileMock={true}
          />
        </div>
      ))}
    </BundlesLayout>
  );
};
