import { EEncryptionType } from './IntegrationSettings/IntegrationSettings';

export enum EPlayersAuthenticationModel {
  PUBLISHER = 'publisher',
  APPCHARGE = 'appcharge'
}

export enum EPublisherEventValue {
  ORDER_CREATED = 'order_created',
  PAYMENT_INTENT_SUCCESS = 'payment_intent_success',
  PAYMENT_INTENT_FAILED = 'payment_intent_failed',
  ORDER_COMPLETED_SUCCESS = 'order_completed_success',
  ORDER_COMPLETED_FAILED = 'order_completed_failed',
  ORDER_CANCELED = 'order_canceled',
  ORDER_REFUNDED = 'order_refunded',
  ORDER_DISPUTE_OPEN = 'order_dispute_open',
  ORDER_DISPUTE_LOST = 'order_dispute_lost',
  ORDER_DISPUTE_WON = 'order_dispute_won',
  OFFERS_SHOWN = 'offers_shown',
  OFFERS_LOADED = 'offers_loaded',
  LOGIN_LAND = 'login_land',
  LOGIN_CLICKED = 'login_clicked',
  LOGIN_SCREEN_PRESENTED = 'login_screen_presented',
  LOGIN_OTP_IOS_BUTTON = 'login_otp_ios_button',
  LOGIN_OTP_ANDROID_BUTTON = 'login_otp_android_button',
  LOGIN_CANCELED = 'login_canceled',
  LOGIN_APPROVAL = 'login_approval',
  LOGIN_RESULT = 'login_result',
  INVALID_PROPERTY_ERROR = 'invalid_property_error',
  POPUP_APPROVAL_CLOSED = 'popup_approval_closed',
  POPUP_APPROVAL_PRESENTED = 'popup_approval_presented'
}

export interface ProfileData {
  _id: string;
  name: string;
  ordersReportingApiUrl: string;
  playersAuthWebhook: string;
  playerInfoSyncUrl?: string;
  eventsWebhookUrl?: string;
  fbAppId?: string;
  otpDeepLink?: {
    otpGenerateDeeplinkUrl?: string;
    otpOn?: boolean;
    otpTextModel?: string;
  };
}

export interface IntegrationSettingsData {
  _id: string;
  apiUrlPrimaryKey: string;
  apiUrlSecondaryKey: string;
  checkoutPublicKey: string;
  backToGameDeepLink: string;
  ordersReportingApiUrl: string;
  playersAuthModel: string;
  publisherToken: string;
  deeplinkJwtSecret?: string;
  playerInfoSyncUrl: string;
  playersAuthWebhook: string;
  publisherId: string;
  usernamePasswordOn: boolean;
  facebookModel: EPlayersAuthenticationModel;
  usernamePasswordModel: EPlayersAuthenticationModel;
  deepLinkButtonOn: boolean;
  backToStoreButtonOn: boolean;
  encryptionModel: EEncryptionType;
  eventsWebhookUrl: string;
  enabledWebhookEvents: string[];
  playersAuthentication: Partial<{
    appleModel: EPlayersAuthenticationModel;
    appleAppId: string;
    appleOn: boolean;
    appleAppSecret: string;
    googleAppSecret: string;
    googleAppId: string;
    googleModel: EPlayersAuthenticationModel;
    googleOn: boolean;
    fbAppSecret: string;
    fbAppId: string;
    fbModel: string;
    fbOn: boolean;
    userTokenOn: boolean;
    userTokenModel: string;
    usernamePasswordOn: boolean;
    usernamePasswordModel: string;
    otpOn: boolean;
    otpTextModel: string;
  }>;
  deepLinks: any;
  otpGenerateDeeplinkUrl: string;
  appsFlyerIntegration: {
    appsFlyerIntegrationOn: boolean;
    appsFlyerApplicationID: string;
    appsFlyerS2SToken: string;
  },
  isProfilesEnabled?: boolean;
}

export interface PublisherEventType {
  value?: EPublisherEventValue;
  displayName?: string;
  subCategory?: string;
}

export enum ESettingsState {
  COMPANY = 'company',
  USERS = 'users',
  INTEGRATION = 'integration',
  PROFILES = 'profiles',
  PLAYER_AUTH = 'playerAuth',
  PAYMENTS = 'payments',
  ENCRYPTION = 'encryption',
  LAYOUT = 'layout',
  EVENTS = 'events',
  STORE_SETTINGS = 'storeSettings'
}

export enum ProfileField {
  ORDER_REPORTING_API = 'Order Reporting API',
  AUTHENTICATION_WEBHOOK = 'Authentication Webhook',
  PERSONALIZATION_API_URL = 'Personalization API URL',
  EVENTS_WEBHOOK = 'Events Webhook',
  FACEBOOK_ID = 'Facebook ID',
  OTP_GENERATION_DEEPLINK_URL = 'OTP Generation Deeplink Url',
  DESKTOP_TEXT_MODEL = 'Desktop Text Model'
}

export interface ProfileFormValues {
  name: string;
  playerInfoSyncUrl: string;
  playersAuthWebhook: string;
  ordersReportingApiUrl: string;
  eventsWebhookUrl: string;
  fbAppId: string;
  otpDeepLink: {
    otpGenerateDeeplinkUrl: string;
    otpOn: boolean;
    otpTextModel: string;
  };
}
