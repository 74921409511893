import { useState } from 'react';

import { Events } from 'constants/enums';

import { PublisherEventType } from 'components/Settings/Settings.types';

import { Offer } from '../common/contracts';
import { Product } from '../components/ProductsForm/ProductsForm.types';

export enum PublisherIdType {
  OFFER_ID = 'publisherOfferId',
  PRODUCT_ID = 'publisherProductId',
  DISPLAY_NAME = 'displayName',
  CATEGORY = 'category'
}

export enum SearchKeyType {
  NAME = 'name',
  DISPLAY_NAME = 'displayName'
}

export type IDataType = Product | Offer | PublisherEventType;

export type IUseTableSearchFiltersProps = {
  data: IDataType[];
  publisherId: PublisherIdType;
  searchKey: SearchKeyType;
  additionalPromotionFilter?: (item: IDataType) => boolean;
};

export const useTableSearch = ({
  data,
  publisherId,
  searchKey,
  additionalPromotionFilter
}: IUseTableSearchFiltersProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const searchByValue = (item: IDataType): boolean => {
    return (item as any)[searchKey]
      .toLowerCase()
      .includes(searchValue.toLowerCase());
  };

  const filterBySku = (item: IDataType): boolean => {
    return (item as any)[publisherId]
      ?.toLowerCase()
      .includes(filterValue.toLowerCase());
  };

  const filterByCategory = (item: IDataType): boolean => {
    const category = Object.keys(Events).find((category) =>
      Events[category as keyof typeof Events]?.includes((item as any).value)
    );

    return category
      ? category.toLowerCase().includes(filterValue.toLowerCase())
      : filterValue === '' || 'new events'.includes(filterValue.toLowerCase());
  };

  const filteredData = (): IDataType[] => {
    if (data && data.length > 0) {
      return data.filter((item) => {
        if (additionalPromotionFilter && !additionalPromotionFilter(item)) {
          return false;
        }
        if (publisherId === PublisherIdType.CATEGORY)
          return searchByValue(item) && filterByCategory(item);

        return searchByValue(item) && filterBySku(item);
      });
    }

    return [];
  };

  const isTableDataEmpty = filteredData.length === 0 && searchValue.length === 0 && filterValue.length === 0;

  return { searchValue, filterValue, setSearchValue, setFilterValue, filteredData, isTableDataEmpty };
};
