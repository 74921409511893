import * as z from 'zod';
import { Tab } from '../../Tabs/Tabs';

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/svg'];

export enum EImageUploadModalTabs {
    UPLOAD = 'upload',
    MEDIA = 'media'
}

export const IMAGE_UPLOAD_TABS: Tab[] = [
    { id: EImageUploadModalTabs.UPLOAD, value: 'Upload' },
    { id: EImageUploadModalTabs.MEDIA, value: 'Media' }
];

export const IMG_UPLOAD_FORM_SCHEMA = z.object({
    idNumber: z.string().min(1, { message: 'ID Number is required' }),
    image: z
        .union([z.instanceof(File), z.null()])
        .refine((file) => file === null || ACCEPTED_IMAGE_TYPES.includes(file.type), {
            message: 'Unsupported file format. Only JPEG, PNG, SVG and GIF are allowed.',
        }),
});
