import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { FilterCardProps } from 'common/contracts';

import Button from 'components/Button/Button';
import Input from 'components/Input/Input';

const FilterSearchCard: React.FC<FilterCardProps> = ({
  header,
  filterValue,
  setFilterValue
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const filterCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSearchValue(filterValue || '');
  }, [filterValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterCardRef.current &&
        !filterCardRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleApply = () => {
    setFilterValue(searchValue);
    setIsOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setFilterValue('');
    setSearchValue('');
  };

  return (
    <div className="relative" ref={filterCardRef}>
      <div
        className="flex items-center gap-2 border border-dashed border-gray-300 rounded-md px-3 py-2 cursor-pointer bg-white focus-within:border-gray-400"
        onClick={() => setIsOpen(!isOpen)}
      >
        {filterValue ? (
          <FontAwesomeIcon
            icon="fa-regular fa-circle-xmark"
            onClick={handleClear}
          />
        ) : (
          <FontAwesomeIcon icon="fa-regular fa-plus-circle" />
        )}
        <div className="text-gray-700 text-sm truncate leading-[1.6rem]">
          <span className="font-medium">Filter By {header}</span>
          {filterValue && (
            <>
              <span className="mx-1 text-gray-300">|</span>
              <Button variant={'secondary'} className="h-6 font-normal">
                {filterValue}
              </Button>
            </>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="absolute w-full bg-white border border-gray-300 rounded-md shadow-md mt-1 z-10 p-3">
          <div className="text-sm font-semibold text-gray-600 mb-2">
            Filter By {header}
          </div>
          <Input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            className="w-full"
            rightIcon={
              <FontAwesomeIcon icon="fa-regular fa-magnifying-glass" />
            }
            rightIconStyles="absolute"
          />
          <Button className="mt-2 w-full" onClick={handleApply}>
            Apply
          </Button>
        </div>
      )}
    </div>
  );
};

export default FilterSearchCard;
