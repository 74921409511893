import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { UILabel, UITextarea } from 'components/ui';

import { cn } from '../lib/utils';

type TextareaProps = React.ComponentProps<typeof UITextarea> & {
  label?: string;
  rows?: number;
  isRequired?: boolean;
  className?: string;
};

const Textarea: FC<TextareaProps> = ({
  label,
  className,
  rows = 4,
  isRequired = false,
  ...rest
}) => {
  return (
    <>
      <UILabel
        htmlFor={'uiInput'}
        className={cn('text-base-foreground mb-1.5')}
      >
        {isRequired && (
          <div className="mr-1 mt-[-2px]">
            <FontAwesomeIcon
              icon="fa-regular fa-asterisk"
              className="h-2 w-2 text-[8px]"
            />
          </div>
        )}
        {label}
      </UILabel>
      <UITextarea
        rows={rows}
        className={cn(
          'shadow-none focus-visible:ring-base-foreground text-base-foreground mt-0 placeholder:text-base-mutedForeground',
          className
        )}
        {...rest}
      />
    </>
  );
};

export default Textarea;
