import { Route, Routes, useParams } from 'react-router-dom';

import useUsers from '../../api/useUsers';
import { EFeatureFlag } from '../../constants/enums';

import RollingOfferForm from './Form/RollingOfferForm';
import RollingOffersTable from './RollingOffersTable';

const RollingOffersView = () => {
  const { publisherId } = useParams();
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const featureFlags = fetchFeatureFlags.data?.featureFlags;

  return (
    <Routes>
      <Route
        path="/"
        element={
          !featureFlags?.[EFeatureFlag.DASHBOARD_ROLLING_OFFER] ? (
            <RollingOfferForm />
          ) : (
            <RollingOffersTable />
          )
        }
      />
      <Route path="/form" element={<RollingOfferForm />} />
      <Route
        path="/form/:rollingOfferId"
        element={<RollingOfferForm edit={true} />}
      />
      <Route
        path="/dup/:rollingOfferId"
        element={<RollingOfferForm edit={false} dup={true} />}
      />
    </Routes>
  );
};

export default RollingOffersView;
