import { FormikProps } from 'common/contracts';

import Input from 'components/Input/Input';
import RichText from 'components/RichText/RichText';

export interface PermanentOfferProps {
  formikProps: FormikProps;
}

const PermanentOffer: React.FC<PermanentOfferProps> = ({ formikProps }) => {
  const { values, handleChange, handleBlur } = formikProps;

  return (
    <div className="mt-8">
      <div className="grid gap-6">
        <Input
          label="Availability"
          name="playerAvailability"
          placeholder="01"
          value={values.playerAvailability || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          required={values?.setAsFree || values?.schedule?.interval}
          type="number"
          className="w-[200px]"
        />
        <RichText
          description="Permanent events won’t have an end date"
          className="mb-6"
        />
      </div>
    </div>
  );
};

export default PermanentOffer;
