import React, { FC, ReactNode } from 'react';

import { cn } from '../lib/utils';
import { UITabs, UITabsList, UITabsTrigger } from '../ui';

export type Tab = {
  id: string;
  value: string;
  isDisabled?: boolean;
};

type TabsListProps = React.ComponentProps<typeof UITabsList> & {
  tabs: Tab[];
  className?: string;
};

type TabsProps = React.ComponentProps<typeof UITabs> & {
  children: ReactNode;
  className?: string;
};

export const TabsList: FC<TabsListProps> = ({ tabs, className, ...rest }) => {
  return (
    <UITabsList
      {...rest}
      className={cn(
        `w-fit grid grid-cols-${tabs.length} min-w-[318px] mb-0 bg-base-muted h-10`,
        className
      )}
    >
      {tabs.map(({ id, value, isDisabled = false }, i) => (
        <UITabsTrigger
          disabled={isDisabled}
          key={i}
          value={id}
          className="text-p-s py-1.5 font-normal text-base-mutedForeground data-[state=active]:shadow-sm data-[state=active]:bg-base-background data-[state=active]:text-base-foreground"
        >
          {value}
        </UITabsTrigger>
      ))}
    </UITabsList>
  );
};

export const Tabs: FC<TabsProps> = ({ children, className, ...rest }) => {
  return (
    <UITabs {...rest} className={cn('w-full', className)}>
      {children}
    </UITabs>
  );
};
