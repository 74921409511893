'use client';

import React, { forwardRef, useCallback, useMemo, useState } from 'react';

import { HexColorPicker } from 'react-colorful';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { useForwardedRef } from '../../lib/use-forwarded-ref';
import { cn } from '../../lib/utils';
import Typography from '../../Typography/Typography';
import { Button, ButtonProps } from '../button';
import { UILabel } from '../index';
import { Input } from '../input';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';

import './styles.css';

interface ColorPickerProps {
  defaultValue?: string;
  label?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const ColorPicker = forwardRef<
  HTMLInputElement,
  Omit<ButtonProps, 'value' | 'onChange' | 'onBlur'> & ColorPickerProps
>(
  (
    {
      defaultValue = '#4338ca',
      disabled,
      onChange,
      onBlur,
      name,
      className,
      label,
      ...props
    },
    forwardedRef
  ) => {
    const ref = useForwardedRef(forwardedRef);
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState(defaultValue);

    const parsedValue = useMemo(() => {
      return (color || defaultValue).toUpperCase();
    }, [color, defaultValue]);

    const handleColorChange = useCallback(
      (newColor: string) => {
        setColor(newColor);
        onChange?.(newColor);
      },
      [onChange]
    );

    return (
      <div className="w-full h-fit">
        {label && (
          <UILabel
            htmlFor="select"
            className={cn(
              'block text-p-s font-medium text-base-foreground mb-2'
            )}
          >
            {label}
          </UILabel>
        )}
        <Popover onOpenChange={setOpen} open={open}>
          <PopoverTrigger asChild disabled={disabled} onBlur={onBlur}>
            <Button
              {...props}
              className={cn(
                'shadow-none h-10 w-full flex items-center justify-start p-3 rounded-md border-base-input',
                className
              )}
              name={name}
              onClick={() => {
                setOpen(true);
              }}
              size="default"
              variant="outline"
            >
              <div
                style={{ backgroundColor: parsedValue }}
                className="border h-3.5 w-3.5 rounded-full"
              />
              <Typography className="text-p-s text-base-mutedForeground">
                {parsedValue}
              </Typography>
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-down"
                className={cn(
                  'ml-auto text-[#404040] text-p-s transition-transform',
                  open && 'rotate-180'
                )}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[--radix-popover-trigger-width] bg-base-background border-none rounded-xl shadow-lg">
            <HexColorPicker
              className="hexColorPicker"
              color={parsedValue}
              onChange={handleColorChange}
            />
            <Input
              maxLength={7}
              onChange={(e) => handleColorChange(e.currentTarget.value)}
              ref={ref}
              value={parsedValue}
              className={cn(
                '!text-p-xs focus-visible:ring-base-foreground text-base-sidebarForeground rounded-md border-base-input shadow-none h-7 px-1.5 py-2'
              )}
            />
          </PopoverContent>
        </Popover>
      </div>
    );
  }
);
ColorPicker.displayName = 'ColorPicker';

export { ColorPicker };
