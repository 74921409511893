import { ChangeEvent, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import useOffers from 'api/useOffers';
import { useProduct } from 'api/useProduct';
import { type FormikProps, ProductsSequenceProduct } from 'common/contracts';
import { EAssetType, OfferType } from 'constants/enums';
import { transformQuantityValue } from 'utils/formattingUtil';

import AutoCompleteInput from 'components/AutoCompleteInput/AutoCompleteInput';
import Button from 'components/Button/Button';
import {
  type ImageObject,
  Product
} from 'components/ProductsForm/ProductsForm.types';
import ProductsTable from 'components/ProductsTable/ProductsTable';

import Input from '../../../components/Input/Input';
import RichText from '../../../components/RichText/RichText';
import { ProductProps } from '../CheckoutLinkForm';

export interface SettingsTabProps {
  formikProps: FormikProps;
  products: ProductProps[];
  setProducts: (products: ProductProps[]) => void;
  setDirtyProducts: (isDirty: boolean) => void;
  setCurrentProduct?: (product: ProductsSequenceProduct | null) => void;
}

const SettingsTab: React.FC<SettingsTabProps> = ({
  formikProps,
  products,
  setProducts,
  setDirtyProducts
}) => {
  const { handleChange, handleBlur, values, touched, errors } = formikProps;
  const { publisherId } = useParams();

  const [currentProduct, setCurrentProduct] =
    useState<ProductsSequenceProduct | null>(null);
  const { data: productsData, isLoading: isLoadingProducts } =
    useProduct(publisherId).getProducts;
  const productsList: Product[] = productsData?.result;
  const { formatProductQuantity } = useOffers({
    offerId: values.offerId,
    offerType: OfferType.CHECKOUT_LINK
  });
  const getProductsOptions = useMemo(() => {
    if (isLoadingProducts || !productsList) return [];
    const productIds = new Set(products.map((item) => item.productId));
    const filteredProducts = productsList.filter(
      (product: Product) => !productIds.has(product.productId as string)
    );
    if (filteredProducts.length === 0) return [];
    return filteredProducts.map((product) => ({
      url: product.images?.[0]?.url,
      name: product.name,
      key: product.name,
      value: product.productId
    }));
  }, [productsList, products, isLoadingProducts]);

  const selectedProduct =
    getProductsOptions.find(
      (product) => product.value === currentProduct?.productId
    ) || null;
  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newQuantity =
      e.target.value.length !== 0
        ? transformQuantityValue(e.target.value)
        : e.target.value;
    handleChange({
      target: {
        name: 'quantity',
        value: String(newQuantity)
      }
    });
    setCurrentProduct({
      productId: currentProduct?.productId || '',
      quantity: newQuantity
    });
  };

  const addProduct = async () => {
    setDirtyProducts(true);
    const foundProduct = productsData!.result.find((pd: Product) => {
      return pd.productId === currentProduct?.productId;
    });
    const formattedProductQuantity = await formatProductQuantity.mutateAsync({
      quantity: currentProduct!.quantity.toString(),
      productType: foundProduct.type
    });
    setProducts([
      ...products,
      {
        priority: 'main',
        _id: currentProduct!.productId!,
        productId: currentProduct!.productId!,
        image: foundProduct!.images[0].url,
        imagePrefix: foundProduct.images?.find(
          (i: ImageObject) => i.type === EAssetType.PRODUCT_PREFIX
        )?.url,
        name: foundProduct!.name,
        amount: currentProduct!.quantity,
        type: foundProduct!.type,
        textFontColorHex: foundProduct!.textFontColorHex,
        prefix: foundProduct!.prefix,
        suffix: foundProduct!.suffix,
        quantityDisplay: formattedProductQuantity.data
      }
    ]);
    setCurrentProduct({
      productId: '',
      quantity: 0
    });
  };
  return (
    <div className="w-[456px] mx-auto">
      <RichText
        title="General"
        description="Start with setting up your offer mandatory fields"
        className="mb-9"
      />
      <div className="flex flex-col gap-5 mb-4">
        <div className="grid grid-cols-2 gap-5">
          <Input
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            isRequired
            state={touched.name && errors.name ? 'error' : 'default'}
            description={errors.name}
          />
          <Input
            label="Player clicked TTL (Seconds)"
            placeholder="Player clicked TTL"
            type="number"
            name="playerClickedTtl"
            value={values.playerClickedTtl}
            onChange={handleChange}
            onBlur={handleBlur}
            isRequired
            state={
              touched.playerClickedTtl && errors.playerClickedTtl
                ? 'error'
                : 'default'
            }
            description={errors.playerClickedTtl}
          />
          <Input
            label="External ID"
            placeholder="ID Number"
            type="text"
            name="publisherOfferId"
            value={values.publisherOfferId}
            onChange={handleChange}
            onBlur={handleBlur}
            isRequired
            state={
              touched.publisherOfferId && errors.publisherOfferId
                ? 'error'
                : 'default'
            }
            description={errors.publisherOfferId}
          />
          <Input
            label="Price"
            placeholder="29.99"
            type="number"
            name="price"
            value={values.price}
            onChange={handleChange}
            onBlur={handleBlur}
            isRequired
            state={touched.price && errors.price ? 'error' : 'default'}
            description={errors.price}
            leftIcon={<span>$</span>}
            leftIconStyles="text-base-foreground text-p-s absolute left-3 top-1/2 -translate-y-1/2"
          />
        </div>
      </div>
      <div className="my-5 border-t" />

      <RichText
        title="Products"
        description="Add at least one product to your new offer"
        className="mb-9"
      />

      <div className="flex flex-col gap-5 mb-9 w-full">
        <div className="grid grid-cols-2 gap-5">
          <AutoCompleteInput
            label="Product name"
            placeholder="Search product"
            options={getProductsOptions}
            onChange={(newValue: any) => {
              setCurrentProduct({
                ...currentProduct,
                productId: newValue?.value || '',
                quantity: currentProduct?.quantity || 0
              });
            }}
            currentOption={selectedProduct}
          />

          <Input
            label="Quantity"
            placeholder="1"
            type="number"
            value={currentProduct?.quantity}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                quantity: Number(e.target.value) || 0
              });
              handleQuantityChange(e);
            }}
            min={0}
          />
        </div>
        <Button
          isCreateButton
          onClick={addProduct}
          disabled={!currentProduct?.productId || !currentProduct?.quantity}
          className="w-full bg-base-accent text-base-accentForeground"
        >
          Add Product
        </Button>
      </div>

      <ProductsTable
        products={products}
        setProducts={setProducts}
        setDirtyProducts={setDirtyProducts}
        setCurrentProduct={setCurrentProduct}
        formikProps={formikProps}
      />
    </div>
  );
};

export default SettingsTab;
