import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useLogin } from '../../../hooks/useLogin';

import { EProfileDropdownItems } from './constants';

export const useProfileDropdownActions = () => {
  const { publisherId } = useParams();
  const navigate = useNavigate();
  const { logout } = useLogin();

  useEffect(() => {
    if (!document.getElementById('ze-snippet')) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=4fc76614-a8b6-4bb1-9122-4672df382011';
      script.async = true;
      script.onload = () => {
        if (window.zE) {
          window.zE('webWidget', 'hide');
        }
      };
      document.body.appendChild(script);
    } else if (window.zE) {
      window.zE('webWidget', 'hide');
    }
  }, []);

  const onProfileClick = () => navigate(`profile/personal`);

  const onSupportClick = () => {
    const userDetailsJSON = localStorage.getItem('user_details');
    if (userDetailsJSON) {
      const userDetails = JSON.parse(userDetailsJSON);
      const { email, firstName, lastName } = userDetails;

      const fullName = `${firstName} ${lastName}`.trim();

      if (window.zE) {
        window.zE('webWidget', 'identify', {
          name: fullName || 'Anonymous',
          email: email || ''
        });
      }
    }

    if (window.zE) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');

      window.zE('webWidget', 'set', 'contactForm', {
        tags: ['backoffice_tickets']
      });
    } else {
      console.error('Zendesk Web Widget API is not available yet.');
    }
  };

  const onLogoutClick = () => {
    logout();
  };

  return {
    [EProfileDropdownItems.PROFILE]: onProfileClick,
    [EProfileDropdownItems.SUPPORT]: onSupportClick,
    [EProfileDropdownItems.LOGOUT]: onLogoutClick
  };
};
