import React from 'react';

import { Stack } from '@mui/material';
import { RollingOfferProductSequence } from 'common/contracts';
import { TabsProps } from 'views/RollingOffers/types';

import ActionButton from 'components/ActionButton/ActionButton';

import SubOfferCard from '../SubOfferCard/Card';

const ConfigurationTab: React.FC<TabsProps> = ({
  formikProps,
  rollingOfferId,
  productsSequence,
  setProductsSequence
}) => {
  const handleCreateNewProductSequence = async () => {
    setProductsSequence([
      ...productsSequence,
      {
        index: productsSequence.length + 1,
        priceInUsdCents: 0,
        products: [
          {
            product: {
              images: []
            }
          }
        ]
      }
    ]);
  };

  return (
    <Stack gap={2}>
      {productsSequence &&
        productsSequence.map(
          (
            productSequenceItem: RollingOfferProductSequence,
            sequenceIndex: number
          ) => (
            <SubOfferCard
              key={productSequenceItem.index}
              formikProps={formikProps}
              rollingOfferId={rollingOfferId}
              productSequenceItem={productSequenceItem}
              sequenceIndex={sequenceIndex}
              productsSequence={productsSequence}
              setProductsSequence={setProductsSequence}
            />
          )
        )}
      <ActionButton
        text="Add Offer"
        style={{ width: '125px' }}
        variant="outlined"
        onClick={handleCreateNewProductSequence}
      />
    </Stack>
  );
};

export default ConfigurationTab;
