export enum EProfileDropdownItems {
  PROFILE = 'Profile',
  SUPPORT = 'Support',
  LOGOUT = 'Logout'
}

export type DropdownMenuItemsProps = {
  name: EProfileDropdownItems;
  iconType: string;
};

export const DROPDOWN_MENU_ITEMS: DropdownMenuItemsProps[] = [
  {
    name: EProfileDropdownItems.PROFILE,
    iconType: 'fa-user'
  },
  {
    name: EProfileDropdownItems.SUPPORT,
    iconType: 'fa-circle-question'
  },
  {
    name: EProfileDropdownItems.LOGOUT,
    iconType: 'fa-arrow-right-from-bracket'
  }
];
