import React, { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  AddToHomeScreenButton,
  Background,
  createGradientOrSolid,
  overrideCssVars
} from '@appcharge/shared-ui';
import { Checkbox, Divider, Grid, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import useImages from 'api/useImages';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import { EAssetType, EFeatureFlag, ESectionViewModel } from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips } from 'hooks/useUpload';
import { mapModelSection } from 'utils/mapModelSection';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import AcSolidInput from '../../AcSolidInput/AcSolidInput';
import { ThemeLoader } from '../ThemeLoader';

import { Sections } from './components/Sections';
import { StoreThemeBundles } from './components/StoreThemeBundles';
import { SectionsItem, StoreThemeFormValues } from './storeTheme.types';

import 'style/forms.scss';

export const StoreTheme: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<StoreThemeFormValues>();

  const { publisherId } = useParams();

  const { getGeneralTheme: generalTheme, getStoreScreen: storeScreen } =
    useTheme(publisherId);

  const pictures = useImages(publisherId).getImages;
  const [sections, setSections] = useState<SectionsItem[]>(values.sections);

  const { fetchFeatureFlags } = useUsers({ publisherId });

  const showAddToHomeScreen: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_ADD_TO_HOME_SCREEN
      ],
    [fetchFeatureFlags.data]
  );

  const SectionLayout =
    mapModelSection[generalTheme?.data?.sectionViewModel as ESectionViewModel];

  useEffect(() => {
    if (values.sections.length > 0) {
      setSections(values.sections);
    }
  }, [values.sections]);

  useEffect(() => {
    if (!values) return;

    // ACDEV-10485: move params from general to screen
    const cssMappingOverride = {
      '--button-text-color': values.buttonTextColor,
      '--button-bg-color': createGradientOrSolid(values.buttonColor),
      '--bundle-border-color': createGradientOrSolid(values.bundleBorderColor)
    };

    overrideCssVars(cssMappingOverride, document);
  }, [values?.buttonTextColor, values?.buttonColor, values?.bundleBorderColor]);

  const sectionsData = sections.filter((section) => !section.toBeDeleted);

  const getAddToHomeScreenImgItems = ({
    assetType,
    picturesData,
    imageWidth = 30,
    defaultImageUrl = '',
    defaultAltText = ''
  }: {
    defaultImageUrl?: string;
    defaultAltText?: string;
    assetType: EAssetType;
    picturesData: any[];
    imageWidth?: string | number;
  }) => {
    const createItem = (url: string, alt: string, content = '') => ({
      content,
      key: url,
      value: url,
      renderFunction: () => (
        <Stack direction="row" alignItems="center" gap={1}>
          <img
            src={url}
            alt={alt}
            style={{
              width: imageWidth,
              maxHeight: 30
            }}
          />
        </Stack>
      )
    });

    const defaultItem = defaultImageUrl
      ? createItem(defaultImageUrl, defaultAltText || '')
      : null;

    const filteredItems = picturesData
      .filter((p) => p.type === assetType)
      .map((picture) => createItem(picture.url, picture.name, picture.name));

    return defaultItem ? [defaultItem, ...filteredItems] : filteredItems;
  };

  const hasValues = useMemo(() => Object.keys(values).length > 0, [values]);

  useEffect(
    () =>
      setIsLoading(
        storeScreen.isLoading ||
          !storeScreen.data ||
          !hasValues ||
          generalTheme.isLoading ||
          !generalTheme.data
      ),
    [
      storeScreen?.isLoading,
      storeScreen?.data,
      generalTheme.isLoading,
      generalTheme.data,
      hasValues
    ]
  );

  if (isLoading) {
    return <ThemeLoader />;
  }

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        {!pictures.isLoading && (
          <Stack>
            {fetchFeatureFlags.data?.featureFlags?.[
              EFeatureFlag.STORE_HEADER_V2
            ] && (
              <>
                <AcCard
                  stackContainer={false}
                  title="Store Header"
                  description="These settings will apply to your store header"
                >
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Background Opacity"
                        name="header.backgroundOpacity"
                        value={`${values.header?.backgroundOpacity}%`}
                        items={[
                          { value: 0, content: '0%' },
                          { value: 10, content: '10%' },
                          { value: 20, content: '20%' },
                          { value: 30, content: '30%' },
                          { value: 40, content: '40%' },
                          { value: 50, content: '50%' },
                          { value: 60, content: '60%' },
                          { value: 70, content: '70%' },
                          { value: 80, content: '80%' },
                          { value: 90, content: '90%' },
                          { value: 100, content: '100%' }
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.header?.backgroundOpacity &&
                          Boolean(errors.header?.backgroundOpacity)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Logo"
                        name="header.showLogo"
                        value={String(values.header?.showLogo)}
                        items={[
                          {
                            content: 'Yes',
                            key: 'true',
                            value: 'true',
                            selected: true
                          },
                          { content: 'No', key: 'false', value: 'false' }
                        ]}
                        onChange={(e) => {
                          e.target.value = e.target.value === 'true';
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.header?.showLogo &&
                          Boolean(errors.header?.showLogo)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Player Name"
                        name="header.showPlayerName"
                        value={String(values.header?.showPlayerName)}
                        items={[
                          {
                            content: 'Yes',
                            key: 'true',
                            value: 'true',
                            selected: true
                          },
                          {
                            content: 'No',
                            key: 'false',
                            value: 'false'
                          }
                        ]}
                        onChange={(e) => {
                          e.target.value = e.target.value === 'true';
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.header?.showPlayerName &&
                          Boolean(errors.header?.showPlayerName)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Header Sticky"
                        name="header.isSticky"
                        value={String(values.header?.isSticky)}
                        items={[
                          {
                            content: 'Yes',
                            key: 'true',
                            value: 'true'
                          },
                          {
                            content: 'No',
                            key: 'false',
                            value: 'false',
                            selected: true
                          }
                        ]}
                        onChange={(e) => {
                          e.target.value = e.target.value === 'true';
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.header?.isSticky &&
                          Boolean(errors.header?.isSticky)
                        }
                      />
                    </Grid>
                  </Grid>
                </AcCard>
                <Divider />
              </>
            )}
            <AcCard stackContainer={false} title="Banner">
              <Grid item xs={12}>
                <AcSelect
                  header="Banner Image"
                  name="defaultBanner"
                  value={values?.defaultBanner}
                  onClear={() => {
                    setFieldValue('defaultBanner', '');
                  }}
                  uploadConfig={{
                    onUploadSuccess: async (uploadData: any) => {
                      await pictures.refetch();
                      setFieldValue('defaultBanner', uploadData!.data.url);
                    },
                    uploadType: EAssetType.BANNER,
                    uploadMessage: UploadsTooltips[EAssetType.BANNER]
                  }}
                  items={[
                    ...pictures.data
                      .filter((p: any) => p.type === EAssetType.BANNER)
                      .map((picture: any) => {
                        return {
                          content: picture.name,
                          key: picture.url,
                          value: picture.url,
                          renderFunction: () => {
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <img
                                  src={picture.url}
                                  alt={picture.name}
                                  style={{
                                    width: 30,
                                    maxHeight: 30
                                  }}
                                />
                                <Typography>{picture.name}</Typography>
                              </Stack>
                            );
                          }
                        };
                      })
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.defaultBanner && Boolean(errors.defaultBanner)}
                />
              </Grid>
            </AcCard>
            <Divider />
            <Sections />
            <Divider />
            <AcCard
              stackContainer={false}
              title="Store Buttons"
              description="These settings will apply to buttons throughout your store"
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={6}>
                  <AcGradientInput
                    header="Button Color"
                    name="buttonColor"
                    defaultValue={values.buttonColor}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    error={
                      touched.buttonColor?.colorOne &&
                      Boolean(errors.buttonColor?.colorOne)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AcSolidInput
                    header="Button Text Color"
                    name="buttonTextColor"
                    defaultValue={values?.buttonTextColor}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    error={
                      touched.buttonTextColor && Boolean(errors.buttonTextColor)
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            {showAddToHomeScreen && (
              <>
                <Divider />
                <AcCard
                  stackContainer={false}
                  title="Add To Home Screen Button"
                >
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        sx={{ paddingLeft: '10px' }}
                        slotProps={{
                          typography: {
                            fontSize: 14,
                            sx: {
                              marginLeft: '2px'
                            },
                            color: '#717188'
                          }
                        }}
                        control={
                          <Checkbox
                            name="addToHomeScreen.active"
                            checked={values.addToHomeScreen.active}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFieldValue(
                                'addToHomeScreen.active',
                                isChecked
                              );
                            }}
                            size="small"
                            sx={{
                              padding: 0,
                              marginRight: '6px',
                              '&.Mui-checked': {
                                color: '#7122FF'
                              }
                            }}
                          />
                        }
                        label="Activate this feature in your store"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSolidInput
                        header="Border and Text Color"
                        name="addToHomeScreen.borderColor"
                        defaultValue={
                          values.addToHomeScreen.borderColor.colorOne
                        }
                        setValue={(fieldName: string, color: string) => {
                          setFieldValue(fieldName, {
                            colorOne: color
                          });
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcGradientInput
                        header="Background Color"
                        name="addToHomeScreen.backgroundColor"
                        defaultValue={values.addToHomeScreen.backgroundColor}
                        setValue={setFieldValue}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AcSelect
                        header="Button Image"
                        name="addToHomeScreen.buttonImage"
                        value={values.addToHomeScreen?.buttonImage}
                        onClear={() => {
                          setFieldValue('addToHomeScreen.buttonImage', '');
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={pictures.isLoading || pictures.isRefetching}
                        uploadConfig={{
                          onUploadSuccess: async (uploadData: any) => {
                            await pictures.refetch();
                            setFieldValue(
                              'addToHomeScreen.buttonImage',
                              uploadData!.data.url
                            );
                          },
                          uploadType: EAssetType.ADD_TO_HOME_BUTTON_IMAGE,
                          uploadMessage:
                            UploadsTooltips[EAssetType.ADD_TO_HOME_BUTTON_IMAGE]
                        }}
                        items={getAddToHomeScreenImgItems({
                          assetType: EAssetType.ADD_TO_HOME_BUTTON_IMAGE,
                          picturesData: pictures.data,
                          imageWidth: 'auto'
                        })}
                        tooltip="Image will always be with 32px hight"
                        error={
                          touched.addToHomeScreen?.buttonImage &&
                          Boolean(errors.addToHomeScreen?.buttonImage)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AcSelect
                        required
                        header="Icon Image"
                        name="addToHomeScreen.iconImage"
                        value={values.addToHomeScreen.iconImage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={pictures.isLoading || pictures.isRefetching}
                        uploadConfig={{
                          onUploadSuccess: async (uploadData: any) => {
                            await pictures.refetch();
                            setFieldValue(
                              'addToHomeScreen.iconImage',
                              uploadData!.data.url
                            );
                          },
                          uploadType: EAssetType.ADD_TO_HOME_ICON_IMAGE,
                          uploadMessage:
                            UploadsTooltips[EAssetType.ADD_TO_HOME_ICON_IMAGE]
                        }}
                        items={getAddToHomeScreenImgItems({
                          defaultImageUrl:
                            'https://media.appcharge.com/defaults/logo.svg',
                          defaultAltText: 'Default Appcharge icon',
                          picturesData: pictures.data,
                          assetType: EAssetType.ADD_TO_HOME_ICON_IMAGE
                        })}
                        tooltip="Image with 1:1 ratio with max 192px width"
                        error={
                          touched.addToHomeScreen?.iconImage &&
                          Boolean(errors.addToHomeScreen?.iconImage)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AcInput
                        required
                        header="Store Title"
                        name="addToHomeScreen.shortcutName"
                        value={values.addToHomeScreen.shortcutName}
                        tooltip="Will be the name of your home screen icon"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched?.addToHomeScreen?.shortcutName &&
                          Boolean(errors?.addToHomeScreen?.shortcutName)
                        }
                        helperText={
                          touched.addToHomeScreen?.shortcutName
                            ? errors.addToHomeScreen?.shortcutName?.toString()
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </AcCard>
              </>
            )}
            <Divider />
            <AcCard stackContainer={false} title="Lack of Special Offers">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Title Text"
                    name="noOffersTitleText"
                    value={values.noOffersTitleText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.noOffersTitleText &&
                      Boolean(errors.noOffersTitleText)
                    }
                    helperText={
                      touched.noOffersTitleText
                        ? errors.noOffersTitleText?.toString()
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Message Text"
                    name="noOffersMessageText"
                    value={values.noOffersMessageText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.noOffersMessageText &&
                      Boolean(errors.noOffersMessageText)
                    }
                    helperText={
                      touched.noOffersMessageText
                        ? errors.noOffersMessageText?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard
              stackContainer={false}
              title="Borders"
              description="These settings will apply to borders throughout your store"
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcGradientInput
                    header="Bundle Frame Color"
                    name="bundleBorderColor"
                    defaultValue={values.bundleBorderColor}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    error={
                      touched.bundleBorderColor &&
                      Boolean(errors.bundleBorderColor)
                    }
                    helperText={
                      touched.bundleBorderColor
                        ? errors.bundleBorderColor?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
          </Stack>
        )}
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div className="button-container">
          {showAddToHomeScreen && (
            <AddToHomeScreenButton
              borderColor={values.addToHomeScreen?.borderColor?.colorOne}
              backgroundColor={
                values.addToHomeScreen.backgroundColor?.colorOne
                  ? values.addToHomeScreen.backgroundColor
                  : {
                      colorOne: '#522AD8',
                      colorTwo: '#7946FA'
                    }
              }
              buttonImage={values.addToHomeScreen?.buttonImage}
              onClick={() => ''}
              isMockPreview
            />
          )}
        </div>
        <div
          className="store-preview-wrapper iphone-mock"
          style={{ overflow: 'scroll' }}
        >
          <Background
            backgroundImageMobile={generalTheme.data?.backgroundImageMobile}
            backgroundImageDesktop={generalTheme.data?.backgroundImageMobile}
            width="100%"
            height="100%"
            position="absolute"
          />
          <img
            style={{ position: 'relative' }}
            alt="logo"
            className={`storeLogo ${generalTheme.data?.logoSize}`}
            src={generalTheme.data?.logo}
          />
          <div className="bundles-wrapper">
            {sectionsData.length > 0 ? (
              <>
                {sectionsData.map((section, index) => (
                  <div
                    key={section.publisherSectionId}
                    className="store-theme-sections"
                  >
                    <SectionLayout
                      sectionName={section.name}
                      image={section.image}
                    />
                    <StoreThemeBundles
                      bundleBorderColor={values.bundleBorderColor}
                      buttonTextColor={values.buttonTextColor}
                      buttonColor={values.buttonColor}
                    />
                  </div>
                ))}
              </>
            ) : (
              <StoreThemeBundles
                bundleBorderColor={values.bundleBorderColor}
                buttonTextColor={values.buttonTextColor}
                buttonColor={values.buttonColor}
              />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
