import { EFeatureFlag } from '../../constants/enums';
import { ESideBarChipType, SideBarGroup, ESidebarGroupKey, SideBarItem } from './types';
import { EPermissionGroup } from 'common/permissions.types';

// =====================
// Individual page definitions
// =====================

// Company-level pages
export const Orders: SideBarItem = {
  title: 'Orders',
  url: `orders`,
  icon: 'fa-credit-card',
  permissions: [EPermissionGroup.ORDERS]
};

export const Reports: SideBarItem = {
  title: 'Reports',
  url: `reports/financialreports`,
  icon: 'fa-file-chart-column',
  permissions: [EPermissionGroup.FINANCIAL_REPORT]
};

// Project-level pages - Management
export const Analytics: SideBarItem = {
  title: 'Analytics',
  url: 'statistics',
  icon: 'fa-regular fa-chart-simple',
  permissions: [EPermissionGroup.ANALYTICS]
};

export const Players: SideBarItem = {
  title: 'Players',
  url: 'players/blocked',
  icon: 'fa-user',
  permissions: [EPermissionGroup.PLAYER]
};

export const Segments: SideBarItem = {
  title: 'Segments',
  url: 'segments',
  icon: 'fa-circle-quarter-stroke',
  permissions: [EPermissionGroup.SEGMENTS]
};

export const Pricing: SideBarItem = {
  title: 'Pricing',
  url: 'pricing/points',
  icon: 'fa-circle-dollar',
  permissions: [EPermissionGroup.PRICING]
};

export const Coupons: SideBarItem = {
  title: 'Coupons',
  url: 'coupons',
  icon: 'fa-ticket-simple',
  permissions: [EPermissionGroup.COUPONS]
};
export const Localization: SideBarItem = {
  title: 'Localization',
  url: 'localization',
  icon: 'fa-globe',
  permissions: [EPermissionGroup.OFFERS]
};

export const Settings: SideBarItem = {
  title: 'Settings',
  url: 'settings',
  icon: 'fa-gear',
  permissions: [EPermissionGroup.COMPANY]
};

// Project-level pages - Studio
export const Builder: SideBarItem = {
  title: 'Builder',
  url: 'theme',
  icon: 'fa-swatchbook',
  permissions: [EPermissionGroup.BUILDER]
};

export const Products: SideBarItem = {
  title: 'Products',
  url: 'products',
  icon: 'fa-tag',
  permissions: [EPermissionGroup.PRODUCTS]
};

export const OffersDesign: SideBarItem = {
  title: 'Offers Design',
  url: 'offersui',
  icon: 'fa-palette',
  permissions: [EPermissionGroup.OFFER_DESIGN]
};

export const Badges: SideBarItem = {
  title: 'Badges',
  url: 'badges',
  icon: 'fa-badge-check',
  permissions: [EPermissionGroup.BADGES]
};

export const AssetsLibrary: SideBarItem = {
  title: 'Assets Library',
  url: 'assets',
  icon: 'fa-image',
  permissions: [EPermissionGroup.ASSET_LIBRARY]
};

// Project-level pages - Offers
export const Bundles: SideBarItem = {
  title: 'Bundles',
  url: 'bundles',
  icon: 'fa-bag-shopping',
  permissions: [EPermissionGroup.OFFERS]
};

export const Promotions: SideBarItem = {
  title: 'Promotions',
  url: 'promotions',
  icon: 'fa-gallery-thumbnails',
  permissions: [EPermissionGroup.OFFERS]
};

export const Popups: SideBarItem = {
  title: 'Popups',
  url: 'popups',
  icon: 'fa-gift',
  permissions: [EPermissionGroup.OFFERS]
};

export const Rolling: SideBarItem = {
  title: 'Rolling',
  url: 'rolling',
  icon: 'fa-grid-2-plus',
  permissions: [EPermissionGroup.OFFERS]
};

export const CheckoutLink: SideBarItem = {
  title: 'Checkout links',
  url: 'checkoutlinks',
  icon: 'fa-square-up-right',
  permissions: [EPermissionGroup.OFFERS]
};

// Portal-level pages
export const Pages: SideBarItem = {
  title: 'Pages',
  url: 'pages',
  icon: 'fa-page'
};

export const Content: SideBarItem = {
  title: 'Content',
  url: 'content',
  icon: 'fa-pen'
};

export const PortalSettings: SideBarItem = {
  title: 'Settings',
  url: 'settings',
  icon: 'fa-gear'
};

export const PortalBuilder: SideBarItem = {
  title: 'Builder',
  url: 'builder',
  icon: 'fa-edit',
  chip: ESideBarChipType.COMING_SOON,
  isDisabled: true
};

// =====================
// Navigation structures using the individual page constants
// =====================

export const companyLevelNavItems: SideBarGroup[] = [
  {
    title: ESidebarGroupKey.Dashboard,
    isCollapsable: true,
    items: [Orders, Reports]
  }
];

export const storeLevelNavItems: SideBarGroup[] = [
  {
    title: ESidebarGroupKey.Management,
    items: [
      Analytics,
      Players,
      Segments,
      Pricing,
      Coupons,
      Localization,
      Settings
    ],
    isCollapsable: true
  },
  {
    title: ESidebarGroupKey.Studio,
    items: [Builder, Products, OffersDesign, Badges, AssetsLibrary],
    isCollapsable: true
  },
  {
    title: ESidebarGroupKey.Offers,
    items: [Bundles, Promotions, Popups, Rolling, CheckoutLink],
    isCollapsable: true
  }
];

export const portalLevelNavItems: SideBarGroup[] = [
  {
    title: ESidebarGroupKey.Dashboard,
    items: [Pages, Content, PortalSettings, PortalBuilder],
    isCollapsable: true
  }
];

export const pageFeatureFlagMap: Record<string, EFeatureFlag> = {
  Deeplink: EFeatureFlag.DASHBOARD_DEEPLINKS,
  'Rolling Offers': EFeatureFlag.DASHBOARD_ROLLING_OFFER,
  Coupons: EFeatureFlag.DASHBOARD_COUPONS,
  Players: EFeatureFlag.DASHBOARD_PLAYERS_LIST_BLOCKED,
  Localization: EFeatureFlag.STORE_MULTI_LANGUAGE_SUPPORT
};

export const projectTypeRestrictions = {
  independentCheckout: {
    disabledItems: {
      [ESidebarGroupKey.Management]: [Segments],
      [ESidebarGroupKey.Studio]: [Products, OffersDesign, Badges, AssetsLibrary],
      [ESidebarGroupKey.Offers]: [Bundles, Promotions, Popups, Rolling]
    } as Record<ESidebarGroupKey, SideBarItem[]>
  }
};

