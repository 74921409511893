import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { User } from '../../../common/contracts';
import { DropdownMenu, DropdownMenuTrigger } from '../../../components/ui';
import { Avatar, AvatarFallback } from '../../../components/ui/avatar';

import { DROPDOWN_MENU_ITEMS } from './constants';
import DropdownMenuContent from './DropdownMenuContent';

type ProfileDropDownProps = {
  user: User | null;
};

const ProfileDropDown: FC<ProfileDropDownProps> = ({ user }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center gap-x-2 group focus:outline-none focus:ring-0 rounded-md border border-transparent hover:border-base-border data-[state=open]:border-base-border data-[state=open]:shadow-md py-2.5 pl-1 pr-2 data-[state=open]:rounded-b-none">
        <Avatar className="flex-auto justify-center items-center bg-[#ded6fb] w-8 h-8">
          <FontAwesomeIcon icon="fa-solid fa-user"/>
        </Avatar>
        <div className="flex flex-col gap-y-0.5 text-left min-w-[148px]">
          <p className="text-p-s font-medium text-base-sidebarForeground leading-[100%]">
            Hi, {user?.firstName}
          </p>
          <p className="text-p-xs text-muted-foreground text-base-sidebarForeground leading-[100%]">
            {user?.email}
          </p>
        </div>
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          className="text-neutral-600 text-p-s transition-transform group-data-[state=open]:rotate-180"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent dropdownMenuItems={DROPDOWN_MENU_ITEMS} />
    </DropdownMenu>
  );
};

export default ProfileDropDown;
