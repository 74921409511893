import React from 'react';

import ImageUploadItem from './ImageUploadItem';

const MediaContent = () => {
  return (
    <div className="h-[440px] overflow-y-scroll">
      <div className="grid grid-cols-4 gap-2 my-4">
        {Array.from({ length: 12 }).map((_, i) => (
          <ImageUploadItem key={i} />
        ))}
      </div>
    </div>
  );
};

export default MediaContent;
