import { Route, Routes } from 'react-router-dom';

import CheckoutLinkForm from './CheckoutLinkForm';
import CheckoutLinksTable from './CheckoutLinksTable';

const CheckoutLinksView = () => {
  return (
    <Routes>
      <Route path="/" element={<CheckoutLinksTable />} />
      <Route path="/form" element={<CheckoutLinkForm />} />
      <Route
        path="/form/:checkoutLinkId"
        element={<CheckoutLinkForm edit={true} />}
      />
      <Route
        path="/dup/:checkoutLinkId"
        element={<CheckoutLinkForm edit={false} dup={true} />}
      />
    </Routes>
  );
};

export default CheckoutLinksView;
