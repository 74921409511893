import React, { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { usePages } from '../../../api/portal/usePagesApi';
import FormHeader from '../../../components/FormHeader/FormHeader';
import ImageUpload from '../../../components/ImageUpload/ImageUpload';
import Input from '../../../components/Input/Input';
import InputSelectDropdown from '../../../components/InputSelectDropdown/InputSelectDropdown';
import RichText from '../../../components/RichText/RichText';
import Textarea from '../../../components/Textarea/Textarea';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../../components/ui/form';
import { EToasterStatus, showToast } from '../../../utils/showToast';
import { PagesFormProps } from '../types';

import {
  EPagesRadioButtonOptions,
  EPagesTemplateOptions,
  FORM_SCHEMA,
  PAGES_RADIO_BUTTON_OPTIONS,
  pageTemplateOptions
} from './constants';

const PagesForm: FC<PagesFormProps> = ({ edit = false, dup = false }) => {
  const { pageId } = useParams();
  const navigate = useNavigate();
  const { addPage, updatePage, getPage, getPages } = usePages(pageId);

  const DEFAULT_VALUES = {
    pageName: '',
    pageIcon: 'test',
    slug: '',
    pageTemplate: EPagesTemplateOptions.CONTENT,
    metadataImage: 'test',
    metadataDescription: '',
    state: EPagesRadioButtonOptions.HIDDEN,
    publishDate: new Date().toISOString()
  };

  const BUTTON_TEXT = edit ? 'Update' : 'Save';

  const form = useForm<z.infer<typeof FORM_SCHEMA>>({
    resolver: zodResolver(FORM_SCHEMA),
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES
  });

  const initializeForm = (data: Partial<typeof DEFAULT_VALUES>) => {
    form.reset({
      ...DEFAULT_VALUES,
      ...data
    });
  };

  useEffect(() => {
    console.log(getPage.data);
    if (edit && getPage.data) {
      initializeForm(getPage.data);
    } else if (dup && getPage.data) {
      initializeForm({
        ...getPage.data,
        pageName: `${getPage.data.pageName}_duplicated`
      });
    }
  }, [edit, dup, getPage.data, form]);

  const onSubmit = async (data: z.infer<typeof FORM_SCHEMA>) => {
    if (edit && pageId && !dup) {
      updatePage.mutate(
        { form: data, pageId },
        {
          onSuccess: () => {
            showToast({
              message: 'Page updated successfully',
              status: EToasterStatus.SUCCESS
            });
            getPages.refetch();
            navigate('../');
          },
          onError: () => {
            showToast({
              message: 'Error updating page',
              status: EToasterStatus.ERROR
            });
          }
        }
      );
    } else {
      addPage.mutate(data, {
        onSuccess: () => {
          showToast({
            message: 'Page added successfully',
            status: EToasterStatus.SUCCESS
          });
          getPages.refetch();
          navigate('../');
        },
        onError: () => {
          showToast({
            message: 'Error adding page',
            status: EToasterStatus.ERROR
          });
        }
      });
    }
  };

  const handleFormSubmit = () => {
    form.handleSubmit(onSubmit)();
  };

  const onCloseForm = () => {
    navigate('../');
  };

  return (
    <div className="bg-white fixed h-screen w-full top-0 left-0 z-[1000] overflow-scroll">
      <FormHeader
        title="Create Portal Page"
        buttonText={BUTTON_TEXT}
        onCloseForm={onCloseForm}
        onSubmitForm={handleFormSubmit}
        onOptionChange={(option) => {
          form.setValue('state', option as EPagesRadioButtonOptions);
        }}
        selectedOption={form.watch('state')}
        options={PAGES_RADIO_BUTTON_OPTIONS}
        isFormValid={!form.formState.isValid}
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mt-7 mb-48 w-[456px] mx-auto"
        >
          <RichText title="Page Settings" className="mb-9" />
          <FormField
            control={form.control}
            name="pageName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    isRequired
                    label="Page Name"
                    placeholder="e.g., Blog"
                    {...field}
                    className="mb-5"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/*TODO update image component*/}
          <FormField
            control={form.control}
            name="pageIcon"
            render={({ field: { onChange } }) => (
              <FormItem>
                <FormControl>
                  <ImageUpload
                    title="Add an icon"
                    description={`Add an icon to your page.\nImage will be scaled to 130px x 60px`}
                    buttonText="Upload"
                    onUpload={(url: string) => {
                      onChange(url);
                    }}
                    className="w-full mb-5"
                    isRequired
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 gap-5 mb-9 border-b pb-[50px] border-base-sidebarBorder w-full">
            <FormField
              control={form.control}
              name="slug"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      label="Portal/"
                      placeholder="e.g., blog"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="pageTemplate"
              render={({ field: { onChange } }) => (
                <FormItem>
                  <FormControl>
                    <InputSelectDropdown
                      label="Page Type"
                      defaultSelected={pageTemplateOptions.find(
                        (option) => option.value === form.watch('pageTemplate')
                      )}
                      options={pageTemplateOptions}
                      onOptionChange={onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <RichText
            title="Metadata"
            description="Enhance your page’s visibility and searchability"
            className="mb-9"
          />
          {/*TODO update image component*/}
          <FormField
            control={form.control}
            name="metadataImage"
            render={({ field: { onChange } }) => (
              <FormItem>
                <FormControl>
                  <ImageUpload
                    title="Add an image"
                    description="The image will be scaled to 32px x 32px"
                    buttonText="Upload"
                    onUpload={(url: string) => {
                      onChange(url);
                    }}
                    className="w-full mb-5"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="metadataDescription"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Textarea
                    label="Metadata Description"
                    placeholder="Write Something..."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
};

export default PagesForm;
