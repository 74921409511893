import { GridRowParams } from '@mui/x-data-grid';
import { RowData } from '@tanstack/react-table';
import {
  ExtendedAppearanceStatus,
  FormikProps,
  Offer,
  ScheduleTimeFrame
} from 'common/contracts';
import { DATE_TIME_FORMAT_UTC } from 'constants/constants';
import {
  EAppearanceStatus,
  EIntervalsRepeat,
  EOfferStatus,
  EPendingAppearanceStatus,
  EScheduleType
} from 'constants/enums';
import dayjs, { Dayjs } from 'dayjs';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import { FormikValues } from 'formik';

export const STATUS_PRIORITY = {
  [EAppearanceStatus.RUNNING]: 3,
  [EAppearanceStatus.UPCOMING]: 2,
  [EAppearanceStatus.ENDED]: 1
};

// ---- Status Utilities ----
export const statusUtils = {
  determineStatus(startTime: Date, endTime: Date): EAppearanceStatus {
    const currentDate = new Date();
    if (currentDate >= startTime && currentDate <= endTime) {
      return EAppearanceStatus.RUNNING;
    } else if (currentDate < startTime) {
      return EAppearanceStatus.UPCOMING;
    } else {
      return EAppearanceStatus.ENDED;
    }
  },

  getStatusLabel(status: ExtendedAppearanceStatus): EStatusLabel {
    const statusLabelMap: Partial<
      Record<ExtendedAppearanceStatus, EStatusLabel>
    > = {
      [EAppearanceStatus.RUNNING]: EStatusLabel.ACTIVE,
      [EAppearanceStatus.UPCOMING]: EStatusLabel.PENDING,
      [EPendingAppearanceStatus.PENDING_SAVE]: EStatusLabel.CANCELED
    };
    return statusLabelMap[status] ?? EStatusLabel.FAILED;
  },

  getOfferStatus: (offer: Offer | RowData, isActive: boolean) => {
    if (!isActive) {
      return EOfferStatus.INACTIVE;
    }

    const { schedule } = offer as Offer;
    const scheduleStatus = appearanceUtils.getStrongestStatusWithTime(
      schedule?.timeFrames
    ).status;

    return scheduleStatus;
  }
};

// ---- Time Utilities ----
export const timeUtils = {
  getTimeFromCron(cronString: string): {
    hourFromCron?: string;
    weekDayFromCron?: number;
    dayInMonthFromCron?: number;
  } {
    if (!cronString) return {};
    const [minute, hour, day, month, weekDay] = cronString.split(' ');
    return {
      hourFromCron:
        hour !== '*'
          ? `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`
          : undefined,
      weekDayFromCron: weekDay !== '*' ? Number(weekDay) : undefined,
      dayInMonthFromCron: day !== '*' ? Number(day) : undefined
    };
  },

  generateCronString(
    repeat: string,
    hour: string,
    weekDay?: number,
    dayInMonth?: number
  ) {
    const [h, m] = hour.split(':').map(Number) || '*';
    switch (repeat) {
      case EIntervalsRepeat.DAILY:
        return `${m} ${h} * * *`; // Every day at HH:mm
      case EIntervalsRepeat.WEEKLY:
        return `${m} ${h} * * ${weekDay}`; // Every week on specific day
      case EIntervalsRepeat.MONTHLY:
        return `${m} ${h} ${dayInMonth} * *`; // Every month on specific date
      default:
        return ''; // No cron
    }
  },

  isOverlapping(
    newStartTime: Dayjs,
    newEndTime: Dayjs,
    values: FormikValues
  ): boolean {
    return values?.schedule?.timeFrames.some((timeFrame: ScheduleTimeFrame) => {
      const existingStart = new Date(timeFrame.startTime).getTime();
      const existingEnd = new Date(timeFrame.endTime).getTime();
      return (
        (newStartTime.valueOf() >= existingStart &&
          newStartTime.valueOf() <= existingEnd) ||
        (newEndTime.valueOf() >= existingStart &&
          newEndTime.valueOf() <= existingEnd) ||
        (newStartTime.valueOf() <= existingStart &&
          newEndTime.valueOf() >= existingEnd)
      );
    });
  }
};

// ---- Appearance Utilities ----
export const appearanceUtils = {
  getRowClassName: (params: GridRowParams<Offer>): string => {
    const { schedule } = params.row;
    if (schedule?.permanent) return '';

    const timeFrames = schedule?.timeFrames || [];
    const { status } = appearanceUtils.getStrongestStatusWithTime(timeFrames);
    return status === EAppearanceStatus.ENDED ? 'row-ended' : '';
  },

  getStrongestStatusWithTime(timeFrames: ScheduleTimeFrame[] = []): {
    status: EAppearanceStatus | EScheduleType.PERMANENT;
    appearanceTime: string;
  } {
    if (!timeFrames.length) {
      return {
        status: EScheduleType.PERMANENT,
        appearanceTime: '-'
      };
    }

    const initialAccumulator = {
      status: EAppearanceStatus.ENDED,
      appearanceTime: '-'
    };

    return timeFrames.reduce((acc, timeFrame) => {
      const currentStatus = statusUtils.determineStatus(
        new Date(timeFrame.startTime),
        new Date(timeFrame.endTime)
      );

      if (STATUS_PRIORITY[currentStatus] > STATUS_PRIORITY[acc.status]) {
        return {
          status: currentStatus,
          appearanceTime: dayjs
            .utc(timeFrame.startTime)
            .format(DATE_TIME_FORMAT_UTC)
        };
      }
      return acc;
    }, initialAccumulator);
  },

  sortByStatusOrder(
    params1: { id: string | number },
    params2: { id: string | number },
    appearancesTableData: any
  ): number {
    const rowA = appearancesTableData?.find(
      (row: any) => row.id === params1.id
    );
    const rowB = appearancesTableData?.find(
      (row: any) => row.id === params2.id
    );

    if (!rowA || !rowB) return 0;

    const statusA =
      rowA?.startTime && rowA?.endTime
        ? statusUtils.determineStatus(
            new Date(rowA?.startTime),
            new Date(rowA?.endTime)
          )
        : appearanceUtils.getStrongestStatusWithTime(
            rowA?.schedule?.timeFrames || []
          ).status;

    const statusB =
      rowB.startTime && rowB?.endTime
        ? statusUtils.determineStatus(
            new Date(rowB.startTime),
            new Date(rowB.endTime)
          )
        : appearanceUtils.getStrongestStatusWithTime(
            rowB?.schedule?.timeFrames || []
          ).status;

    // Handle EScheduleType.PERMANENT explicitly
    const priorityA =
      STATUS_PRIORITY[statusA as EAppearanceStatus] ||
      (statusA === EScheduleType.PERMANENT ? 0 : -1);
    const priorityB =
      STATUS_PRIORITY[statusB as EAppearanceStatus] ||
      (statusB === EScheduleType.PERMANENT ? 0 : -1);

    return priorityA - priorityB;
  },

  getNextAppearanceByRefreshTime(cronInterval: string) {
    const { hourFromCron, weekDayFromCron, dayInMonthFromCron } =
      timeUtils.getTimeFromCron(cronInterval);
    let nextAppearanceTime = dayjs.utc().set('second', 0).set('millisecond', 0);

    if (hourFromCron) {
      const [hour, minute] = hourFromCron.split(':').map(Number);
      nextAppearanceTime = nextAppearanceTime
        .set('hour', hour)
        .set('minute', minute);
    }

    const now = dayjs.utc();

    // Daily Cron:
    const isDaily =
      weekDayFromCron === undefined && dayInMonthFromCron === undefined;
    if (isDaily && nextAppearanceTime.isBefore(now)) {
      nextAppearanceTime = nextAppearanceTime.add(1, 'day');
    }

    // Monthly Cron:
    if (dayInMonthFromCron) {
      nextAppearanceTime = nextAppearanceTime.set('date', dayInMonthFromCron);

      if (nextAppearanceTime.isBefore(now)) {
        nextAppearanceTime = nextAppearanceTime
          .add(1, 'month')
          .set('date', dayInMonthFromCron);
      }
    }

    // Weekly Cron:
    if (weekDayFromCron !== undefined) {
      const today = now.day();
      let daysUntilTarget = (weekDayFromCron - today + 7) % 7;

      if (daysUntilTarget === 0) {
        if (nextAppearanceTime.isBefore(now)) {
          daysUntilTarget = 7;
        }
      }

      nextAppearanceTime = nextAppearanceTime.add(daysUntilTarget, 'day');
    }

    return nextAppearanceTime.format(DATE_TIME_FORMAT_UTC);
  }
};

// ---- Validation Utilities ----
export const validationUtils = {
  getErrorMessage(
    startDate: Dayjs,
    endDate: Dayjs,
    diffMinutes: number,
    values: FormikProps,
    editRangeDatesValues: boolean | null
  ): string | null {
    const startHour = startDate.hour();
    const endHour = endDate.hour();
    const isInvalidTimeRange =
      startHour > endHour ||
      (startHour === endHour && startDate.minute() >= endDate.minute());

    if (!startDate || !endDate) return null;

    if (startDate.isSame(endDate, 'day')) {
      if (isInvalidTimeRange) {
        return 'Start time cannot be after or at the same time as the end time.';
      }
      if (diffMinutes <= 0) {
        return 'An appearance must have a duration greater than 0.';
      }
    } else if (diffMinutes > 100 * 24 * 60) {
      return "You can't select a time range that exceeds 100 days.";
    }

    if (
      timeUtils.isOverlapping(startDate, endDate, values) &&
      !editRangeDatesValues
    ) {
      return 'Selected time range overlaps with an existing range.';
    }

    return null;
  }
};
