import { useMutation, useQuery } from '@tanstack/react-query';

import { EFeatureFlag, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';
import useUsers from './useUsers';
import { useParams } from 'react-router';
import { useMemo } from 'react';
import { AxiosRequestHeaders } from 'axios';

export default function useImages(currentPublisherId?: string) {
  const axios = useAxios();
  const { portalId } = useParams();
  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });

  const hasMediaV2Enabled = useMemo(
    () => portalId ? true :
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_PROJECT_USE_MEDIA_V2
      ],
    [fetchFeatureFlags?.data]
  );

  const apiRoutes = useMemo(
    () => ({
      images: getApiRouteOrMock(
        hasMediaV2Enabled ? EApiRoutes.IMAGES_V2 : EApiRoutes.IMAGES
      ),
      deleteImage: getApiRouteOrMock(
        hasMediaV2Enabled
          ? EApiRoutes.DELETE_IMAGES_V2
          : EApiRoutes.DELETE_IMAGES
      ),
      addImage: getApiRouteOrMock(
        hasMediaV2Enabled ? EApiRoutes.POST_IMAGE_V2 : EApiRoutes.POST_IMAGE
      )
    }),
    [hasMediaV2Enabled]
  );

  const customHeaders: AxiosRequestHeaders | undefined = useMemo(() => {
    if (!hasMediaV2Enabled) return undefined;

    const headers: Record<string, string> = {};
    if (currentPublisherId) {
      headers['x-publishers'] = currentPublisherId;
    } else if (portalId) {
      headers['x-portal'] = portalId;
    }

    return headers as AxiosRequestHeaders;
  }, [hasMediaV2Enabled, currentPublisherId, portalId]);

  const getImages = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.IMAGES, hasMediaV2Enabled],
    queryFn: async () => {
      return await axios.get(apiRoutes.images, {}, customHeaders);
    },
    enabled:
      hasMediaV2Enabled !== undefined && !!currentPublisherId
  });

  const deleteImage = useMutation(async (imageId: string) => {
    return await axios.del(
      `${apiRoutes.deleteImage}/${imageId}`,
      {},
      customHeaders
    );
  });

  const addImage = useMutation(async (newImage: FormData) => {
    return await axios.post(apiRoutes.addImage, newImage, customHeaders);
  });

  return {
    addImage,
    getImages,
    deleteImage
  };
}
