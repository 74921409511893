import { ChangeEvent, useEffect } from 'react';

import { FormikProps } from 'common/contracts';
import { EIntervalsRepeatRequired, EWeekDay } from 'constants/enums';
import { permissionsUtil } from 'utils/permissionsUtil';
import { timeUtils } from 'utils/scheduleUtils';
import {
  dayInMonthOptions,
  intervalsRepeatOptionsRequired,
  roundedHoursOptions
} from 'utils/selectOptionsUtils';

import Input from 'components/Input/Input';
import InputSelectDropdown from 'components/InputSelectDropdown/InputSelectDropdown';
import RichText from 'components/RichText/RichText';

export interface IntervalOfferProps {
  formikProps: FormikProps;
  repeat: EIntervalsRepeatRequired;
  setRepeat: (repeat: EIntervalsRepeatRequired) => void;
  weekDay: number;
  setWeekDay: (day: number) => void;
  dayInMonth: number;
  setDayInMonth: (day: number) => void;
  publisherId?: string;
  startHour: string;
  setStartHour: (hour: string) => void;
  intervalCron: string;
  setIntervalCron: (cron: string) => void;
}

const IntervalOffer: React.FC<IntervalOfferProps> = ({
  formikProps,
  repeat,
  setRepeat,
  weekDay,
  setWeekDay,
  dayInMonth,
  setDayInMonth,
  publisherId,
  startHour,
  setStartHour,
  intervalCron,
  setIntervalCron
}) => {
  const { values, setFieldValue, handleBlur, handleChange, touched, errors } =
    formikProps;

  useEffect(() => {
    if (!repeat) {
      setIntervalCron('');
      return;
    }

    const newCron = timeUtils.generateCronString(
      repeat,
      startHour,
      weekDay as number,
      dayInMonth as number
    );

    if (newCron !== intervalCron) {
      setIntervalCron(newCron || '');
    }
  }, [repeat, startHour, weekDay, dayInMonth]);

  useEffect(() => {
    if (values?.schedule?.permanent) {
      setFieldValue('schedule', {
        permanent: values?.schedule?.permanent,
        timeFrames: values?.schedule?.timeFrames,
        ...(repeat && intervalCron && { interval: intervalCron })
      });
      setFieldValue(
        'startHour',
        timeUtils.getTimeFromCron(intervalCron || '')?.hourFromCron
      );
    }
  }, [intervalCron]);

  useEffect(() => {
    const interval = values?.schedule?.interval;

    if (!interval) {
      return;
    }

    const { dayInMonthFromCron, hourFromCron, weekDayFromCron } =
      timeUtils.getTimeFromCron(interval);

    const newRepeat = dayInMonthFromCron
      ? EIntervalsRepeatRequired.MONTHLY
      : weekDayFromCron !== undefined
        ? EIntervalsRepeatRequired.WEEKLY
        : EIntervalsRepeatRequired.DAILY;

    if (newRepeat !== repeat) setRepeat(newRepeat);
    if (dayInMonthFromCron && dayInMonthFromCron !== dayInMonth)
      setDayInMonth(dayInMonthFromCron);
    if (weekDayFromCron !== undefined && weekDayFromCron !== weekDay)
      setWeekDay(weekDayFromCron);
    if (hourFromCron && hourFromCron !== startHour) setStartHour(hourFromCron);
  }, [values?.schedule?.interval]);

  const weekDaysOptions = Object.entries(EWeekDay)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      label: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
      value
    }));

  const isSuperAdmin =
    publisherId && permissionsUtil.isSuperAdminByProject(publisherId);

  return (
    <div className="mt-8">
      <div className="flex flex gap-5 mb-6">
        <Input
          label="Availability"
          name="playerAvailability"
          placeholder="01"
          value={values.playerAvailability || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          isRequired={values?.setAsFree || values?.schedule?.interval}
          type="number"
          state={
            touched.playerAvailability && errors.playerAvailability
              ? 'error'
              : 'default'
          }
          description={errors.playerAvailability}
        />
        {/* Repeat */}
        <InputSelectDropdown
          label="Repeat"
          options={intervalsRepeatOptionsRequired}
          defaultSelected={intervalsRepeatOptionsRequired.find(
            (option) => option.value === repeat
          )}
          onOptionChange={(value: string) =>
            setRepeat(value as EIntervalsRepeatRequired)
          }
        />
      </div>
      <div className="flex flex gap-5">
        {/* Week Day */}
        {repeat === EIntervalsRepeatRequired.WEEKLY && (
          <InputSelectDropdown
            label="Week Day"
            options={weekDaysOptions}
            defaultSelected={weekDaysOptions.find(
              (option) => option.value === weekDay
            )}
          />
        )}
        {/* Day in Month */}
        {repeat === EIntervalsRepeatRequired.MONTHLY && (
          <InputSelectDropdown
            label="Day in Month"
            options={dayInMonthOptions}
            defaultSelected={dayInMonthOptions.find(
              (option) => option.value === dayInMonth
            )}
          />
        )}
        <div className="flex flex-col min-w-[220px]">
          {/* Hour (UTC) */}
          {isSuperAdmin ? (
            <Input
              label="Hour (UTC)"
              type="time"
              value={startHour}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setStartHour(e.target.value);
                setFieldValue('startHour', e.target.value);
              }}
              onBlur={handleBlur}
            />
          ) : (
            <InputSelectDropdown
              label="Hour (UTC)"
              options={roundedHoursOptions}
              defaultSelected={roundedHoursOptions.find(
                (option) => option.value === startHour
              )}
              onOptionChange={(value: string) => setStartHour(value as string)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IntervalOffer;
