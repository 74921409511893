import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { Table } from '@tanstack/react-table';
import { MAX_TABLE_ROWS } from 'constants/constants';

import Button from 'components/Button/Button';

const DataTablePagination = ({ table }: { table: Table<any> }) => {
  const pageIndex = table.getState().pagination.pageIndex;
  const totalRows = table.getFilteredRowModel().rows.length;

  const pageSize = Math.min(MAX_TABLE_ROWS, totalRows);
  const startItem = pageIndex * pageSize + 1;
  const endItem = Math.min((pageIndex + 1) * pageSize, totalRows);

  return (
    <div className="flex items-center justify-between py-4 w-full">
      <div className="text-sm text-base-mutedForeground">
        {totalRows > 0
          ? `Showing ${startItem}-${endItem} of ${totalRows} rows`
          : 'No data available'}
      </div>
      <div className="flex items-center gap-2">
        <Button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          variant="secondary"
        >
          <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
        </Button>
        <Button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          variant="secondary"
        >
          <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
        </Button>
      </div>
    </div>
  );
};

export default DataTablePagination;
