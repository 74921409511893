import { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { FilterCardProps } from 'common/contracts';

import Button from 'components/Button/Button';
import InputSelectDropdown from 'components/InputSelectDropdown/InputSelectDropdown';

const FilterDropdownCard: React.FC<FilterCardProps> = ({
  header,
  filterValue,
  setFilterValue,
  options = []
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterCardRef.current &&
        !filterCardRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (selectedValue: string) => {
    setFilterValue(selectedValue);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={filterCardRef}>
      <div
        className="flex items-center gap-2 border border-dashed border-gray-300 rounded-md px-3 py-2 cursor-pointer bg-white focus-within:border-gray-400"
        onClick={() => setIsOpen(!isOpen)}
      >
        {filterValue ? (
          <FontAwesomeIcon
            icon="fa-regular fa-circle-xmark"
            onClick={(e: React.MouseEvent<SVGElement>) => {
              e.stopPropagation();
              setFilterValue('');
            }}
          />
        ) : (
          <FontAwesomeIcon icon="fa-regular fa-plus-circle" />
        )}
        <div className="text-gray-700 text-sm truncate leading-[1.6rem]">
          <span className="font-medium">{header}</span>
          {filterValue && (
            <>
              <span className="mx-1 text-gray-300">|</span>
              <Button variant="secondary" className="h-6 font-normal">
                {options?.find((opt) => opt.value === filterValue)?.label ||
                  filterValue}
              </Button>
            </>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-md mt-1 z-10 p-3">
          <InputSelectDropdown
            label={header}
            options={options}
            defaultSelected={options?.find((opt) => opt.value === filterValue)}
            onOptionChange={handleSelect}
          />
        </div>
      )}
    </div>
  );
};

export default FilterDropdownCard;
