import React, {
  cloneElement,
  ComponentProps,
  FC,
  isValidElement,
  ReactElement
} from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { cn } from '../lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  UIInput,
  UILabel
} from '../ui';

export type InputProps = ComponentProps<typeof UIInput> & {
  label?: string;
  isHorizontal?: boolean;
  isRequired?: boolean;
  rightIcon?: ReactElement;
  rightIconStyles?: string;
  leftIcon?: ReactElement;
  leftIconStyles?: string;
  infoTooltipContent?: string;
  description?: string;
  state?: 'default' | 'error' | 'success';
};

const focusStateClasses = {
  default: 'focus:ring-base-ring',
  error: 'focus:ring-base-destructive',
  success: 'focus:ring-base-chart-2'
};

const descriptionStateClasses = {
  default: 'text-base-mutedForeground',
  error: 'text-base-destructive',
  success: 'text-base-chart-2'
};

const Input: FC<InputProps> = ({
  className,
  type,
  name,
  label,
  placeholder,
  isHorizontal = false,
  isRequired = false,
  disabled = false,
  rightIcon,
  rightIconStyles = 'w-4 h-4',
  leftIcon,
  leftIconStyles = 'w-4 h-4',
  state = 'default',
  infoTooltipContent,
  description,
  children,
  value,
  onChange,
  onBlur,
  ...rest
}) => (
  <div
    className={cn(
      'relative flex w-full',
      isHorizontal
        ? label
          ? 'items-top space-x-4'
          : 'items-top space-x-0'
        : 'flex-col',
      className
    )}
  >
    {label && (
      <div
        className={cn(
          'flex text-p-s mb-2',
          disabled ? 'text-base-mutedForeground' : 'text-base-foreground'
        )}
      >
        <UILabel
          htmlFor={'uiInput'}
          className={cn('flex text-p-s', isHorizontal ? 'pt-[10px]' : '')}
        >
          {isRequired && (
            <div className="mr-2 mt-1">
              <FontAwesomeIcon
                icon="fa-regular fa-asterisk"
                className="h-2 w-2 text-[8px]"
              />
            </div>
          )}
          {label}
        </UILabel>
        {infoTooltipContent && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className={cn(
                    'cursor-pointer text-base-input ml-2',
                    isHorizontal ? 'pt-2' : ''
                  )}
                >
                  <FontAwesomeIcon icon="fa-regular fa-circle-question" />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="right"
                align="center"
                className="rounded-md border border-base-popover bg-base-popover shadow-md py-[6px] px-[12px]"
              >
                <span className="text-p-s text-base-mutedForeground">
                  {infoTooltipContent}
                </span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    )}

    <div className="flex-1">
      <div className="relative">
        <UIInput
          {...rest}
          id={'uiInput'}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(
            'border-base-input placeholder:text-base-mutedForeground shadow-none h-10 bg-base-background text-base-foreground text-p-s focus:ring-2 focus:outline-none placeholder-base-muted-foreground px-[12px] py-2 overflow-hidden text-ellipsis whitespace-nowrap',
            rightIcon ? 'pr-10' : '',
            leftIcon ? 'pl-10' : '',
            focusStateClasses[state]
          )}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="off"
        />
        {leftIcon && (
          <div
            className={cn(
              'absolute left-3 transform -translate-y-1/2 w-4 h-4',
              'top-1/2',
              disabled || state === 'error'
                ? 'text-base-mutedForeground'
                : 'text-base-foreground'
            )}
          >
            {isValidElement(leftIcon)
              ? cloneElement(leftIcon as ReactElement, {
                  className: leftIconStyles
                })
              : leftIcon}
          </div>
        )}
        {rightIcon && (
          <div
            className={cn(
              'absolute right-3 transform -translate-y-1/2 w-4 h-4',
              'top-1/2',
              disabled || state === 'error'
                ? 'text-base-mutedForeground'
                : 'text-base-foreground'
            )}
          >
            {isValidElement(rightIcon)
              ? cloneElement(rightIcon as ReactElement, {
                  className: rightIconStyles
                })
              : rightIcon}
          </div>
        )}
      </div>
      {description && (
        <div
          className={cn(
            'text-p-s mt-2',
            disabled
              ? 'text-base-mutedForeground'
              : descriptionStateClasses[state]
          )}
        >
          {description}
        </div>
      )}
    </div>
  </div>
);

export default Input;
