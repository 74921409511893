import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../../Button/Button';
import Input from '../../Input/Input';
import { Tab, Tabs, TabsList } from '../../Tabs/Tabs';
import {
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../ui/dialog';
import { Form } from 'components/ui';
import { TabsContent } from '../../ui/tabs';

import MediaContent from './MediaContent';
import UploadContent from './UploadContent';
import { useForm } from 'react-hook-form';
import {
  EImageUploadModalTabs,
  IMAGE_UPLOAD_TABS,
  IMG_UPLOAD_FORM_SCHEMA
} from './constants';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import useImages from '../../../api/useImages';
import { EToasterStatus, showToast } from '../../../utils/showToast';

type ImageUploadModalProps = {};

const ImageUploadModal: FC<ImageUploadModalProps> = ({}) => {
  const { addImage } = useImages();

  const initialValues = {
    idNumber: '',
    image: null
  };

  const imgUploadFormConfig = useForm<z.infer<typeof IMG_UPLOAD_FORM_SCHEMA>>({
    resolver: zodResolver(IMG_UPLOAD_FORM_SCHEMA),
    defaultValues: initialValues,
    mode: 'onChange'
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid, isDirty }
  } = imgUploadFormConfig;

  const handleImgUploadFormSubmit = async (
    data: Record<string, string | File | null>
  ) => {
    try {
      const trimmedImageId = (data.idNumber as string).trim();
      const uploadConfig = {
        onUploadSuccess: () => {},
        uploadType: 'general', // TODO replace to form value
        name: trimmedImageId
      };
      const file = data.image;
      if (!file) return;
      const formData = new FormData();
      formData.append('image', file as any);
      formData.append('type', 'general' as string);
      formData.append('name', uploadConfig!.name as string);
      await addImage.mutateAsync(formData);
      showToast({
        message: 'File has been uploaded successfully',
        status: EToasterStatus.SUCCESS
      });
      reset(initialValues);
    } catch (error: any) {
      showToast({
        message:
          error?.response?.data?.message ||
          'File has failed to upload. Please try again',
        status: EToasterStatus.ERROR
      });
    }
  };

  return (
    <Form {...imgUploadFormConfig}>
      <DialogContent className="bg-base-card overflow-hidden p-0 max-w-[646px] max-h-[680px] h-full w-full gap-0">
        <DialogHeader className="bg-base-muted py-4 px-6 flex flex-row items-center justify-between">
          <DialogTitle className="text-base-foreground text-p-l font-semibold">
            Upload Image
          </DialogTitle>
          <DialogClose asChild>
            <FontAwesomeIcon
              icon="fa-regular fa-xmark"
              className="!mt-0 text-base-secondaryForeground cursor-pointer"
            />
          </DialogClose>
        </DialogHeader>

        <Tabs
          defaultValue={EImageUploadModalTabs.UPLOAD}
          className="px-6 pt-6 mt-2.5 h-[520px]"
        >
          <div className="flex items-center">
            <TabsList tabs={IMAGE_UPLOAD_TABS} className="mb-4" />
            <TabsContent
              value={EImageUploadModalTabs.MEDIA}
              className="mt-0 mb-4 ml-auto"
            >
              <Input
                rightIcon={
                  <FontAwesomeIcon icon="fa-regular fa-magnifying-glass" />
                }
                placeholder="Search"
                className="h-10"
                isHorizontal
              />
            </TabsContent>
          </div>

          <TabsContent value={EImageUploadModalTabs.UPLOAD} className="mt-0">
            <UploadContent
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </TabsContent>

          <TabsContent value={EImageUploadModalTabs.MEDIA} className="mt-0">
            <MediaContent />
          </TabsContent>
        </Tabs>

        <DialogFooter className="bg-base-muted p-6">
          <DialogClose asChild>
            <Button type="button" variant="link">
              Cancel
            </Button>
          </DialogClose>
          <Button
            type="submit"
            disabled={!isValid}
            onClick={handleSubmit(handleImgUploadFormSubmit)}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Form>
  );
};

export default ImageUploadModal;
