import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { Offer } from '../common/contracts';
import { PAGINATION_ROWS_PER_PAGE } from '../constants/constants';
import {
  EProductType,
  EQueryKeys,
  OfferType,
  SortingDirection,
  SortingOffersValue
} from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export interface Pagination {
  currentPage: number;
  rowsPerPage: number;
}

export interface OffersSorting {
  sortValue: SortingOffersValue;
  direction: SortingDirection;
}

export interface UseOffersProps {
  offerId?: string;
  offerType?: OfferType | OfferType[];
  customPagination?: Pagination;
  sorting?: OffersSorting;
  fetchOnlyActiveOffers?: boolean;
  shouldIgnorePagination?: boolean;
}

export default function useOffers({
  offerId,
  customPagination,
  fetchOnlyActiveOffers,
  shouldIgnorePagination = false,
  offerType = OfferType.BUNDLE,
  sorting = {
    sortValue: SortingOffersValue.CREATED_AT,
    direction: SortingDirection.DESC
  }
}: UseOffersProps) {
  const axios = useAxios();

  const pagination = getPagination(shouldIgnorePagination, customPagination);

  const offset = pagination && pagination.currentPage * pagination.rowsPerPage;
  const recordLimit = pagination && pagination.rowsPerPage;

  const { publisherId } = useParams();
  const { id } = useParams<{ id: string }>();
  const currentPublisherId = publisherId ?? id;

  const { sortValue, direction } = sorting;

  const getOffers = useQuery({
    ...fetchConfig.list,
    queryKey: [`${EQueryKeys.OFFERS}${offerType.toString()}`],
    queryFn: async () => {
      const payload = {
        offerType,
        offset,
        recordLimit,
        direction,
        sortValue,
        active: fetchOnlyActiveOffers
      };

      const response = await axios.get(
        getApiRouteOrMock(EApiRoutes.OFFERS),
        payload,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );

      if (response.result && response.result.offers) {
        // New format
        const totalCount = response.result.totalCount;
        return {
          offers: [...response.result.offers],
          currentPage: pagination?.currentPage || 0,
          rowsPerPage: pagination?.rowsPerPage || 25,
          totalCount
        } as {
          offers: Offer[];
        };
      } else {
        // Old format
        return response;
      }
    },
    enabled: !!currentPublisherId
  });

  const getOffer = useQuery({
    ...fetchConfig.single,
    queryKey: [`${EQueryKeys.OFFERS}_${offerId}`],
    queryFn: async () => {
      return await axios.get(
        `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerId}`,
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    enabled: !!currentPublisherId && !!offerId
  });

  const addOffer = useMutation(
    async (newOffer: Partial<Offer> & { sectionId?: string | null }) => {
      return await axios.post(getApiRouteOrMock(EApiRoutes.OFFERS), newOffer);
    },
    {}
  );

  const addPopUpOffer = useMutation(
    async (newOffer: Partial<Offer> & { sectionId?: string | null }) => {
      return await axios.post(
        getApiRouteOrMock(EApiRoutes.POPUP_OFFERS),
        newOffer
      );
    },
    {}
  );

  const deleteOffer = useMutation(async (offerIdToDelete: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerIdToDelete}`
    );
  }, {});

  const updateOffer = useMutation(
    async ({ form, offerId }: { form: Partial<Offer>; offerId: string }) => {
      return axios.put(
        `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerId}`,
        form
      );
    },
    {}
  );

  const updatePopUpOffer = useMutation(
    async ({ form, offerId }: { form: Partial<Offer>; offerId: string }) => {
      return axios.put(
        `${getApiRouteOrMock(EApiRoutes.POPUP_OFFERS)}/${offerId}`,
        form
      );
    },
    {}
  );

  const updatePopUpActiveStatus = useMutation(
    async ({ active, offerId }: { active: boolean; offerId: string }) => {
      const url = `${getApiRouteOrMock(EApiRoutes.POPUP_OFFERS)}/${offerId}`;
      return axios.patch(url, { active });
    },
    {}
  );

  const formatProductQuantity = useMutation(
    async (productQuantityData: {
      quantity: string;
      productType: EProductType;
    }) => {
      return await axios.post(
        getApiRouteOrMock(EApiRoutes.PRODUCT_QUANTITY_FORMAT),
        productQuantityData
      );
    }
  );

  return {
    getOffer,
    getOffers,
    addOffer,
    addPopUpOffer,
    deleteOffer,
    updateOffer,
    updatePopUpOffer,
    updatePopUpActiveStatus,
    formatProductQuantity
  };
}

export const defaultPagination = {
  currentPage: 0,
  rowsPerPage: PAGINATION_ROWS_PER_PAGE
};

const getPagination = (
  shouldIgnorePagination: boolean,
  customPagination?: Pagination
): Pagination | undefined => {
  if (shouldIgnorePagination) {
    return undefined;
  }
  return customPagination ?? defaultPagination;
};
