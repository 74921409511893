import React, { FC, ReactNode } from 'react';

import { cn } from '../lib/utils';
import { UIButton } from '../ui';

import { useButtonIcons } from './useButtonIcons';

export type ButtonProps = React.ComponentProps<typeof UIButton> & {
  children?: ReactNode;
  icon?: ReactNode;
  isLoading?: boolean;
  isCreateButton?: boolean;
  isUploadButton?: boolean;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
};

const sizeStyles: Record<string, string> = {
  sm: 'h-9',
  default: 'h-10',
  icon: 'h-10 w-10',
  lg: 'h-11'
};

const variantStyles: Record<string, string> = {
  default:
    'bg-base-primary text-base-primaryForeground hover:bg-button-hover-default',
  secondary:
    'bg-base-secondary text-base-secondaryForeground hover:bg-button-hover-secondary',
  destructive:
    'bg-base-destructive text-base-destructiveForeground hover:bg-button-hover-destructive',
  outline:
    'bg-base-background text-base-foreground border-base-input hover:bg-base-accent',
  ghost: 'bg-transparent text-base-foreground hover:bg-base-accent',
  link: 'text-base-primary hover:text-base-primary'
};

const Button: FC<ButtonProps> = ({
  children,
  icon,
  rightIcon,
  leftIcon,
  className,
  size = 'default',
  variant = 'default',
  isLoading = false,
  disabled = false,
  isCreateButton = false,
  isUploadButton = false,
  ...rest
}) => {
  const { leftIconElement, rightIconElement } = useButtonIcons({
    leftIcon,
    rightIcon,
    isLoading,
    isCreateButton,
    isUploadButton
  });

  const uploadButtonClasses =
    isUploadButton &&
    cn(
      'text-base-secondaryForeground bg-base-border border border-base-input relative hover:bg-button-hover-upload',
      children &&
        'pl-4 pr-3 gap-6 after:content-[""] after:h-[calc(100%+2px)] after:absolute after:w-px after:bg-neutral-50 after:right-10',
      size === 'lg' && 'pl-8 gap-9'
    );

  const computedClassName = cn(
    'shadow-none text-p-s',
    sizeStyles[size as keyof typeof UIButton],
    variantStyles[variant as keyof typeof UIButton],
    isCreateButton && 'bg-indigo-700 text-white hover:bg-button-hover-create',
    uploadButtonClasses,
    className
  );

  return (
    <UIButton
      {...rest}
      size={size}
      variant={variant}
      disabled={isLoading || disabled}
      className={computedClassName}
    >
      {leftIconElement}
      {children}
      {rightIconElement}
    </UIButton>
  );
};

export default Button;
