import React, { FC } from 'react';

import { cn } from '../lib/utils';
import Typography from '../Typography/Typography';
import { UILabel, UIRadioGroupItem } from '../ui';

export type RadioButtonOptionsProps = {
  value: string;
  label?: string;
  id?: string;
};

export type RadioButtonProps = React.ComponentProps<typeof UIRadioGroupItem> &
  RadioButtonOptionsProps & {
    description?: string;
    className?: string;
  };

const RadioButton: FC<RadioButtonProps> = ({
  value,
  label,
  id,
  description,
  className
}) => {
  return (
    <div
      className={cn(
        'flex items-center',
        description && 'items-start',
        className
      )}
    >
      <UIRadioGroupItem
        className={cn(
          'shadow-none border-base-primary [&>span>svg]:data-[state=checked]:fill-base-primary [&>span>svg]:h-3 [&>span>svg]:w-3',
          description && 'mt-1'
        )}
        value={value}
        id={id}
      />
      {label && (
        <div className="ml-2">
          <UILabel className="text-p-s text-base-foreground mb-1" htmlFor={id}>
            {label}
          </UILabel>
          {description && (
            <Typography className="text-p-s text-base-mutedForeground">
              {description}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default RadioButton;
