import React, { useMemo, useState } from 'react';

import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import useLocalization from 'api/useLocalization';
import countries from 'i18n-iso-countries';
import {
  DELETE_LANGUAGE_SUCCESS_MSG,
  EDIT_LANGUAGE_SUCCESS_MSG
} from 'views/Localization/constants/constants';

import AcCard from 'components/AcCard/AcCard';

import { EToasterStatus, showToast } from '../../../../utils/showToast';
import { LanguageDataParams } from '../types';

import AddedLanguagesTable from './components/AddedLanguagesTable/AddedLanguagesTable';
import { LanguageTableRow } from './components/AddedLanguagesTable/AddedLanguagesTable.types';
import AddLanguageForm from './components/AddLanguageForm/AddLanguageForm';
import DeleteLanguageModal from './components/DeleteLanguageModal/DeleteLanguageModal';
import EditLanguageModal from './components/EditLanguageModal/EditLanguageModal';

import './style.scss';

const LanguagesListSection = () => {
  const [isManageLngModalOpen, setIsManageLngModalOpen] = useState(false);
  const [isDeleteLngModalOpen, setIsDeleteLngModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageTableRow | null>(null);
  const {
    getAddedLanguagesList,
    editLanguage,
    deleteLanguage,
    addNewLanguage
  } = useLocalization();
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countriesList = countries.getNames('en');

  const handleAddNewLanguage = (data: LanguageDataParams[]) => {
    addNewLanguage.mutate(data, {
      onSuccess: () => {
        getAddedLanguagesList.refetch();
        showToast({
          message: 'New language was added successfully',
          status: EToasterStatus.SUCCESS
        });
      },
      onError: (err: any) => {
        showToast({
          message:
            err?.response?.data?.message ||
            'New language was not added. Please try again',
          status: EToasterStatus.SUCCESS
        });
      }
    });
  };

  const editSelectedLanguage = (updatedLanguagesList: LanguageDataParams[]) => {
    setSelectedLanguage(null);
    editLanguage.mutate(updatedLanguagesList, {
      onSuccess: () => {
        getAddedLanguagesList.refetch();
        showToast({
          message: EDIT_LANGUAGE_SUCCESS_MSG,
          status: EToasterStatus.SUCCESS
        });
      },
      onError: (data: any) => {
        showToast({
          message: data.response.data.message,
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const handleEditLanguageSelection = (langToEdit: LanguageDataParams[]) => {
    if (!selectedLanguage) return;
    const updatedLanguagesList = getAddedLanguagesList.data.filter(
      (data: LanguageDataParams) => data.language !== selectedLanguage.language
    );

    langToEdit.forEach((item) => {
      updatedLanguagesList.push(item);
    });

    setIsManageLngModalOpen(false);
    setSelectedLanguage(null);
    editSelectedLanguage(updatedLanguagesList);
  };

  const handleDeleteLanguage = () => {
    const updatedLanguagesList = getAddedLanguagesList.data.filter(
      (item: LanguageDataParams) => item.language !== selectedLanguage?.language
    );

    setIsDeleteLngModalOpen(false);
    setSelectedLanguage(null);
    deleteLanguage.mutate(updatedLanguagesList, {
      onSuccess: () => {
        getAddedLanguagesList.refetch();
        showToast({
          message: DELETE_LANGUAGE_SUCCESS_MSG,
          status: EToasterStatus.SUCCESS
        });
      },
      onError: (data: any) => {
        showToast({
          message: data.response.data.message,
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const parsedLanguagesList = useMemo(() => {
    if (!getAddedLanguagesList.data) return [];
    return getAddedLanguagesList.data
      .filter((item: LanguageDataParams) => item.language !== 'English')
      .map((language: LanguageDataParams) => {
        const countryName = Object.entries(countriesList).find(
          ([key]) => key === language.country
        )?.[1];
        return {
          ...language,
          country: countryName,
          countryCode: language.country
        };
      });
  }, [getAddedLanguagesList.data, countriesList]);

  return (
    <Grid container sx={{ marginTop: '22px' }}>
      <Grid size={12}>
        {
          <AcCard
            title={'1. Manage Languages'}
            description={
              'Choose the language display name and define the countries where this language will be applied for users'
            }
            stackContainer={false}
            className="languagesList"
            customDescriptionClass="languagesListdDescription"
            customTitleClass="sectionTitle"
            customStyles={{ border: '1px solid #cacbd4', borderRadius: '4px' }}
          >
            <Stack>
              <Grid container direction="column">
                <Grid>
                  <AddLanguageForm onSave={handleAddNewLanguage} />
                </Grid>
                <Grid>
                  <AddedLanguagesTable
                    languagesList={parsedLanguagesList}
                    isLoading={getAddedLanguagesList?.isLoading}
                    selectedLanguage={selectedLanguage}
                    setIsDeleteLngModalOpen={setIsDeleteLngModalOpen}
                    setSelectedLanguage={setSelectedLanguage}
                    setIsManageLngModalOpen={setIsManageLngModalOpen}
                    editSelectedLanguage={editSelectedLanguage}
                  />
                </Grid>
              </Grid>
            </Stack>
          </AcCard>
        }
      </Grid>
      <EditLanguageModal
        currentLanguage={selectedLanguage}
        countriesList={countriesList}
        isOpen={isManageLngModalOpen}
        onClose={() => {
          setIsManageLngModalOpen(false);
          setSelectedLanguage(null);
        }}
        onSave={(data: LanguageDataParams[]) =>
          handleEditLanguageSelection(data)
        }
      />
      <DeleteLanguageModal
        isOpen={isDeleteLngModalOpen}
        setIsDeleteModalOpen={setIsDeleteLngModalOpen}
        handleDeleteLanguage={handleDeleteLanguage}
        handleSelectedLanguage={setSelectedLanguage}
      />
    </Grid>
  );
};

export default LanguagesListSection;
