import React, {
  FC,
  useCallback,
  DragEvent,
  useRef,
  ChangeEvent,
  useState,
  MouseEvent
} from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../../Button/Button';
import Input from 'components/Input/Input';
import { cn } from '../../lib/utils';
import Typography from '../../Typography/Typography';
import { FormField, FormItem, UIInput } from '../../ui';
import { Control, FieldErrors } from 'react-hook-form';
import { FormControl } from '@mui/material';
import { IMG_UPLOAD_FORM_SCHEMA } from './constants';
import { z } from 'zod';
import { EToasterStatus, showToast } from '../../../utils/showToast';
import { ImagePreview } from '../ImagePreview';

type uploadContentProps = {
  control: Control<any>;
  setValue: (
    fieldName: any,
    file: File | null,
    p: { shouldValidate: boolean }
  ) => void;
  errors: FieldErrors<z.infer<typeof IMG_UPLOAD_FORM_SCHEMA>>;
};

const UploadContent: FC<uploadContentProps> = ({
  control,
  setValue,
  errors
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const fileSrcRef = useRef<string | null>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setValue('image', file, { shouldValidate: true });
    fileSrcRef.current = URL.createObjectURL(file);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const onDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(false);
      const file = e.dataTransfer.files[0];
      if (!file) return;
      if (file.size > 2000000) {
        showToast({
          message: 'File size limit is 2MB',
          status: EToasterStatus.ERROR
        });
        return;
      }
      fileSrcRef.current = URL.createObjectURL(file);
      setValue('image', file, { shouldValidate: true });
    },
    [setValue]
  );

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const handleRemoveImage = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    fileSrcRef.current = null;
    setValue('image', null, { shouldValidate: true });
  };

  return (
    <div className="flex flex-col gap-4">
      <div
        onDragOver={(e) => handleDragOver(e)}
        onDragLeave={handleDragLeave}
        onDrop={(e) => onDrop(e)}
        className={cn(
          'w-full h-[300px] flex flex-col items-center justify-center bg-violet-50 rounded-md border-dashed border border-indigo-200',
          isDragging && 'border-indigo-600 border-solid'
        )}
      >
        {fileSrcRef?.current ? (
          <ImagePreview
            preview={fileSrcRef.current}
            handleButtonClick={() => handleButtonClick()}
            handleRemoveImage={handleRemoveImage}
          />
        ) : (
          <>
            <Typography className="text-p-xl text-indigo-600 mb-0.5">
              Start adding your files
            </Typography>
            <Typography className="text-p-s text-base-mutedForeground">
              Drag and drop files or upload from your computer
            </Typography>
            <Button
              className="mt-6"
              isUploadButton
              rightIcon={<FontAwesomeIcon icon="fa-solid fa-plus" />}
              onClick={handleButtonClick}
            >
              Upload
            </Button>
          </>
        )}
        <FormField
          name="image"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <UIInput
                  type="file"
                  accept="image/png, image/jpeg, image/svg+xml, img/gif"
                  ref={inputRef}
                  onChange={(e) => handleInputChange(e)}
                  className="hidden"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      <div className="w-full">
        <FormField
          name="idNumber"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormItem>
              <Input
                placeholder="* ID Number"
                value={value}
                onChange={onChange}
                isRequired={true}
                state={errors.idNumber?.message ? 'error' : 'default'}
                description={errors.idNumber?.message}
              />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default UploadContent;
