import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import useIntegration from 'api/useIntegration';
import useTheme from 'api/useTheme';

import { defaultCompletedTheme } from './defaults';

export const useGetCompletedInitialValues = () => {
  const location = useLocation();

  const { publisherId } = useParams();

  const [initialValues, setInitialValue] = useState<any>({});

  const { getCompletedTheme: completedTheme } = useTheme(publisherId);

  const { getIntegration } = useIntegration(publisherId);

  useEffect(() => {
    setInitialValue({
      headerText:
        completedTheme.data?.headerText || defaultCompletedTheme.headerText,
      headerColor: {
        colorOne:
          completedTheme.data?.headerColor?.colorOne ||
          defaultCompletedTheme.headerColor.colorOne,
        colorTwo:
          completedTheme.data?.headerColor?.colorTwo ||
          defaultCompletedTheme.headerColor.colorTwo,
        gradientDirection:
          completedTheme.data?.headerColor?.gradientDirection ||
          defaultCompletedTheme.headerColor.gradientDirection
      },
      headerSize:
        completedTheme.data?.headerSize || defaultCompletedTheme.headerSize,
      headerWeight:
        completedTheme.data?.headerWeight || defaultCompletedTheme.headerWeight,
      text: completedTheme.data?.text || defaultCompletedTheme.text,
      textColor:
        completedTheme.data?.textColor || defaultCompletedTheme.textColor,
      textSize: completedTheme.data?.textSize || defaultCompletedTheme.textSize,
      textWeight:
        completedTheme.data?.textWeight || defaultCompletedTheme.textWeight,
      backToGameButtonText:
        completedTheme.data?.backToGameButtonText ||
        defaultCompletedTheme.backToGameButtonText,
      deepLinks: {
        ios:
          getIntegration.data?.deepLinks?.find(
            (platform: { platform: string; deepLink: string }) =>
              platform.platform === 'ios'
          )?.deepLink || defaultCompletedTheme.deepLinks.ios,
        android:
          getIntegration.data?.deepLinks?.find(
            (platform: { platform: string; deepLink: string }) =>
              platform.platform === 'android'
          )?.deepLink || defaultCompletedTheme.deepLinks.android,
        web:
          getIntegration.data?.deepLinks?.find(
            (platform: { platform: string; deepLink: string }) =>
              platform.platform === 'web'
          )?.deepLink || defaultCompletedTheme.deepLinks.web
      }
    });
  }, [completedTheme?.data, getIntegration.data, location]);

  return { initialValues };
};
