import { EFontWeight, ELogoSize } from 'constants/enums';

import { LoginThemeFormValues } from './types';

export const defaultLoginTheme: LoginThemeFormValues = {
  text: 'Welcome to a new members-only clubs',
  font: 'montserrat',
  textColor: 'white',
  textSize: 28,
  textWeight: EFontWeight.Bold,
  loginLogoImage: '',
  loginBackgroundImageMobile: '',
  loginBackgroundImageDesktop: '',
  loginLogoSize: ELogoSize.SMALL,
  otpButton: {
    backgroundColor: {
      colorOne: '#7122ff',
      colorTwo: ''
    },
    text: 'OTP Text',
    icon: '',
    textColor: {
      colorOne: '#7122ff',
      colorTwo: ''
    }
  },
  consentSection: {
    htmlText:
      '<p>By logging in you acknowledge that you have read and agree to the Privacy Policy And Terms and Conditions</p>',
    consentRequired: false
  },
  accountApprovalModal: {
    backgroundImage: 'https://media.appcharge.com/defaults/background.png',
    backgroundColor: {
      colorOne: '#7122ff',
      colorTwo: ''
    },
    borderColor: '#FFFFFF',
    borderWidth: 0,
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: {
      colorOne: '#7122ff',
      colorTwo: ''
    },
    textColor: '#FFFFFF',
    additionalText: ''
  }
};
