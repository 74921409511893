import { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';

import AcInput from 'components/AcInput/AcInput';

const IntegrationLinkCell = ({ link }: { link: string }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  return (
    <Box className={'integraion-profile-cell'}>
      <Tooltip
        title="Copied to clipboard"
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <ContentCopyOutlinedIcon onClick={copyToClipboard} />
      </Tooltip>
      <AcInput value={link} disabled={true} />
    </Box>
  );
};

export default IntegrationLinkCell;
