import { FontAwesomeIcon } from '@appcharge/shared-ui';
import Typography from '../../components/Typography/Typography';

const WipBanner = ({ setShowBanner }: { setShowBanner: () => void }) => {
  return (
    <div className="fixed bottom-0 left-0 bg-[linear-gradient(to_top,rgba(255,255,255,1)_60%,rgba(255,255,255,0.15)_100%)] right-0 pb-6 pt-10 pl-12 pr-[18px] shadow-md flex items-center justify-between z-[100] before:absolute before:inset-0 before:content-[''] before:bg-[linear-gradient(0deg,rgba(129,140,248,0.20)_60%,transparent_100%)] before:z-[-1] before:blur">
      <div className="flex items-center gap-x-4">
        <div>
          <img
            alt="appcharge-icon"
            src="./assets/icons/ac-icon-purple.png"
            className="min-h-[43px] h-[43px] min-w-[43px] w-[43px]"
          />
        </div>
        <div>
          <Typography className="text-p-s font-bold text-indigo-950">
            We're launching Dashboard 2.0!
          </Typography>
          <Typography tag="p" className="text-p-s text-indigo-950">
            Enjoy a smoother, more powerful interface as we roll out exciting
            upgrades. You may notice a mix of new and familiar UI—it's all part
            of making your experience even better!
          </Typography>
        </div>
      </div>
      <FontAwesomeIcon
        icon="fa-regular fa-xmark"
        className="text-base-foreground opacity-70 cursor-pointer"
        onClick={setShowBanner}
      />
    </div>
  );
};

export default WipBanner;
