import { useState } from 'react';

import { Box } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { GOOGLE_AUTH_CLIENT_ID } from '../../constants/constants';
import { required } from '../../utils/errorsTextHelper';
import AcInput from '../AcInput/AcInput';
import ActionButton from '../ActionButton/ActionButton';

import { GoogleLogin } from './GoogleLogin/GoogleLogin';
import { LoginFormProps } from './LoginForm.types';

import './style.scss';

const LoginForm = ({
  login,
  setAnimateFailed,
  setLoginNotification,
  setModalState
}: LoginFormProps) => {
  let loginSchema = yup.object().shape({
    userName: yup
      .string()
      .required(required('Email'))
      .email('Enter a valid email'),
    password: yup.string().required(required('Password')),
    rememberMe: yup.boolean()
  });

  const onLoginFailed = () => {
    setLoginNotification({
      msg: 'There was a problem with your login credentials, please try again.',
      type: 'error'
    });
    setAnimateFailed(true);
    setTimeout(() => {
      setAnimateFailed(false);
    }, 1000);
  };

  const {
    initialValues,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    isValid
  } = useFormik({
    initialValues: {
      userName: '',
      password: '',
      rememberMe: true
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const formattedUserName = values.userName.toLowerCase().trim();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/dashboard/user/login`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            body: JSON.stringify({
              userName: formattedUserName,
              password: values.password,
              rememberMe: values.rememberMe
            }),
            credentials: 'include'
          }
        );

        if (!response.ok) {
          throw new Error('Login failed');
        }

        login(
          {
            userName: formattedUserName,
            password: values.password,
            rememberMe: values.rememberMe
          },
          (success: boolean) => {
            setIsLoading(false);
            if (!success) {
              onLoginFailed();
            }
          }
        );
      } catch (error) {
        setIsLoading(false);
        onLoginFailed();
      }
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <Box className="login__header">
        <h1>Login</h1>
      </Box>
      <Box className="body">
        <form autoComplete="off">
          <Box className="login__input">
            <label>Email</label>
            <AcInput
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  submitForm();
                }
              }}
              name="userName"
              value={values.userName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.userName && !!errors.userName}
              helperText={(touched.userName && errors.userName) || ''}
              dataTestId={'login-email-input'}
              autoComplete="username"
            />
          </Box>
          <Box className="login__input">
            <label>Password</label>
            <AcInput
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  submitForm();
                }
              }}
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              dataTestId={'login-password-input'}
              autoComplete="current-password"
            />
          </Box>
          <Box className="login__actions">
            <span onClick={() => setModalState('forgetPassword')}>
              Forgot Password?
            </span>
          </Box>
        </form>
        <ActionButton
          disabled={
            isLoading ||
            !!Object.keys(errors).length ||
            !isValid ||
            initialValues === values
          }
          color={'primary'}
          onClick={() => {
            submitForm();
          }}
          style={{
            textTransform: 'capitalize',
            marginTop: '1em',
            border: 'none'
          }}
          isLoading={isLoading}
          text={'Login'}
        />
        <div className={'login-divider'} style={{ position: 'relative' }}>
          <p className={'login-divider__text'}>Or</p>
        </div>
        <GoogleLogin login={login} onLoginFailed={onLoginFailed} />
      </Box>
    </GoogleOAuthProvider>
  );
};

export default LoginForm;
