import React, { ReactNode, useMemo } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { cn } from '../lib/utils';

type UseButtonIconsProps = {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isLoading: boolean;
  isCreateButton: boolean;
  isUploadButton: boolean;
};

export const useButtonIcons = ({
  leftIcon,
  rightIcon,
  isLoading,
  isCreateButton,
  isUploadButton
}: UseButtonIconsProps) => {
  const leftIconElement = useMemo(() => {
    if (isUploadButton) return null;
    if (isLoading) return <FontAwesomeIcon icon="fa-solid fa-loader fa-spin" />;
    if (isCreateButton)
      return <FontAwesomeIcon icon="fa-regular fa-circle-plus" />;
    return leftIcon || null;
  }, [isUploadButton, isLoading, isCreateButton, leftIcon]);

  const rightIconElement = useMemo(() => {
    if (isCreateButton) return null;
    if (isUploadButton && isLoading)
      return <FontAwesomeIcon icon="fa-solid fa-loader fa-spin" />;
    if (isUploadButton)
      return rightIcon ? (
        rightIcon
      ) : (
        <FontAwesomeIcon icon={cn('fa-regular fa-database')} />
      );
    return rightIcon || null;
  }, [isCreateButton, isLoading, isUploadButton, rightIcon]);

  return { leftIconElement, rightIconElement };
};
