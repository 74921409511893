import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import useTheme from 'api/useTheme';

import { defaultStoreSettings } from './defaults';
import { StoreSettingsFormValues } from './generalTheme.types';

export const useGetStoreSettingsInitialValues = () => {
  const location = useLocation();
  const { publisherId } = useParams();

  const [initialValues, setInitialValue] =
    useState<StoreSettingsFormValues>(defaultStoreSettings);
  const { getGeneralTheme: generalTheme } = useTheme(publisherId);

  useEffect(() => {
    setInitialValue({
      productsQuantityFormat: {
        milSeparator:
          generalTheme.data?.productsQuantityFormat?.milSeparator ||
          defaultStoreSettings.productsQuantityFormat.milSeparator,
        fractionalSeparator:
          generalTheme.data?.productsQuantityFormat?.fractionalSeparator ||
          defaultStoreSettings.productsQuantityFormat.fractionalSeparator,
        thousandShorthand:
          generalTheme.data?.productsQuantityFormat?.thousandShorthand ||
          defaultStoreSettings.productsQuantityFormat.thousandShorthand,
        millionShorthand:
          generalTheme.data?.productsQuantityFormat?.millionShorthand ||
          defaultStoreSettings.productsQuantityFormat.millionShorthand,
        billionShorthand:
          generalTheme.data?.productsQuantityFormat?.billionShorthand ||
          defaultStoreSettings.productsQuantityFormat.billionShorthand
      },
      balanceQuantityFormat: {
        milSeparator:
          generalTheme.data?.balanceQuantityFormat?.milSeparator ||
          defaultStoreSettings.balanceQuantityFormat.milSeparator,
        fractionalSeparator:
          generalTheme.data?.balanceQuantityFormat?.fractionalSeparator ||
          defaultStoreSettings.balanceQuantityFormat.fractionalSeparator,
        thousandShorthand:
          generalTheme.data?.balanceQuantityFormat?.thousandShorthand ||
          defaultStoreSettings.balanceQuantityFormat.thousandShorthand,
        millionShorthand:
          generalTheme.data?.balanceQuantityFormat?.millionShorthand ||
          defaultStoreSettings.balanceQuantityFormat.millionShorthand,
        billionShorthand:
          generalTheme.data?.balanceQuantityFormat?.billionShorthand ||
          defaultStoreSettings.balanceQuantityFormat.billionShorthand
      },
      specialOfferQuantityFormat: {
        milSeparator:
          generalTheme.data?.specialOfferQuantityFormat?.milSeparator ||
          defaultStoreSettings.specialOfferQuantityFormat.milSeparator,
        fractionalSeparator:
          generalTheme.data?.specialOfferQuantityFormat?.fractionalSeparator ||
          defaultStoreSettings.specialOfferQuantityFormat.fractionalSeparator,
        thousandShorthand:
          generalTheme.data?.specialOfferQuantityFormat?.thousandShorthand ||
          defaultStoreSettings.specialOfferQuantityFormat.thousandShorthand,
        millionShorthand:
          generalTheme.data?.specialOfferQuantityFormat?.millionShorthand ||
          defaultStoreSettings.specialOfferQuantityFormat.millionShorthand,
        billionShorthand:
          generalTheme.data?.specialOfferQuantityFormat?.billionShorthand ||
          defaultStoreSettings.specialOfferQuantityFormat.billionShorthand
      }
    });
  }, [generalTheme.data, location]);

  return { initialValues };
};
