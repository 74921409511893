import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { serializeDate } from './formattingUtil';

dayjs.extend(utc);

interface DateRangeAppliedProps {
  range: [Dayjs, Dayjs];
  setRangeDates: (dates: [Dayjs, Dayjs]) => void;
  setFormattedRangeDates?: (formattedDates: [string, string]) => void;
  setPreviousFormattedRangeDates?: (
    previousFormattedDates: [string, string]
  ) => void;
  isUTC?: boolean;
}

interface SameDateRangeAppliedProps extends DateRangeAppliedProps {
  hours: [string, string];
}

export const handleDateRangeApplied = ({
  range,
  setRangeDates,
  setFormattedRangeDates,
  setPreviousFormattedRangeDates
}: DateRangeAppliedProps) => {
  const [from, to] = range;
  setRangeDates([from, to]);

  if (setFormattedRangeDates) {
    const formattedFrom = serializeDate(from.toDate(), false, from.isUTC());
    const formattedTo = serializeDate(to.toDate(), true, to.isUTC());

    setFormattedRangeDates([formattedFrom, formattedTo]);
  }

  if (setPreviousFormattedRangeDates) {
    const daysDiff = from.diff(to, 'day');
    const previousFrom = from.subtract(Math.abs(daysDiff || 1), 'day');
    const previousTo = to
      .subtract(Math.abs(daysDiff || 1), 'day')
      .add(1, 'second');

    const formattedPreviousFrom = serializeDate(
      previousFrom.toDate(),
      false,
      previousFrom.isUTC()
    );
    const formattedPreviousTo = serializeDate(
      previousTo.toDate(),
      true,
      previousTo.isUTC()
    );

    setPreviousFormattedRangeDates([
      formattedPreviousFrom,
      formattedPreviousTo
    ]);
  }
};

export const handleSameDateRangeApplied = ({
  range,
  hours,
  setRangeDates,
  setFormattedRangeDates,
  setPreviousFormattedRangeDates,
  isUTC
}: SameDateRangeAppliedProps) => {
  const datetimeObjects = range.map((date: any, index: any) => {
    const [hour, minute] = hours[index].split(':');

    return new Date(
      isUTC ? date.$d.getUTCFullYear() : date.$d.getFullYear(),
      isUTC ? date.$d.getUTCMonth() : date.$d.getMonth(),
      isUTC ? date.$d.getUTCDate() : date.$d.getDate(),
      parseInt(hour),
      parseInt(minute),
      0,
      0
    );
  });
  setRangeDates([dayjs(datetimeObjects[0]), dayjs(datetimeObjects[1])]);

  if (setFormattedRangeDates) {
    const formattedFrom = serializeDate(datetimeObjects[0], false, isUTC);
    const formattedTo = serializeDate(datetimeObjects[1], false, isUTC);

    setFormattedRangeDates([formattedFrom, formattedTo]);
  }

  if (setPreviousFormattedRangeDates) {
    const timeDifference =
      datetimeObjects[1].getTime() - datetimeObjects[0].getTime();
    const previousStartTime = new Date(
      datetimeObjects[0].getTime() - timeDifference
    );
    const previousEndTime = new Date(
      datetimeObjects[1].getTime() - timeDifference
    );

    const formattedPreviousFrom = serializeDate(
      previousStartTime,
      false,
      isUTC
    );
    const formattedPreviousTo = serializeDate(previousEndTime, false, isUTC);

    setPreviousFormattedRangeDates([
      formattedPreviousFrom,
      formattedPreviousTo
    ]);
  }
};
