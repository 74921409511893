import { SyntheticEvent, useMemo, useState, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useIntegration from 'api/useIntegration';
import usePermissions from 'api/usePermissions';
import usePublisher from 'api/usePublisher';
import useUsers from 'api/useUsers';
import { EPublisherSolutionModel, User } from 'common/contracts';
import { EPermissionGroup } from 'common/permissions.types';
import { EFeatureFlag, ELocalStorageKeys } from 'constants/enums';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { useProfilesManager } from 'hooks/useProfilesManager';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';

import PageTopBar from '../Topbar/PageTopBar';

import { ESettingsState } from './Settings.types';

import './style.scss';

const SettingsView = () => {
  const { publisherId } = useParams();
  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;
  const { getPublisher } = usePublisher(publisherId);
  const { getIntegration } = useIntegration(publisherId);
  const { isProfilesFeatureFlagOn, isProfilesEnabled } = useProfilesManager();
  const { getPermissions } = usePermissions();
  const permissions = getPermissions();
  const location = useLocation();
  const navigate = useNavigate();

  const projectItem = projectsDetails?.find(
    (item) => item.publisherId === publisherId
  );

  const isCheckout =
    projectItem?.projectType === EPublisherSolutionModel.CHECKOUT;
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const hasFeatureFlagEventCenter =
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_EVENT_CENTER];

  const handleAuthTabShown = () => {
    return (
      !isCheckout ||
      permissionsUtil.shouldHideGroupInPermission(
        permissions,
        EPermissionGroup.AUTHENTICATION,
        getPublisher.data?._id
      )
    );
  };

  const isCheckoutUser =
    localStorageUtil.get(ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL) ===
    EPublisherSolutionModel.CHECKOUT;

  const [tab, setTab] = useState('company');

  const getTabs = useMemo(() => {
    const tabs = [];
    const isCompanyTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.COMPANY,
      getPublisher.data?._id
    );
    const isUsersTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.USERS,
      getPublisher.data?._id
    );
    const isIntegrationsTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.INTEGRATION,
      getPublisher.data?._id
    );
    const isIntegrationsProfilesTabShown =
      isIntegrationsTabShown && isProfilesFeatureFlagOn && isProfilesEnabled;
    const isSuperAdminByProject = permissionsUtil.isSuperAdminByProject(
      getPublisher.data?._id || ''
    );
    const isStoreSettingsTabShown =
      (isSuperAdminByProject ||
        permissionsUtil.isAdminByProject(getPublisher.data?._id || '')) &&
      !isCheckoutUser;

    if (isCompanyTabShown) {
      tabs.push({ label: 'Company', value: ESettingsState.COMPANY });
    }

    if (isUsersTabShown) {
      tabs.push({ label: 'Users', value: ESettingsState.USERS });
    }

    if (isIntegrationsTabShown) {
      tabs.push({ label: 'Integration', value: ESettingsState.INTEGRATION });
    }

    if (isIntegrationsProfilesTabShown) {
      tabs.push({
        label: 'Integration Profiles',
        value: ESettingsState.PROFILES
      });
    }

    if (handleAuthTabShown()) {
      tabs.push({ label: 'Authentication', value: ESettingsState.PLAYER_AUTH });
    }

    if (isSuperAdminByProject) {
      tabs.push({ label: 'Payments', value: ESettingsState.PAYMENTS });
      if (!isCheckoutUser) {
        tabs.push({ label: 'Store Layout', value: ESettingsState.LAYOUT });
      }
    }

    if (
      isSuperAdminByProject ||
      (permissionsUtil.isAdminByProject(getPublisher.data?._id || '') &&
        hasFeatureFlagEventCenter)
    ) {
      tabs.push({ label: 'Event Center', value: ESettingsState.EVENTS });
    }

    if (isStoreSettingsTabShown) {
      const storeSettingsTabData = {
        label: 'Store Settings',
        value: ESettingsState.STORE_SETTINGS
      };
      const storeLayoutTabData = tabs.find(
        (tab) => tab.value === ESettingsState.LAYOUT
      );
      if (storeLayoutTabData) {
        tabs.splice(
          tabs.indexOf(storeLayoutTabData) + 1,
          0,
          storeSettingsTabData
        );
      } else {
        tabs.push({
          label: 'Store Settings',
          value: ESettingsState.STORE_SETTINGS
        });
      }
    }

    return tabs;
  }, [permissions, getPublisher.data?._id, handleAuthTabShown]);

  const handleTabChange = (event: SyntheticEvent, newValue: ESettingsState) => {
    getIntegration.refetch();
    setTab(newValue);
    const newPath = `/project/${publisherId}/settings/${newValue}`;
    navigate(newPath);
  };

  return (
    <TabsPanel
      className="settings-view"
      tabs={getTabs}
      headerComponent={<PageTopBar withTabsDesign={true} headline="Settings" />}
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};

export default SettingsView;
