import { useState } from 'react';

import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import useUrlQuery from '../../hooks/useUrlQuery';
import { required } from '../../utils/errorsTextHelper';
import AcInput from '../AcInput/AcInput';
import ActionButton from '../ActionButton/ActionButton';
import Typography from '../Typography/Typography';

import { PasswordResetFormProps } from './ResetPasswordForm.types';

import './style.scss';
YupPassword(yup);

const ResetPasswordForm = ({
  setNewPassword,
  setModalState
}: PasswordResetFormProps) => {
  const token = useUrlQuery('token');
  const email = useUrlQuery('email');
  let resetPasswordSchema = yup.object().shape({
    password1: yup.string().password().required(required('Password')),
    password2: yup
      .string()
      .oneOf([yup.ref('password1'), null], 'Passwords must match')
      .required()
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      password1: '',
      password2: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = {
        email,
        rawNewPassword: values.password1,
        token
      };
      setNewPassword(data, (success: boolean) => {
        setIsLoading(false);
        if (success) setModalState('login');
      });
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Box className="header mb-4">
        <Typography className="text-p-l font-bold mb-4">
          Reset Password
        </Typography>
        <Typography className="text-p-s">
          Enter your email and we'll send you instructions on how to reset your
          password.
        </Typography>
      </Box>
      <Box className="body">
        <form
          className="flex flex-col gap-y-4"
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
          }}
        >
          <Box className="input !mb-0">
            <AcInput header="Email" value={email} disabled />
          </Box>
          <Box className="input !mb-0">
            <AcInput
              header="New Password"
              name="password1"
              type="password"
              value={values.password1}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password1 && Boolean(errors.password1)}
              helperText={touched.password1 ? errors.password1?.toString() : ''}
            />
            <Typography className="mt-1 text-[10px] text-[#717188] font-medium">
              Your password must be at least 8 characters long and contain at
              least one capital letter (A-Z), one lowercase letter (a-z), and
              one numeral (0-9).
            </Typography>
          </Box>
          <Box className="input !mb-0">
            <AcInput
              header="Confirm Password"
              name="password2"
              type="password"
              value={values.password2}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password2 && Boolean(errors.password2)}
              helperText={touched.password2 ? errors.password2?.toString() : ''}
            />
          </Box>
          <Box>
            <p
              onClick={() => setModalState('login')}
              className="login__actions text-p-s"
            >
              Go back to login view
            </p>
          </Box>
          <ActionButton
            disabled={!!Object.keys(errors).length || !isValid || !dirty}
            type="submit"
            onClick={submitForm}
            variant="contained"
            style={{
              textTransform: 'capitalize'
            }}
            isLoading={isLoading}
            text="Send"
          />
        </form>
      </Box>
    </>
  );
};

export default ResetPasswordForm;
