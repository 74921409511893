import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Grid, Stack } from '@mui/material';
import useProfiles from 'api/useProfiles';
import {
  IS_NO_IP_ONLY_HTTPS,
  PROFILES_DEFAULT_NAME
} from 'constants/constants';
import { useFormik } from 'formik';
import { required } from 'utils/errorsTextHelper';
import { textModelOptions } from 'utils/selectOptionsUtils';
import * as yup from 'yup';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';

import { EToasterStatus, showToast } from '../../../utils/showToast';
import { ProfileData, ProfileField } from '../Settings.types';

import ProfileCard from './ProfileCard';

import './style.scss';

export enum EActions {
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete'
}

const IntegrationProfiles = () => {
  const [profiles, setProfiles] = useState<ProfileData[]>([]);
  const [editedProfile, setEditedProfile] = useState<ProfileData>();
  const { publisherId } = useParams();
  const { getProfiles, updateProfile, createProfile } = useProfiles(
    publisherId as string
  );

  const webhooksSchema = yup.object().shape({
    name: yup
      .string()
      .required(required('Name'))
      .test('unique', 'Name must be unique', (value: any) => {
        if (editedProfile && editedProfile.name === value) {
          return true;
        }
        return !profiles.some((p: ProfileData) => p.name === value);
      }),
    ordersReportingApiUrl: yup
      .string()
      .required(required('Order reporting URL'))
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    playerInfoSyncUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    eventsWebhookUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    otpDeepLink: yup.object().shape({
      otpGenerateDeeplinkUrl: yup
        .string()
        .test(
          'Must be a valid URL, starting with https://, and not an IP address',
          'Must be a valid URL, starting with https://, and not an IP address',
          (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
        ),
      otpTextModel: yup.string().when('otpGenerateDeeplinkUrl', {
        is: (url: string | undefined) => Boolean(url?.trim()),
        then: yup
          .string()
          .required(
            'OTP Text Model is required when OTP Generate Deeplink URL is set'
          ),
        otherwise: yup.string().notRequired()
      }),
      otpOn: yup.boolean()
    }),
    playersAuthWebhook: yup
      .string()
      .required(required('Login authentication URL'))
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    fbAppId: yup.string()
  });

  const integrationProfileForm = useFormik({
    initialValues: {
      name: '',
      playerInfoSyncUrl: '',
      playersAuthWebhook: '',
      ordersReportingApiUrl: '',
      eventsWebhookUrl: '',
      fbAppId: '',
      otpDeepLink: {
        otpGenerateDeeplinkUrl: '',
        otpOn: false,
        otpTextModel: ''
      }
    },
    validationSchema: webhooksSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      resetForm();
      if (
        values.otpDeepLink.otpGenerateDeeplinkUrl &&
        values.otpDeepLink.otpTextModel
      ) {
        values.otpDeepLink.otpOn = true;
      }
      if (values) {
        saveProfile(
          values as ProfileData,
          editedProfile ? EActions.UPDATE : EActions.CREATE
        );
      }
    }
  });

  const saveProfile = (profile: ProfileData, action: EActions) => {
    if (
      !profile.otpDeepLink?.otpGenerateDeeplinkUrl &&
      !profile.otpDeepLink?.otpTextModel
    ) {
      delete profile.otpDeepLink;
    }
    if (action === EActions.CREATE) {
      createProfileHandler(profile);
    } else {
      updateProfileHandler(profile);
    }
  };

  const createProfileHandler = (newProfile: ProfileData) => {
    createProfile.mutate(newProfile, {
      onSuccess: (response: any) => {
        const newProfilesList = [...profiles, response.data];
        setProfiles(newProfilesList);
        showToast({
          message: 'Integration profile created successfully',
          status: EToasterStatus.SUCCESS
        });
      },
      onError: () => {
        setProfiles(getProfiles.data!);
        showToast({
          message: 'Failed to create Integration profile',
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const updateProfileHandler = (newProfile: ProfileData) => {
    setEditedProfile(undefined);
    updateProfile.mutate(
      { ...newProfile, _id: editedProfile!._id },
      {
        onSuccess: (response: any) => {
          const newProfilesList = [...profiles, response];
          setProfiles(newProfilesList);
          showToast({
            message: `Integration profile updated successfully`,
            status: EToasterStatus.SUCCESS
          });
        },
        onError: () => {
          setProfiles(getProfiles.data!);
          showToast({
            message: `Failed to update Integration profile`,
            status: EToasterStatus.ERROR
          });
        }
      }
    );
  };

  const cancelEditProfile = () => {
    setEditedProfile(undefined);
    integrationProfileForm.resetForm();
  };

  useEffect(() => {
    if (!getProfiles.data) return;
    setProfiles(getProfiles.data);
  }, [getProfiles.data]);

  return (
    <Grid container className="formContent">
      <Grid item xs={12} className="formContent-minWIdth">
        <Stack>
          <AcCard
            stackContainer={false}
            title="Integration Profiles"
            description="You can create and manage multiple profiles to personalize your experience in your sandbox. These profiles will be available every time you visit the webstore, making it simple to switch between them and customize your webhooks to ease your testing proccess."
          >
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={6}>
                <AcInput
                  header="Profile Name"
                  name="name"
                  value={integrationProfileForm.values.name}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  disabled={
                    integrationProfileForm.values.name === PROFILES_DEFAULT_NAME
                  }
                  error={
                    integrationProfileForm.touched.name &&
                    Boolean(integrationProfileForm.errors.name)
                  }
                  helperText={
                    integrationProfileForm.touched.name
                      ? integrationProfileForm.errors.name?.toString()
                      : ''
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Orders Reporting API"
                  name="ordersReportingApiUrl"
                  value={integrationProfileForm.values.ordersReportingApiUrl}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.ordersReportingApiUrl &&
                    Boolean(integrationProfileForm.errors.ordersReportingApiUrl)
                  }
                  helperText={
                    integrationProfileForm.touched.ordersReportingApiUrl
                      ? integrationProfileForm.errors.ordersReportingApiUrl?.toString()
                      : ''
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header={ProfileField.PERSONALIZATION_API_URL}
                  name="playerInfoSyncUrl"
                  value={integrationProfileForm.values.playerInfoSyncUrl}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.playerInfoSyncUrl &&
                    Boolean(integrationProfileForm.errors.playerInfoSyncUrl)
                  }
                  helperText={
                    integrationProfileForm.touched.playerInfoSyncUrl
                      ? integrationProfileForm.errors.playerInfoSyncUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Authentication Webhook"
                  name="playersAuthWebhook"
                  value={integrationProfileForm.values.playersAuthWebhook}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched?.playersAuthWebhook &&
                    Boolean(integrationProfileForm.errors?.playersAuthWebhook)
                  }
                  helperText={
                    integrationProfileForm.touched?.playersAuthWebhook
                      ? integrationProfileForm.errors?.playersAuthWebhook?.toString()
                      : ''
                  }
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <AcInput
                  header="Events Webhook"
                  name="eventsWebhookUrl"
                  value={integrationProfileForm.values.eventsWebhookUrl}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched?.eventsWebhookUrl &&
                    Boolean(integrationProfileForm.errors?.eventsWebhookUrl)
                  }
                  helperText={
                    integrationProfileForm.touched?.eventsWebhookUrl
                      ? integrationProfileForm.errors?.eventsWebhookUrl?.toString()
                      : ''
                  }
                  customClass="url-input"
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Facebook ID"
                  name="fbAppId"
                  value={integrationProfileForm.values.fbAppId}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.fbAppId &&
                    Boolean(integrationProfileForm.errors.fbAppId)
                  }
                  helperText={
                    integrationProfileForm.touched.fbAppId
                      ? integrationProfileForm.errors.fbAppId?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="OTP Generation Deeplink Url"
                  name="otpDeepLink.otpGenerateDeeplinkUrl"
                  value={
                    integrationProfileForm.values.otpDeepLink
                      .otpGenerateDeeplinkUrl
                  }
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.otpDeepLink
                      ?.otpGenerateDeeplinkUrl &&
                    Boolean(
                      integrationProfileForm.errors.otpDeepLink
                        ?.otpGenerateDeeplinkUrl
                    )
                  }
                  helperText={
                    integrationProfileForm.touched.otpDeepLink
                      ?.otpGenerateDeeplinkUrl
                      ? integrationProfileForm.errors.otpDeepLink?.otpGenerateDeeplinkUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AcSelect
                  header="Desktop Text Model"
                  name="otpDeepLink.otpTextModel"
                  value={integrationProfileForm.values.otpDeepLink.otpTextModel}
                  items={textModelOptions}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.otpDeepLink?.otpTextModel &&
                    Boolean(
                      integrationProfileForm.errors.otpDeepLink?.otpTextModel
                    )
                  }
                  helperText={
                    integrationProfileForm.touched.otpDeepLink?.otpTextModel
                      ? integrationProfileForm.errors.otpDeepLink?.otpTextModel?.toString()
                      : ''
                  }
                  required={
                    !!integrationProfileForm.values.otpDeepLink
                      .otpGenerateDeeplinkUrl.length
                  }
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Grid item mt={3} xs={6}>
          <ActionButton
            variant="outlined"
            text={editedProfile ? 'Update Profile' : 'Add Profile'}
            onClick={integrationProfileForm.submitForm}
            disabled={
              !integrationProfileForm.isValid ||
              !integrationProfileForm.dirty ||
              integrationProfileForm.isSubmitting
            }
          />
          <ActionButton
            style={{ marginLeft: '12px' }}
            variant="outlined"
            text={'Cancel'}
            onClick={cancelEditProfile}
            disabled={!editedProfile}
          />
        </Grid>
      </Grid>
      <Grid item mt={3} xs={12}>
        <Grid container spacing={2}>
          {profiles.map((profile) => (
            <Grid item xs={12} key={profile.name}>
              <ProfileCard
                profile={profile}
                publisherId={publisherId}
                setValues={integrationProfileForm.setValues}
                setEditedProfile={setEditedProfile}
                setProfiles={setProfiles}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationProfiles;
