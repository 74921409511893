import { useMemo } from 'react';

import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { EFeatureFlag } from 'constants/enums';

import Chip from '../../../components/Chip/Chip';
import { cn } from '../../../components/lib/utils';
import { projectTypeRestrictions } from '../constants';
import { useSidebar } from '../context';
import { SidebarMenuButton } from '../elements';
import { useSidebarUrl } from '../hooks';
import {
  ESideBarChipType,
  ESideBarContext,
  ESidebarGroupKey,
  SideBarItem
} from '../types';

interface SidebarItemProps {
  item: SideBarItem;
  groupTitle?: string;
}

export const SidebarMenuItemComponent = ({
  item,
  groupTitle
}: SidebarItemProps) => {
  const { context, isCheckout, userDetails, featureFlags } = useSidebar();
  const location = useLocation();
  const { getNavFullUrl } = useSidebarUrl(context);

  const getChip = (type: ESideBarChipType | undefined) => {
    const sidebarChipType: Record<ESideBarChipType, any> = {
      coming_soon: {
        styles: 'border-zinc-100 bg-zinc-100 text-zinc-500',
        title: 'Coming Soon'
      }
    };

    if (!type) return null;

    return (
      <Chip
        variant="bordered"
        className={cn('w-full ml-auto', sidebarChipType[type].styles)}
      >
        {sidebarChipType[type].title}
      </Chip>
    );
  };

  // Determine if item should be disabled
  const { isDisabled, chip, conditionalOnClick } = useMemo(() => {
    let isDisabled = item?.isDisabled;
    let chip = item?.chip;
    let conditionalOnClick;

    // Handle checkout restrictions
    if (isCheckout && context === ESideBarContext.PROJECT) {
      const publisherId = location.pathname
        .split('/project/')[1]
        ?.split('/')[0];
      const currentProject = userDetails?.projects?.find(
        (project) => project.publisherId === publisherId
      );

      if (currentProject?.projectType === 'independentCheckout' && groupTitle) {
        const disabledItems =
          projectTypeRestrictions.independentCheckout.disabledItems[
            groupTitle as ESidebarGroupKey
          ];
        if (
          disabledItems &&
          disabledItems.some(
            (disabledItem: SideBarItem) => disabledItem.title === item.title
          )
        ) {
          isDisabled = true;
        }
      }
    }

    // Handle feature flag restrictions
    if (item.title === 'Rolling' && featureFlags) {
      if (!featureFlags[EFeatureFlag.DASHBOARD_ROLLING_OFFER]) {
        chip = ESideBarChipType.COMING_SOON;
        conditionalOnClick = () =>
          window.open(
            'https://developers.appcharge.com/docs/rolling-offers',
            '_blank'
          );
      }
    }

    return { isDisabled, chip, conditionalOnClick };
  }, [
    item,
    isCheckout,
    context,
    location.pathname,
    userDetails?.projects,
    featureFlags,
    groupTitle
  ]);

  const navUrl = getNavFullUrl(item);

  const basicContent = (
    <>
      {typeof item.icon === 'string' ? (
        <FontAwesomeIcon className="p-3" icon={cn('fa-regular', item.icon)} />
      ) : (
        item.icon
      )}
      <span className="flex-1 min-w-0 truncate">{item.title}</span>
      {getChip(chip)}
    </>
  );

  return (
    <SidebarMenuButton
      asChild
      isActive={location.pathname.includes(navUrl)}
      isDisabled={isDisabled}
    >
      {isDisabled ? (
        <div className="flex items-center gap-2">{basicContent}</div>
      ) : (
        <Link to={navUrl} onClick={conditionalOnClick}>
          {basicContent}
        </Link>
      )}
    </SidebarMenuButton>
  );
};
