import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';
import useUsers from 'api/useUsers';
import { AxiosError } from 'axios';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { useFormik } from 'formik';
import { featureFlagUtil } from 'utils/featureFlagUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import * as yup from 'yup';

import useImages from '../../api/useImages';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcSearch from '../../components/AcSearch/AcSearch';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import ImageItem from '../../components/ImageItem/ImageItem';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { EFeatureFlag } from '../../constants/enums';
import { EToasterStatus, showToast } from '../../utils/showToast';

import { getAssetItemValue } from './modals/constants/constants';
import { AssetsCategories } from './modals/constants/enums';
import DeleteAssetModal from './modals/DeleteAssetModal';
import UploadAssetModal from './modals/UploadAssetModal';

import './style.scss';
import '../view.scss';

interface Filter {
  name: string;
  type?: string;
}

export interface AssetsFormValues {
  file: File | null;
  imageId: string;
  category: string;
}

const AssetsView = () => {
  const { publisherId } = useParams();
  const pictures = useImages(publisherId).getImages;
  const { addImage, getImages } = useImages(publisherId);
  const [isUploading, setIsUploading] = useState(false);
  const [filter, setFilter] = useState<Filter>({ name: '', type: 'All' });
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isUploadImageOpen, setUploadImageOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState<string>('');
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const hasFeatureFlagCompressUploadedImages =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_COMPRESS_UPLOADED_IMAGES
    ];

  let imageUploadSchema = yup.object().shape({
    file: yup.mixed().required('Image file is required'),
    imageId: yup
      .string()
      .required('ID / Name is required')
      .test(
        'is-not-only-spaces',
        'ID / Name cannot be only spaces',
        (value) => {
          return (value || '').trim().length > 0;
        }
      )
  });

  const {
    values,
    handleBlur,
    submitForm,
    resetForm,
    setFieldValue,
    isValid,
    isSubmitting
  } = useFormik({
    validateOnMount: true,
    validationSchema: imageUploadSchema,
    enableReinitialize: true,
    initialValues: {
      file: null,
      imageId: '',
      category: getAssetItemValue(AssetsCategories.general)
    },
    onSubmit: async (values: AssetsFormValues, { resetForm }) => {
      const trimmedImageId = values.imageId.trim();
      const uploadConfig = {
        onUploadSuccess: async (uploadData: any) => {
          await pictures.refetch();
        },
        uploadType: values.category,
        name: trimmedImageId
      };
      const file = values.file;
      const formData = new FormData();
      if (!file) return;
      if (file && file.size > 2000000) {
        showToast({
          message: 'File size limit is 2MB',
          status: EToasterStatus.ERROR
        });
        return;
      }
      setIsUploading(true);
      formData.append('image', file as any);
      formData.append('type', uploadConfig!.uploadType as string);
      formData.append('name', uploadConfig!.name as string);
      addImage.mutate(formData, {
        onSuccess: async (data) => {
          showToast({
            message: hasFeatureFlagCompressUploadedImages
              ? `File has been compressed and uploaded successfully`
              : `File has been uploaded successfully`,
            status: EToasterStatus.SUCCESS
          });
          uploadConfig!.onUploadSuccess?.(data);

          resetForm();
          setUploadImageOpen(false);
        },
        onError(error) {
          console.log({ error });
          showToast({
            message: 'Asset has failed to upload',
            status: EToasterStatus.ERROR,
            description: (
              (error as AxiosError).response?.data as {
                message: string;
              }
            ).message
          });
        },
        onSettled() {
          setIsUploading(false);
        }
      });
    }
  });

  if (getImages.isError) {
    showToast({
      message: 'Could not load assets list from server',
      status: EToasterStatus.ERROR
    });
  }

  const filterSearch = (value: string, category?: string) => {
    if (value !== filter.name || category !== filter.type) {
      setFilter({
        name: value,
        type: category
      });
    }
  };

  const openDeleteDialog = (imageId: string) => {
    setDeleteDialogOpen(true);
    setSelectedImageId(imageId);
  };

  const uploadAsset = () => {
    setUploadImageOpen(true);
  };

  return (
    <AcViewWrapper
      header={
        <PageTopBar
          headline="Assets"
          buttons={[
            {
              text: 'Upload Asset',
              action: () => uploadAsset(),
              hidden: !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.ASSET_LIBRARY,
                EPermissionAction.MANAGE
              )
            }
          ]}
        />
      }
    >
      <AcContentWrapper>
        <AcSearch
          categories={Object.keys(AssetsCategories).map(
            (key) => AssetsCategories[key as keyof typeof AssetsCategories]
          )}
          callback={filterSearch}
        />
        <Box className="gallery">
          {getImages.isLoading ? (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: '60px',
                width: '60px'
              }}
            />
          ) : getImages.data.length > 0 ? (
            getImages.data?.map((image: any, i: number) => {
              if (
                (filter.type ===
                  AssetsCategories[
                    image.type as keyof typeof AssetsCategories
                  ] ||
                  filter.type === 'All') &&
                (image.name.toLowerCase().includes(filter.name.toLowerCase()) ||
                  filter.name === '')
              )
                return (
                  <ImageItem
                    key={i}
                    deleteImage={openDeleteDialog}
                    data={image}
                    classes={'products'}
                    uploading={false}
                  />
                );
              return null;
            })
          ) : (
            <FirstActionModal
              headline="Your asset library is still empty"
              text="Any visual you’ll add here (art elements, background images etc.) will be available to use in your offers"
            />
          )}
        </Box>
        <DeleteAssetModal
          isModalOpen={isDeleteDialogOpen}
          onModalClosed={() => setDeleteDialogOpen(false)}
          setDeleteDialogOpen={setDeleteDialogOpen}
          selectedImageId={selectedImageId}
        />
        <UploadAssetModal
          onUpload={() => submitForm()}
          isUploadDisabled={!isValid || isSubmitting}
          isModalOpen={isUploadImageOpen}
          onModalClosed={() => {
            resetForm();
            setUploadImageOpen(false);
          }}
          setFieldValue={setFieldValue}
          isUploading={isUploading}
          imageId={values.imageId}
          category={values.category}
          handleBlur={handleBlur}
        />
      </AcContentWrapper>
    </AcViewWrapper>
  );
};

export default AssetsView;
