import { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import usePublisher from 'api/usePublisher';
import useRoles from 'api/useRoles';
import useUsers from 'api/useUsers';
import { User, UserRole, UserRoleV2 } from 'common/contracts';
import { EButtonColor } from 'constants/enums';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useFormik } from 'formik';
import { required } from 'utils/errorsTextHelper';
import { capitalizeFirstLetter } from 'utils/formattingUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import * as yup from 'yup';

import { EToasterStatus, showToast } from '../../../utils/showToast';
import ActionButton from '../../ActionButton/ActionButton';
import DataTable from '../../DataTable/DataTable';
import DialogModal from '../../Dialog/Dialog';

import UserSettingsDrawer from './UserSettingsDrawer';
import { getUsersColumns } from './utils';

import './style.scss';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const UsersSettings = () => {
  const [addUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogUserId, setDialogUserId] = useState<string>('');
  const { getRoles } = useRoles();

  const v2UserRolesOptions = useMemo(() => {
    return getRoles.data?.map((role: UserRoleV2) => {
      return {
        value: role.roleId,
        content: capitalizeFirstLetter(role.roleName),
        key: role.roleId
      };
    });
  }, [getRoles.data]);

  const { publisherId } = useParams();

  const users = useUsers({ publisherId, enableFeatureFlags: false });
  const publisherUsers = usePublisher(publisherId).getUsersByPublisher;
  const [isChangeRoleDialogOpen, setIsChangeRoleDialogOpen] = useState(false);
  const [selectedRoleChange, setSelectedRoleChange] =
    useState<UserRole | null>();
  const [selectedUser, setSelectedUser] =
    useState<Pick<User, 'userId' | 'name'>>();
  const openDeleteDialog = (userId: string) => {
    setDialogUserId(userId);
    setDeleteDialogOpen(true);
  };

  const deleteUser = (id: string) => {
    users.deleteUser.mutate(id, {
      onSuccess: (data: any) => {
        publisherUsers.refetch();
        showToast({
          message: 'User has been deleted',
          status: EToasterStatus.SUCCESS
        });
      },
      onError: (data: any) => {
        showToast({
          message: `Something went wrong while trying to delete the user`,
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const deleteUserHandler = () => {
    deleteUser(dialogUserId);
  };

  const handleChangeUserRole = (e: any, id: string, name: string) => {
    setSelectedRoleChange(e.target.value);
    setSelectedUser({
      userId: id,
      name
    });
    setIsChangeRoleDialogOpen(true);
  };

  const resendUserInvitation = (email: string) => {
    users.resendUserInvitation.mutate(
      { email },
      {
        onSuccess: () => {
          showToast({
            message: `The invitation has been successfully resent and the user has been notified`,
            status: EToasterStatus.SUCCESS
          });
        },
        onError: () => {
          showToast({
            message: `Something went wrong while trying to resend invitation for the user`,
            status: EToasterStatus.ERROR
          });
        }
      }
    );
  };

  const columnsV2 = useMemo(() => {
    return permissionsUtil.isAdmin()
      ? getUsersColumns(
          handleChangeUserRole,
          openDeleteDialog,
          permissionsUtil.isAdmin(),
          v2UserRolesOptions,
          resendUserInvitation
        )
      : getUsersColumns(
          handleChangeUserRole,
          openDeleteDialog,
          false,
          v2UserRolesOptions
        );
  }, [v2UserRolesOptions]);

  let addUserSchema = yup.object().shape({
    name: yup.string().required(required('Name')),
    email: yup.string().required(required('Email')).email('Enter a valid email')
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: 'admin'
    },
    validationSchema: addUserSchema,
    onSubmit: async (formData) => {
      setAddUserDrawerOpen(false);
      formData.name = formData.name.trim();
      (formData as any).publisherId = publisherId ?? '';
      (formData as any).email = formData.email.toLowerCase().trim();
      users.addUser.mutate(formData, {
        onSuccess: () => {
          resetForm();
          publisherUsers.refetch();
          showToast({
            message: `${formData.name} has been invited to AppCharge dashboard`,
            status: EToasterStatus.SUCCESS
          });
        },
        onError: (error: any) => {
          resetForm();
          showToast({
            message:
              error.response.data.message ??
              `Something went wrong while trying to invite a user`,
            status: EToasterStatus.ERROR
          });
        }
      });
    }
  });

  const changeUserPermission = (userId: string, role: UserRole | string) => {
    users.updateRoleV2.mutate(
      {
        roleId: role as string,
        userId
      },
      {
        onSuccess: () => {
          showToast({
            message: `User permission has been updated`,
            status: EToasterStatus.SUCCESS
          });
          publisherUsers.refetch();
        },
        onError: () => {
          showToast({
            message: `Something went wrong while trying to update user permission`,
            status: EToasterStatus.ERROR
          });
        },
        onSettled: () => {
          setIsChangeRoleDialogOpen(false);
        }
      }
    );
  };

  return (
    <Box className="user-settings-container">
      <DataTable
        columns={columnsV2}
        rows={
          !publisherUsers.isLoading && !getRoles.isLoading
            ? (publisherUsers.data as User[]).map((user) => {
                return {
                  _id: user.userId,
                  name: user.name,
                  lastActive: user.lastLogin,
                  email: user.email,
                  role: user?.role ? capitalizeFirstLetter(user.role) : '',
                  active: !!user.lastLogin
                };
              })
            : []
        }
        loading={publisherUsers.isLoading}
        checkboxSelection
      />
      {!publisherUsers.isLoading && (
        <Box paddingTop={2}>
          <ActionButton
            variant="outlined"
            text="Add User"
            onClick={() => setAddUserDrawerOpen(true)}
          />
        </Box>
      )}
      <UserSettingsDrawer
        addUserDrawerOpen={addUserDrawerOpen}
        setAddUserDrawerOpen={setAddUserDrawerOpen}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        v2UserRolesOptions={v2UserRolesOptions}
        isValid={isValid}
        dirty={dirty}
        submitForm={submitForm}
      />
      <DialogModal
        isOpen={isChangeRoleDialogOpen}
        headline="Change User Role"
        text={
          <>
            Are you sure you want to change <b>{selectedUser?.name}</b> role to{' '}
            <b>{capitalizeFirstLetter(selectedRoleChange ?? '')}</b>?
          </>
        }
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsChangeRoleDialogOpen(false);
            }
          },
          {
            text: 'Change Role',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: () => {
              changeUserPermission(selectedUser!.userId, selectedRoleChange!);
            }
          }
        ]}
        closeDialog={() => setIsChangeRoleDialogOpen(false)}
      />
      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete User"
        text="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: () => {
              deleteUserHandler();
              setDeleteDialogOpen(false);
            }
          }
        ]}
        closeDialog={() => setDeleteDialogOpen(false)}
      />
    </Box>
  );
};

export default UsersSettings;
