import { SyntheticEvent, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TabsPanel } from 'design-system/TabPanel/TabPanel';

import PageTopBar from 'components/Topbar/PageTopBar';

enum EPlayersState {
  BLOCKED = 'blocked'
}

export const PlayersView = () => {
  const navigate = useNavigate();
  const { publisherId } = useParams();
  const location = useLocation();
  const currentTab = location.pathname.split('/')[4];
  const [tab, setTab] = useState(currentTab);

  const handleTabChange = (event: SyntheticEvent, newValue: EPlayersState) => {
    setTab(newValue);
    const newPath = `/project/${publisherId}/players/${newValue}`;
    navigate(newPath);
  };

  const tabs = [{ label: 'Blocked players', value: EPlayersState.BLOCKED }];

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent={<PageTopBar withTabsDesign={true} headline="Players" />}
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};
