import { cn } from '../../components/lib/utils';
import React from 'react';

interface SkeletonProps {
  className?: string;
  style?: React.CSSProperties;
  'data-sidebar'?: string;
}

export const Skeleton = ({ className, style, ...props }: SkeletonProps) => {
  return (
    <div
      className={cn(
        'h-4 w-full animate-pulse rounded-md bg-zinc-200',
        className
      )}
      style={style}
      {...props}
    />
  );
};
