import { useSelector } from 'react-redux';

import { Grid, Stack } from '@mui/material';
import useUsers from 'api/useUsers';
import { ELocalStorageKeys } from 'constants/enums';
import { useFormik } from 'formik';
import { AuthSliceState } from 'store/store.types';
import { required } from 'utils/errorsTextHelper';
import { localStorageUtil } from 'utils/localStorageUtil';
import { UpdatePasswordData } from 'views/Profile/ProfileView.types';
import * as yup from 'yup';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import ActionButton from 'components/ActionButton/ActionButton';

import { EToasterStatus, showToast } from '../../utils/showToast';

import './style.scss';

const PasswordTab = () => {
  const { getUsers, updatePassword } = useUsers({
    shouldFetchUsersData: true,
    enableFeatureFlags: false
  });

  const userId = useSelector(
    ({ auth }: { auth: AuthSliceState }) =>
      auth.userId ||
      localStorageUtil.getAny(ELocalStorageKeys.USER_DETAILS)?.userId
  );
  const changePassword = (passwordsData: UpdatePasswordData, cb: Function) => {
    passwordsData.userId = userId;
    updatePassword.mutate(passwordsData, {
      onSuccess: () => {
        getUsers.refetch();
        showToast({
          message: 'Password updated successfully',
          status: EToasterStatus.SUCCESS
        });
        cb();
      },
      onError: (data: any) => {
        showToast({
          message: data.response.data.message,
          status: EToasterStatus.ERROR
        });
        cb();
      }
    });
  };

  let updatePasswordSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .password()
      .required(required('Password'))
      .label('Password'),
    rawNewPassword: yup
      .string()
      .password()
      .required(required('New Password'))
      .label('New Password'),
    password2: yup
      .string()
      .oneOf([yup.ref('rawNewPassword'), null], 'Passwords must match')
      .required(required('Confirm Password'))
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    resetForm,
    submitForm,
    dirty,
    isSubmitting,
    isValid
  } = useFormik({
    initialValues: {
      oldPassword: '',
      rawNewPassword: '',
      password2: ''
    },
    validationSchema: updatePasswordSchema,
    onSubmit: async (values) => {
      const data = {
        oldPassword: values.oldPassword,
        rawNewPassword: values.rawNewPassword
      };
      changePassword(data, () => {
        resetForm();
      });
    }
  });

  return (
    <Grid container className="formContent">
      <Grid item xs={5} className="formContent-minWIdth">
        <Stack>
          <AcCard stackContainer={false} title="Password">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  header="Current Password"
                  name="oldPassword"
                  type="password"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.oldPassword && !!errors.oldPassword}
                  helperText={(touched.oldPassword && errors.oldPassword) || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  header="New Password"
                  name="rawNewPassword"
                  type="password"
                  value={values.rawNewPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.rawNewPassword && !!errors.rawNewPassword}
                  helperText="Your password must be at least 8 characters long and contain at least
          one capital letter (A-Z), one lowercase letter (a-z), one numeral
          (0-9) and at least one special symbol (e.g., !, @, #, $, %, etc.)"
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  header="Confirm Password"
                  name="password2"
                  type="password"
                  value={values.password2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password2 && !!errors.password2}
                  helperText={(touched.password2 && errors.password2) || ''}
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Grid item mt={3} xs={12}>
          <ActionButton
            text="Update"
            variant="outlined"
            onClick={submitForm}
            disabled={!isValid || !dirty || isSubmitting}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PasswordTab;
