import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { RefreshOutlined } from '@mui/icons-material';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Stack } from '@mui/material';
import { GridActionsCellItem, type GridColumns } from '@mui/x-data-grid';
import usePricing from 'api/usePricing';
import { AxiosError } from 'axios';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { DATE_TIME_FORMAT } from 'constants/constants';
import dayjs from 'dayjs';
import { errorResponse } from 'utils/errorsTextHelper';
import { permissionsUtil } from 'utils/permissionsUtil';

import ActionButton from '../ActionButton/ActionButton';
import DataTable from '../DataTable/DataTable';
import TableHeaderButton from '../DataTable/TableHeaderButton';
import FirstActionModal from '../FirstActionModal/FirstActionModal';

import PricingModals from './PricingModals';

import './style.scss';

const PricingTable = () => {
  const { publisherId } = useParams();

  const navigate = useNavigate();
  const { getPricings, getPricingPointsTemplate } = usePricing(publisherId);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState<string | null>(null);
  const [updateExchangeRatesDialogOpen, setUpdateExchangeRatesDialogOpen] =
    useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isUploadCSVOpen, setUploadCSVOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleTemplateDownload = () => {
    getPricingPointsTemplate
      .refetch()
      .then((response) => {
        const { data } = response;
        if (data) {
          const url = window.URL.createObjectURL(
            new Blob([data], { type: 'text/csv' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Import_price_point_template.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          setErrorMessage(errorResponse(error));
        }
      });
  };

  function onImportCSV() {
    setUploadCSVOpen(true);
  }

  const columns: GridColumns = [
    {
      field: 'usd',
      headerName: 'USD Price Point',
      width: 200,
      renderCell: (params: any) => {
        return `$${params.value}`;
      }
    },
    {
      field: 'lastEdited',
      headerName: 'Last Edited',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <span>
            {dayjs(params.row.lastEdited).utc().format(DATE_TIME_FORMAT)}
          </span>
        );
      },
      valueGetter: (params: any) => {
        return params.row.lastEdited;
      }
    },
    {
      field: 'actions',
      renderHeader: () => {
        return (
          <TableHeaderButton
            cb={() => setUpdateExchangeRatesDialogOpen(true)}
            text="Update exchange rates"
            icon={<RefreshOutlined />}
          />
        );
      },
      headerClassName: 'actions-header',
      flex: 0,
      align: 'right',
      type: 'actions',
      width: 200,
      disableReorder: true,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          onClick={() => {
            navigate(`./form/${params.id}`);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.PRICING,
              EPermissionAction.MANAGE
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          onClick={() => {
            setDeleteDialogOpen(true);
            setSelectedPricing(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.PRICING,
              EPermissionAction.MANAGE
            )
          }
          showInMenu
        />
      ]
    }
  ];

  return (
    <>
      <Stack>
        <div className="import-export-wrapper">
          <ActionButton
            variant="outlined"
            text="Import"
            onClick={onImportCSV}
          />
          <ActionButton
            variant="outlined"
            text="Export"
            onClick={handleTemplateDownload}
          />
        </div>
        <DataTable
          hideFooter={false}
          columns={columns}
          rows={
            !getPricings.isLoading &&
            getPricings.data?.result.map((pricing: any) => ({
              _id: pricing._id,
              usd: (pricing.priceInCents / 100).toFixed(2),
              lastEdited: pricing.updatedAt
            }))
          }
          loading={getPricings.isLoading || tableLoader}
          onNoData={
            <FirstActionModal
              headline="No pricing points yet"
              text="Currently there is no pricing poits in your store"
            />
          }
        />
      </Stack>
      <PricingModals
        publisherId={publisherId}
        updateExchangeRatesDialogOpen={updateExchangeRatesDialogOpen}
        isUploadCSVOpen={isUploadCSVOpen}
        selectedPricing={selectedPricing}
        isDeleteDialogOpen={isDeleteDialogOpen}
        setSelectedPricing={setSelectedPricing}
        setUploadCSVOpen={setUploadCSVOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setTableLoader={setTableLoader}
        setUpdateExchangeRatesDialogOpen={setUpdateExchangeRatesDialogOpen}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export default PricingTable;
