import { useMemo, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { Divider, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import useIntegration from 'api/useIntegration';
import { PROFILES_ENABLED_PLACEHOLDER } from 'constants/constants';
import { useFormik } from 'formik';
import { useProfilesManager } from 'hooks/useProfilesManager';
import { textModelOptions } from 'utils/selectOptionsUtils';
import * as yup from 'yup';

import AcCard from 'components/AcCard/AcCard';
import AcSelect from 'components/AcSelect/AcSelect';

import { EToasterStatus, showToast } from '../../../utils/showToast';
import {
  EPlayersAuthenticationModel,
  IntegrationSettingsData
} from '../Settings.types';

import { AuthCard } from './AuthCard';

import 'style/forms.scss';
import '../style.scss';

const PlayerAuthSettings = () => {
  const { publisherId } = useParams();
  const { getIntegration, updateIntegration } = useIntegration(publisherId);
  const { isProfilesEnabled } = useProfilesManager();
  const data = getIntegration?.data;

  const playersAuthenticationSchema = yup.object().shape({
    playersAuthentication: yup.object().shape({
      appleModel: yup.string(),
      appleAppSecret: yup.string(),
      appleAppId: yup.string(),
      appleOn: yup.boolean(),
      googleAppId: yup.string(),
      googleAppSecret: yup.string(),
      googleOn: yup.boolean(),
      googleModel: yup.string(),
      fbAppSecret: yup.string(),
      fbAppId: yup.string(),
      fbModel: yup.string(),
      fbOn: yup.boolean(),
      usernamePasswordModel: yup.string(),
      usernamePasswordOn: yup.boolean(),
      otpTextModel: yup.string(),
      otpOn: yup.boolean()
    }),
    otpGenerateDeeplinkUrl: yup.string()
  });

  const initialValues = useMemo(
    () => ({
      isToggleOnOff:
        !!data?.playersAuthentication?.fbOn ||
        !!data?.playersAuthentication?.googleOn ||
        !!data?.playersAuthentication?.appleOn ||
        !!data?.playersAuthentication?.userTokenOn ||
        !!data?.playersAuthentication?.usernamePasswordOn ||
        !!data?.playersAuthentication?.otpOn,
      otpGenerateDeeplinkUrl: data?.otpGenerateDeeplinkUrl || '',
      playersAuthentication: {
        appleModel:
          data?.playersAuthentication?.appleModel ||
          EPlayersAuthenticationModel.APPCHARGE,
        appleAppSecret: data?.playersAuthentication?.appleAppSecret || '',
        appleAppId: data?.playersAuthentication?.appleAppId || '',
        appleOn: !!data?.playersAuthentication?.appleOn,
        googleAppId: data?.playersAuthentication?.googleAppId || '',
        googleAppSecret: data?.playersAuthentication?.googleAppSecret || '',
        googleModel:
          data?.playersAuthentication?.googleModel ||
          EPlayersAuthenticationModel.APPCHARGE,
        googleOn: !!data?.playersAuthentication?.googleOn,
        fbAppSecret: data?.playersAuthentication?.fbAppSecret || '',
        fbAppId: data?.playersAuthentication?.fbAppId || '',
        fbOn: !!data?.playersAuthentication?.fbOn,
        fbModel: !!data?.playersAuthentication?.fbModel,
        usernamePasswordOn: !!data?.playersAuthentication?.usernamePasswordOn,
        usernamePasswordModel: !!data?.usernamePasswordModel,
        userTokenOn: !!data?.playersAuthentication?.userTokenOn,
        userTokenModel: !!data?.playersAuthentication?.userTokenModel,
        otpTextModel: data?.playersAuthentication?.otpTextModel || '',
        otpOn: !!data?.playersAuthentication?.otpOn
      }
    }),
    [data]
  );

  const prevAuthSettingsValues = useRef(initialValues);

  const playersAuthenticationForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: playersAuthenticationSchema,
    onSubmit: async (values, { resetForm }) => {
      const newValues: any = {};
      if (values.otpGenerateDeeplinkUrl) {
        newValues.otpGenerateDeeplinkUrl = values.otpGenerateDeeplinkUrl;
      }
      if (
        values.isToggleOnOff !== prevAuthSettingsValues.current.isToggleOnOff
      ) {
        newValues.isToggleOnOff = values.isToggleOnOff;
      }
      const changedValues = Object.keys(values.playersAuthentication).reduce(
        (acc, key) => {
          const typedKey =
            key as keyof typeof initialValues.playersAuthentication;
          if (
            JSON.stringify(
              prevAuthSettingsValues.current.playersAuthentication[typedKey]
            ) !== JSON.stringify(values.playersAuthentication[typedKey])
          ) {
            acc[typedKey] = values.playersAuthentication[typedKey];
          }
          return acc;
        },
        {} as any
      );
      newValues.playersAuthentication = changedValues;
      savePlayersAuthenticationDetails(newValues, resetForm);
      prevAuthSettingsValues.current = values;
    }
  });

  const savePlayersAuthenticationDetails = (
    values: Partial<IntegrationSettingsData> & { isToggleOnOff: boolean },
    resetForm: () => void
  ) => {
    updateIntegration.mutate(values, {
      onSuccess: () => {
        showToast({
          message: values.isToggleOnOff
            ? 'App Authentication details updated successfully'
            : 'Authentication details updated successfully',
          status: EToasterStatus.SUCCESS
        });
        resetForm();
      },
      onError: () => {
        showToast({
          message: 'Failed to update Player Authentication details',
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const {
    values: {
      playersAuthentication: {
        appleOn,
        fbOn,
        googleOn,
        userTokenOn,
        usernamePasswordOn,
        otpOn
      }
    }
  } = playersAuthenticationForm;

  const appStates = [
    appleOn,
    fbOn,
    googleOn,
    userTokenOn,
    usernamePasswordOn,
    otpOn
  ];

  const disabledWhenProfilesEnabled = {
    fbOn: true,
    googleOn: true,
    appleOn: false,
    usernamePasswordOn: true,
    userTokenOn: false,
    otpOn: true
  };

  return (
    <Grid container className="formContent">
      <Grid item xs={5} className="formContent-minWIdth">
        <Stack>
          <AcCard
            stackContainer={false}
            title="Authentication"
            description="Choose the log in methods you wish to enable"
          >
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12} mt={1}>
                <AuthCard
                  title="Facebook App"
                  state={fbOn}
                  form={playersAuthenticationForm}
                  id="fbAppId"
                  model="fbModel"
                  on="fbOn"
                  secret="fbAppSecret"
                  logo="facebook"
                  appStates={appStates}
                  disabledWhenProfilesEnabled={disabledWhenProfilesEnabled.fbOn}
                />
              </Grid>
              <Divider />
              <Grid item xs={12} mt="-16px">
                <AuthCard
                  title="Google App"
                  state={googleOn}
                  form={playersAuthenticationForm}
                  id="googleAppId"
                  model="googleModel"
                  on="googleOn"
                  secret="googleAppSecret"
                  logo="google"
                  appStates={appStates}
                  disabledWhenProfilesEnabled={
                    disabledWhenProfilesEnabled.googleOn
                  }
                />
              </Grid>
              <Divider />
              <Grid item mt="-16px" xs={12}>
                <AuthCard
                  title="Apple App"
                  state={appleOn}
                  form={playersAuthenticationForm}
                  id="appleAppId"
                  model="appleModel"
                  on="appleOn"
                  secret="appleAppSecret"
                  logo="apple"
                  appStates={appStates}
                  disabledWhenProfilesEnabled={
                    disabledWhenProfilesEnabled.appleOn
                  }
                />
              </Grid>
              <Divider />
              <Grid item mt="-16px" xs={12}>
                <AuthCard
                  title="Username and Password"
                  state={usernamePasswordOn}
                  form={playersAuthenticationForm}
                  on="usernamePasswordOn"
                  appStates={appStates}
                  disabledWhenProfilesEnabled={
                    disabledWhenProfilesEnabled.usernamePasswordOn
                  }
                />
              </Grid>
              <Divider />
              <Grid item mt="-16px" xs={12}>
                <AuthCard
                  title="Player ID"
                  state={userTokenOn}
                  form={playersAuthenticationForm}
                  on="userTokenOn"
                  appStates={appStates}
                  disabledWhenProfilesEnabled={
                    disabledWhenProfilesEnabled.userTokenOn
                  }
                />
              </Grid>
              <Divider />
              <Grid item mt="-16px" xs={12}>
                <AuthCard
                  title="OTP"
                  state={otpOn}
                  form={playersAuthenticationForm}
                  on="otpOn"
                  appStates={appStates}
                  inputs={[
                    {
                      header: 'OTP Generation Deeplink Url',
                      name: 'otpGenerateDeeplinkUrl'
                    }
                  ]}
                  disabledWhenProfilesEnabled={
                    disabledWhenProfilesEnabled.otpOn
                  }
                >
                  <Box marginTop={1}>
                    <AcSelect
                      header="Desktop Text Model"
                      name="playersAuthentication.otpTextModel"
                      value={
                        isProfilesEnabled
                          ? PROFILES_ENABLED_PLACEHOLDER
                          : playersAuthenticationForm.values
                              .playersAuthentication.otpTextModel
                      }
                      items={textModelOptions}
                      onChange={playersAuthenticationForm.handleChange}
                      disabled={isProfilesEnabled}
                    />
                  </Box>
                </AuthCard>
              </Grid>
              <Divider />
            </Grid>
          </AcCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PlayerAuthSettings;
