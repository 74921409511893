import { useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { EFontWeights } from '@appcharge/shared-ui';
import { Divider, Grid, Stack } from '@mui/material';
import useImages from 'api/useImages';
import useOffersUI from 'api/useOffersUI';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import { OfferUI } from 'common/contracts';
import {
  EFeatureFlag,
  EPopupSubType,
  ESpecialOfferInternalViewModel
} from 'constants/enums';
import { useFormik } from 'formik';
import { permissionsUtil } from 'utils/permissionsUtil';
import { initialOfferDesignTypesItems } from 'utils/selectOptionsUtils';
import * as yup from 'yup';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import PageTopBar from 'components/Topbar/PageTopBar';

import { EToasterStatus, showToast } from '../../../utils/showToast';

import OfferUiAssets from './OfferUiSections/Assets';
import OfferUiMock from './OfferUiSections/Mock';
import OfferUiSettings from './OfferUiSections/Settings';
import OfferUiTitle from './OfferUiSections/Title';
import RollingOfferUiForm from './RollingOffers';
import { OfferDesignTypes, OffersUIFormProps } from './types';

import 'style/forms.scss';

const OffersUIForm = ({ edit = false, dup = false }: OffersUIFormProps) => {
  const { publisherId } = useParams();
  const { offerUIId } = useParams();
  const navigate = useNavigate();
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const { getGeneralTheme: generalTheme } = useTheme(publisherId);
  const { addOfferUI, updateOfferUI, getOfferUI, getOffersUI } = useOffersUI(
    publisherId,
    offerUIId
  );
  const addPictureInputRef = useRef<HTMLInputElement>(null);
  const [currentUploadingField, setCurrentUploadingField] = useState('');
  const { getImages } = useImages(publisherId);
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [isExternalIdUnique, setIsExternalIdUnique] = useState(true);

  useEffect(() => {
    if (offerUIId && publisherId) {
      getOfferUI.refetch();
    }
  }, [offerUIId, publisherId]);

  const updateInfoSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    externalId: yup
      .string()
      .required('External Id is required')
      .matches(/^\S+$/, 'External ID cannot contain spaces'),
    backgroundImage: yup.string().when('offerUiType', {
      is: (offerUiType: any) =>
        offerUiType === OfferDesignTypes.SpecialOffer ||
        offerUiType === OfferDesignTypes.PopUp,
      then: yup.string().required('Background image is required'),
      otherwise: yup.string().notRequired()
    }),
    badgeCoverImage: yup.string().when('offerUiType', {
      is: (offerUiType: any) => offerUiType === OfferDesignTypes.PopUp,
      then: yup.string().notRequired()
    }),
    offerUiType: yup.string().required('Offer UI Type is required'),
    specialOffer: yup.object().when('offerUiType', {
      is: (offerUiType: any) => offerUiType !== OfferDesignTypes.Bundle,
      then: yup.object().shape({
        subTitle: yup.object().shape({
          text: yup
            .string()
            .test('max-lines', 'Maximum of two lines allowed', (value) => {
              if (!value) return true;
              return (value.match(/\n/g) || []).length <= 1;
            })
        })
      })
    }),
    borderWidth: yup.mixed().when('offerUiType', {
      is: (offerUiType: OfferDesignTypes) =>
        offerUiType === OfferDesignTypes.RollingOffer,
      then: yup
        .number()
        .typeError('Border width must be a number')
        .min(0, 'Border width must be minimum 0px')
        .max(8, 'Border width cannot exceed 8px')
        .required('Border width is required for Rolling Offer'),
      otherwise: yup
        .string()
        .matches(/^[0-9]*$/, 'Border width cannot contain letters')
    }),
    rollingOffer: yup.object().when('offerUiType', {
      is: OfferDesignTypes.RollingOffer,
      then: yup.object().shape({
        backgroundColor: yup
          .object()
          .default({
            colorOne: '#323281'
          })
          .required('Offer background color is required'),
        arrowColor: yup
          .string()
          .default('#FFFF00')
          .required('Arrow color is required'),
        subRollingOffer: yup.object().shape({
          backgroundColor: yup
            .object()
            .default({
              colorOne: '#ffffff'
            })
            .required('Offers background color is required'),
          lockImage: yup
            .string()
            .default(
              'https://media-dev.appcharge.com/media/66be029440fd9d64e8b421a9/1732101369729__f5611ed5-cb85-4b81-b8f1-373921b64d86'
            ),
          collectText: yup
            .string()
            .required('Collect text is required')
            .min(2, 'Collect text must be minimum 2 characters')
            .max(12, 'Collect text cannot exceed 12 characters')
        })
      }),
      otherwise: yup.object().notRequired()
    })
  });

  const initialValues = useMemo(() => {
    const offerUiType =
      getOfferUI?.data?.offerUiType || OfferDesignTypes.SpecialOffer;
    const dataByOfferType: any =
      offerUiType === OfferDesignTypes.RollingOffer
        ? {
          rollingOffer: {
            arrowColor: getOfferUI.data.rollingOffer.arrowColor || '#FFFF00',
            backgroundColor: getOfferUI.data.rollingOffer.backgroundColor || {
              colorOne: '#323281'
            },
            collectBehavior: 'disappear',
            subRollingOffer: {
              backgroundColor: {
                colorOne:
                  getOfferUI.data.rollingOffer.subRollingOffer
                    ?.backgroundColor?.colorOne || '#ffffff',
                colorTwo:
                  getOfferUI.data.rollingOffer.subRollingOffer
                    ?.backgroundColor?.colorTwo || '',
                gradientDirection:
                  getOfferUI.data.rollingOffer.subRollingOffer
                    ?.backgroundColor?.gradientDirection || ''
              },
              ...(getOfferUI.data.rollingOffer.subRollingOffer
                ?.backgroundImage && {
                backgroundImage:
                  getOfferUI.data.rollingOffer.subRollingOffer
                    ?.backgroundImage
              }),
              lockImage:
                getOfferUI.data.rollingOffer.subRollingOffer?.lockImage ||
                'https://media-dev.appcharge.com/media/66be029440fd9d64e8b421a9/1732101369729__f5611ed5-cb85-4b81-b8f1-373921b64d86',
              collectText:
                getOfferUI.data.rollingOffer.subRollingOffer?.collectText ||
                'FREE'
            }
          }
        }
        : {
          specialOffer: {
            templateType:
              getOfferUI.data?.specialOffer?.templateType || 'Single',
            presentOfferEndTimer: false,
            backgroundColor: getOfferUI.data?.specialOffer
              ?.backgroundColor || {
              colorOne: '#323281'
            },
            title: getOfferUI.data?.specialOffer?.title || '',
            fontSize: getOfferUI.data?.specialOffer?.fontSize || 20,
            fontWeight: getOfferUI.data?.specialOffer?.fontWeight || 'normal',
            fontColor: {
              colorOne:
                getOfferUI.data?.specialOffer?.fontColor?.colorOne ||
                '#ffffff'
            },
            subTitle: {
              text: getOfferUI.data?.specialOffer?.subTitle?.text || '',
              fontSize:
                getOfferUI.data?.specialOffer?.subTitle?.fontSize || 16,
              fontWeight:
                getOfferUI.data?.specialOffer?.subTitle?.fontWeight ||
                'normal',
              fontColor: {
                colorOne:
                  getOfferUI.data?.specialOffer?.subTitle?.fontColor
                    ?.colorOne || '#ffffff'
              }
            }
          },
          ...(offerUiType === OfferDesignTypes.PopUp &&
            getOfferUI.data?.badgeCoverImage
            ? { badgeCoverImage: getOfferUI.data.badgeCoverImage }
            : {})
        };

    return {
      offerUiType,
      name: getOfferUI.data?.name
        ? `${getOfferUI.data.name}${dup ? '_copy' : ''}`
        : '',
      description: 'N/A',
      backgroundImage: getOfferUI.data?.backgroundImage || '',
      ...dataByOfferType,
      externalId: getOfferUI.data?.externalId
        ? getOfferUI.data.externalId.trim().replace(/\s+/g, '_')
        : getOfferUI.data?.name
          ? getOfferUI.data.name.trim().replace(/\s+/g, '_')
          : '',
      borderColor: {
        colorOne: getOfferUI.data?.borderColor?.colorOne || '#ffffff',
        colorTwo: getOfferUI.data?.borderColor?.colorTwo || '',
        direction: getOfferUI.data?.borderColor?.gradientDirection || ''
      },
      borderWidth: getOfferUI.data?.borderWidth ?? '2'
    };
  }, [getOfferUI.data]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: updateInfoSchema,
    onSubmit: async (values) => {
      const newOfferUI: OfferUI = {
        active: true,
        offerUiType: values.offerUiType,
        name: values.name,
        externalId: values.externalId
          ? values.externalId.trim().replace(/\s+/g, '_')
          : values.name.trim().replace(/\s+/g, '_'),
        description: 'description',
        backgroundImage: values.backgroundImage,
        ...(values.offerUiType === OfferDesignTypes.PopUp &&
          values.badgeCoverImage
          ? { badgeCoverImage: values.badgeCoverImage }
          : {}),
        borderColor: values.borderColor,
        borderWidth: Number(values.borderWidth)
      };
      newOfferUI.externalId = values?.externalId || null;

      if (
        (values?.offerUiType !== OfferDesignTypes.Bundle ||
          values?.specialOffer?.backgroundColor !==
          initialValues.specialOffer?.backgroundColor) &&
        values?.offerUiType !== OfferDesignTypes.RollingOffer
      ) {
        newOfferUI.specialOffer = {
          templateType: values?.specialOffer?.templateType,
          presentOfferEndTimer: values?.specialOffer?.presentOfferEndTimer,
          title: values?.specialOffer?.title,
          fontSize: Number(values?.specialOffer?.fontSize) || 20,
          fontWeight: values?.specialOffer?.fontWeight as EFontWeights,
          fontColor: values?.specialOffer?.fontColor || {
            colorOne: '#fff'
          },
          subTitle: values?.specialOffer?.subTitle || {
            fontSize: 16,
            fontColor: {
              colorOne: '#fff'
            }
          },
          backgroundColor: values.specialOffer.backgroundColor
        };
      }

      if (newOfferUI.offerUiType === OfferDesignTypes.PopUp) {
        newOfferUI.offerUiSubType =
          newOfferUI.offerUiSubType || EPopupSubType.DAILY_BONUS;
      }

      if (newOfferUI.offerUiType === OfferDesignTypes.RollingOffer) {
        newOfferUI.rollingOffer = {
          arrowColor: values.rollingOffer?.arrowColor || '#FFFF00',
          backgroundColor: values.rollingOffer?.backgroundColor || {
            colorOne: '#323281'
          },
          collectBehavior: 'disappear',
          subRollingOffer: {
            backgroundColor: {
              colorOne:
                values.rollingOffer?.subRollingOffer?.backgroundColor
                  ?.colorOne || '#ffffff',
              colorTwo:
                values.rollingOffer?.subRollingOffer?.backgroundColor
                  ?.colorTwo || '',
              direction:
                values.rollingOffer?.subRollingOffer?.backgroundColor
                  ?.gradientDirection || ''
            },
            ...(values.rollingOffer?.subRollingOffer?.backgroundImage && {
              backgroundImage:
                values.rollingOffer?.subRollingOffer?.backgroundImage
            }),
            lockImage:
              values.rollingOffer?.subRollingOffer?.lockImage ||
              'https://media-dev.appcharge.com/media/66be029440fd9d64e8b421a9/1732101369729__f5611ed5-cb85-4b81-b8f1-373921b64d86',
            collectText:
              values.rollingOffer?.subRollingOffer?.collectText || 'FREE'
          }
        };
      }

      if (edit && offerUIId && !dup) {
        updateOfferUI.mutate(
          { offerId: offerUIId, form: newOfferUI },
          {
            onSuccess: () => {
              showToast({
                message: 'Offer Design saved successfully',
                status: EToasterStatus.SUCCESS
              });
              getOfferUI.refetch();
              navigate('../');
            },
            onError: () => {
              showToast({
                message: 'Error editing Offer Design',
                status: EToasterStatus.ERROR
              });
            }
          }
        );
      } else {
        addOfferUI.mutate(newOfferUI, {
          onSuccess: () => {
            showToast({
              message: 'Offer Design added successfully',
              status: EToasterStatus.SUCCESS
            });
            getOfferUI.refetch();
            navigate('../');
          },
          onError: () => {
            showToast({
              message: 'Error adding Offer Design',
              status: EToasterStatus.ERROR
            });
          }
        });
      }
    }
  });

  useEffect(() => {
    if (!edit && getOffersUI.data?.result) {
      const { result } = getOffersUI.data;

      const checkUniqueness = (key: keyof OfferUI, value: any) =>
        !result.some((offerUI: OfferUI) => offerUI[key] === value);

      setIsNameUnique(checkUniqueness('name', values.name));
      setIsExternalIdUnique(checkUniqueness('externalId', values.externalId));
    }
  }, [getOffersUI.data, edit, values.name, values.externalId]);

  const isRollingOfferEnabled =
    fetchFeatureFlags.data?.featureFlags?.[
    EFeatureFlag.DASHBOARD_ROLLING_OFFER
    ];

  const offerDesignTypesItems = initialOfferDesignTypesItems.filter(
    (item) =>
      (item.key !== OfferDesignTypes.RollingOffer || isRollingOfferEnabled)
  );

  const shouldDisplaySubTitle = useMemo(
    () =>
      generalTheme?.data?.specialOffersInternalViewModel ===
      ESpecialOfferInternalViewModel.JACKPOT_SLOTS ||
      generalTheme?.data?.specialOffersInternalViewModel ===
      ESpecialOfferInternalViewModel.BIG_FISH_CASINO,
    [generalTheme.data?.specialOffersInternalViewModel]
  );

  const formikProps = {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    setFieldValue
  };
  return (
    <AcViewWrapper
      header={
        <PageTopBar
          disable={false}
          headline={`${edit ? 'Edit' : 'New'} Offer Design ${edit ? `(${getOfferUI.data ? getOfferUI.data._id : '...'})` : ''
            }`}
          buttons={[
            {
              text: 'Save',
              action: submitForm,
              disabled: !permissionsUtil.canUserEdit() || !isValid
            }
          ]}
          backFunction={() => navigate('../')}
        />
      }
    >
      <AcContentWrapper className="formContent">
        {!getImages.isLoading && getImages.data && (
          <Grid container pt={'24px'}>
            <Grid item xs={5}>
              <Stack>
                <OfferUiSettings
                  {...formikProps}
                  isNameUnique={isNameUnique}
                  isExternalIdUnique={isExternalIdUnique}
                  offerDesignTypesItems={offerDesignTypesItems}
                />
                <Divider />
                {values.offerUiType === OfferDesignTypes.RollingOffer ? (
                  <RollingOfferUiForm
                    {...formikProps}
                    addPictureInputRef={addPictureInputRef}
                    getImages={getImages}
                    setCurrentUploadingField={setCurrentUploadingField}
                    currentUploadingField={currentUploadingField}
                    dup={dup}
                  />
                ) : (
                  <>
                    <OfferUiAssets
                      {...formikProps}
                      addPictureInputRef={addPictureInputRef}
                      getImages={getImages}
                      setCurrentUploadingField={setCurrentUploadingField}
                      currentUploadingField={currentUploadingField}
                      dup={dup}
                    />
                    <Divider />
                    <OfferUiTitle
                      {...formikProps}
                      shouldDisplaySubTitle={shouldDisplaySubTitle}
                    />
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item xs={7} className="iphone-mock-wrapper">
              {!generalTheme.isLoading && (!edit || !getOfferUI.isLoading) && (
                <OfferUiMock values={values} />
              )}
            </Grid>
          </Grid>
        )}
      </AcContentWrapper>
    </AcViewWrapper>
  );
};

export default OffersUIForm;
