import { FontAwesomeIcon } from '@appcharge/shared-ui';
import React from 'react';
import { LinkCardData, WELCOME_CARDS_DATA } from './constants';
import Typography from '../../components/Typography/Typography';

const LinkCard: React.FC<LinkCardData> = ({ title, iconName, links }) => {
  return (
    <div className="flex flex-col rounded-lg transition-shadow duration-300 flex-1">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon
          icon={iconName}
          className="h-6 w-6 text-indigo-500 mr-2"
        />
        <Typography tag="h2" className="text-p-s font-semibold">
          {title}
        </Typography>
      </div>
      <div className="text-sm font-normal flex flex-col gap-2 ml-8">
        {links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            target="_blank"
            className="text-base-mutedForeground underline hover:no-underline"
          >
            {link.text}
          </a>
        ))}
      </div>
    </div>
  );
};

const WelcomeView: React.FC = () => {
  return (
    <div className="flex flex-col gap-4 px-10">
      <img
        src="./assets/images/welcome-banner.png"
        alt="Welcome to Appcharge"
        className="rounded-md w-full object-cover"
      />
      <div className="links-container">
        <Typography tag="h3" className="py-[30px]">
          Getting Started
        </Typography>
        <div className="flex flex-row justify-between gap-6">
          {WELCOME_CARDS_DATA.map((cardData, index) => (
            <LinkCard key={index} {...cardData} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WelcomeView;
