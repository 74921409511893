import { useEffect, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import {
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';

import { ErrorFallback } from '@appcharge/shared-ui';
import usePermissions from 'api/usePermissions';
import { useAxiosInterceptor } from 'hooks/useAxiosInterceptor';
import LoginPage from 'pages/Login/Login';
import { permissionsUtil } from 'utils/permissionsUtil';
import PrivateRoute from 'utils/privateRoute';

import FreeOrdersTable from 'components/Orders/FreeOrdersTable/FreeOrdersTable';
import OrdersTable from 'components/Orders/OrdersTable/OrdersTable';

import { ModalState } from '../components/LoginModal/LoginModal.types';
import PasswordTab from '../components/PasswordsTab/PasswordTab';
import PersonalInfo from '../components/PersonalInfo/PersonalInfo';
import Layout from '../pages/Layout/Layout';
import OrderDetails from '../views/Orders/OrderDetails';
import OrdersView from '../views/Orders/OrdersView';
import ProfileView from '../views/Profile/ProfileView';
import FinancialReportsTable from '../views/Reports/FinancialReports';
import PayoutsTable from '../views/Reports/Payouts';
import ReportsView from '../views/Reports/ReportsView';
import WelcomeView from '../views/Welcome/WelcomeView';

import portalLevelRoutes from './PortalLevelRoutes';
import projectLevelRoutes from './ProjectLevelRoutes';

const AppRoutes = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/');
  };
  const { publisherId: routeParamPublisherId } = useParams();
  const location = useLocation();
  const { getPermissions } = usePermissions();
  const permissions = getPermissions();
  const [searchParams] = useSearchParams();

  const matchPublisher = matchPath(
    '/project/:publisherId/*',
    location.pathname
  );
  const publisherId =
    matchPublisher?.params?.publisherId || routeParamPublisherId;

  useAxiosInterceptor();

  useEffect(() => {
    const mode = searchParams.get('mode') as ModalState | null;
    const validModes: ModalState[] = [
      'login',
      'resetPassword',
      'forgetPassword',
      'finishAccount'
    ];
    if (mode && validModes.includes(mode)) {
      navigate(`/login?${searchParams.toString()}`, { replace: true });
    }
  }, [searchParams, navigate]);

  const checkDefaultProjectPage = () => {
    if (!publisherId) {
      return 'statistics';
    }

    const hasAnalyticsViewPermission = permissionsUtil.canAccessAnalytics(
      permissions,
      publisherId
    );

    return hasAnalyticsViewPermission ? 'statistics' : 'bundles';
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <ErrorBoundary
              FallbackComponent={() => (
                <ErrorFallback handleClick={handleNavigate} />
              )}
            >
              <Layout />
            </ErrorBoundary>
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to="/welcome" replace />} />
        <Route path="welcome" element={<WelcomeView />} />

        <Route path="orders" element={<OrdersView />}>
          <Route index element={<OrdersTable />} />
          <Route path="free" element={<FreeOrdersTable />} />
        </Route>

        <Route
          path="orders/:orderId"
          element={<OrderDetails isFree={false} />}
        />
        <Route
          path="orders/free/:orderId"
          element={<OrderDetails isFree={true} />}
        />
        <Route path="reports" element={<ReportsView />}>
          <Route path="" element={<Navigate to="financialreports" replace />} />
          <Route path="financialreports" element={<FinancialReportsTable />} />
          <Route path="payouts" element={<PayoutsTable />} />
        </Route>
        <Route path="profile" element={<ProfileView />}>
          <Route path="personal" element={<PersonalInfo />} />
          <Route path="password" element={<PasswordTab />} />
        </Route>
        {projectLevelRoutes(checkDefaultProjectPage())}
        {portalLevelRoutes}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
