import { useSelector } from 'react-redux';

import { CircularProgress, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LogRocket from 'logrocket';
import { Toaster } from 'sonner';
import { UISliceState } from 'store/store.types';

import BlockOverlay from './components/BlockOverlay/BlockOverlay';
import { LOG_ROCKET_KEY } from './conf';
import AppRoutes from './routes/AppRoutes';

import './style/theme.scss';

if (process.env.REACT_APP_ENV === 'production') {
  LogRocket.init(LOG_ROCKET_KEY);
  const clarityScript = `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "qwvw83y7zm")`;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = clarityScript;
  document.head.appendChild(script);
}

LicenseInfo.setLicenseKey(
  (process.env.REACT_APP_DATA_GRID_KEY_NEW as string) ||
    '26352ff0346454e6c919d47b90644e1cTz05ODYyNyxFPTE3NTg3MDk0NjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
);

const queryClient = new QueryClient();
function App() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    },
    palette: {
      primary: {
        main: 'rgb(67, 56, 202)'
      },
      secondary: {
        main: '#26282B'
      },
      error: {
        main: '#FF7C7C'
      }
    }
  });

  const uiState = useSelector(({ ui }: { ui: UISliceState }) => ui);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div id="theme">
          {uiState.isTokenLoading && (
            <Stack
              width="100vw"
              height="100vh"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0"
              left="0"
              bgcolor="rgba(0, 0, 0, 0.8)"
              zIndex="1000"
              gap={2}
            >
              <CircularProgress
                sx={{
                  color: 'white'
                }}
              />
              <Typography variant="h5" color="white">
                Logging you in...
              </Typography>
            </Stack>
          )}
          <BlockOverlay />
          <Toaster className="w-[388px] gap-3.5 pr-4 pb-4 !right-6 !bottom-6" />
          <AppRoutes />
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
