import { EPermissionGroup } from "common/permissions.types";

export enum ESideBarChipType {
  COMING_SOON = 'coming_soon'
}

export enum ESidebarGroupKey {
  Management = 'Management',
  Studio = 'Studio',
  Offers = 'Offers',
  Dashboard = 'Dashboard',
  Projects = 'Projects'
}
export interface SideBarItem {
  title: string;
  url: string;
  icon: string | React.ReactNode;
  permissions?: EPermissionGroup[];
  chip?: ESideBarChipType;
  isDisabled?: boolean;
}

export interface SideBarGroup {
  title: ESidebarGroupKey;
  items: SideBarItem[];
  isCollapsable?: boolean;
  type?: ESideBarChipType;
}

export enum ESideBarContext {
  COMPANY = 'company',
  PROJECT = 'project',
  PORTAL = 'portal'
}
