import { Dispatch, ReactNode, SetStateAction } from 'react';

import { BaseSelectProps, MenuItemProps } from '@mui/material';

import { EAssetType } from '../../constants/enums';

export interface AcSelectItem extends MenuItemProps {
  content: string | ReactNode | number;
  key?: string | number;
  renderFunction?: (item: AcSelectItem) => ReactNode;
  filterBy?: string;
}

export interface AcSelectImageItem extends AcSelectItem {
  url: string;
  name?: string;
}

export enum EAcSelectItemRenderType {
  TEXT = 'text',
  IMAGE = 'image'
}

export interface AcSelectUploadConfig {
  onUploadSuccess: Function;
  uploadType: EAssetType;
  uploadMessage?: string;
  validator?: (file: File) => Promise<boolean>;
}

export interface AcSelectProps extends BaseSelectProps {
  items: AcSelectItem[] | AcSelectImageItem[];
  header?: string;
  helperText?: string;
  tooltip?: string;
  renderType?: EAcSelectItemRenderType;
  uploadConfig?: AcSelectUploadConfig;
  onClear?: () => void;
  required?: boolean;
  headerSize?: string;
  imgHeight?: string;
  isCheckBox?: boolean;
  loading?: boolean;
  setSelectedItems?:
    | Dispatch<SetStateAction<any[]>>
    | ((ids: string[]) => void);
  className?: string;
  isRepeatSelect?: any;
  defaultCheckedItems?: string[];
  value: any;
  isSelectAllEnabled?: boolean;
  isApplyBtnEnabled?: boolean;
  isLimited?: boolean;
  isFree?: boolean;
  multipleSelect?: boolean;
  selectOptions?: boolean;
  isSearchEnabled?: boolean;
  renderStatusTextOverride?: (status: string | string[]) => ReactNode;
  onOpen?: () => void;
  uploadBtnTestId?: string;
  dataTestValidationId?: string;
  dataTestListId?: string;
  dataTestBoxId?: string;
  dataTestHeaderId?: string;
  selectAllCustomLabel?: string
}

export enum EAcSelectOptions {
  ALL = 'all'
}
