import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { ESideBarContext, SideBarItem } from './types';
import {
  getCompanyLevelNavItems,
  getPortalLevelNavItems,
  getProjectLevelNavItems
} from './utils';
import { useSidebar } from './context';
import usePermissions from 'api/usePermissions';
import usePublisher from 'api/usePublisher';
import { EPublisherSolutionModel } from 'common/contracts';
export const useSidebarNavigation = () => {
  const {
    context,
    setContext,
    setNavItems,
    setIsCheckout,
    userDetails,
    featureFlags,
    setIsLoading
  } = useSidebar();
  const { getPermissions } = usePermissions();
  const location = useLocation();
  const { publisherId, portalId } = useParams();
  const { getPublisher } = usePublisher(publisherId || undefined);

  // Update context based on URL
  useEffect(() => {
    // Determine what the new context should be
    let newContext;
    if (publisherId) {
      newContext = ESideBarContext.PROJECT;
    } else if (portalId) {
      newContext = ESideBarContext.PORTAL;
    } else {
      newContext = ESideBarContext.COMPANY;
    }

    // Only set loading and update context if it's actually changing
    if (newContext !== context) {
      setIsLoading(true);
      setContext(newContext);
    }
  }, [location, publisherId, portalId, context, setContext, setIsLoading]);

  // Set navigation items based on context
  useEffect(() => {
    const permissions = getPermissions();

    const timer = setTimeout(() => {
      switch (context) {
        case ESideBarContext.COMPANY:
          setNavItems(getCompanyLevelNavItems(permissions));
          break;
        case ESideBarContext.PROJECT:
          if (!publisherId) {
            break;
          }
          setNavItems(
            getProjectLevelNavItems(permissions, publisherId, featureFlags)
          );

          break;
        case ESideBarContext.PORTAL:
          setNavItems(getPortalLevelNavItems(permissions));
          break;
        default:
          setNavItems(getCompanyLevelNavItems(permissions));
      }

      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [context, featureFlags, userDetails, setNavItems, setIsLoading]);

  // Check if project is checkout
  useEffect(() => {
    if (getPublisher.data) {
      setIsCheckout(
        getPublisher.data.publisherSolutionModel ===
          EPublisherSolutionModel.CHECKOUT
      );
    }
  }, [publisherId, userDetails, setIsCheckout]);
};

export const useProjectInfo = () => {
  const { userDetails } = useSidebar();
  const location = useLocation();

  const getProjectName = () => {
    const publisherId = location.pathname.split('/project/')[1]?.split('/')[0];
    const project = userDetails?.projects?.find(
      (p) => p.publisherId === publisherId
    );
    return project?.publisherName || 'Project';
  };

  const getPortalName = () => {
    const portalId = location.pathname.split('/portal/')[1]?.split('/')[0];
    const portal = userDetails?.portals?.find((p) => p.portalId === portalId);
    return portal?.portalName || 'Portal';
  };

  return {
    getProjectName,
    getPortalName
  };
};

export const useSidebarUrl = (context: ESideBarContext) => {
  const { publisherId, portalId } = useParams();

  const getNavFullUrl = (item: SideBarItem) => {
    if (context !== ESideBarContext.COMPANY) {
      return `${context}/${publisherId || portalId}/${item.url}`;
    } else {
      return item.url;
    }
  };

  return { getNavFullUrl };
};
