import { Route, Routes } from 'react-router-dom';

import PagesForm from './PagesForm/PagesForm';
import PagesTable from './PagesTable';

const PagesView = () => {
  return (
    <Routes>
      <Route path="/" element={<PagesTable />} />
      <Route path="/form" element={<PagesForm />} />
      <Route path="/form/:pageId" element={<PagesForm edit={true} />} />
      <Route
        path="/dup/:pageId"
        element={<PagesForm edit={false} dup={true} />}
      />
    </Routes>
  );
};

export default PagesView;
