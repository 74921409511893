import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import useIntegration from 'api/useIntegration';
import useProfiles from 'api/useProfiles';
import useUsers from 'api/useUsers';

import { EToasterStatus, showToast } from '../utils/showToast';

export const useProfilesManager = () => {
  const { publisherId } = useParams();
  const { getIntegration }: any = useIntegration(publisherId as string);
  const { toggleProfile } = useProfiles(publisherId as string);
  const [isProfilesFeatureFlagOn, setIsProfilesFeatureFlagOn] =
    useState<boolean>(false);
  const [isProfilesEnabled, setIsProfilesEnabled] = useState<boolean>(
    getIntegration.data?.isProfilesEnabled
  );
  const { fetchFeatureFlags } = useUsers({ enableFeatureFlags: false });
  const [isToggleInProgress, setIsToggleInProgress] = useState<boolean>(false);

  const toggleIntegrationProfiles = (isProfilesToggleOn: boolean) => {
    toggleProfile.mutate(
      {
        isEnabled: isProfilesToggleOn
      },
      {
        onSuccess: () => {
          showToast({
            message: 'Integration profile changed successfully',
            status: EToasterStatus.SUCCESS
          });
          getIntegration.refetch();
        },
        onError: () => {
          showToast({
            message: 'Failed to toggle profile',
            status: EToasterStatus.ERROR
          });
        },
        onSettled: () => setIsToggleInProgress(false)
      }
    );
  };

  useEffect(() => {
    if (!fetchFeatureFlags.data) return;
    setIsProfilesFeatureFlagOn(
      fetchFeatureFlags.data.featureFlags.integration_profiles
    );
  }, [fetchFeatureFlags.data]);

  useEffect(() => {
    if (!getIntegration.data) return;
    const isEnabled =
      getIntegration.data.isProfilesEnabled && isProfilesFeatureFlagOn;
    setIsProfilesEnabled(isEnabled);
  }, [getIntegration.data, isProfilesFeatureFlagOn]);

  return {
    toggleIntegrationProfiles,
    isToggleInProgress,
    isProfilesEnabled,
    isProfilesFeatureFlagOn
  };
};
