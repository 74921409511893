import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import {
  Background,
  BundlesLayout,
  DailyBonus,
  EBundlesViewModel,
  EFontWeights,
  HugeRollingOffer
} from '@appcharge/shared-ui';
import useTheme from 'api/useTheme';
import {
  EBundlesInternalViewModel,
  ESpecialOfferInternalViewModel
} from 'constants/enums';
import { FormikValues } from 'formik';
import { renderingUtil } from 'utils/renderingUtil';

import { OfferDesignTypes } from '../types';

interface OfferUiMockProps {
  values: FormikValues;
}

const OfferUiMock: React.FC<OfferUiMockProps> = ({ values }) => {
  const { publisherId } = useParams();

  const { getGeneralTheme: generalTheme, getStoreScreen: storeScreen } =
    useTheme(publisherId);

  const viewModel = generalTheme.data?.bundlesViewModel as EBundlesViewModel;

  const bundlesAmount = useMemo(() => {
    if (viewModel === EBundlesViewModel.TWO_IN_ONE) return 2;
    if (viewModel === EBundlesViewModel.THREE_IN_ONE) return 3;
    if (viewModel === EBundlesViewModel.SIX_IN_ONE) return 6;
    return 1;
  }, [viewModel]);

  return (
    <div className="iphone-mock" id={'iphone-mock'}>
      <Background
        backgroundImageMobile={generalTheme.data?.backgroundImageMobile}
        backgroundImageDesktop={generalTheme.data?.backgroundImageDesktop}
        width="100%"
        height="100%"
        position="absolute"
      />
      {values.offerUiType === OfferDesignTypes.Bundle && (
        <div className="bundles-wrapper">
          <BundlesLayout bundlesViewModel={viewModel} isPreview={true}>
            {Array.from(
              {
                length: bundlesAmount
              },
              () => null
            ).map((_, index) =>
              renderingUtil.renderBundleByInternalModel(
                generalTheme.data?.bundlesInternalViewModel ||
                  EBundlesInternalViewModel.MAIN_SUB,
                {
                  index: index,
                  backgroundSrc: values.backgroundImage,
                  backgroundColor: values?.specialOffer?.backgroundColor,
                  bundleBorderColor: storeScreen.data?.bundleBorderColor,
                  productList: [],
                  bundleButtonColor: storeScreen.data?.buttonColor,
                  bundleButtonTextColor: storeScreen.data?.buttonTextColor,
                  fontFamily: generalTheme.data?.font,
                  prices: {
                    priceDisplay: '$ 0.00',
                    priceInUsdCents: 2
                  },
                  inMobileMock: true,
                  gameName: generalTheme.data?.bundlesInternalViewModel
                }
              )
            )}
          </BundlesLayout>
        </div>
      )}
      {values.offerUiType === OfferDesignTypes.SpecialOffer && (
        <div className="bundles-wrapper">
          {renderingUtil.renderSpecialOfferByInternalModel(
            generalTheme.data?.specialOffersInternalViewModel ||
              ESpecialOfferInternalViewModel.HUGE,
            {
              title: values?.specialOffer?.title,
              fontSize: values.specialOffer?.fontSize || 20,
              fontWeight:
                values.specialOffer?.fontWeight || ('normal' as EFontWeights),
              textColor: values?.specialOffer?.fontColor || '#FFF',
              subTitle: values?.specialOffer?.subTitle?.text
                ? values?.specialOffer?.subTitle?.text
                : '',
              subTitleSize: values.specialOffer?.subTitle?.fontSize || 16,
              subTitleWeight:
                values.specialOffer?.subTitle?.fontWeight ||
                ('normal' as EFontWeights),
              subTitleColor:
                values?.specialOffer?.subTitle?.fontColor || '#FFF',
              borderColor: values.borderColor,
              buttonColor: storeScreen.data?.buttonColor,
              backgroundImage: values.backgroundImage,
              design: 'match',
              prices: {
                priceDisplay: '$ 0.00',
                priceInUsdCents: 2
              },
              productsList: [],
              selectOffer: () => {},
              backgroundColor: values?.specialOffer?.backgroundColor
            }
          )}
        </div>
      )}
      {values.offerUiType === OfferDesignTypes.PopUp && (
        <DailyBonus
          isPreview={true}
          title={values?.specialOffer?.title}
          modalContainer={() => document.getElementById('iphone-mock')}
          backgroundColor={values?.specialOffer?.backgroundColor?.colorOne}
          backgroundImage={values.backgroundImage}
          titleWeight={values?.specialOffer?.fontWeight as any}
          titleSize={(values?.specialOffer?.fontSize || 24) + 'px'}
          titleColor={values?.specialOffer?.fontColor.colorOne}
          onCollect={async () => true}
          borderColor={values.borderColor}
          borderWidth={values.borderWidth}
        />
      )}
      {values.offerUiType === OfferDesignTypes.RollingOffer && (
        <HugeRollingOffer
          isPreview={true}
          backgroundImage={values.backgroundImage}
          backgroundColor={
            values?.rollingOffer?.backgroundColor || {
              colorOne: '#323281'
            }
          }
          borderColor={values.borderColor}
          borderWidth={values.borderWidth}
          indexToCollect={1}
          collectBehavior={'disappear'}
          arrowColor={values?.rollingOffer?.arrowColor}
          subRollingOffersProducts={Array.from({ length: 6 }, (_, index) => ({
            index: index + 1,
            prices: {
              priceInUsdCents: 0
            },
            products: [
              {
                images: {
                  product: ''
                }
              }
            ]
          }))}
          subRollingOfferUiProps={{
            backgroundColor: values?.rollingOffer?.subRollingOffer
              ?.backgroundColor || { colorOne: '#ffffff' },
            backgroundImage:
              values?.rollingOffer?.subRollingOffer?.backgroundImage,
            lockImage:
              values?.rollingOffer?.subRollingOffer?.lockImage ||
              'https://media-dev.appcharge.com/media/66be029440fd9d64e8b421a9/1732101369729__f5611ed5-cb85-4b81-b8f1-373921b64d86',
            buttonColor: storeScreen.data?.buttonColor,
            collectText: values.rollingOffer?.subRollingOffer?.collectText,
            buttonTextColor: storeScreen.data?.buttonTextColor
          }}
          selectOffer={async () => true}
        />
      )}
    </div>
  );
};

export default OfferUiMock;
