import React from 'react';

import { ELocalStorageKeys } from 'constants/enums';
import { localStorageUtil } from 'utils/localStorageUtil';

import { User } from '../../common/contracts';

import ProfileDropDown from './ProfileDropDown/ProfileDropDown';

export default function Header() {
  const userDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  );

  return (
    <header className="sticky top-0 bg-white mt-2.5 mb-4 mx-7 flex justify-end z-50">
      <ProfileDropDown user={userDetails} />
    </header>
  );
}
