import { useMemo, useState } from 'react';

import {
  Cell,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  Header,
  HeaderGroup,
  Row,
  useReactTable
} from '@tanstack/react-table';
import { MAX_TABLE_ROWS } from 'constants/constants';

import { cn } from '../lib/utils';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow
} from '../ui';

import DataTablePagination from './components/DataTablePagination';

export type Size = 'sm' | 'md' | 'lg';

export interface DataTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  showHeader?: boolean;
  showDataAggregatedRow?: boolean;
  aggregatedRowData?: { title: string; amount: number };
  size?: Size;
  className?: string;
  showPagination?: boolean;
  rowsPerPage?: number;
}

const sizeClasses = {
  sm: 'h-12',
  md: 'h-16',
  lg: 'h-24'
};

const DataTable = <T extends object>({
  data,
  columns,
  className,
  showHeader = true,
  showDataAggregatedRow = false,
  aggregatedRowData,
  size = 'sm',
  showPagination = true,
  rowsPerPage = MAX_TABLE_ROWS
}: DataTableProps<T>) => {
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: rowsPerPage
  });
  const table = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: false,
    // **Automated Pagination**:
    // By default, this enables automated pagination, where React Table controls pagination state
    // (page index, page size) and handles the row display.
    //
    // If you need **manual pagination** (e.g., controlling pagination state yourself or fetching data
    // from an API per page), you can replace the pagination logic here with your own.
    // This would involve managing the pagination state manually (e.g., pageIndex, pageSize) and
    // handling data fetching accordingly.    
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: { pageIndex, pageSize }
    },
    onPaginationChange: setPagination
  });

  return (
    <Table className={cn('w-full', className)}>
      {showHeader && (
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup: HeaderGroup<T>) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(
                (header: Header<T, any>, index: number) => (
                  <TableHead
                    key={header.id}
                    className={cn(
                      'py-4 px-4 text-base-mutedForeground text-p-s font-normal text-left',
                      'last:text-right'
                    )}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHead>
                )
              )}
            </TableRow>
          ))}
        </TableHeader>
      )}
      <TableBody>
        {table.getRowModel().rows.map((row: Row<T>) => (
          <TableRow
            key={row.id}
            className={cn(
              'first:border-t-0 box-border',
              'hover:bg-base-muted',
              sizeClasses[size as Size]
            )}
          >
            {row.getVisibleCells().map((cell: Cell<T, any>) => (
              <TableCell
                key={cell.id}
                className={cn(
                  'border-t px-4 break-words text-left text-base-foreground text-p-s',
                  'last:text-right'
                )}
              >
                <div
                  className={cn(
                    'text-black',
                    'whitespace-nowrap overflow-auto',
                    'max-w-[110px]',
                    'inline-block',
                    '[&::-webkit-scrollbar]:hidden'
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </TableCell>
            ))}
          </TableRow>
        ))}
        {showDataAggregatedRow && aggregatedRowData && (
          <TableRow className="bg-base-muted">
            <TableCell
              colSpan={columns.length - 1}
              className="px-4 py-4 text-left  text-base-foreground text-p-s"
            >
              {aggregatedRowData.title}
            </TableCell>
            <TableCell className="px-4 py-4 font-medium text-right  text-base-foreground text-p-s">
              {aggregatedRowData.amount}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {showPagination && (
        <TableFooter>
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="px-5 text-base-foreground text-p-s"
            >
              <DataTablePagination table={table} />
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default DataTable;
