import { SideBarGroup } from '../types';
import { SidebarMenu, SidebarMenuItem } from 'components/ui/sidebar';
import { SidebarGroup, SidebarGroupLabel } from '../elements';
import { SidebarMenuItemComponent } from './SidebarItem';
import { useSidebar } from '../context';

interface SidebarGroupComponentProps {
  group: SideBarGroup;
}

export const SidebarGroupComponent = ({
  group
}: SidebarGroupComponentProps) => {
  const { collapsedGroups, toggleGroup } = useSidebar();

  const isCollapsed = collapsedGroups[group.title];
  const isCollapsable = group.isCollapsable !== false; // Default to true if not specified

  const handleGroupToggle = () => {
    if (isCollapsable) {
      toggleGroup(group.title);
    }
  };

  return (
    <SidebarGroup>
      <SidebarGroupLabel
        onClick={handleGroupToggle}
        className={isCollapsable ? 'cursor-pointer hover:text-primary' : ''}
        isChevronIconVisible
        isCollapsed={
          !!collapsedGroups[group.title as keyof typeof collapsedGroups]
        }
      >
        {group.title}
      </SidebarGroupLabel>
      {!isCollapsed && (
        <SidebarMenu>
          {group.items.map((item, index) => (
            <SidebarMenuItem key={`${item.title}-${index}`}>
              <SidebarMenuItemComponent item={item} groupTitle={group.title} />
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      )}
    </SidebarGroup>
  );
};
