import React, { FC, ReactNode } from 'react';

import { cn } from '../../../components/lib/utils';
import { UISidebarGroup } from '../../../components/ui';

type SidebarGroupProps = React.ComponentProps<typeof UISidebarGroup> & {
  children?: ReactNode;
  className?: string;
};

const SidebarGroup: FC<SidebarGroupProps> = ({
  children,
  className,
  ...rest
}) => (
  <UISidebarGroup className={cn('p-0')} {...rest}>
    {children}
  </UISidebarGroup>
);

export default SidebarGroup;
