import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../Button/Button';
import { cn } from '../lib/utils';
import Typography from '../Typography/Typography';

type EmptyStateProps = {
  title?: string;
  description: React.ReactNode;
  buttonText?: string;
  className?: string;
  onClick?: () => void;
};

const EmptyState: FC<EmptyStateProps> = ({
  title,
  description,
  buttonText,
  className,
  onClick
}) => {
  if (!title && !description) return null;

  return (
    <div
      className={cn(
        'w-full max-w-[780px] bg-base-card border border-base-border px-32 py-24 rounded-xl text-center',
        className
      )}
    >
      <div className={cn('flex items-center justify-center flex-col gap-2.5')}>
        <div className="p-4 rounded-md bg-base-muted">
          <FontAwesomeIcon
            icon="fa-regular fa-cube"
            className="text-base-mutedForeground h-5 w-5 text-p-l"
          />
        </div>
        <Typography className={cn('text-p-xl text-base-cardForeground')}>
          {title}
        </Typography>
        <Typography className={cn('text-p-s text-base-cardForeground')}>
          {description}
        </Typography>
      </div>
      <Button isCreateButton className={cn('mt-6')} onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  );
};

export default EmptyState;
