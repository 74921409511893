import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

export const storeSettingsSchema = yup.object().shape({
  productsQuantityFormat: yup.object().shape({
    milSeparator: yup.string().required(required('Million separator'))
  }),
  specialOfferQuantityFormat: yup.object().shape({
    milSeparator: yup.string().required(required('Million separator'))
  })
});
