import { z } from 'zod';

import { RadioButtonOptionsProps } from '../../../components/RadioButton/RadioButton';

export enum EPagesRadioButtonOptions {
  HIDDEN = 'hidden',
  VISIBLE = 'visible'
  // SCHEDULED = 'scheduled'
}

export enum EPagesTemplateOptions {
  CONTENT = 'Content',
  WEBSTORE = 'Webstore'
}

export const pageTemplateOptions = [
  {
    value: EPagesTemplateOptions.CONTENT,
    label: EPagesTemplateOptions.CONTENT
  },
  {
    value: EPagesTemplateOptions.WEBSTORE,
    label: EPagesTemplateOptions.WEBSTORE
  }
];

export const PAGES_RADIO_BUTTON_OPTIONS: RadioButtonOptionsProps[] = [
  {
    value: EPagesRadioButtonOptions.VISIBLE,
    id: EPagesRadioButtonOptions.VISIBLE,
    label: 'Visible'
  },
  {
    value: EPagesRadioButtonOptions.HIDDEN,
    id: EPagesRadioButtonOptions.HIDDEN,
    label: 'Hidden'
  }
  // {
  //   value: EPagesRadioButtonOptions.SCHEDULED,
  //   id: EPagesRadioButtonOptions.SCHEDULED,
  //   label: 'Scheduled'
  // }
];

export const FORM_SCHEMA = z.object({
  pageName: z.string().min(1, { message: 'Page name is required.' }),
  pageIcon: z.string().min(1, { message: 'Page icon is required.' }),
  slug: z.string().optional(),
  pageTemplate: z.string().min(1, { message: 'Page template is required.' }),
  metadataImage: z.string().optional(),
  metadataDescription: z.string().optional(),
  //TODO add 'scheduled' option in next phase
  state: z.enum([
    EPagesRadioButtonOptions.VISIBLE,
    EPagesRadioButtonOptions.HIDDEN
    // EPagesRadioButtonOptions.SCHEDULED
  ]),
  publishDate: z.string()
});
