import { useEffect, useRef, useState } from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import useLocalization from 'api/useLocalization';
import { AxiosError } from 'axios';
import { UploadTypes } from 'constants/enums';
import { formatDate } from 'utils/formattingUtil';

import AcCard from 'components/AcCard/AcCard';
import ActionButton from 'components/ActionButton/ActionButton';
import ImportCSVModal from 'components/ImportCSVModal/ImportCSVModal';

import { EToasterStatus, showToast } from '../../../../utils/showToast';

import './style.scss';

const ImportTranslationsCSVSection = () => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [activeUploadType, setActiveUploadType] = useState<UploadTypes | null>(
    null
  );
  const [lastUploadDateStatic, setLastUploadDateStatic] = useState<
    string | null
  >(null);
  const [lastUploadDateDynamic, setLastUploadDateDynamic] = useState<
    string | null
  >(null);
  const {
    importStaticTranslationsCsvFile,
    importDynamicTranslationsCsvFile,
    getDynamicTranslationsCsvLastUpdateDate,
    getStaticTranslationsCsvLastUpdateDate
  } = useLocalization();

  useEffect(() => {
    const updateLastUpdatedDate = async () => {
      const lastUpdateDateStatic =
        await getStaticTranslationsCsvLastUpdateDate.refetch();
      const lastUpdateDateDynamic =
        await getDynamicTranslationsCsvLastUpdateDate.refetch();
      if (lastUpdateDateStatic.data.lastUpdate) {
        setLastUploadDateStatic(
          formatDate(lastUpdateDateStatic.data.lastUpdate)
        );
      }
      if (lastUpdateDateDynamic.data.lastUpdate) {
        setLastUploadDateDynamic(
          formatDate(lastUpdateDateDynamic.data.lastUpdate)
        );
      }
    };
    updateLastUpdatedDate();
  }, []);

  const handleStaticImportCSV = (formData: FormData) => {
    importStaticTranslationsCsvFile.mutate(formData, {
      onSuccess: async () => {
        showToast({
          message: 'Static translations file has been uploaded successfully',
          status: EToasterStatus.SUCCESS
        });
        const updatedDate =
          await getStaticTranslationsCsvLastUpdateDate.refetch();
        if (updatedDate.data.lastUpdate) {
          setLastUploadDateStatic(formatDate(updatedDate.data.lastUpdate));
        }
        setActiveUploadType(null);
      },
      onError(error) {
        showToast({
          message: 'Static translations file has failed to upload',
          status: EToasterStatus.ERROR,
          description: (
            (error as AxiosError).response?.data as {
              message: string;
            }
          ).message
        });
      }
    });
  };

  const handleDynamicImportCSV = (formData: FormData) => {
    importDynamicTranslationsCsvFile.mutate(formData, {
      onSuccess: async () => {
        showToast({
          message: 'Dynamic translations file has been uploaded successfully',
          status: EToasterStatus.SUCCESS
        });
        const updatedDate =
          await getDynamicTranslationsCsvLastUpdateDate.refetch();
        if (updatedDate.data.lastUpdate) {
          setLastUploadDateDynamic(formatDate(updatedDate.data.lastUpdate));
        }
        setActiveUploadType(null);
      },
      onError(error) {
        showToast({
          message: 'Dynamic translations file has failed to upload',
          status: EToasterStatus.ERROR,
          description: (
            (error as AxiosError).response?.data as {
              message: string;
            }
          ).message
        });
      }
    });
  };

  return (
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid size={12} sx={{ position: 'relative' }}>
        <AcCard
          title={'3. Upload New Translations file'}
          description={
            'Upload a CSV with translations for static text (e.g., labels, buttons) or dynamic content that varies by user'
          }
          stackContainer={false}
          className="localizationCSVCard"
          customTitleClass="localizationCSVCardTitle"
          customDescriptionClass="localizationCSVCardDescription"
          customStyles={{
            border: '1px solid #cacbd4',
            borderRadius: '4px',
            marginBottom: '23px'
          }}
        >
          <Stack direction="row" spacing={2}>
            <ActionButton
              variant="outlined"
              text="Upload Static strings"
              onClick={() => setActiveUploadType(UploadTypes.STATIC)}
            />
            <ActionButton
              variant="outlined"
              text="Upload Dynamic Strings"
              onClick={() => setActiveUploadType(UploadTypes.DYNAMIC)}
            />
          </Stack>
          {lastUploadDateStatic && (
            <Typography
              variant="body2"
              sx={{
                marginTop: '14px',
                fontSize: '14px',
                fontFamily: `'Montserrat', sans-serif`
              }}
            >
              {`Last upload: ${lastUploadDateStatic} (Static strings)`}
            </Typography>
          )}
          {lastUploadDateDynamic && (
            <Typography
              variant="body2"
              sx={{
                marginTop: '14px',
                fontSize: '14px',
                fontFamily: `'Montserrat', sans-serif`
              }}
            >
              {`Last upload: ${lastUploadDateDynamic} (Dynamic strings)`}
            </Typography>
          )}
        </AcCard>
        <ImportCSVModal
          isOpen={!!activeUploadType}
          onCloseModal={() => setActiveUploadType(null)}
          fileRef={fileRef}
          onImportFile={(formData: FormData) =>
            activeUploadType === UploadTypes.STATIC
              ? handleStaticImportCSV(formData)
              : handleDynamicImportCSV(formData)
          }
          fileKeyName="languagesFile"
        />
      </Grid>
    </Grid>
  );
};

export default ImportTranslationsCSVSection;
