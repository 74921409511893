import { useState, useEffect } from 'react';

import { UseQueryResult } from '@tanstack/react-query';

import { usePrevious } from 'hooks/usePrevious';
import { OrdersQueryResponse } from 'api/useOrders';
import { EAwardFlowStatus, EOrderStatus } from 'constants/enums';

export function useFailedAwardRetryTracking(
  ordersQueryResponse: UseQueryResult<OrdersQueryResponse, unknown>
) {
  const [isFailedAwardModalOpen, setIsFailedAwardModalOpen] = useState(false);
  const [retryingAwardOrderIds, setRetryingAwardOrderIds] = useState<string[]>(
    []
  );
  const orders = ordersQueryResponse.data?.orders;
  const previousOrders = usePrevious(orders);

  useEffect(() => {
    const shouldRefetch = orders?.some(
      (order) =>
        order.awardFlow === EAwardFlowStatus.MANUAL_RETRY &&
        order.state === EOrderStatus.charge_pending
    );
    let timeout: NodeJS.Timeout;
    if (shouldRefetch) {
      timeout = setTimeout(() => {
        ordersQueryResponse.refetch();
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [orders]);

  useEffect(() => {
    setRetryingAwardOrderIds([]);
    const ordersMap = new Map(
      previousOrders?.map((order) => [order._id, order])
    );
    const failedRetryAwardOrders = orders?.filter((order) => {
      const previousOrder = ordersMap.get(order._id);
      return (
        previousOrder &&
        order.state !== previousOrder.state &&
        order.state === EOrderStatus.charge_failed &&
        order.awardFlow === EAwardFlowStatus.MANUAL_RETRY
      );
    });
    if (failedRetryAwardOrders?.length) {
      setIsFailedAwardModalOpen(true);
    }
  }, [orders]);

  return {
    retryingAwardOrderIds,
    setRetryingAwardOrderIds,
    isFailedAwardModalOpen,
    setIsFailedAwardModalOpen
  };
}
