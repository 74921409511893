import { Navigate, useLocation } from 'react-router-dom';

import { useLogin } from '../hooks/useLogin';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const { isLoggedIn } = useLogin();
  const location = useLocation();

  return isLoggedIn() ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default PrivateRoute;
