import React, { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import useProfiles from 'api/useProfiles';
import usePublisher from 'api/usePublisher';
import { PROFILES_DEFAULT_NAME } from 'constants/constants';
import { textModelOptions } from 'utils/selectOptionsUtils';

import { EToasterStatus, showToast } from '../../../utils/showToast';
import {
  type ProfileData,
  ProfileField,
  type ProfileFormValues
} from '../Settings.types';

import IntegrationLinkCell from './IntegrationLinkCell';

export interface ProfileCardProps {
  profile: ProfileData;
  publisherId?: string;
  setValues: React.Dispatch<React.SetStateAction<ProfileFormValues>>;
  setEditedProfile: (profile: ProfileData) => void;
  setProfiles: React.Dispatch<React.SetStateAction<ProfileData[]>>;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  profile,
  publisherId,
  setValues,
  setEditedProfile,
  setProfiles
}) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const { getProfiles, deleteProfile } = useProfiles(publisherId || '');
  const { getPublisher } = usePublisher(publisherId);

  const handleEditProfile = () => {
    setMenuAnchor(null);
    setEditedProfile(profile);
    setValues((prevValues) => ({
      ...prevValues,
      name: profile.name,
      playerInfoSyncUrl: profile.playerInfoSyncUrl || '',
      playersAuthWebhook: profile.playersAuthWebhook,
      ordersReportingApiUrl: profile.ordersReportingApiUrl,
      eventsWebhookUrl: profile.eventsWebhookUrl || '',
      fbAppId: profile.fbAppId || '',
      otpDeepLink: {
        otpGenerateDeeplinkUrl:
          profile?.otpDeepLink?.otpGenerateDeeplinkUrl || '',
        otpTextModel: profile?.otpDeepLink?.otpTextModel || '',
        otpOn: true
      }
    }));
  };

  const handleDeleteProfile = () => {
    deleteProfile.mutate(profile._id, {
      onSuccess: () => {
        setProfiles((prevProfiles) =>
          prevProfiles.filter((p) => p._id !== profile._id)
        );
        getPublisher.refetch();
        showToast({
          message: 'Integration profile deleted successfully',
          status: EToasterStatus.SUCCESS
        });
      },
      onError: () => {
        setProfiles(getProfiles.data || []);
        showToast({
          message: 'Failed to delete Integration profile',
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const profileFields = [
    {
      label: ProfileField.ORDER_REPORTING_API,
      value: profile.ordersReportingApiUrl
    },
    {
      label: ProfileField.AUTHENTICATION_WEBHOOK,
      value: profile.playersAuthWebhook
    },
    {
      label: ProfileField.PERSONALIZATION_API_URL,
      value: profile.playerInfoSyncUrl
    },
    { label: ProfileField.EVENTS_WEBHOOK, value: profile.eventsWebhookUrl },
    {
      label: ProfileField.OTP_GENERATION_DEEPLINK_URL,
      value: profile?.otpDeepLink?.otpGenerateDeeplinkUrl
    },
    {
      label: ProfileField.DESKTOP_TEXT_MODEL,
      value:
        textModelOptions.find(
          (option) => option.value === profile?.otpDeepLink?.otpTextModel
        )?.content || ''
    },
    { label: ProfileField.FACEBOOK_ID, value: profile.fbAppId }
  ];

  const isDefaultProfile = profile.name === PROFILES_DEFAULT_NAME;

  return (
    <Card variant="outlined" sx={{ borderRadius: 2 }}>
      <Grid container justifyContent="space-between" p={2} sx={{ mb: 1 }}>
        <Typography color={isDefaultProfile ? '#717188' : 'inherit'}>
          Profile Name: {profile.name}
        </Typography>
        <IconButton
          onClick={(event) => setMenuAnchor(event.currentTarget)}
          sx={{ padding: 0 }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
        >
          <MenuItem onClick={handleEditProfile} disabled={isDefaultProfile}>
            <ContentCopyOutlinedIcon sx={{ mr: 1 }} /> Edit
          </MenuItem>
          <MenuItem onClick={handleDeleteProfile} disabled={isDefaultProfile}>
            <DeleteOutlineTwoToneIcon sx={{ mr: 1, color: 'error.main' }} />
            Delete
          </MenuItem>
        </Menu>
      </Grid>

      <Grid
        container
        spacing={2}
        gap={2}
        bgcolor={'#F8FAFC'}
        justifyContent="space-between"
        sx={{ padding: '16px' }}
      >
        {profileFields.map((item, index) => (
          <Grid
            container
            spacing={2}
            xs={6}
            key={index}
            display={'flex'}
            alignItems={'center'}
            overflow={'hidden'}
          >
            <Grid item xs={4}>
              <Typography variant="body2" sx={{ ml: 1 }}>
                {item.label}
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <IntegrationLinkCell link={item.value || ''} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default ProfileCard;
