import { useMutation, useQuery } from '@tanstack/react-query';

import { CheckoutThemeFormValues } from 'components/Theme/CheckoutTheme/types';
import { CompletedThemeFormValues } from 'components/Theme/CompletedTheme/types';
import { GeneralThemeFormValues } from 'components/Theme/GeneralSettings/generalTheme.types';
import { LoginThemeFormValues } from 'components/Theme/LoginTheme/types';
import { StoreThemeFormValues } from 'components/Theme/StoreTheme/storeTheme.types';

import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useTheme(currentPublisherId?: string) {
  const axios = useAxios();

  const getStoreTheme = useQuery<any, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [
      EQueryKeys.STORE_THEME + currentPublisherId ? currentPublisherId : ''
    ],
    queryFn: async () => {
      const checkoutTheme = await axios.get(
        getApiRouteOrMock(EApiRoutes.CHECKOUT_THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      const theme = await axios.get(
        getApiRouteOrMock(EApiRoutes.THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      // ACDEV-10489
      return { ...theme, checkout: checkoutTheme };
    },
    enabled: !!currentPublisherId
  });

  const getGeneralTheme = useQuery<GeneralThemeFormValues, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [EQueryKeys.GENERAL_THEME + (currentPublisherId ?? '')],
    queryFn: async () =>
      await axios.get(
        getApiRouteOrMock(EApiRoutes.GENERAL_THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      ),
    enabled: !!currentPublisherId
  });

  const getStoreScreen = useQuery<StoreThemeFormValues, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [EQueryKeys.STORE_SCREEN + (currentPublisherId ?? '')],
    queryFn: async () =>
      await axios.get(
        getApiRouteOrMock(EApiRoutes.STORE_SCREEN),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      ),
    enabled: !!currentPublisherId
  });

  const getLoginTheme = useQuery<LoginThemeFormValues, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [EQueryKeys.LOGIN_THEME + (currentPublisherId ?? '')],
    queryFn: async () =>
      await axios.get(
        getApiRouteOrMock(EApiRoutes.LOGIN_THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      ),
    enabled: !!currentPublisherId
  });

  const getCheckoutTheme = useQuery<CheckoutThemeFormValues, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [EQueryKeys.CHECKOUT_THEME + (currentPublisherId ?? '')],
    queryFn: async () =>
      await axios.get(
        getApiRouteOrMock(EApiRoutes.CHECKOUT_THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      ),
    enabled: !!currentPublisherId
  });

  const getCompletedTheme = useQuery<CompletedThemeFormValues, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [EQueryKeys.COMPLETED_THEME + (currentPublisherId ?? '')],
    queryFn: async () =>
      await axios.get(
        getApiRouteOrMock(EApiRoutes.COMPLETED_THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      ),
    enabled: !!currentPublisherId
  });

  const updateStoreTheme = useMutation(async (theme: any) => {
    const promises = [];

    if (theme.checkout || theme.checkoutScreen) {
      const checkoutUpdate = await axios.put(
        getApiRouteOrMock(EApiRoutes.CHECKOUT_THEME),
        theme.checkout,
        { ...(currentPublisherId && { 'x-publishers': currentPublisherId }) }
      );
      promises.push(checkoutUpdate);
    }

    const themeUpdate = await axios
      .put(
        getApiRouteOrMock(EApiRoutes.THEME),
        theme,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      )
      .catch((err) => {
        return err;
      });
    promises.push(themeUpdate);

    return await Promise.allSettled(promises);
  }, {});

  const updateGeneralTheme = useMutation(
    async (generalTheme: Partial<GeneralThemeFormValues>) => {
      return await axios.put(
        getApiRouteOrMock(EApiRoutes.GENERAL_THEME),
        generalTheme,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    {}
  );

  const updateLoginTheme = useMutation(
    async (storeTheme: Partial<LoginThemeFormValues>) => {
      return await axios.put(
        getApiRouteOrMock(EApiRoutes.LOGIN_THEME),
        storeTheme,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    {}
  );

  const updateCheckoutTheme = useMutation(
    async (storeTheme: Partial<CheckoutThemeFormValues>) => {
      return await axios.put(
        getApiRouteOrMock(EApiRoutes.CHECKOUT_THEME),
        storeTheme,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    {}
  );

  const updateStoreScreen = useMutation(
    async (storeTheme: Partial<StoreThemeFormValues>) => {
      return await axios.put(
        getApiRouteOrMock(EApiRoutes.STORE_SCREEN),
        storeTheme,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    {}
  );

  const updateCompletedTheme = useMutation(
    async (storeTheme: Partial<CompletedThemeFormValues>) => {
      return await axios.put(
        getApiRouteOrMock(EApiRoutes.COMPLETED_THEME),
        storeTheme,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    {}
  );

  return {
    getStoreTheme, // TODO: remove!!!
    updateStoreTheme, // TODO: remove!!!
    getGeneralTheme,
    updateGeneralTheme,
    getStoreScreen,
    updateStoreScreen,
    getLoginTheme,
    updateLoginTheme,
    getCheckoutTheme,
    updateCheckoutTheme,
    getCompletedTheme,
    updateCompletedTheme
  };
}
