export interface LinkCardData {
  title: string;
  iconName: string;
  links: Array<{
    text: string;
    url: string;
  }>;
}

export const WELCOME_CARDS_DATA: LinkCardData[] = [
  {
    title: 'Webstore',
    iconName: 'fa-regular fa-store',
    links: [
      { text: 'About the Webstore', url: 'https://developers.appcharge.com/docs/webstore-overview' },
      { text: 'Webstore High-Level Flow', url: 'https://developers.appcharge.com/docs/webstore-high-level-flow' },
      { text: 'Choose a Personalization Model', url: 'https://developers.appcharge.com/docs/store-personalization-models' }
    ]
  },
  {
    title: 'Checkout',
    iconName: 'fa-regular fa-credit-card',
    links: [
      { text: 'About the Checkout', url: 'https://developers.appcharge.com/docs/checkout-overview' },
      { text: 'Checkout High-Level Flow', url: 'https://developers.appcharge.com/docs/checkout-high-level-flow' },
      { text: 'Price Localization', url: 'https://developers.appcharge.com/docs/local-currencies-player-localization' }
    ]
  },
  {
    title: 'Events Center',
    iconName: 'fa-regular fa-code',
    links: [{ text: 'About the Events Center', url: 'https://developers.appcharge.com/docs/events' }]
  },
  // {
  //   title: 'Help Center',
  //   iconName: 'fa-regular fa-circle-info',
  //   links: [
  //     { text: 'Go to help center', url: 'http://publishers.appcharge.com/' },
  //     { text: 'Open a ticket', url: '#' }
  //   ]
  // }
]; 