import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Typography from '../Typography/Typography';

type ImagePreviewProps = {
  preview: string;
  handleButtonClick: () => void;
  handleRemoveImage: (event: React.MouseEvent<SVGSVGElement>) => void;
};

export const ImagePreview: FC<ImagePreviewProps> = ({
  preview,
  handleButtonClick,
  handleRemoveImage
}) => (
  <div className="w-[152px] h-[86px] relative overflow-hidden rounded-lg group">
    <img
      src={preview}
      alt="Uploaded"
      className="w-full h-full object-cover rounded-lg"
    />
    <div
      className="hidden opacity-0 w-full h-full absolute top-0 left-0 cursor-pointer rounded-lg group-hover:flex group-hover:items-center group-hover:justify-center group-hover:bg-button-hover-upload-change group-hover:opacity-100"
      onClick={handleButtonClick}
    >
      <Typography className="text-p-xs text-base-popover">Change</Typography>
      <FontAwesomeIcon
        icon="fa-solid fa-circle-xmark"
        onClick={handleRemoveImage}
        className="w-5 h-5 absolute text-white opacity-70 top-0 right-0 text-[10px]"
      />
    </div>
  </div>
);
