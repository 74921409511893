import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { EStatusLabel, StatusLabelProps } from 'design-system/StatusLabel/types';

import { capitalizeFirstLetter } from '../../utils/formattingUtil';

export enum EBlockedPlayerStatus {
  BLOCKED = 'blocked',
  UNBLOCKED= 'unblocked'
}

export interface BlockedPlayer {
  playerId: string;
  bannedUntil: Date;
  banReason: string;
  banDate: Date;
  isBlocked: boolean;
}
export const blockedPlayersEnumStatusMap: Record<
  EBlockedPlayerStatus,
  StatusLabelProps
> = {
  [EBlockedPlayerStatus.UNBLOCKED]: {
     text: capitalizeFirstLetter(EBlockedPlayerStatus.UNBLOCKED),
     status: EStatusLabel.SUCCESS,
     prefixIcon: <CheckCircleOutlineIcon />
  },
  [EBlockedPlayerStatus.BLOCKED]: {
     text: capitalizeFirstLetter(EBlockedPlayerStatus.BLOCKED),
     status: EStatusLabel.FAILED,
     prefixIcon: <DoNotDisturbAltIcon />
  }
};

export enum SortingBlockedPlayersValue {
    PLAYER_ID = 'playerId',
    BLOCK_DATE = 'blockDate',
    END_DATE = 'endDate',
    REASON = 'reason',
    STATUS = 'status'
}
