import React from 'react';

import { NavigateFunction } from 'react-router-dom';

import { CellContext } from '@tanstack/react-table';
import dayjs from 'dayjs';

import Checkbox from 'components/Checkbox/Checkbox';
import Chip from 'components/Chip/Chip';
import ActionCell from 'components/DataTableComponent/components/ActionCell';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from 'components/ui';

import { ContentTableDataType } from './ContentTable';

const statusMap: Record<string, 'success' | 'primary' | 'warning'> = {
  visible: 'success',
  hidden: 'primary',
  scheduled: 'warning'
};

export const getContentTableColumns = (
  handleDeleteSelectedContent: (id: string) => void,
  navigate: NavigateFunction
) => [
  // {
  //   accessorKey: 'checkbox',
  //   header: () => <Checkbox variant="outlined" />,
  //   cell: () => <Checkbox variant="outlined" />
  // },
  {
    accessorKey: 'title',
    header: 'Content Name',
    cell: (cell: CellContext<Partial<ContentTableDataType>, unknown>) =>
      cell.getValue() as string
  },
  {
    accessorKey: 'pages',
    header: 'Page',
    cell: (cell: CellContext<Partial<ContentTableDataType>, unknown>) => (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <div className="w-[120px] overflow-hidden text-ellipsis whitespace-nowrap hover:z-10">
              {cell.getValue() as string}
            </div>
          </TooltipTrigger>
          <TooltipContent
            side="right"
            align="center"
            className="rounded-md border border-base-popover bg-base-popover shadow-md py-[6px] px-[12px]"
          >
            {cell.getValue() as string}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  },
  {
    accessorKey: 'creationDate',
    header: 'Created Date',
    cell: (cell: CellContext<Partial<ContentTableDataType>, unknown>) =>
      dayjs(cell.getValue() as string)
        .utc()
        .format('MMM DD, YYYY')
  },
  {
    accessorKey: 'publishDate',
    header: 'Last published',
    cell: (cell: CellContext<Partial<ContentTableDataType>, unknown>) =>
      cell.getValue()
        ? dayjs(cell.getValue() as string)
            .utc()
            .format('MMM DD, YYYY')
        : ''
  },
  {
    accessorKey: 'state',
    header: 'Status',
    cell: (cell: CellContext<Partial<ContentTableDataType>, unknown>) => (
      <Chip
        variant="dot"
        status={statusMap[cell.getValue() as string] || 'primary'}
        className="capitalize"
      >
        {cell.getValue() as string}
      </Chip>
    )
  },
  {
    accessorKey: 'actions',
    header: '',
    cell: ({ row }: any) => (
      <ActionCell
        rowData={row.original}
        actions={[
          {
            label: 'Edit',
            handler: () => navigate(`./form/${row.original.id}`)
          },
          {
            label: 'Duplicate',
            handler: () => navigate(`./dup/${row.original.id}`)
          },
          {
            label: 'Delete',
            handler: () => {
              handleDeleteSelectedContent(row.original.id);
            }
          }
        ]}
      />
    )
  }
];
