import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import useTheme from 'api/useTheme';

import { defaultLoginTheme } from './defaults';

export const useGetLoginInitialValues = () => {
  const location = useLocation();

  const { publisherId } = useParams();

  const [initialValues, setInitialValue] = useState<any>({});

  const { getLoginTheme: loginTheme } = useTheme(publisherId);

  useEffect(() => {
    setInitialValue({
      text: loginTheme?.data?.text || defaultLoginTheme.text,
      textColor: loginTheme?.data?.textColor || defaultLoginTheme.textColor,
      textSize: loginTheme?.data?.textSize || defaultLoginTheme.textSize,
      textWeight: loginTheme?.data?.textWeight || defaultLoginTheme.textWeight,
      font: loginTheme?.data?.font || defaultLoginTheme.font,
      loginLogoImage:
        loginTheme?.data?.loginLogoImage || defaultLoginTheme.loginLogoImage,
      loginLogoSize:
        loginTheme?.data?.loginLogoSize || defaultLoginTheme.loginLogoSize,
      loginBackgroundImageMobile:
        loginTheme?.data?.loginBackgroundImageMobile ||
        defaultLoginTheme.loginBackgroundImageMobile,
      loginBackgroundImageDesktop:
        loginTheme?.data?.loginBackgroundImageDesktop ||
        defaultLoginTheme.loginBackgroundImageDesktop,
      otpButton: {
        backgroundColor: {
          colorOne:
            loginTheme?.data?.otpButton?.backgroundColor?.colorOne ||
            defaultLoginTheme.otpButton.backgroundColor.colorOne,
          colorTwo:
            loginTheme?.data?.otpButton?.backgroundColor?.colorTwo ||
            defaultLoginTheme.otpButton.backgroundColor.colorTwo,
          gradientDirection:
            loginTheme?.data?.otpButton?.backgroundColor?.gradientDirection ||
            defaultLoginTheme.otpButton.backgroundColor.gradientDirection
        },
        icon:
          loginTheme?.data?.otpButton?.icon || defaultLoginTheme.otpButton.icon,
        text:
          loginTheme?.data?.otpButton?.text || defaultLoginTheme.otpButton.text,
        textColor: {
          colorOne:
            loginTheme?.data?.otpButton?.textColor?.colorOne ||
            defaultLoginTheme.otpButton.textColor.colorOne,
          colorTwo:
            loginTheme?.data?.otpButton?.textColor?.colorTwo ||
            defaultLoginTheme.otpButton.textColor.colorTwo,
          gradientDirection:
            loginTheme?.data?.otpButton?.textColor?.gradientDirection ||
            defaultLoginTheme.otpButton.textColor.gradientDirection
        }
      },
      consentSection: {
        htmlText:
          loginTheme?.data?.consentSection?.htmlText ||
          defaultLoginTheme.consentSection.htmlText,
        consentRequired:
          loginTheme?.data?.consentSection?.consentRequired ||
          defaultLoginTheme.consentSection.consentRequired
      },
      accountApprovalModal: {
        backgroundImage:
          loginTheme?.data?.accountApprovalModal?.backgroundImage ||
          defaultLoginTheme.accountApprovalModal.backgroundImage,
        backgroundColor: {
          colorOne:
            loginTheme?.data?.accountApprovalModal?.backgroundColor?.colorOne ||
            defaultLoginTheme.accountApprovalModal.backgroundColor.colorOne,
          colorTwo:
            loginTheme?.data?.accountApprovalModal?.backgroundColor?.colorTwo ||
            defaultLoginTheme.accountApprovalModal.backgroundColor.colorTwo,
          gradientDirection:
            loginTheme?.data?.accountApprovalModal?.backgroundColor
              ?.gradientDirection || ''
        },
        borderColor:
          loginTheme?.data?.accountApprovalModal?.borderColor ||
          defaultLoginTheme.accountApprovalModal.borderColor,
        borderWidth:
          loginTheme?.data?.accountApprovalModal?.borderWidth ||
          defaultLoginTheme.accountApprovalModal.borderWidth,
        buttonBackgroundColor: {
          colorOne:
            loginTheme?.data?.accountApprovalModal?.buttonBackgroundColor
              ?.colorOne ||
            defaultLoginTheme.accountApprovalModal.buttonBackgroundColor
              .colorOne,
          colorTwo:
            loginTheme?.data?.accountApprovalModal?.buttonBackgroundColor
              ?.colorTwo ||
            defaultLoginTheme.accountApprovalModal.buttonBackgroundColor
              .colorTwo,
          gradientDirection:
            loginTheme?.data?.accountApprovalModal?.buttonBackgroundColor
              ?.gradientDirection || ''
        },
        buttonTextColor:
          loginTheme?.data?.accountApprovalModal?.buttonTextColor ||
          defaultLoginTheme.accountApprovalModal.buttonTextColor,
        textColor:
          loginTheme?.data?.accountApprovalModal?.textColor ||
          defaultLoginTheme.accountApprovalModal.textColor,
        additionalText:
          loginTheme?.data?.accountApprovalModal?.additionalText || ''
      }
    });
  }, [loginTheme?.data, location]);

  return { initialValues };
};
