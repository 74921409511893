import { useMutation } from '@tanstack/react-query';

import useAxios from '../hooks/useAxios';

import { EApiRoutes, getApiRouteOrMock } from './api.utils';

export default function useCheckoutLinks(currentPublisherId?: string) {
  const axios = useAxios();

  const generateOrGetCheckoutLink = useMutation(
    async (params: { offerId: string }) => {
      return await axios.get(
        getApiRouteOrMock(EApiRoutes.GENERATE_DEEPLINK) +
          `/${params.offerId}/deeplink`,
        {},
        {
          'x-publishers': currentPublisherId!
        }
      );
    },
    {}
  );

  return {
    generateOrGetCheckoutLink
  };
}
