import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../Button/Button';
import { RadioButtonOptionsProps } from '../RadioButton/RadioButton';
import RadioButtonGroup from '../RadioButton/RadioButtonGroup';
import Typography from '../Typography/Typography';

type FormHeaderProps = {
  buttonText: string;
  onCloseForm: () => void;
  onSubmitForm: () => void;
  onOptionChange?: (value: string) => void;
  selectedOption?: string;
  title?: string;
  options?: RadioButtonOptionsProps[];
  isFormValid: boolean;
};

const FormHeader: FC<FormHeaderProps> = ({
  buttonText,
  onCloseForm,
  onSubmitForm,
  onOptionChange,
  selectedOption,
  isFormValid,
  title = '',
  options = []
}) => {
  return (
    <div className="flex items-center justify-between px-8 py-5 border-b border-base-sidebarBorder bg-white sticky top-0 z-10">
      <div className="flex items-center gap-4">
        <FontAwesomeIcon
          icon="fa-regular fa-xmark"
          className="text-neutral-700 cursor-pointer"
          onClick={onCloseForm}
        />
        <Typography className="font-medium text-base-mutedForeground border-l border-base-border h-6 pl-4">
          {title}
        </Typography>
      </div>
      <div className="flex items-center gap-7">
        {options?.length > 0 && (
          <RadioButtonGroup
            value={selectedOption}
            onValueChange={onOptionChange}
            className="border-r border-base-border h-6 pr-7"
            options={options}
          />
        )}
        <Button
          onClick={() => {
            onSubmitForm();
            onCloseForm();
          }}
          className="bg-indigo-700 hover:bg-button-hover-create"
          type="submit"
          disabled={isFormValid}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default FormHeader;
