import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Box, CircularProgress, Grid, Stack } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import useCoupons from 'api/useCoupons';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import {
  handleDateRangeApplied,
  handleSameDateRangeApplied
} from 'utils/getDateRange';

import AcInput from 'components/AcInput/AcInput';
import AcSearchInput from 'components/AcInput/AcSearchInput';
import AcSearchSelect from 'components/AcSelect/AcSearchSelect';
import DialogModal from 'components/Dialog/Dialog';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { DATE_TIME_FORMAT } from '../../constants/constants';
import { EButtonColor, ELocalStorageKeys } from '../../constants/enums';
import { AuthSliceState } from '../../store/store.types';
import { permissionsUtil } from '../../utils/permissionsUtil';
import { EToasterStatus, showToast } from '../../utils/showToast';

import { DateRangeFilter } from './Form/components/DateRangeFilter';
import { isDatePassed, STATUS_TO_ICON_MAP } from './utils';

dayjs.extend(isSameOrAfter);

const CouponsTable = () => {
  const { publisherId } = useParams();
  const navigate = useNavigate();
  const {
    getCoupons,
    setCouponActive,
    updateCouponsConfiguration,
    getCouponsConfiguration
  } = useCoupons(publisherId);
  const [searchValue, setSearchValue] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [rangeDates, setRangeDates] = useState<[Dayjs, Dayjs]>();
  const [formattedRangeDates, setFormattedRangeDates] = useState<
    string[] | null[]
  >([null, null]);
  const [, setPreviousFormattedRangeDates] = useState<string[] | null[]>([
    null,
    null
  ]);

  const [statusValues, setStatusValues] = useState<string[]>([]);
  const [searchedRows, setSearchedRows] = useState<any[]>([]);
  const [isCouponsDialogOpen, setIsCouponsDialogOpen] = useState(false);
  const isCouponsEnabled = Boolean(getCouponsConfiguration.data?.enabled);

  const toggleActivateCoupon = async (couponId: string, active: boolean) => {
    setCouponActive.mutate(
      {
        couponId,
        active
      },
      {
        onSuccess: () => {
          showToast({
            message: `Coupon ${active ? 'activated' : 'deactivated'} successfully`,
            status: EToasterStatus.SUCCESS
          });
          getCoupons.refetch();
        },
        onError: (error: any) => {
          showToast({
            message: `Failed to ${active ? 'activate' : 'deactivate'} coupon`,
            status: EToasterStatus.ERROR,
            description: error.message || ''
          });
        }
      }
    );
  };

  const isCouponExpired = (expiredBy: string) => {
    if (!expiredBy) {
      return false;
    }
    return isDatePassed(expiredBy);
  };

  const columns = [
    {
      field: 'id',
      flex: 1,
      headerName: 'ID',
      width: 100
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100
    },
    {
      field: 'discountPercentage',
      flex: 1,
      headerName: 'Discount',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <span>{params.value}%</span>;
      }
    },
    {
      field: 'redemptions',
      flex: 1,
      headerName: 'Redemptions',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span>
            {params.row.currentRedemptions}/{params.row.maxRedemptions}
          </span>
        );
      }
    },
    // {
    //   field: 'redemptions',
    //   flex: 1,
    //   headerName: 'Redemptions',
    //   width: 100,
    //   renderCell: (params: GridRenderCellParams) => {
    //     return (
    //       <span>
    //         {params.row.currentRedemptions}/{params.row.maxRedemptions}
    //       </span>
    //     );
    //   }
    // },
    {
      field: 'expiredBy',
      flex: 1,
      headerName: 'Expires on',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span>
            {params.value
              ? dayjs(params.value).utc().format(DATE_TIME_FORMAT)
              : 'Never'}
          </span>
        );
      }
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Created at',
      width: 200,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) return <span>N/A</span>;
        return (
          <span>{dayjs(params.value).utc().format(DATE_TIME_FORMAT)}</span>
        );
      }
    },
    {
      field: 'active',
      flex: 1,
      headerName: 'Status',
      width: 200,
      renderCell: ({ value, row }: any) => {
        const status = !!value ? EStatusLabel.SUCCESS : EStatusLabel.FAILED;
        const isExpired = isCouponExpired(row.expiredBy);
        if (isExpired) {
          return (
            <StatusLabel
              prefixIcon={STATUS_TO_ICON_MAP[EStatusLabel.FAILED]}
              text={'Inactive'}
              status={EStatusLabel.FAILED}
            />
          );
        }
        return (
          <StatusLabel
            prefixIcon={STATUS_TO_ICON_MAP[status]}
            text={!!value ? 'Active' : 'Inactive'}
            status={status}
          />
        );
      }
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,

      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          onClick={() => {
            navigate(`./form/${params.row.id}`);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.OFFERS,
              EPermissionAction.MANAGE,
              publisherId
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={
            params.row.active ? (
              <DoNotDisturbIcon />
            ) : (
              <CheckCircleOutlineIcon />
            )
          }
          label={params.row.active ? 'Deactivate' : 'Activate'}
          onClick={() => {
            toggleActivateCoupon(params.row.id, !params.row.active);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.OFFERS,
              EPermissionAction.MANAGE,
              publisherId
            ) ||
            isCouponExpired(params.row.expiredBy)
          }
          showInMenu
        />
      ]
    }
  ];

  useEffect(() => {
    let returnedRows = getCoupons?.data || [];
    if (searchValue === '') {
      setSearchedRows(getCoupons?.data || []);
    } else {
      returnedRows = returnedRows.filter(
        (row) =>
          row.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          row.promoCodeNames?.find((promoCodeName) =>
            promoCodeName.toLowerCase().includes(searchValue.toLowerCase())
          )
      );
    }
    if (discountValue !== '') {
      returnedRows = returnedRows.filter((row: any) => {
        return row?.discountPercentage === +discountValue;
      });
    }
    if (statusValues.length > 0) {
      returnedRows = returnedRows.filter((row: any) => {
        const filterActive = statusValues.includes('active');
        const filterInactiveActive = statusValues.includes('inactive');
        const isExpired = isCouponExpired(row.expiredBy);
        if (filterActive && filterInactiveActive) {
          return row;
        } else if (filterActive) {
          return !!row.active && !isExpired;
        }
        return !row.active || isExpired;
      });
    }
    if (rangeDates?.length) {
      const [from, to] = rangeDates;
      returnedRows = returnedRows.filter((row: any) => {
        const createdAt = row.createdAt;
        const isAfterStartDay = dayjs(createdAt).isSameOrAfter(from, 'day');
        const isBeforeEndDay =
          dayjs(createdAt).isBefore(to, 'day') ||
          dayjs(createdAt).isSame(to, 'day');
        return isAfterStartDay && isBeforeEndDay;
      });
    }
    setSearchedRows(returnedRows);
  }, [searchValue, discountValue, statusValues, getCoupons?.data, rangeDates]);

  const handleDateApply = (range: any, hours: any) => {
    if (!hours) {
      handleDateRangeApplied({
        range,
        setRangeDates,
        setFormattedRangeDates,
        setPreviousFormattedRangeDates
      });
      return;
    }
    handleSameDateRangeApplied({
      range,
      hours,
      setRangeDates,
      setFormattedRangeDates,
      setPreviousFormattedRangeDates
    });
  };

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline={
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box>Coupons</Box>
                {!getCouponsConfiguration.isLoading && (
                  <CustomizedSwitch
                    status={isCouponsEnabled}
                    texts={['Enable coupons', 'Enable coupons']}
                    functions={[
                      () => setIsCouponsDialogOpen(true),
                      () => setIsCouponsDialogOpen(true)
                    ]}
                  />
                )}
              </Stack>
            }
            buttons={[
              {
                text: 'Add New Coupon',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit() || !isCouponsEnabled,
                hidden: !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.OFFERS,
                  EPermissionAction.MANAGE,
                  publisherId
                )
              }
            ]}
          />
        }
      >
        {!getCouponsConfiguration.isLoading && !isCouponsEnabled && (
          <FirstActionModal
            headline="Coupons are disabled"
            text="Turn on coupons for players using the toggle in the top-left corner."
          />
        )}
        <AcContentWrapper>
          {!getCouponsConfiguration.isLoading && isCouponsEnabled && (
            <>
              <Grid container>
                <Grid item xs={12} mt={2}>
                  <AcInput
                    name="notes"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    sx={{
                      maxWidth: '340px'
                    }}
                  />
                </Grid>
                <Grid item mt={2}>
                  <DateRangeFilter
                    setRangeDates={setRangeDates}
                    rangeDates={rangeDates}
                    handleDateApply={handleDateApply}
                  />
                </Grid>
                <Grid item mt={2} ml={1}>
                  <AcSearchSelect
                    header="Status"
                    name="status"
                    items={[
                      {
                        content: 'Active',
                        key: '0',
                        value: 0,
                        filterBy: 'active'
                      },
                      {
                        content: 'Inactive',
                        key: '1',
                        value: 1,
                        filterBy: 'inactive'
                      }
                    ]}
                    sx={{ flexGrow: 1 }}
                    renderStatusTextOverride={(status: string | string[]) => {
                      return (
                        <span>
                          {status[0].charAt(0).toUpperCase() +
                            status[0].slice(1)}
                          {status.length > 1 &&
                            ` and ${status.length - 1} more`}
                        </span>
                      );
                    }}
                    isCheckBox
                    setSelectedItems={(ids: string[]) => {
                      setStatusValues(ids);
                    }}
                    value={statusValues}
                    className="selector-checkbox"
                  />
                </Grid>

                <Grid item mt={2} ml={1}>
                  <AcSearchInput
                    size="small"
                    header="Discount"
                    onChange={(e: any) => setDiscountValue(e.target.value)}
                    value={discountValue}
                    setValue={setDiscountValue}
                    renderStatusTextOverride={(value: string) => `${value}%`}
                  />
                </Grid>
              </Grid>
              <DataTable
                columns={columns}
                rows={searchedRows || []}
                loading={getCoupons.isLoading || getCoupons.isFetching}
                defaultHiddenFields={['id']}
                localStorageColumnsKey={
                  ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY
                }
                hideFooter={false}
                rowIdIdentifier="id"
                error={false}
                onNoData={
                  getCoupons.data?.length === 0 ? (
                    <FirstActionModal
                      headline="Create your first coupon"
                      text="Hit the “Add new coupon” button on the top right corner of the screen to get started"
                    />
                  ) : (
                    <FirstActionModal
                      headline="No exact matches"
                      text="Try changing or removing some of your filters or adjusting your search area."
                    />
                  )
                }
              />
            </>
          )}
        </AcContentWrapper>

        {getCouponsConfiguration.isLoading && (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              height: '60px',
              width: '60px'
            }}
          />
        )}
      </AcViewWrapper>
      <DialogModal
        isOpen={isCouponsDialogOpen}
        headline="Please note"
        text={
          isCouponsEnabled
            ? 'This will remove the promo code field from your checkout.'
            : 'This will add a promo code field to your checkout.'
        }
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => setIsCouponsDialogOpen(false)
          },
          {
            text: 'Got it',
            disabled: updateCouponsConfiguration.isLoading,
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: () =>
              updateCouponsConfiguration.mutate(
                { enabled: !isCouponsEnabled },
                {
                  onError: () => setIsCouponsDialogOpen(false),
                  onSuccess: () => setIsCouponsDialogOpen(false)
                }
              )
          }
        ]}
      />
    </>
  );
};

export default CouponsTable;
