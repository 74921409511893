import { Navigate, Route } from 'react-router-dom';

import AssetsView from 'views/Assets/AssetsView';
import BadgesView from 'views/Badges/BadgesView';
import OffersView from 'views/Bundles/OffersView';
import CheckoutLinksView from 'views/CheckoutLinks/CheckoutLinksView';
import CouponsView from 'views/Coupons/CouponsView';
import LocalizationView from 'views/Localization/LocalizationView';
import OffersUIView from 'views/OffersUI/OffersUIView';
import { PlayersView } from 'views/Players/PlayersView';
import PopupsView from 'views/Popups/PopupsView';
import { PricingView } from 'views/Pricing/PricingView';
import ProductsView from 'views/Products/ProductsView';
import ProfileView from 'views/Profile/ProfileView';
import PromotionsView from 'views/Promotions/PromotionsView';
import RollingOffersView from 'views/RollingOffers/RollingOffersView';
import SegmentsView from 'views/Segments/SegmentsView';
import StatisticsView from 'views/Statistics/StatisticsView';

import PasswordTab from 'components/PasswordsTab/PasswordTab';
import PersonalInfo from 'components/PersonalInfo/PersonalInfo';
import { BlockedPlayersTable } from 'components/Players/BlockedPlayersTable/BlockedPlayersTable';
import PricingForm from 'components/Pricing/PricingForm';
import PricingSettings from 'components/Pricing/PricingSettings';
import PricingTable from 'components/Pricing/PricingTable';
import CompanySettings from 'components/Settings/CompanySettings/CompanySettings';
import EventsSettings from 'components/Settings/EventsSettings/EventsSettings';
import IntegrationProfiles from 'components/Settings/IntegrationProfiles/IntegrationProfiles';
import IntegrationSettings from 'components/Settings/IntegrationSettings/IntegrationSettings';
import PaymentSettings from 'components/Settings/PaymentSettings/PaymentSettings';
import PlayerAuthSettings from 'components/Settings/PlayerAuthSettings/PlayerAuthSettings';
import StoreSettings from 'components/Settings/Settings/StoreSettings';
import SettingsView from 'components/Settings/SettingsView';
import StoreLayout from 'components/Settings/StoreLayout/StoreLayout';
import UsersSettings from 'components/Settings/UsersSettings/UsersSettings';
import CheckoutTheme from 'components/Theme/CheckoutTheme/CheckoutTheme';
import { CompletedTheme } from 'components/Theme/CompletedTheme/CompletedTheme';
import { GeneralSettings } from 'components/Theme/GeneralSettings/GeneralSettings';
import { LoginTheme } from 'components/Theme/LoginTheme/LoginTheme';
import { StoreTheme } from 'components/Theme/StoreTheme/StoreTheme';
import { ThemeView } from 'components/Theme/Theme';

const projectLevelRoutes = (defaultProjectPage: string) => (
  <Route path="project/:publisherId/*">
    <Route path="" element={<Navigate to={defaultProjectPage} replace />} />
    <Route path="statistics" element={<StatisticsView />} />
    <Route path="segments/*" element={<SegmentsView />} />

    <Route path="settings" element={<SettingsView />}>
      <Route path="" element={<Navigate to="company" replace />} />
      <Route path="company" element={<CompanySettings />} />
      <Route path="users" element={<UsersSettings />} />
      <Route path="integration" element={<IntegrationSettings />} />
      <Route path="profiles" element={<IntegrationProfiles />} />
      <Route path="playerAuth" element={<PlayerAuthSettings />} />
      <Route path="payments" element={<PaymentSettings />} />
      <Route path="layout" element={<StoreLayout />} />
      <Route path="storeSettings" element={<StoreSettings />} />
      <Route path="events" element={<EventsSettings />} />
    </Route>

    <Route path="profile" element={<ProfileView />}>
      <Route path="personal" element={<PersonalInfo />} />
      <Route path="password" element={<PasswordTab />} />
    </Route>

    <Route path="theme" element={<ThemeView />}>
      <Route path="general" element={<GeneralSettings />} />
      <Route path="store" element={<StoreTheme />} />
      <Route path="login" element={<LoginTheme />} />
      <Route path="completed" element={<CompletedTheme />} />
      <Route path="checkout" element={<CheckoutTheme />} />
    </Route>

    <Route path="products/*" element={<ProductsView />} />
    <Route path="offersui/*" element={<OffersUIView />} />
    <Route path="coupons/*" element={<CouponsView />} />
    <Route path="badges/*" element={<BadgesView />} />
    <Route path="assets" element={<AssetsView />} />
    <Route path="bundles/*" element={<OffersView />} />
    <Route path="promotions/*" element={<PromotionsView />} />
    <Route path="popups/*" element={<PopupsView />} />
    <Route path="rolling/*" element={<RollingOffersView />} />
    <Route path="checkoutlinks/*" element={<CheckoutLinksView />} />
    <Route path="pricing" element={<PricingView />}>
      <Route path="points" element={<PricingTable />} />
      <Route path="settings" element={<PricingSettings />} />
    </Route>
    <Route path="pricing/form" element={<PricingForm />} />
    <Route path="pricing/points/form/:pricePointId" element={<PricingForm />} />
    <Route path="localization/*" element={<LocalizationView />} />
    <Route path="players/*" element={<PlayersView />}>
      <Route path="blocked" element={<BlockedPlayersTable />} />
    </Route>
  </Route>
);

export default projectLevelRoutes;
