import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@appcharge/shared-ui';
import useUsers from 'api/useUsers';
import { User } from 'common/contracts';
import { ELocalStorageKeys } from 'constants/enums';
import { localStorageUtil } from 'utils/localStorageUtil';

import {
  Sidebar,
  SidebarContent,
  SidebarMenu,
  SidebarMenuItem
} from 'components/ui/sidebar';

import { cn } from '../../components/lib/utils';
import AppchargeIcon from './elements/AppchargeIcon';

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenuButton
} from './elements/index';
import { ESideBarContext } from './types';
import { SidebarProvider, useSidebar } from './context';
import { useParams } from 'react-router';
import { useSidebarNavigation, useProjectInfo } from './hooks';
import { SidebarGroupComponent } from './components';
import { Skeleton } from 'components/ui/skeleton';

// Main component that wraps the sidebar with context
export function AcSidebar() {
  const { publisherId } = useParams();
  const { fetchFeatureFlags } = useUsers({ publisherId });

  // Get user details from local storage
  const userDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  );

  return (
    <SidebarProvider
      initialUserDetails={userDetails}
      initialFeatureFlags={fetchFeatureFlags.data?.featureFlags}
    >
      <AcSidebarContent />
    </SidebarProvider>
  );
}

// Actual sidebar content component that uses the context
function AcSidebarContent() {
  const { context, navItems, userDetails, isLoading } = useSidebar();
  const { getProjectName, getPortalName } = useProjectInfo();

  // Setup navigation based on URL and context
  useSidebarNavigation();

  // Create a skeleton placeholder for nav items
  const renderSkeletons = () => {
    return Array(3)
      .fill(0)
      .map((_, index) => (
        <SidebarGroup key={`skeleton-${index}`}>
          <SidebarGroupLabel>
            <Skeleton className="h-4 w-24" />
          </SidebarGroupLabel>
          <SidebarMenu>
            {Array(index + 2)
              .fill(0)
              .map((_, itemIndex) => (
                <SidebarMenuItem key={`skeleton-item-${itemIndex}`}>
                  <div className="flex items-center gap-2 py-2 px-3 w-full">
                    <Skeleton className="h-4 w-4" />
                    <Skeleton className="h-4 w-full" />
                  </div>
                </SidebarMenuItem>
              ))}
          </SidebarMenu>
        </SidebarGroup>
      ));
  };

  return (
    <Sidebar>
      <SidebarContent className="p-6 gap-y-4">
        <img
          className={cn('logo w-[111px]')}
          alt="logo"
          src="./assets/images/ac-logo-purple.svg"
        />
        <SidebarGroup>
          <SidebarGroupLabel>Account</SidebarGroupLabel>
          <SidebarMenu className="gap-0">
            {(context === ESideBarContext.PROJECT ||
              context === ESideBarContext.PORTAL) && (
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <Link to="/" className="flex items-center gap-x-2">
                    <FontAwesomeIcon icon="fa-solid p-3 fa-arrow-left-to-bracket" />
                    Back
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            )}
            <SidebarMenuItem>
              <Link to="/" title="Go to Welcome Page">
                <SidebarMenuButton>
                  <AppchargeIcon context={context} />
                  <span className="font-semibold">
                    {context === ESideBarContext.COMPANY
                      ? userDetails?.company
                      : context === ESideBarContext.PROJECT
                        ? getProjectName()
                        : getPortalName()}
                  </span>
                </SidebarMenuButton>
              </Link>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>

        {isLoading
          ? renderSkeletons()
          : navItems.map((group, index) => (
              <SidebarGroupComponent
                key={`${group.title}-${index}`}
                group={group}
              />
            ))}
      </SidebarContent>
    </Sidebar>
  );
}

export default AcSidebar;
