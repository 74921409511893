import { useEffect } from 'react';

import axios from 'axios';

import { ELocalStorageKeys } from '../constants/enums';
import { localStorageUtil } from '../utils/localStorageUtil';
import { EToasterStatus, showToast } from '../utils/showToast';

import { useLogin } from './useLogin';

axios.defaults.baseURL =
  localStorageUtil.getAny(ELocalStorageKeys.ENVIRONMENT) ||
  process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

export const useAxiosInterceptor = () => {
  const { isLoggedIn, logout } = useLogin();

  useEffect(() => {
    let errorCount = 0;
    let firstErrorTime: number | null = null;
    const errorThreshold = 4;
    const timeWindow = 30000;

    const responseInterceptor = axios.interceptors.response.use(
      undefined,
      (error) => {
        if (error.response && error.response.status === 403 && isLoggedIn()) {
          const currentTime = Date.now();

          if (firstErrorTime === null) {
            firstErrorTime = currentTime;
          }

          if (currentTime - firstErrorTime <= timeWindow) {
            errorCount++;
          } else {
            errorCount = 1;
            firstErrorTime = currentTime;
          }

          if (errorCount >= errorThreshold && isLoggedIn()) {
            errorCount = 0;
            showToast({
              message: 'Your session has expired. Please log in again.',
              status: EToasterStatus.SUCCESS
            });
            logout();
          }

          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};
