import React, { FC } from 'react';

import { cn } from '../lib/utils';
import { UIRadioGroup } from '../ui';

import RadioButton, { RadioButtonOptionsProps } from './RadioButton';

type RadioButtonGroupProps = React.ComponentProps<typeof UIRadioGroup> & {
  value?: string;
  onValueChange?: (value: string) => void;
  className?: string;
  itemClassName?: string;
  options?: RadioButtonOptionsProps[];
  isColumn?: boolean;
};

const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  value = '',
  onValueChange,
  className,
  itemClassName,
  options = [],
  isColumn = false
}) => {
  const columnGroupClasses = isColumn && 'gap-2 flex-col';

  return (
    <UIRadioGroup
      value={value}
      onValueChange={onValueChange}
      className={cn('flex gap-7', columnGroupClasses, className)}
    >
      {options.length > 0 &&
        options.map((option, index) => (
          <RadioButton className={itemClassName} key={index} {...option} />
        ))}
    </UIRadioGroup>
  );
};

export default RadioButtonGroup;
