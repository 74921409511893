import { useState } from 'react';

import Stack from '@mui/system/Stack';
import { Dayjs } from 'dayjs';
import { handleDateRangeApplied, handleSameDateRangeApplied } from 'utils/getDateRange';

import AcSearchInput from 'components/AcInput/AcSearchInput';
import DatePickerOverlay from 'components/DatePickerOverlay/DatePickerOverlay';

interface DateRangeFilterProps {
  rangeDates?: [Dayjs, Dayjs];
  setRangeDates?: (dates?: [Dayjs, Dayjs]) => void;
  header?: string
}

export function DateRangeFilter({
  rangeDates,
  setRangeDates,
  header = "Creation date"
}: DateRangeFilterProps) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  if (!setRangeDates) return  null;

  const formattedDate = rangeDates?.length
    ? `${rangeDates?.[0].format(
        'DD/MM/YY'
       )} - ${rangeDates?.[1].format('DD/MM/YY')}`
    : '';

    const handleDateApply = (range: any, hours: any) => {
      if (!hours) {
        handleDateRangeApplied({
          range,
          setRangeDates
        });
        return;
      }
      handleSameDateRangeApplied({
        range,
        hours,
        setRangeDates
      });
    };

  return (
    <>
      <DatePickerOverlay
         isOpen={Boolean(isDatePickerOpen)}
         isHourPicker={false}
         setIsOpen={setIsDatePickerOpen}
         onApply={(range: any, hours: [string, string]) => {
           handleDateApply(range, hours);
           setIsDatePickerOpen(false);
         }}
         onApplyButtonText={'Apply'}
         shouldValidateDaysDiff={false}
      />
      <Stack direction="column" justifyContent="center">
         <AcSearchInput
            size="small"
            header={header}
            sx={{ flexGrow: 1 }}
            onClearClick={() => setRangeDates(undefined)}
            onClick={() => setIsDatePickerOpen(true)}
            id="outlined-adornment-weight"
            setValue={setIsDatePickerOpen}
            value={formattedDate || ''}
            isDate={true}
         />
      </Stack>
    </>
  );
}
