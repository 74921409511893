import React, { FC, ReactNode } from 'react';

import { cn } from '../lib/utils';

type ChipSize = 'sm' | 'md' | 'lg';

type ChipVariant =
  | 'solid'
  | 'bordered'
  | 'link'
  | 'flat'
  | 'faded'
  | 'shadow'
  | 'dot';

export type ChipStatus =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'active'
  | 'optional';

type ChipProps = {
  className?: string;
  children?: ReactNode;
  size?: ChipSize;
  variant?: ChipVariant;
  status?: ChipStatus;
};

const sizeStyles: Record<ChipSize, string> = {
  sm: 'px-2 h-6 text-p-xs',
  md: 'px-2 h-9 text-p-s',
  lg: 'px-3.5 h-[42px] text-p-m'
};

const statusVariantStyles: Record<ChipStatus, Record<ChipVariant, string>> = {
  default: {
    solid: 'text-base-popoverForeground bg-base-popover',
    bordered: 'text-base-popoverForeground border-2 border-base-popover',
    link: 'text-base-popoverForeground',
    flat: 'text-base-popoverForeground bg-[rgba(255,255,255,0.2)] ',
    faded:
      'text-base-popoverForeground bg-[rgba(9,9,11,0.20)] border-2 border-base-sidebarRing',
    shadow: 'text-base-popoverForeground bg-base-popover shadow-chip-shadow',
    dot: 'text-base-popoverForeground border-2 border-base-sidebarBorder'
  },
  primary: {
    solid: 'bg-base-popoverForeground text-base-primaryForeground',
    bordered:
      'text-base-popoverForeground border-2 border-base-popoverForeground',
    link: 'text-base-popoverForeground',
    flat: 'text-base-popoverForeground bg-[rgba(9,9,11,0.30)]',
    faded:
      'bg-[rgba(9,9,11,0.20)] text-base-popoverForeground border-2 border-base-sidebarRing',
    shadow:
      'text-base-popoverForeground bg-base-primaryForeground shadow-chip-shadow',
    dot: 'text-base-popoverForeground border-2 border-base-sidebarBorder'
  },
  secondary: {
    solid: 'bg-neutral-700 text-base-primaryForeground',
    bordered: 'text-neutral-700 border-2 border-neutral-700',
    link: 'ext-zinc-700',
    flat: 'text-neutral-700 bg-[rgba(64,64,64,0.20)]',
    faded:
      'text-neutral-700 bg-[rgba(9,9,11,0.20)] border-2 border-base-sidebarRing',
    shadow: 'bg-neutral-700 text-base-primaryForeground shadow-chip-shadow',
    dot: 'text-base-sidebarRing border-2 border-base-sidebarBorder'
  },
  success: {
    solid: 'text-base-popoverForeground bg-green-500',
    bordered: 'text-green-500 border-2 border-green-500',
    link: 'text-green-500',
    flat: 'text-green-500 bg-[rgba(34,197,94,0.20)]',
    faded:
      'text-green-500 bg-[rgba(9,9,11,0.20)] border-2 border-base-sidebarRing',
    shadow: 'text-base-popoverForeground bg-green-500 shadow-chip-shadow',
    dot: 'text-base-popoverForeground border-2 border-base-sidebarBorder'
  },
  warning: {
    solid: 'text-base-popoverForeground bg-base-chart5',
    bordered: 'text-base-chart5 border-2 border-base-chart5',
    link: 'text-base-chart5',
    flat: 'text-base-chart5 bg-[rgba(244,164,98,0.20)]',
    faded:
      'text-base-chart5 bg-[rgba(9,9,11,0.20)] border-2 border-base-sidebarRing',
    shadow: 'text-base-popoverForeground bg-base-chart5 shadow-chip-shadow',
    dot: 'text-base-popoverForeground border-2 border-base-sidebarBorder'
  },
  danger: {
    solid: 'text-base-primaryForeground bg-rose-500',
    bordered: 'text-rose-500 border-2 border-rose-500',
    link: 'text-rose-500',
    flat: 'text-rose-500 bg-[rgba(244,63,94,0.20)]',
    faded:
      'text-rose-500 bg-[rgba(9,9,11,0.20)] border-2 border-base-sidebarRing',
    shadow: 'text-base-primaryForeground shadow-chip-shadow bg-rose-500',
    dot: 'text-base-primaryForeground border-2 border-base-sidebarBorder'
  },
  active: {
    solid: 'text-blue-950 bg-[rgba(14,165,233,0.20)]',
    bordered: 'text-blue-950 border-2 border-blue-950',
    link: 'text-blue-950',
    flat: 'text-blue-950 bg-[rgba(14,165,233,0.20)]',
    faded:
      'text-blue-950 bg-[rgba(9,9,11,0.20)] border-2 border-base-sidebarRing',
    shadow: 'text-blue-950 bg-[rgba(14,165,233,0.20)] shadow-chip-shadow',
    dot: 'text-blue-950 border-2 border-base-sidebarBorder'
  },
  optional: {
    solid: 'text-slate-600 bg-[rgba(72,143,161,0.20)]',
    bordered: 'text-slate-600 border-2 border-slate-600',
    link: 'text-slate-600',
    flat: 'text-slate-600 bg-[rgba(72,143,161,0.20)]',
    faded:
      'text-slate-600 bg-[rgba(9,9,11,0.20)] border-2 border-base-sidebarRing',
    shadow: 'text-slate-600 bg-[rgba(72,143,161,0.20)] shadow-chip-shadow',
    dot: 'text-slate-600 border-2 border-base-sidebarBorder'
  }
};

const dotStatusStyles: Record<ChipStatus, string> = {
  default: 'bg-base-popover',
  primary: 'bg-base-popoverForeground',
  secondary: 'bg-base-sidebarBorder',
  success: 'bg-green-500',
  warning: 'bg-base-chart5',
  danger: 'bg-rose-500',
  active: 'bg-blue-600',
  optional: 'bg-slate-600'
};

const Chip: FC<ChipProps> = ({
  className,
  children,
  size = 'sm',
  variant = 'solid',
  status = 'default'
}) => {
  return (
    <div
      className={cn(
        'rounded-full max-w-max flex items-center justify-center',
        sizeStyles[size],
        statusVariantStyles[status][variant],
        className
      )}
    >
      {variant === 'dot' && (
        <div
          className={cn('rounded-full w-2 h-2 mr-2', dotStatusStyles[status])}
        ></div>
      )}
      {children}
    </div>
  );
};

export default Chip;
