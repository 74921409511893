import { StoreThemeFormValues } from './storeTheme.types';

export const defaultStoreTheme: StoreThemeFormValues = {
  defaultBanner: '',
  sections: [],
  buttonColor: {
    colorOne: '#7122ff',
    colorTwo: ''
  },
  buttonTextColor: 'white',
  addToHomeScreen: {
    active: false,
    buttonImage: '',
    iconImage: 'https://media.appcharge.com/defaults/logo.svg',
    shortcutName: 'Gaming Store',
    borderColor: {
      colorOne: '#ffffff'
    },
    backgroundColor: {
      colorOne: '#522AD8',
      colorTwo: ''
    }
  },
  header: {
    backgroundOpacity: 40,
    showLogo: true,
    showPlayerName: true,
    isSticky: false
  },
  noOffersTitleText: 'No offers available',
  noOffersMessageText: 'Please check back later.',
  bundleBorderColor: {
    colorOne: '#e3d3ff',
    colorTwo: ''
  }
};
