import { Divider, Stack } from '@mui/material';
import { ScheduleTimeFrame, TimeframeDateRange } from 'common/contracts';
import { OfferType } from 'constants/enums';
import dayjs from 'dayjs';

import AcCard from 'components/AcCard/AcCard';
import AcDateTimePicker from 'components/AcDateTimePicker/AcDateTimePicker';
import AcInput from 'components/AcInput/AcInput';
import TimeframesTable from 'components/TimeframesTable/TimeframesTable';

const ScheduledOffer = ({
  formikProps,
  editRangeDatesValues,
  setEditRangeDatesValues,
  intervalCron,
  appearancesTableData,
  notes,
  setNotes,
  dateRange,
  setDateRange,
  resetScheduleValues
}: any) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    formikProps;

  const handleApply = (range: TimeframeDateRange, notes?: string) => {
    const [fromUtcDate, toUtcDate] = range;

    if (fromUtcDate && toUtcDate) {
      const newTimeFrame: ScheduleTimeFrame = {
        startTime: new Date(fromUtcDate.toISOString()), // UTC start date
        endTime: new Date(toUtcDate.toISOString()), // UTC end date
        notes
      };

      if (editRangeDatesValues) {
        const [editStartTime, editEndTime] = editRangeDatesValues;

        const updatedTimeFrames = values?.schedule?.timeFrames.map(
          (timeFrame: ScheduleTimeFrame) => {
            const currentStartTimestamp = new Date(
              timeFrame.startTime
            ).getTime();
            const currentEndTimestamp = new Date(timeFrame.endTime).getTime();

            return currentStartTimestamp ===
              new Date(editStartTime).getTime() &&
              currentEndTimestamp === new Date(editEndTime).getTime()
              ? newTimeFrame
              : timeFrame;
          }
        );
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: updatedTimeFrames,
          ...(intervalCron &&
            values?.schedule?.permanent && { interval: intervalCron })
        });
      } else {
        setEditRangeDatesValues(null);
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: [...values?.schedule?.timeFrames, newTimeFrame],
          ...(intervalCron &&
            values?.schedule?.permanent && { interval: intervalCron })
        });
      }
    } else {
      console.error('Invalid date range provided: Start or End date is null.');
    }
  };

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: '2rem'
      }}
    >
      <AcCard
        stackContainer={false}
        title={editRangeDatesValues ? 'Edit appearance' : 'Scheduled offer'}
      >
        {values.type === OfferType.SPECIAL_OFFER && (
          <>
            <AcInput
              header="Availability"
              name="playerAvailability"
              value={values.playerAvailability || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              onBlur={handleBlur}
              required={values.setAsFree}
              type="number"
              error={
                touched.playerAvailability && Boolean(errors.playerAvailability)
              }
              helperText={
                touched.playerAvailability
                  ? errors.playerAvailability?.toString()
                  : ''
              }
              sx={{ width: '250px' }}
            />
            <Divider
              sx={{
                margin: '22px 0'
              }}
            />
          </>
        )}
        <AcDateTimePicker
          onApply={(dateRange: TimeframeDateRange, notes?: string) =>
            handleApply(dateRange, notes)
          }
          editRangeDatesValues={editRangeDatesValues}
          setEditRangeDatesValues={setEditRangeDatesValues}
          disableFuture={false}
          minDate={dayjs().utc().startOf('day')}
          applyText={editRangeDatesValues ? 'Apply' : 'Add Appearance'}
          notes={notes}
          setNotes={setNotes}
          formikProps={formikProps}
          dateRange={dateRange}
          setDateRange={setDateRange}
          resetScheduleValues={resetScheduleValues}
        />
      </AcCard>
      <Divider />
      <TimeframesTable
        formikProps={formikProps}
        appearancesTableData={appearancesTableData}
        handleEditTimeframe={(startTime: string, endTime: string, notes: string) => {
          setEditRangeDatesValues([
            new Date(startTime),
            new Date(endTime),
            notes
          ]);
        }}
        onDeleteRow={resetScheduleValues}
      />
    </Stack>
  );
};

export default ScheduledOffer;
