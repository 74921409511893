import { ESideBarContext } from '../../types';
import { getIconByContext } from '../../utils';
import React from 'react';

interface AppchargeIconProps {
  context: ESideBarContext;
  size?: number;
}

const AppchargeIcon: React.FC<AppchargeIconProps> = ({
  context,
  size = 20
}) => {
  const renderIcon = () => {
    switch (context) {
      case ESideBarContext.PROJECT:
        return getIconByContext(context, false);
      case ESideBarContext.PORTAL:
        return getIconByContext(context, false);
      default:
        return (
          <img
            src={'./assets/icons/ac-black-green-icon.png'}
            alt={`${context} icon`}
            className="w-5 h-5 object-contain"
            style={{ borderRadius: '6px' }}
          />
        );
    }
  };

  return renderIcon();
};

export default AppchargeIcon;
