import { SyntheticEvent, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { TabsPanel } from 'design-system/TabPanel/TabPanel';

import PageTopBar from 'components/Topbar/PageTopBar';

import '../view.scss';

enum EReportsTabs {
  FINANCIAL = 'financialreports',
  PAYOUTS = 'payouts'
}

const ReportsView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getInitialActiveTab = () => {
    const path = location.pathname;
    if (path.includes(EReportsTabs.PAYOUTS)) return EReportsTabs.PAYOUTS;
    return EReportsTabs.FINANCIAL;
  };

  const [activeTab, setActiveTab] = useState(getInitialActiveTab());

  const handleTabChange = (e: SyntheticEvent, value: EReportsTabs) => {
    setActiveTab(value);
    const basePath = '/reports/';
    navigate(`${basePath}${value}`);
  };

  const tabs = [
    { label: 'Financial', value: EReportsTabs.FINANCIAL },
    { label: 'Payouts', value: EReportsTabs.PAYOUTS }
  ];

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent={<PageTopBar withTabsDesign={true} headline="Reports" />}
      handleTabChange={handleTabChange}
      activeTabVal={activeTab}
    />
  );
};

export default ReportsView;
