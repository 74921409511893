import React from 'react';

import { Outlet } from 'react-router-dom';

import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcTabs from 'components/AcTabs/AcTabs';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';

import { TabsPanelProps } from './types';

export const TabsPanel: React.FC<TabsPanelProps> = ({
  className,
  tabs,
  headerComponent,
  handleTabChange,
  activeTabVal
}) => {

  return (
    <AcViewWrapper
      className={className}
      header={
        <>
          {headerComponent}
          <Box pl={'3rem'} pr={'3rem'}>
            <AcTabs
              className="tabPanel-tabs"
              value={activeTabVal}
              onChange={handleTabChange}
            >
              {tabs
                .filter(({ shouldHide }) => !shouldHide)
                .map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
            </AcTabs>
          </Box>
        </>
      }
    >
      <AcContentWrapper>
        <TabContext value={activeTabVal}>
          <TabPanel value={activeTabVal} sx={{ padding: 0 }}>
            <Outlet />
          </TabPanel>
        </TabContext>
      </AcContentWrapper>
    </AcViewWrapper>
  );
};
