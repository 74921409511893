import { geo } from 'constants/geo';
import Decimal from 'decimal.js';

export interface FormatSettings {
  currency?: 'USD' | 'EUR';
  ratio?: number;
}

export const capitalizeWords = (str: string) => {
  return str.replace(/\b\w/g, (c) => c.toUpperCase());
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const transformQuantityValue = (inputValue: string) => {
  let value = inputValue;
  if (inputValue.startsWith('0') && inputValue !== '0') {
    value = inputValue.replace(/^0+/, '');
  }
  const parsedNumber = parseInt(value);
  if (!isNaN(parsedNumber) && String(parsedNumber) === value) {
    return parsedNumber;
  }
  try {
    return BigInt(inputValue).toString();
  } catch (error) {
    return 0;
  }
};

export const serializeDate = (
  date: Date | null | undefined,
  endOfDay: boolean = false,
  isUTC: boolean = false
) => {
  if (!date) return '';
  let { year, month, day, hours, minutes, seconds, milliseconds } =
    getDateBreakdown(date, isUTC);

  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  if (milliseconds < 10) {
    milliseconds = `00${milliseconds}`;
  } else if (milliseconds < 100) {
    milliseconds = `0${milliseconds}`;
  }

  if (endOfDay) {
    hours = 23;
    minutes = 59;
    seconds = 59;
    milliseconds = 999;
  }

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

function getDateBreakdown(
  date: Date,
  isUTC: boolean = false
): Record<string, any> {
  if (isUTC) {
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate(),
      hours: date.getUTCHours(),
      minutes: date.getUTCMinutes(),
      seconds: date.getUTCSeconds(),
      milliseconds: date.getUTCMilliseconds()
    };
  }
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    milliseconds: date.getMilliseconds()
  };
}

export const formatReceiptNumber = (receiptNumber: string): string => {
  if (!/^\d{9}$/.test(receiptNumber)) {
    return receiptNumber;
  }
  return receiptNumber.replace(/(\d{3})(?=\d)/g, '$1-');
};

export const getFormattedNumberWithCurrency = (
  value: number | Decimal | undefined,
  currencyCode: string
) => {
  if (value === undefined) {
    return 'N/A';
  }
  const currency = geo.find((c) => c.currencyCode === currencyCode);
  const multiplier = currency?.multiplier || 100;
  const currencySymbol = currency?.currencySymbol;
  const fractionDigits = Math.log10(multiplier);
  const amount = `${currencySymbol}${new Decimal(value)
    .div(multiplier)
    .toFixed(fractionDigits)}`;

  return amount;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${day}/${month}/${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
};

// Utility function to format the duration
export const getDurationTextFromMinutes = (totalMinutes: number): string => {
  const days = Math.floor(totalMinutes / (60 * 24));
  const remainingMinutesAfterDays = totalMinutes % (60 * 24);
  const hours = Math.floor(remainingMinutesAfterDays / 60);
  const minutes = remainingMinutesAfterDays % 60;

  let durationParts: string[] = [];

  if (days > 0) {
    durationParts.push(`${days} day${days !== 1 ? 's' : ''}`);
  }

  if (hours > 0) {
    durationParts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
  }

  if (minutes > 0) {
    durationParts.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
  }

  // Join the duration parts with "and" if there's more than one part
  return durationParts.length > 1
    ? durationParts.slice(0, -1).join(', ') + ' and ' + durationParts.slice(-1)
    : durationParts[0] || '';
};
