import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useBadges from '../../../api/useBadges';
import { Badge } from '../../../common/contracts';
import AcContentWrapper from '../../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../../components/DataTable/DataTable';
import DialogModal from '../../../components/Dialog/Dialog';
import FirstActionModal from '../../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../../components/Topbar/PageTopBar';
import { DATE_TIME_FORMAT } from '../../../constants/constants';
import { EButtonColor, ELocalStorageKeys } from '../../../constants/enums';
import { capitalizeFirstLetter } from '../../../utils/formattingUtil';
import { permissionsUtil } from '../../../utils/permissionsUtil';
import { EToasterStatus, showToast } from '../../../utils/showToast';

import './style.scss';

const dayjsUTC = dayjs.extend(utc);

const BadgesTable = () => {
  const { publisherId } = useParams();
  const navigate = useNavigate();
  const { getBadges, deleteBadge } = useBadges(publisherId);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState<string | null>(null);

  const handleDeleteBadge = async () => {
    if (!selectedBadge) return;
    await deleteBadge.mutate(selectedBadge, {
      onSuccess: () => {
        showToast({
          message: 'Badge deleted successfully',
          status: EToasterStatus.SUCCESS
        });
        getBadges.refetch();
      },
      onError: (e: any) => {
        showToast({
          message: 'Failed to delete badge',
          status: EToasterStatus.ERROR,
          description: e.response.data.message ?? ''
        });
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
        setSelectedBadge(null);
      }
    });
  };

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 80,
      renderCell: (params: any) => {
        const imageUrl =
          params.row?.emblem?.imageUrl || params.row?.ribbon?.imageUrl;
        const colorOne = params.row?.ribbon?.backgroundColor.colorOne;
        const colorTwo = params.row?.ribbon?.backgroundColor.colorTwo;
        const gradientDirection =
          params.row?.ribbon?.backgroundColor.gradientDirection;

        return imageUrl ? (
          <img
            style={{
              objectFit: 'cover',
              width: '32px',
              height: '32px'
            }}
            src={imageUrl}
            alt="emblem"
          />
        ) : (
          <div
            style={{
              background: colorTwo
                ? `linear-gradient(${gradientDirection || 'to bottom'}, ${colorOne}, ${colorTwo})`
                : colorOne,
              borderRadius: '50%',
              width: '32px',
              height: '32px'
            }}
          ></div>
        );
      },
      headerClassName: 'badgesTableHeaderCell',
      cellClassName: 'badgesTableCell'
    },
    {
      field: 'id',
      flex: 1,
      headerName: 'ID',
      width: 100,
      headerClassName: 'badgesTableHeaderCell',
      cellClassName: 'badgesTableCell'
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100,
      headerClassName: 'badgesTableHeaderCell',
      cellClassName: 'badgesTableCell'
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Date',
      width: 200,
      type: 'date',
      renderCell: (params: any) => {
        if (getBadges.isLoading) return '';
        return (
          <span>
            {dayjsUTC.utc(params.row.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        );
      },
      headerClassName: 'badgesTableHeaderCell',
      cellClassName: 'badgesTableCell'
    },
    {
      field: 'type',
      flex: 1,
      headerName: 'Type',
      width: 100,
      valueGetter: (params: any) => {
        return capitalizeFirstLetter(params.row.type);
      },
      headerClassName: 'badgesTableHeaderCell',
      cellClassName: 'badgesTableCell'
    },
    {
      field: 'externalId',
      flex: 1,
      headerName: 'External ID',
      width: 150,
      renderCell: (params: any) => {
        if (getBadges.isLoading) return '';
        return <span>{params.row.publisherBadgeId}</span>;
      },
      headerClassName: 'badgesTableHeaderCell',
      cellClassName: 'badgesTableCell'
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          onClick={() => {
            navigate(`./form/${params.id}`);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.BADGES,
              EPermissionAction.MANAGE,
              publisherId
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ContentCopyOutlinedIcon />}
          label="Duplicate"
          onClick={() => {
            navigate(`./dup/${params.id}`);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.BADGES,
              EPermissionAction.MANAGE,
              publisherId
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          onClick={() => {
            setIsDeleteDialogOpen(true);
            setSelectedBadge(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.BADGES,
              EPermissionAction.MANAGE,
              publisherId
            )
          }
          showInMenu
        />
      ],
      headerClassName: 'badgesTableHeaderCell'
    }
  ];

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline="Badges"
            buttons={[
              {
                text: 'Add New Badge',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit(),
                hidden: !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.BADGES,
                  EPermissionAction.MANAGE,
                  publisherId
                )
              }
            ]}
          />
        }
      >
        <AcContentWrapper>
          <DataTable
            columns={columns}
            rows={
              !getBadges.isLoading &&
              getBadges.data.result.map((badge: Badge) => ({
                _id: badge.badgeId,
                ...badge
              }))
            }
            loading={getBadges.isLoading}
            defaultHiddenFields={['id']}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter={false}
            error={false}
            initialSorting={{
              sortModel: [{ field: 'createdAt', sort: 'desc' }]
            }}
            onNoData={
              <FirstActionModal
                headline="Badges highlight your offer’s value"
                text="Hit the “Add new badge” button to create your first badge: from “Best Value” to “100% more!”, you can add any badge you want!"
              />
            }
          />
        </AcContentWrapper>
      </AcViewWrapper>

      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Badge"
        text="Are you sure you want to delete this badge?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: handleDeleteBadge
          }
        ]}
        closeDialog={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default BadgesTable;
