import React, { FC, ReactNode } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { cn } from 'components/lib/utils';

import { UISidebarGroupLabel } from '../../../components/ui';

type SidebarGroupLabelProps = React.ComponentProps<
  typeof UISidebarGroupLabel
> & {
  children?: ReactNode;
  className?: string;
  isChevronIconVisible?: boolean;
  isCollapsed?: boolean;
};

const SidebarGroupLabel: FC<SidebarGroupLabelProps> = ({
  children,
  className,
  isChevronIconVisible = false,
  isCollapsed = false,
  ...rest
}) => (
  <UISidebarGroupLabel
    className={cn(
      'flex items-center justify-between text-p-xs text-base-sidebarRing font-medium',
      isChevronIconVisible && 'cursor-pointer'
    )}
    {...rest}
  >
    {children}
    {isChevronIconVisible && (
      <FontAwesomeIcon
        icon="fa-solid fa-chevron-up"
        className={cn(
          'text-base-sidebarForeground transition-transform text-p-xs',
          isCollapsed && 'rotate-180'
        )}
      />
    )}
  </UISidebarGroupLabel>
);

export default SidebarGroupLabel;
