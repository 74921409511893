import { useNavigate, useParams } from 'react-router-dom';

import { Box, Tab } from '@mui/material';
import useOffers from 'api/useOffers';
import { MIN_ROLLING_SUB_OFFERS } from 'constants/constants';
import {
  EFeatureFlag,
  ERollingOfferFormState,
  OfferType
} from 'constants/enums';
import { permissionsUtil } from 'utils/permissionsUtil';
import { HeaderProps } from 'views/RollingOffers/types';

import AcTabs from 'components/AcTabs/AcTabs';
import PageTopBar from 'components/Topbar/PageTopBar';

import useUsers from '../../../../api/useUsers';

const RollingOfferHeader: React.FC<HeaderProps> = ({
  edit,
  formikProps,
  rollingOfferId,
  tab,
  setTab,
  setIsUpdateDialogOpen,
  setIsSaveDialogOpen,
  productsSequence,
  isLoading,
  hasMissingProductDetails
}) => {
  const { publisherId } = useParams();
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const featureFlags = fetchFeatureFlags.data?.featureFlags;
  const { values, submitForm, dirty, isValid } = formikProps;
  const navigate = useNavigate();
  const { getOffer } = useOffers({
    offerId: rollingOfferId,
    offerType: OfferType.ROLLING_OFFER
  });

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue:
      | ERollingOfferFormState
      | ((prevState: ERollingOfferFormState) => ERollingOfferFormState)
  ) => {
    setTab(newValue);
  };

  const updateContent = async () => {
    if (!hasMissingProductDetails && submitForm) {
      await submitForm();
    } else {
      if (edit && values.active) {
        setIsUpdateDialogOpen(true);
      } else {
        setIsSaveDialogOpen(true);
      }
    }
  };

  return (
    <>
      <PageTopBar
        withTabsDesign={true}
        disable={false}
        headline={`${edit ? 'Edit' : 'New'} Rolling Offer ${
          edit
            ? `(${
                !getOffer.isLoading && getOffer.data?.result && !isLoading
                  ? getOffer.data?.result?.name
                  : '...'
              })`
            : ''
        }`}
        buttons={[
          {
            text: 'Save',
            action: () => updateContent(),
            disabled:
              isLoading ||
              !isValid ||
              !permissionsUtil.canUserEdit() ||
              (!values.schedule?.permanent &&
                !values.schedule?.timeFrames?.length) ||
              productsSequence.length < MIN_ROLLING_SUB_OFFERS ||
              (edit && (!dirty || getOffer.isLoading))
          }
        ]}
        backFunction={() => navigate('../')}
        isBackButtonVisible={
          featureFlags?.[EFeatureFlag.DASHBOARD_ROLLING_OFFER]
        }
      />
      <Box pl={'3rem'} pr={'3rem'}>
        <AcTabs value={tab} onChange={handleTabChange}>
          <Tab label="Settings" value={ERollingOfferFormState.GENERAL} />
          <Tab
            label="Configuration"
            value={ERollingOfferFormState.CONFIGURATION}
          />
          <Tab label="Schedule" value={ERollingOfferFormState.SCHEDULE} />
        </AcTabs>
      </Box>
    </>
  );
};

export default RollingOfferHeader;
