import { useEffect, useMemo, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/system/Grid';
import useIntegration from 'api/useIntegration';
import usePublisher from 'api/usePublisher';
import useUsers from 'api/useUsers';
import {
  IS_NO_IP_ONLY_HTTPS,
  PROFILES_ENABLED_PLACEHOLDER
} from 'constants/constants';
import { useFormik } from 'formik';
import { useProfilesManager } from 'hooks/useProfilesManager';
import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import ActionButton from 'components/ActionButton/ActionButton';
import { cn } from 'components/lib/utils';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import { EToasterStatus, showToast } from '../../../utils/showToast';
import { IntegrationSettingsData, ProfileField } from '../Settings.types';

import 'style/forms.scss';

export enum EEncryptionType {
  SYMMETRIC_ENCRYPTION = 'SymmetricEncryption',
  SIGNATURE_HASHING = 'SignatureHashing'
}

interface IntegrationSettingsFormValues {
  publisherToken: string;
  deeplinkJwtSecret?: string;
  playerInfoSyncUrl: string;
  playersAuthWebhook: string;
  ordersReportingApiUrl: string;
  apiUrlPrimaryKey: string;
  eventsWebhookUrl: string;
  appsFlyerS2SToken: string;
  appsFlyerApplicationID: string;
  appsFlyerIntegrationOn: boolean;
}

const IntegrationSettings = () => {
  const { id } = useParams<{ id: string }>();
  const { toggleIntegrationProfiles, isProfilesEnabled, isToggleInProgress } =
    useProfilesManager();
  const { getIntegration, updateIntegration }: any = useIntegration(
    id as string
  );
  const { fetchFeatureFlags } = useUsers({ enableFeatureFlags: false });
  const { getPublisher } = usePublisher(id || undefined);
  const data = getIntegration?.data;
  const integrationRefetch = getIntegration?.refetch;
  const isProfilesFeatureFlagOn = useMemo(
    () => fetchFeatureFlags?.data?.featureFlags?.integration_profiles,
    [fetchFeatureFlags?.data?.featureFlags?.integration_profiles]
  );
  const isAppsflyerIntegrationFeatureFlagOn =
    fetchFeatureFlags?.data?.featureFlags?.dashboard_appsflyer;

  useEffect(() => {
    return () => {
      integrationRefetch();
    };
  }, [integrationRefetch]);

  const saveWebhooksDetails = (
    webhookValues: Partial<IntegrationSettingsData>
  ) => {
    updateIntegration.mutate(webhookValues, {
      onSuccess: () => {
        getPublisher.refetch();
        showToast({
          message: 'Webhook details updated successfully',
          status: EToasterStatus.SUCCESS
        });
      },
      onError: (data: any) => {
        showToast({
          message: 'Failed to update Webhook details',
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const webhooksSchema = yup.object().shape({
    ordersReportingApiUrl: yup
      .string()
      .required(required('Orders Reporting API URL'))
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    deeplinkJwtSecret: yup.string(),
    playerInfoSyncUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    eventsWebhookUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    playersAuthWebhook: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    apiUrlPrimaryKey: yup.string().required(required('Main Key')),
    appsFlyerIntegrationOn: yup.boolean(),
    appsFlyerS2SToken: yup.string().when('appsFlyerIntegrationOn', {
      is: true,
      then: yup.string().required('AppsFlyer S2S token is required'),
      otherwise: yup.string().notRequired()
    }),
    appsFlyerApplicationID: yup.string().when('appsFlyerIntegrationOn', {
      is: true,
      then: yup.string().required('Application ID is required'),
      otherwise: yup.string().notRequired()
    })
  });

  const initialValues = useMemo(
    () => ({
      publisherToken: data?.publisherToken || '',
      deeplinkJwtSecret: data?.deeplinkJwtSecret || '',
      playerInfoSyncUrl: data?.playerInfoSyncUrl || '',
      playersAuthWebhook: data?.playersAuthWebhook || '',
      ordersReportingApiUrl: data?.ordersReportingApiUrl || '',
      apiUrlPrimaryKey: data?.apiUrlPrimaryKey || '',
      eventsWebhookUrl: data?.eventsWebhookUrl || '',
      appsFlyerS2SToken: data?.appsFlyerIntegration?.appsFlyerS2SToken || '',
      appsFlyerApplicationID:
        data?.appsFlyerIntegration?.appsFlyerApplicationID || '',
      appsFlyerIntegrationOn:
        !!data?.appsFlyerIntegration?.appsFlyerIntegrationOn
    }),
    [data]
  );

  const webhooksForm = useFormik<IntegrationSettingsFormValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: webhooksSchema,
    onSubmit: async (values) => {
      if (values) {
        const {
          appsFlyerIntegrationOn,
          appsFlyerS2SToken,
          appsFlyerApplicationID,
          ...rest
        } = values;
        const webhookDetailsData = {
          appsFlyerIntegration: {
            appsFlyerIntegrationOn,
            appsFlyerS2SToken,
            appsFlyerApplicationID
          },
          ...rest
        } as Partial<IntegrationSettingsData>;
        saveWebhooksDetails(webhookDetailsData);
      }
    }
  });

  const { values, touched, errors, setFieldTouched } = webhooksForm;

  const prevValuesRef = useRef<IntegrationSettingsFormValues>(values);

  useEffect(() => {
    Object.keys(values).forEach((key) => {
      const typedKey = key as keyof IntegrationSettingsFormValues;
      if (values[typedKey] !== prevValuesRef.current[typedKey]) {
        if (!touched[typedKey]) {
          setFieldTouched(typedKey, true);
        }
      }
    });

    prevValuesRef.current = values;
  }, [values, touched]);

  const toggleIntegrationProfilesHandler = () => {
    toggleIntegrationProfiles(!isProfilesEnabled);
  };

  return (
    <Grid container className="formContent">
      <Grid size={5} className="formContent-minWIdth">
        <Stack>
          <AcCard stackContainer={false} title="Integration links">
            {isProfilesFeatureFlagOn && (
              <Stack direction="row" alignItems="center" mb={2}>
                <Typography marginRight={'16px'}>
                  Enable Integration Profiles
                </Typography>
                <CustomizedSwitch
                  status={isProfilesEnabled}
                  texts={[]}
                  disabled={isToggleInProgress}
                  functions={[
                    toggleIntegrationProfilesHandler,
                    toggleIntegrationProfilesHandler
                  ]}
                />
              </Stack>
            )}
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid size={12}>
                <AcInput
                  header="Orders Reporting API"
                  name="ordersReportingApiUrl"
                  value={
                    isProfilesEnabled
                      ? PROFILES_ENABLED_PLACEHOLDER
                      : webhooksForm.values.ordersReportingApiUrl
                  }
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched.ordersReportingApiUrl &&
                    Boolean(webhooksForm.errors.ordersReportingApiUrl)
                  }
                  helperText={
                    webhooksForm.touched.ordersReportingApiUrl
                      ? webhooksForm.errors.ordersReportingApiUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid size={12}>
                <AcInput
                  header={ProfileField.PERSONALIZATION_API_URL}
                  name="playerInfoSyncUrl"
                  value={
                    isProfilesEnabled
                      ? PROFILES_ENABLED_PLACEHOLDER
                      : webhooksForm.values.playerInfoSyncUrl
                  }
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched.playerInfoSyncUrl &&
                    Boolean(webhooksForm.errors.playerInfoSyncUrl)
                  }
                  helperText={
                    webhooksForm.touched.playerInfoSyncUrl
                      ? webhooksForm.errors.playerInfoSyncUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid size={12}>
                <AcInput
                  header="Authentication Webhook"
                  name="playersAuthWebhook"
                  value={
                    isProfilesEnabled
                      ? PROFILES_ENABLED_PLACEHOLDER
                      : webhooksForm.values.playersAuthWebhook
                  }
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched?.playersAuthWebhook &&
                    Boolean(webhooksForm.errors?.playersAuthWebhook)
                  }
                  helperText={
                    webhooksForm.touched?.playersAuthWebhook
                      ? webhooksForm.errors?.playersAuthWebhook?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid
                size={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <AcInput
                  header="Events Webhook"
                  name="eventsWebhookUrl"
                  value={
                    isProfilesEnabled
                      ? PROFILES_ENABLED_PLACEHOLDER
                      : webhooksForm.values.eventsWebhookUrl
                  }
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched?.eventsWebhookUrl &&
                    Boolean(webhooksForm.errors?.eventsWebhookUrl)
                  }
                  helperText={
                    webhooksForm.touched?.eventsWebhookUrl
                      ? webhooksForm.errors?.eventsWebhookUrl?.toString()
                      : ''
                  }
                  customClass="url-input"
                />
              </Grid>
              <Grid size={12}>
                <AcInput
                  header="Main Key"
                  name="apiUrlPrimaryKey"
                  value={webhooksForm.values.apiUrlPrimaryKey}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  error={
                    webhooksForm.touched.apiUrlPrimaryKey &&
                    Boolean(webhooksForm.errors.apiUrlPrimaryKey)
                  }
                  helperText={
                    webhooksForm.touched.apiUrlPrimaryKey
                      ? webhooksForm.errors.apiUrlPrimaryKey?.toString()
                      : ''
                  }
                  type="password"
                  enableCopy
                />
              </Grid>
              <Grid size={12}>
                <AcInput
                  header="Checkout Public Key"
                  name="checkoutPublicKey"
                  value={data?.checkoutPublicKey}
                  disabled={true}
                  enableCopy
                />
              </Grid>
              <Grid size={12}>
                <AcInput
                  header="Publisher Token"
                  name="publisherToken"
                  value={webhooksForm.values.publisherToken}
                  onChange={webhooksForm.handleChange}
                  type="password"
                  onBlur={webhooksForm.handleBlur}
                  disabled
                  error={
                    webhooksForm.touched.publisherToken &&
                    Boolean(webhooksForm.errors.publisherToken)
                  }
                  helperText={
                    webhooksForm.touched.publisherToken
                      ? webhooksForm.errors.publisherToken?.toString()
                      : ''
                  }
                  enableCopy
                />
              </Grid>
              <Grid size={12}>
                <AcInput
                  header="Deeplink JWT Secret"
                  name="publisherToken"
                  value={webhooksForm.values.deeplinkJwtSecret}
                  onChange={webhooksForm.handleChange}
                  type="password"
                  onBlur={webhooksForm.handleBlur}
                  error={
                    webhooksForm.touched.deeplinkJwtSecret &&
                    Boolean(webhooksForm.errors.deeplinkJwtSecret)
                  }
                  helperText={
                    webhooksForm.touched.deeplinkJwtSecret
                      ? webhooksForm.errors.deeplinkJwtSecret?.toString()
                      : ''
                  }
                  enableCopy
                />
              </Grid>
            </Grid>
          </AcCard>
          {isAppsflyerIntegrationFeatureFlagOn && (
            <AcCard
              className="mt-6"
              stackContainer={false}
              title="AppsFlyer Integration"
            >
              <Box className="flex items-center">
                <p className={cn('mr-4', 'text-p-xs')}>
                  Enable AppsFlyer Integration
                </p>
                <CustomizedSwitch
                  status={values.appsFlyerIntegrationOn}
                  texts={[]}
                  functions={[
                    () => {
                      webhooksForm.setFieldValue(
                        'appsFlyerIntegrationOn',
                        false
                      );
                      webhooksForm.setFieldValue('appsFlyerS2SToken', '');
                      webhooksForm.setFieldValue('appsFlyerApplicationID', '');
                    },
                    () =>
                      webhooksForm.setFieldValue('appsFlyerIntegrationOn', true)
                  ]}
                />
              </Box>
              <Grid size={12} className="pt-3">
                <AcInput
                  header="AppsFlyer S2S token"
                  name="appsFlyerS2SToken"
                  value={values.appsFlyerS2SToken}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  error={
                    touched?.appsFlyerS2SToken &&
                    Boolean(errors?.appsFlyerS2SToken)
                  }
                  helperText={
                    touched?.appsFlyerS2SToken
                      ? errors?.appsFlyerS2SToken?.toString()
                      : ''
                  }
                  placeholder="S2S token"
                  disabled={!values.appsFlyerIntegrationOn}
                  required
                />
              </Grid>
              <Grid size={12} className="pt-3">
                <AcInput
                  header="Application ID"
                  name="appsFlyerApplicationID"
                  value={values.appsFlyerApplicationID}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  error={
                    touched?.appsFlyerApplicationID &&
                    Boolean(errors?.appsFlyerApplicationID)
                  }
                  helperText={
                    touched?.appsFlyerApplicationID
                      ? errors?.appsFlyerApplicationID?.toString()
                      : ''
                  }
                  placeholder="Application ID"
                  disabled={!values.appsFlyerIntegrationOn}
                  required
                />
              </Grid>
            </AcCard>
          )}
        </Stack>
        <Grid mt={3} size={6}>
          <ActionButton
            variant="outlined"
            text="Update"
            onClick={webhooksForm.submitForm}
            disabled={
              !webhooksForm.isValid ||
              !webhooksForm.dirty ||
              webhooksForm.isSubmitting
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationSettings;
