import { Box, Stack } from '@mui/system';

const AcViewWrapper = ({
  className,
  children,
  header
}: {
  className?: string;
  children: React.ReactNode;
  header?: React.ReactNode;
}) => {
  return (
    <Stack direction="column" height="100%" className={className}>
      {header}
      <Box overflow="auto">{children}</Box>
    </Stack>
  );
};

export default AcViewWrapper;
