import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../../Button/Button';
import Chip from '../../Chip/Chip';
import { cn } from '../../lib/utils';
import Typography from '../../Typography/Typography';

type ImageUploadItemProps = {};

const ImageUploadItem: FC<ImageUploadItemProps> = ({}) => {
  return (
    <div
      className={cn(
        'group border border-transparent hover:cursor-pointer hover:shadow-sm hover:border hover:border-base-border rounded-xl py-3 px-2.5  w-full text-center'
      )}
    >
      <div className="w-full h-[120px] border border-base-border rounded-xl bg-indigo-400 mb-2 group-hover:bg-image-upload-hover">
        <div className="items-center justify-between mt-1.5 mx-2 hidden group-hover:flex">
          <Chip className="w-[70px] inline-block truncate justify-start py-1">
            Desktop BG image
          </Chip>
          <Button
            className="rounded-lg bg-[rgba(255,255,255,0.10)] hover:bg-[rgba(255,255,255,0.30)] p-1 h-6"
            rightIcon={
              <FontAwesomeIcon
                icon="fa-regular fa-trash"
                className="text-base-sidebarBorder text-p-xs"
              />
            }
          />
        </div>
      </div>
      <Typography className="text-base-mutedForeground text-p-s truncate mx-0.5">
        Screenshot 2025 Test
      </Typography>
      <Typography className="truncate text-p-s text-base-foreground mx-0.5">
        PNG
      </Typography>
    </div>
  );
};

export default ImageUploadItem;
