import { FC, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import useOffers from 'api/useOffers';
import useUsers from 'api/useUsers';
import { Offer, ProductsSequence } from 'common/contracts';
import {
  MIN_USD_PRICE,
  PRICE_VALUE_LIMIT,
  TRIMMED_INPUT_REGEX
} from 'constants/constants';
import {
  EActiveState,
  EAssetType,
  ECheckoutLinkTabs,
  EFeatureFlag,
  OfferType
} from 'constants/enums';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import { useUnsavedChanges } from 'hooks/useUnsavedChanges';
import { timeUtils } from 'utils/scheduleUtils';
import { EToasterStatus, showToast } from 'utils/showToast';
import * as yup from 'yup';

import FormTabs from 'components/FormTabs/FormTabs';
import { Product } from 'components/ProductsForm/ProductsForm.types';

import FormHeader from '../../components/FormHeader/FormHeader';

import ScheduleTab from './Tabs/ScheduleTab';
import SettingsTab from './Tabs/SettingsTab';

export interface ProductProps {
  _id: string;
  productId: string;
  image: string;
  imagePrefix: string;
  name: string;
  amount: number | string;
  type: string;
  textFontColorHex: string;
  prefix: string;
  suffix: string;
  publisherProductId?: string;
  quantityDisplay: string;
  priority?: string;
}

type CheckoutLinkFormProps = {
  edit?: boolean;
  dup?: boolean;
};

const CheckoutLinkForm: FC<CheckoutLinkFormProps> = ({
  edit = false,
  dup = false
}) => {
  const { checkoutLinkId, publisherId } = useParams();
  const navigate = useNavigate();
  const { updateOffer, addOffer, getOffer, getOffers } = useOffers({
    offerId: checkoutLinkId,
    offerType: OfferType.CHECKOUT_LINK
  });
  const { fetchFeatureFlags } = useUsers({ publisherId });

  const [activeTab, setActiveTab] = useState<ECheckoutLinkTabs>(
    ECheckoutLinkTabs.SETTINGS
  );
  const [data, setData] = useState<Partial<Offer>>({});
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState<EActiveState>(
    data?.active ? EActiveState.ACTIVE : EActiveState.INACTIVE
  );
  const [dirtyProducts, setDirtyProducts] = useState(false);
  const [startHour, setStartHour] = useState<string | undefined>('');
  const [intervalCron, setIntervalCron] = useState<string>('');
  const tabTitles = [ECheckoutLinkTabs.SETTINGS, ECheckoutLinkTabs.SCHEDULE];

  const hasScheduleOfferFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_SCHEDULED_OFFERS
    ];

  useEffect(() => {
    if (checkoutLinkId && publisherId) {
      getOffer.refetch();
    }
  }, [checkoutLinkId, publisherId]);

  useEffect(() => {
    if (data) setIsLoading(false);
  }, [edit, data]);

  useEffect(() => {
    if (checkoutLinkId && !getOffer.isLoading && getOffer.data) {
      const offerData = getOffer.data.result;
      setData(offerData);
      setProducts(
        offerData.productsSequence[0].products.map(
          (
            p: {
              product: Partial<Product>;
              quantity: number;
              quantityDisplay: string;
            },
            index: number
          ) => ({
            _id: p.product?.productId || index,
            productId: p.product?.productId,
            image: p.product?.images?.[0].url,
            imagePrefix: p.product?.images?.find(
              (i) => i.type === EAssetType.PRODUCT_PREFIX
            )?.url,
            name: p.product?.name,
            amount: p?.quantity,
            type: p.product?.type,
            textFontColorHex: p.product?.textFontColorHex,
            prefix: p.product?.prefix,
            suffix: p.product?.suffix,
            publisherProductId: p.product?.publisherProductId,
            quantityDisplay: p.quantityDisplay
          })
        )
      );
    }
  }, [getOffer.data, getOffer.isLoading, checkoutLinkId]);

  const checkUniqueness = useCallback(
    (value: string | undefined, field: keyof Offer) => {
      const isTaken = getOffers.data?.offers.find((o: Offer) => {
        if (!edit) return o[field] === value;
        return o[field] === value && data[field] !== value;
      });
      return !isTaken;
    },
    [edit, data, getOffers.data]
  );

  const offerSchema = yup.object().shape({
    name: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
      .min(3, `Name length should be longer`)
      .required('Name is required')
      .test('uniqueness', 'Name already exists', (value) => {
        return checkUniqueness(value, 'name');
      }),
    publisherOfferId: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'External ID should not be spaces')
      .required('External ID is required')
      .test('uniqueness', 'External ID already exists', (value) => {
        return checkUniqueness(value, 'publisherOfferId');
      }),
    price: yup
      .number()
      .min(0.8, 'Price must be at least 0.8')
      .max(5000, 'Price must be at most 5000')
      .required('Price is required')
      .test('maxDigits', 'Price must have at most 10 digits', (value) =>
        value ? value.toString().length <= PRICE_VALUE_LIMIT : true
      ),
    playerClickedTtl: yup
      .number()
      .min(300, 'Player Clicked TTL must be at least 300')
      .required('Player Clicked TTL is required'),
    playerAvailability: yup
      .number()
      .min(1, 'Availability must be at least 1')
      .max(99, 'Availability cannot exceed 99')
      .when('schedule.interval', {
        is: (interval: string) => interval || startHour,
        then: yup
          .number()
          .required(
            'Player availability is required if the offer has intervals.'
          ),
        otherwise: yup.number()
      })
      .max(99, 'Availability cannot exceed 99')
  });

  const {
    values,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    dirty,
    errors,
    touched,
    setFieldValue,
    setTouched,
    validateField,
    validateForm
  } = useFormik({
    validateOnMount: true,
    validationSchema: offerSchema,
    enableReinitialize: true,
    initialValues: {
      publisherOfferId: data?.publisherOfferId || '',
      offerId: data?.offerId,
      price: data?.productsSequence?.[0]?.priceInUsdCents
        ? new Decimal(data.productsSequence[0].priceInUsdCents)
            .div(100)
            .toNumber()
        : MIN_USD_PRICE,
      playerAvailability: data?.productsSequence?.[0]?.playerAvailability,
      name: data?.name ? `${data.name}${dup ? '_copy' : ''}` : '',
      type: data?.type || OfferType.CHECKOUT_LINK,
      segments: data?.segments || [],
      schedule: data?.schedule || { permanent: true, timeFrames: [] },
      startHour: timeUtils.getTimeFromCron(data?.schedule?.interval || '')
        ?.hourFromCron,
      active: data?.active ?? EActiveState.ACTIVE,
      quantity: '',
      productsSequence: data?.productsSequence || {},
      playerClickedTtl: (data?.productsSequence?.[0] as ProductsSequence)
        ?.playerClickedTtl
    },
    onSubmit: async (values) => {
      const newOffer: Partial<Offer> & { sectionId?: string | null } = {
        publisherOfferId: values.publisherOfferId,
        name: values.name,
        type: OfferType.CHECKOUT_LINK,
        active: active === EActiveState.ACTIVE,
        segments: [],
        schedule: {
          ...values.schedule,
          timeFrames: values.schedule.permanent
            ? []
            : values.schedule.timeFrames
        },
        badges: [],
        productsSequence: [
          {
            index: 1,
            products: products.map((p) => ({
              productId: p.productId,
              quantity: p.amount,
              publisherProductId: p.publisherProductId
            })),
            playerClickedTtl: values.playerClickedTtl,
            priceInUsdCents: new Decimal(values.price).mul(100).toNumber(),
            playerAvailability: values.playerAvailability || undefined
          }
        ]
      };

      if (edit && checkoutLinkId && !dup) {
        updateOffer.mutate(
          { offerId: checkoutLinkId, form: newOffer },
          {
            onSuccess: () => {
              showToast({
                message: 'Checkout link edited successfully',
                status: EToasterStatus.SUCCESS
              });
              navigate('../');
              getOffers.refetch();
            },
            onError: () => {
              showToast({
                message: 'Error editing checkout link',
                status: EToasterStatus.ERROR
              });
            }
          }
        );
      } else {
        await addOffer.mutate(newOffer, {
          onSuccess: () => {
            showToast({
              message: 'New Checkout link added successfully',
              status: EToasterStatus.SUCCESS
            });
            navigate('../');
            getOffers.refetch();
          },
          onError: () => {
            showToast({
              message: 'Error adding checkout link',
              status: EToasterStatus.ERROR
            });
          }
        });
      }
    }
  });

  useEffect(() => {
    setActive(values.active ? EActiveState.ACTIVE : EActiveState.INACTIVE);
  }, [values?.active]);

  const updateOfferStatus = async (status: string) => {
    const isActive = status === EActiveState.ACTIVE;

    if (edit) {
      try {
        await updateOffer.mutateAsync({
          offerId: String(values?.offerId),
          form: { active: isActive }
        });
        setFieldValue('active', isActive);
      } catch (error) {
        showToast({
          message: 'Error editing checkout link',
          status: EToasterStatus.ERROR
        });
      }
    } else {
      setFieldValue('active', isActive);
    }
    setActive(status as EActiveState);
  };

  useUnsavedChanges({ dirty });

  const formikProps = {
    values,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    dirty,
    errors,
    touched,
    setFieldValue,
    setTouched,
    validateField,
    validateForm
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="bg-white fixed h-screen w-full top-0 left-0 z-[1000] overflow-hidden"
    >
      <FormHeader
        onCloseForm={() => navigate('../')}
        onSubmitForm={() => submitForm()}
        onOptionChange={updateOfferStatus}
        selectedOption={active}
        options={[
          { value: 'Active', label: 'Active', id: 'option-active' },
          { value: 'Inactive', label: 'Inactive', id: 'option-inactive' }
        ]}
        title={`${edit ? 'Edit' : 'Create'} Checkout link`}
        isFormValid={
          !(
            !isLoading &&
            isValid &&
            !(edit && !dirty && !dirtyProducts) &&
            !(
              hasScheduleOfferFeatureFlag &&
              !values.schedule?.permanent &&
              !values.schedule?.timeFrames?.length
            )
          )
        }
        buttonText="Save"
      />
      <FormTabs
        tabs={tabTitles}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <div className="mt-7 w-full mx-auto overflow-y-auto h-[calc(100vh-160px)]">
        {activeTab === ECheckoutLinkTabs.SETTINGS && (
          <SettingsTab
            formikProps={formikProps}
            products={products}
            setProducts={setProducts}
            setDirtyProducts={setDirtyProducts}
          />
        )}

        {activeTab === ECheckoutLinkTabs.SCHEDULE && (
          <ScheduleTab
            formikProps={formikProps}
            startHour={startHour}
            setStartHour={setStartHour}
            intervalCron={intervalCron}
            setIntervalCron={setIntervalCron}
          />
        )}
      </div>
    </motion.div>
  );
};

export default CheckoutLinkForm;
