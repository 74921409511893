import { useState, useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import AcSidebar from 'design-system/AcSidebar';
import { SidebarProvider } from 'components/ui/sidebar';
import Header from './Header';
import WipBanner from './WipBanner';
import { ELocalStorageKeys } from 'constants/enums';
import { localStorageUtil } from 'utils/localStorageUtil';

export default function Layout() {
  const [showBanner, setShowBanner] = useState(() => {
    const savedState = localStorageUtil.get(
      ELocalStorageKeys.WIP_BANNER_CLOSED
    );
    return savedState === null;
  });

  const handleCloseBanner = () => {
    setShowBanner(false);
    localStorageUtil.set(ELocalStorageKeys.WIP_BANNER_CLOSED, 'true');
  };

  return (
    <SidebarProvider>
      <AcSidebar />
      <div className="flex flex-col h-screen w-full overflow-hidden">
        <Header />
        <main className="overflow-auto">
          <Outlet />
        </main>

        {showBanner && <WipBanner setShowBanner={handleCloseBanner} />}
      </div>
    </SidebarProvider>
  );
}
