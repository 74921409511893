import React, { useState } from 'react';

import { Grid, InputAdornment, Stack, Tooltip } from '@mui/material';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

const TriggersTab = (props: any) => {
  const { formikProps, values, setRepeatValue, offersIds } = props;
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    validateField
  } = formikProps;

  const [when, setWhen] = useState('purchaseCompleted');
  const description: string =
    'Define when the offer will be presented to the player';

  const renderRepeatValue = (e: any) => {
    setRepeatValue(Number(e.target.value));
    handleChange(e);
    validateField('repeatEvery');
  };

  const renderIdValue: React.FC<any> = (selected: any) => {
    const selectedLength = selected?.length;
    if (selectedLength === 0) return 'Select';
    if (selectedLength < offersIds?.length && selectedLength > 1)
      return `${selected[0]} and ${selectedLength - 1} more...`;
    if (selectedLength === 1) return selected[0];
    return 'All';
  };

  return (
    <AcCard stackContainer={false} title="Triggers" description={description}>
      <Tooltip arrow title={description} placement="top">
        <></>
      </Tooltip>
      <Grid container sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <AcSelect
            header="When"
            name="when"
            value={when}
            onChange={(e) => {
              setWhen(e.target.value as string);
            }}
            onBlur={handleBlur}
            items={[
              {
                content: 'Purchase completed',
                key: 'purchaseCompleted',
                value: 'purchaseCompleted'
              }
            ]}
            disabled
          />
        </Grid>
        <Grid item xs={1}>
          <img src={`./assets/images/dots.png`} alt="dots" />
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={2} sx={{ paddingLeft: 1, paddingTop: 5 }}>
            <Grid item xs={12}>
              <AcInput
                header="Repeat every"
                name="repeatEvery"
                type="number"
                value={Number(values.repeatEvery) || 1}
                onFocus={(e) => e.target.select()}
                onMouseUp={(e) => (e.target as HTMLInputElement).select()}
                onChange={(e) => {
                  setFieldValue('repeatEvery', Number(e.target.value));
                  renderRepeatValue(e);
                  validateField('repeatEvery');
                }}
                onBlur={handleBlur}
                slotProps={{
                  htmlInput: {
                    min: 1,
                    max: 999
                  },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">purchases</InputAdornment>
                    )
                  }
                }}
                error={touched.repeatEvery && Boolean(errors?.repeatEvery)}
                helperText={
                  touched.repeatEvery ? errors?.repeatEvery?.toString() : ''
                }
                customClass="left-spinner"
                dataTestValidationId="popupRepeatEveryValidationTxt"
              />
            </Grid>
            <Grid item xs={12}>
              <Stack className="triggers-action">And</Stack>
            </Grid>
            <Grid item xs={12}>
              <AcSelect
                header="For offer external IDs"
                name="offerExternalIds"
                items={offersIds}
                isCheckBox
                setSelectedItems={(value: string[]) =>
                  setFieldValue('offerExternalIds', value)
                }
                value={values.offerExternalIds || []}
                renderValue={renderIdValue}
                isSelectAllEnabled={true}
                isApplyBtnEnabled
                onBlur={handleBlur}
                dataTestValidationId="popupExternalIdsValidationTxt"
                dataTestListId="dashPopupExternalIdsList"
                dataTestBoxId="popupExternalIdsBox"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AcCard>
  );
};

export default TriggersTab;
