import { CellContext } from '@tanstack/react-table';
import { ProductProps } from 'views/CheckoutLinks/CheckoutLinkForm';
import { SettingsTabProps } from 'views/CheckoutLinks/Tabs/SettingsTab';

import ActionCell from 'components/DataTableComponent/components/ActionCell';
import DataTable from 'components/DataTableComponent/DataTable';

const ProductsTable: React.FC<SettingsTabProps> = ({
  products,
  setProducts,
  setDirtyProducts,
  setCurrentProduct
}) => {
  const removeProduct = (productId: string) => {
    setDirtyProducts(true);
    setProducts(products.filter((p) => p.productId !== productId));
  };

  const editProduct = (productId: string) => {
    const product = products.find((p) => p.productId === productId);
    if (product) {
      removeProduct(productId);
      setCurrentProduct?.({
        product: product.image,
        productId: product._id,
        quantity: product.amount
      });
    }
  };

  const removeProductSequence = (productId: string) => {
    setProducts(
      products.filter(
        (product: ProductProps) => product.productId !== productId
      )
    );
    setDirtyProducts(true);
  };

  const columns = [
    {
      accessorKey: 'image',
      header: 'Image',
      cell: (cell: CellContext<ProductProps, unknown>) => (
        <img src={String(cell.getValue())} width="48" alt="product" />
      )
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cell: (cell: CellContext<ProductProps, unknown>) => (
        <div className="text-black">{String(cell.getValue())}</div>
      )
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: (cell: CellContext<ProductProps, unknown>) => (
        <div className="text-black">{String(cell.getValue())}</div>
      )
    },
    {
      accessorKey: 'actions',
      header: '',
      cell: ({ row }: CellContext<ProductProps, void>) => (
        <ActionCell
          rowData={row.original}
          actions={[
            { label: 'Edit', handler: () => editProduct(row.original._id) },
            {
              label: 'Delete',
              handler: () => removeProductSequence(String(row.original._id))
            }
          ]}
        />
      )
    }
  ];

  return (
    <div className="rounded-[20px] mb-10 w-[528px] relative left-1/2 transform -translate-x-1/2">
      {products.length > 0 && (
        <DataTable data={products} columns={columns} showPagination={false} />
      )}
    </div>
  );
};
export default ProductsTable;
