import { ESaleDiscountDisplayType } from '../../constants/enums';
import AcSelect from '../AcSelect/AcSelect';

import { DisplayTypeSelectProps } from './DisplayTypeSelect.types';

const DisplayTypeSelect = ({
  handleChange,
  handleBlur,
  value = ESaleDiscountDisplayType.PERCENTAGE,
  disabled = false
}: DisplayTypeSelectProps) => {
  return (
    <AcSelect
      header="Type"
      name="salePercentageDisplayType"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      dataTestBoxId="bundleProductSaleTypeInpt"
      dataTestHeaderId="bundleProductSaleTypeHdrTxt"
      items={[
        {
          content: 'Percentage',
          key: ESaleDiscountDisplayType.PERCENTAGE,
          value: ESaleDiscountDisplayType.PERCENTAGE
        },
        {
          content: 'Multiplier',
          key: ESaleDiscountDisplayType.MULTIPLIER,
          value: ESaleDiscountDisplayType.MULTIPLIER
        },
        {
          content: 'Fixed amount',
          key: ESaleDiscountDisplayType.FIXED_AMOUNT,
          value: ESaleDiscountDisplayType.FIXED_AMOUNT
        }
      ]}

    />
  );
};

export default DisplayTypeSelect;
