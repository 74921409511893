import { Box, Button, Typography } from '@mui/material';
import { AcSwitchSelectorProps } from 'common/contracts';

import './style.scss';

const AcSwitchSelector = ({
  options,
  value,
  onClick
}: AcSwitchSelectorProps) => {
  return (
    <Box className="ac-switch-selector">
      {options.map((option) => (
        <Button
          key={option.value}
          className={`ac-switch-selector-button ${option.value === value ? 'active' : ''}`}
          onClick={() => onClick(option.value)}
          disableRipple
        >
          <Typography>{option.value}</Typography>
        </Button>
      ))}
    </Box>
  );
};

export default AcSwitchSelector;
