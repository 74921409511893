import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import useImages from '../../../api/useImages';
import DialogModal from '../../../components/Dialog/Dialog';
import { DialogButton } from '../../../components/Dialog/Dialog.types';
import { EButtonColor, EFeatureFlag } from '../../../constants/enums';
import { EToasterStatus, showToast } from '../../../utils/showToast';
import useUsers from '../../../api/useUsers';

interface UploadAssetModalProps {
  isModalOpen: boolean;
  onModalClosed: () => void;
  setDeleteDialogOpen: (value: boolean) => void;
  selectedImageId: string;
}
const DeleteAssetModal = ({
  isModalOpen,
  onModalClosed,
  setDeleteDialogOpen,
  selectedImageId
}: UploadAssetModalProps) => {
  const { publisherId } = useParams();
  const { getImages, deleteImage } = useImages(publisherId);
  const { fetchFeatureFlags } = useUsers({ publisherId });

  const hasMediaV2Enabled = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_PROJECT_USE_MEDIA_V2
      ],
    [fetchFeatureFlags?.data]
  );

  const deleteImageHandler = async () => {
    deleteImage.mutate(selectedImageId, {
      onSuccess: () => {
        const deletedItemName =
          getImages.data.find((item: any) => {
            const currentItemId = hasMediaV2Enabled ? item.mediaId : item._id;
            return currentItemId === selectedImageId;
          })?.name ?? '';
        getImages.refetch();
        showToast({
          message: `${deletedItemName} has been deleted`,
          status: EToasterStatus.SUCCESS
        });
      },
      onError: (data: any) => {
        const errorMsg = `${data.response.data.message} ${data.response.data.data ? data.response.data.data.join(',') : ''}`;
        showToast({
          message: errorMsg,
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const buttonsContent: DialogButton[] = [
    {
      text: 'Cancel',
      color: EButtonColor.SECONDARY,
      variant: 'outlined',
      func: () => setDeleteDialogOpen(false)
    },
    {
      text: 'Delete',
      color: EButtonColor.ERROR,
      variant: 'contained',
      func: () => {
        deleteImageHandler();
        setDeleteDialogOpen(false);
      }
    }
  ];

  return (
    <DialogModal
      isOpen={isModalOpen}
      headline="Delete Image"
      text="Are you sure you want to delete this image?"
      buttons={buttonsContent}
      closeDialog={() => onModalClosed()}
    />
  );
};

export default DeleteAssetModal;
