import { useEffect, useRef, useState } from 'react';

import usePricing from 'api/usePricing';
import { AxiosError } from 'axios';
import { EButtonColor } from 'constants/enums';

import { EToasterStatus, showToast } from '../../utils/showToast';
import DialogModal from '../Dialog/Dialog';
import ImportCSVModal from '../ImportCSVModal/ImportCSVModal';

import { ImportedPricingPoint } from './Pricing.types';

interface PricingModalProps {
  publisherId?: string;
  updateExchangeRatesDialogOpen: boolean;
  isUploadCSVOpen: boolean;
  selectedPricing: string | null;
  isDeleteDialogOpen: boolean;
  setSelectedPricing: (pricing: string | null) => void;
  setUploadCSVOpen: (open: boolean) => void;
  setDeleteDialogOpen: (open: boolean) => void;
  setTableLoader: (loading: boolean) => void;
  setUpdateExchangeRatesDialogOpen: (open: boolean) => void;
  errorMessage: string | null;
  setErrorMessage: (error: string | null) => void;
}

const PricingModals: React.FC<PricingModalProps> = ({
  publisherId,
  updateExchangeRatesDialogOpen,
  isUploadCSVOpen,
  selectedPricing,
  isDeleteDialogOpen,
  setSelectedPricing,
  setUploadCSVOpen,
  setDeleteDialogOpen,
  setTableLoader,
  setUpdateExchangeRatesDialogOpen,
  errorMessage,
  setErrorMessage
}) => {
  const [isMessagesDialogOpen, setMessagesDialogOpen] = useState(false);
  const [importedPricingPointsWithErrors, setImportedPricingPointsWithErrors] =
    useState<ImportedPricingPoint[]>([]);
  const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
  const [isFileErrorDialogOpen, setFileErrorDialogOpen] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);

  const { importCsvFile, deletePricing, getPricings, updateExchangeRates } =
    usePricing(publisherId);

  useEffect(() => {
    if (errorMessage) {
      setFileErrorDialogOpen(true);
      setUploadCSVOpen(false);
    }
  }, [errorMessage, setUploadCSVOpen]);

  const handleImportFile = (formData: FormData) => {
    importCsvFile.mutate(formData, {
      onSuccess: async (response: ImportedPricingPoint[]) => {
        showToast({
          message: `File has been uploaded successfully`,
          status: EToasterStatus.SUCCESS
        });

        setUploadCSVOpen(false);
        getPricings.refetch();

        const pricingPointsWithErrors = response.filter(
          (point) =>
            point.message !== '' ||
            (point.body.errors && point.body.errors.length > 0)
        );

        if (pricingPointsWithErrors.length > 0) {
          setImportedPricingPointsWithErrors(pricingPointsWithErrors);
          setMessagesDialogOpen(true);
        }
      },
      onError(error: unknown) {
        const axiosError = error as AxiosError;
        const message =
          (axiosError.response?.data as { message: string })?.message ??
          'Unknown error';
        setErrorMessage(message);
      }
    });
  };

  const onDeletePricing = () => {
    if (!selectedPricing) return;
    deletePricing.mutate(selectedPricing, {
      onSuccess: () => {
        setDeleteDialogOpen(false);
        setSelectedPricing(null);
        showToast({
          message: 'Pricing deleted successfully',
          status: EToasterStatus.SUCCESS
        });
        getPricings.refetch();
      },
      onError: () => {
        setDeleteDialogOpen(false);
        setSelectedPricing(null);
        showToast({
          message: 'There was a problem deleting the Pricing',
          status: EToasterStatus.ERROR
        });
      }
    });
  };

  const pricingPointsErrorsContent = (
    <div className={'price-points__errors-content-block'}>
      {importedPricingPointsWithErrors.length > 0 && (
        <>
          {/* Display the first error message */}
          <div className={'price-points__error-message'}>
            <div>{importedPricingPointsWithErrors[0].record}</div>
            {/* Check and render message if no errors array or it's empty */}
            {(!importedPricingPointsWithErrors[0].body.errors ||
              importedPricingPointsWithErrors[0].body.errors.length === 0) && (
              <div>{importedPricingPointsWithErrors[0].message}</div>
            )}
            {/* Render first error or more based on toggle if errors exist */}
            {importedPricingPointsWithErrors[0].body.errors
              ?.slice(0, showAllErrors ? undefined : 1)
              .map((err, index) => <div key={index}>{err}</div>)}
            {!showAllErrors &&
              importedPricingPointsWithErrors[0].body.errors &&
              importedPricingPointsWithErrors[0].body.errors.length > 1 && (
                <div
                  className={'price-points__toggle-visibility'}
                  onClick={() => setShowAllErrors(true)}
                >
                  Show more
                </div>
              )}
          </div>
          {/* Handle subsequent errors if "Show all" is active */}
          {showAllErrors &&
            importedPricingPointsWithErrors.slice(1).map((point, index) => (
              <div key={index} className={'price-points__error-message'}>
                <div>{point.record}</div>
                {point.body.errors?.map((err, idx) => (
                  <div key={idx}>{err}</div>
                ))}
                {/* Handle cases where only message is present */}
                {(!point.body.errors || point.body.errors.length === 0) && (
                  <div>{point.message}</div>
                )}
              </div>
            ))}
          {/* Toggle to "Show less" if all errors are being displayed */}
          {showAllErrors && (
            <div
              className={'price-points__toggle-visibility'}
              onClick={() => setShowAllErrors(false)}
            >
              Show less
            </div>
          )}
        </>
      )}
    </div>
  );

  const updateExchangeRatesHandler = async () => {
    setTableLoader(true);
    try {
      await updateExchangeRates.mutateAsync();
    } catch (error) {
      console.log(error);
    }
    setTableLoader(false);
    setUpdateExchangeRatesDialogOpen(false);
  };

  return (
    <>
      <ImportCSVModal
        isOpen={isUploadCSVOpen}
        onImportFile={(formData: FormData) => handleImportFile(formData)}
        onCloseModal={() => {
          setUploadCSVOpen(false);
        }}
        fileRef={fileRef}
        fileKeyName="pricePointsFile"
      />
      <DialogModal
        isOpen={isMessagesDialogOpen}
        closeButton={true}
        headline="Please note"
        width={'392px'}
        content={pricingPointsErrorsContent}
        text={'Following currencies cannot be created:'}
        buttons={[]}
        closeDialog={() => {
          setMessagesDialogOpen(false);
          setShowAllErrors(false);
        }}
      />
      <DialogModal
        isOpen={updateExchangeRatesDialogOpen}
        closeButton={false}
        headline="Update Exchange Rates"
        width={'392px'}
        text={
          'All existing price points will be updated according to the latest exchange rate. Players will automatically see the new prices at the store'
        }
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => setUpdateExchangeRatesDialogOpen(false),
            disabled: false,
            fullWidth: false
          },
          {
            text: 'Update',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: () => updateExchangeRatesHandler(),
            disabled: false,
            fullWidth: false
          }
        ]}
      />
      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Pricing"
        text="Are you sure you want to delete this pricing?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: onDeletePricing
          }
        ]}
        closeDialog={() => setDeleteDialogOpen(false)}
      />
      <DialogModal
        isOpen={isFileErrorDialogOpen}
        headline="File Operation Failed"
        text={errorMessage ?? 'An unknown error occurred.'}
        buttons={[
          {
            text: 'OK',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: () => setFileErrorDialogOpen(false)
          }
        ]}
        closeDialog={() => setFileErrorDialogOpen(false)}
        className="import-error-modal"
      />
    </>
  );
};

export default PricingModals;
