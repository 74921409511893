import React from 'react';

import Button from 'components/Button/Button';

interface TabsProps {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: any) => void;
}

const FormTabs: React.FC<TabsProps> = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className="mt-4 w-[456px] mx-auto sticky">
      <div className="flex gap-4">
        {tabs.map((tab) => (
          <Button
            key={tab}
            variant="ghost"
            className={`border-b-2 w-full pl-0 text-sm rounded-none justify-start flex-1 hover:opacity-90 hover:bg-transparent hover:border-opacity-90 ${
              activeTab === tab
                ? 'border-indigo-700 text-indigo-700'
                : 'border-b-2 border-base-sidebarRing text-base-sidebarRing'
            }`}
            onClick={() => onTabChange(tab)}
          >
            {tab}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default FormTabs;
