import { Avatar } from '@mui/material';

import { ELocalStorageKeys } from '../../constants/enums';
import { UserDetails } from '../../pages/Login/Login.types';
import { localStorageUtil } from '../../utils/localStorageUtil';

import { AcAvatarProps } from './AcAvatar.types';

import './style.scss';

const AcAvatar = ({ content, isSuperAdmin, dataTestId }: AcAvatarProps) => {
  const userDetails = localStorageUtil.get<UserDetails>(
    ELocalStorageKeys.USER_DETAILS
  );

  return (
    <div className={isSuperAdmin ? 'ac-avatar--super-admin' : 'ac-avatar'}>
      <Avatar sx={{ bgcolor: '#A7DAFD', color: 'black' }} data-testid={dataTestId}>
        {content
          ? content
          : userDetails.firstName && userDetails.lastName
            ? `${userDetails.firstName
                .charAt(0)
                .toUpperCase()}${userDetails.lastName.charAt(0).toUpperCase()}`
            : `N/A`}
      </Avatar>
    </div>
  );
};

export default AcAvatar;
