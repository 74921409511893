import React from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { toast as sonnerToast } from 'sonner';

import { cn } from '../components/lib/utils';

export enum EToasterStatus {
  ERROR = 'error',
  SUCCESS = 'success'
}

type ToasterStyles = {
  iconVariant: string;
  iconColor: string;
  toastBackground: string;
  textColor: string;
};

export const showToast = ({
  message,
  description,
  status
}: {
  message: string;
  description?: string;
  status: EToasterStatus;
}) => {
  const statusStyles: Record<EToasterStatus, ToasterStyles> = {
    success: {
      iconVariant: 'fa-regular fa-circle-check',
      iconColor: 'text-base-mutedForeground',
      toastBackground: 'bg-base-background border-base-border',
      textColor: 'text-base-foreground'
    },
    error: {
      iconVariant: 'fa-regular fa-circle-exclamation',
      iconColor: 'text-base-destructiveForeground',
      toastBackground: 'bg-base-destructive border-base-destructive',
      textColor: 'text-base-destructiveForeground'
    }
  };

  return sonnerToast(message, {
    description,
    unstyled: true,
    closeButton: true,
    icon: (
      <FontAwesomeIcon
        icon={statusStyles[status].iconVariant}
        className={statusStyles[status].iconColor}
      />
    ),
    classNames: {
      toast: cn(
        'group flex items-center py-6 pl-6 pr-8 border rounded-md gap-y-1 gap-x-4 w-[388px] absolute hover:cursor-pointer shadow-lg bottom-0 h-max [&:not(:first-child)>div]:data-[expanded=false]:opacity-0',
        statusStyles[status].toastBackground
      ),
      title: cn(
        'text-p-s opacity-90 line-clamp-2',
        description && 'font-semibold opacity-100',
        statusStyles[status].textColor
      ),
      description: cn(
        'opacity-90 text-p-s line-clamp-6',
        statusStyles[status].textColor
      ),
      closeButton: cn(
        'opacity-0 group-hover:opacity-100 absolute top-3 right-3 w-4 h-4 [&>svg]:h-4 [&>svg]:w-4',
        statusStyles[status].iconColor
      )
    }
  });
};
