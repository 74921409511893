import { EScheduleTabs } from 'constants/enums';

interface ScheduleOption {
  value: string;
}

export interface SwitchSelectorProps {
  options: ScheduleOption[];
  onClick: (value: string) => void;
  value?: EScheduleTabs;
}
const SwitchSelector: React.FC<SwitchSelectorProps> = ({
  options,
  value,
  onClick
}) => {
  return (
    <div className="w-full bg-base-accent flex items-center h-10 rounded-lg">
      {options.map((option) => (
        <button
          key={option.value}
          className={`text-sm flex-1 h-8 rounded-lg mx-1 ${
            option.value === value
              ? 'bg-white text-black'
              : 'bg-base-accent text-base-mutedForeground'
          }`}
          onClick={() => onClick(option.value)}
        >
          <span>{option.value}</span>
        </button>
      ))}
    </div>
  );
};

export default SwitchSelector;
