import React from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import ImageUpload from '../../components/ImageUpload/ImageUpload';
import Input from '../../components/Input/Input';
import PortalHeader from '../../components/PortalHeader/PortalHeader';
import RichText from '../../components/RichText/RichText';
import Switch from '../../components/Switch/Switch';
import { Tab, Tabs, TabsList } from '../../components/Tabs/Tabs';
import { ColorPicker } from '../../components/ui/ColorPicker/color-picker';
import { TabsContent } from '../../components/ui/tabs';

enum EPortalSettingsTabs {
  DESIGN_AND_LAYOUT = 'design&layout',
  LOCALIZATION = 'localization'
}

const PORTAL_SETTINGS_TABS: Tab[] = [
  {
    id: EPortalSettingsTabs.DESIGN_AND_LAYOUT,
    value: 'Design & Layout'
  },
  {
    id: EPortalSettingsTabs.LOCALIZATION,
    value: 'Localization'
  }
];

const SettingsView = () => {
  return (
    <div>
      <PortalHeader
        title="Portal Settings"
        primaryButtonText="Save"
        secondaryButtonText="Preview"
        onPrimaryButtonClick={() => {}}
        onSecondaryButtonClick={() => {}}
        secondaryButtonVariant="outline"
        secondaryButtonIcon={<FontAwesomeIcon icon="fa-regular fa-eye" />}
      />
      <div className="w-full px-7">
        <Tabs defaultValue={EPortalSettingsTabs.DESIGN_AND_LAYOUT}>
          <TabsList tabs={PORTAL_SETTINGS_TABS} />

          <TabsContent
            value={EPortalSettingsTabs.DESIGN_AND_LAYOUT}
            className="mt-5"
          >
            <div className="mb-5">
              <Input
                label="Store name"
                placeholder="e.g., Coinsland"
                className="max-w-[275px]"
                disabled
              />
            </div>
            <div className="border border-base-sidebarBorder rounded-[20px] mb-5 p-9">
              <RichText
                title="Header"
                description="Customize your portal’s header"
                className="mb-9 [&>h4]:text-p-l [&>h4]:font-semibold"
              />
              <div className="flex flex-col w-[586px]">
                <div className="grid grid-cols-2 gap-9 mb-9">
                  <Input label="Portal Title" placeholder="Add a title " />
                </div>
                <div className="mb-9">
                  <ImageUpload
                    className="w-full"
                    title="Add a logo"
                    description={`Add an icon to your page.\nImage will be scaled to 130px x 60px`}
                    buttonText="Upload"
                  />
                </div>
                <div className="grid grid-cols-2 mb-9 gap-9">
                  <ColorPicker
                    label="Background Color"
                    onChange={(color) => console.log(color)}
                  />
                  <ColorPicker
                    label="Text Color"
                    onChange={(color) => console.log(color)}
                  />
                </div>
                <div className="grid grid-cols-1 gap-y-9">
                  <Switch
                    label="Show player name"
                    description="Display the logged-in player's name in the header"
                    textAlignment="right"
                    size="lg"
                  />
                  <Switch
                    label="Show player balance"
                    description="Display the logged-in player's account balance in the header"
                    textAlignment="right"
                    size="lg"
                  />
                  <Switch
                    label="Show login button"
                    description="Display a login button in the header"
                    textAlignment="right"
                    size="lg"
                  />
                  <Switch
                    label="Show language switcher"
                    description="Display a dropdown menu for allowing players to change the portal's language"
                    textAlignment="right"
                    size="lg"
                  />
                </div>
              </div>
            </div>
            <div className="border border-base-sidebarBorder rounded-[20px] mb-5 p-9">
              <RichText
                title="Sidebar"
                description="Customize your portal’s sidebar"
                className="mb-9"
              />
              <div className="grid grid-cols-2 w-[586px] xl:grid-cols-3 gap-9 xl:w-[897px]">
                <ColorPicker
                  label="Background Color"
                  onChange={(color) => console.log(color)}
                />
                <ColorPicker
                  label="Text Color"
                  onChange={(color) => console.log(color)}
                />
                <ColorPicker
                  label="Tab on-hover color"
                  onChange={(color) => console.log(color)}
                />
              </div>
            </div>
            <div className="border border-base-sidebarBorder rounded-[20px] mb-7 p-9">
              <RichText
                title="Footer"
                description="Customize your portal’s footer"
                className="mb-9"
              />
              <div className="grid grid-cols-2 mb-9 gap-9 w-[586px]">
                <ColorPicker
                  label="Background Color"
                  onChange={(color) => console.log(color)}
                />
                <ColorPicker
                  label="Text Color"
                  onChange={(color) => console.log(color)}
                />
              </div>
              <div className="grid grid-cols-1 gap-9 w-[586px]">
                <Input label="Privacy link" placeholder="htpp://" />
                <Input label="Terms & Conditions link" placeholder="htpp://" />
                <Input label="Support link" placeholder="htpp://" />
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default SettingsView;
