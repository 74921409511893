import React from 'react';

import { useParams } from 'react-router-dom';

import { Divider, Grid, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { systemTexts } from 'constants/localization';
import { useFormik } from 'formik';

import useTheme from '../../../api/useTheme';
import { EToasterStatus, showToast } from '../../../utils/showToast';
import ActionButton from '../../ActionButton/ActionButton';

import { QuantityFormat } from './Components/QuantityFormat';
import { useGetStoreSettingsInitialValues } from './useGetGeneralInitialValues';
import { storeSettingsSchema } from './validation-schema';

import '../../../style/forms.scss';
import '../../Theme/style.scss';

const StoreLayout: React.FC = () => {
  const { publisherId } = useParams();
  const { getGeneralTheme: generalTheme, updateGeneralTheme } =
    useTheme(publisherId);

  const { initialValues } = useGetStoreSettingsInitialValues();

  const handleSubmit = async (values: any) => {
    try {
      await updateGeneralTheme.mutateAsync(values);
      showToast({
        message: systemTexts.settingsTexts.THEME_UPDATED,
        status: EToasterStatus.SUCCESS
      });
      setSubmitting(false);
    } catch (error) {
      showToast({
        message: systemTexts.settingsTexts.THEME_UPDATE_ERROR,
        status: EToasterStatus.ERROR,
        description:
          (
            (error as AxiosError).response?.data as {
              message: string;
            }
          ).message || 'Unknown error occurred'
      });
    }
  };

  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setSubmitting
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: storeSettingsSchema,
    onSubmit: handleSubmit
  });

  return (
    <Grid container className="formContent">
      <Grid item>
        <Stack>
          <QuantityFormat
            title={systemTexts.settingsTexts.TITLES.PRODUCT_QUANTITY_FORMAT}
            quantityFormat={values.productsQuantityFormat}
            name={'productsQuantityFormat'}
            isFetching={generalTheme.isFetching}
            touched={touched?.productsQuantityFormat}
            errors={errors?.productsQuantityFormat}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <Divider />
          <QuantityFormat
            title={systemTexts.settingsTexts.TITLES.BALANCE_QUANTITY_FORMAT}
            quantityFormat={values.balanceQuantityFormat}
            name={'balanceQuantityFormat'}
            isFetching={generalTheme.isFetching}
            touched={touched?.balanceQuantityFormat}
            errors={errors?.balanceQuantityFormat}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <Divider />
          <QuantityFormat
            title={
              systemTexts.settingsTexts.TITLES.SPECIAL_OFFER_QUANTITY_FORMAT
            }
            quantityFormat={values.specialOfferQuantityFormat}
            name={'specialOfferQuantityFormat'}
            isFetching={generalTheme.isFetching}
            touched={touched?.specialOfferQuantityFormat}
            errors={errors?.specialOfferQuantityFormat}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </Stack>
        <Grid item xs={6} mt={3}>
          <ActionButton
            variant="outlined"
            text={systemTexts.actionTexts.UPDATE}
            onClick={submitForm}
            disabled={!isValid || !dirty || isSubmitting}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={7} className="iphone-mock-wrapper">
                <div
                    className="store-preview-wrapper iphone-mock"
                    style={{ overflow: 'hidden' }}
                >
                    <Background
                        backgroundImageMobile={
                            values.general.backgroundImageMobile
                        }
                        backgroundImageDesktop={
                            values.general.backgroundImageMobile
                        }
                        width="100%"
                        height="100%"
                        position="absolute"
                    />
                    <img
                        style={{ position: 'relative' }}
                        alt="logo"
                        className={`storeLogo ${values.general.logoSize}`}
                        src={values.general.logo}
                    />
                    <div className="bundles-wrapper">
                        <SectionLayout sectionName={''} />
                        <BundlesLayout mobileView={bundlesLayoutMobile}>
                            {Array.from(
                                {
                                    length:
                                        values.general.bundlesViewModel ===
                                        EBundlesViewModel.THREE_IN_ONE
                                            ? 3
                                            : 1
                                },
                                () => null
                            ).map((i, index) => (
                                <div className="bundle" key={index}>
                                    <BundlesInternalLayout
                                        bundleBorderColor={
                                            values.storeScreen.bundleBorderColor
                                        }
                                        productList={[]}
                                        bundleButtonColor={
                                            values.general.buttonColor
                                        }
                                        backgroundSrc="https://media.appcharge.com/defaults/background.png"
                                        bundleButtonTextColor={
                                            values.general.buttonTextColor
                                        }
                                        fontFamily={values.general.font}
                                        price={{
                                            price: 0,
                                            isCents: true,
                                            fractionalSeparator: '.',
                                            milSeparator: ',',
                                            spacing: false,
                                            symbolPosition: 'left',
                                            currencySymbol: '$',
                                            currencyCode: 'USD'
                                        }}
                                        backgroundColor={{
                                            colorOne: 'transparent'
                                        }}
                                        inMobileMock={true}
                                    />
                                </div>
                            ))}
                        </BundlesLayout>
                    </div>
                </div>
            </Grid> */}
    </Grid>
  );
};

export default StoreLayout;
