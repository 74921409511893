import { ComponentProps, FC, useCallback, useState } from 'react';

import { cn } from '../lib/utils';
import { UILabel, UISwitch } from '../ui';

const sizeClasses = {
  sm: 'h-4 w-8 data-[state=checked]:[&>span]:translate-x-3',
  md: 'h-5 w-10 data-[state=checked]:[&>span]:translate-x-5',
  lg: 'h-6 w-11 data-[state=checked]:[&>span]:translate-x-6'
};

type Size = 'sm' | 'md' | 'lg';

export type SwitchProps = ComponentProps<typeof UISwitch> & {
  label?: string;
  description?: string;
  textAlignment?: 'left' | 'right';
  size?: Size;
  defaultChecked?: boolean;
  className?: string;
};

const Switch: FC<SwitchProps> = ({
  label,
  description,
  textAlignment = 'left',
  size = 'md',
  defaultChecked = false,
  className,
  onCheckedChange,
  ...rest
}) => {
  const [checked, setChecked] = useState(defaultChecked);
  const handleChange = useCallback(
    (newChecked: boolean) => {
      setChecked(newChecked);
      if (onCheckedChange) onCheckedChange(newChecked);
    },
    [onCheckedChange]
  );

  return (
    <div
      className={cn(
        'flex items-top',
        textAlignment === 'left'
          ? 'flex-row-reverse space-x-reverse justify-end'
          : 'space-x-2',
        className
      )}
    >
      <UISwitch
        {...rest}
        checked={checked}
        onCheckedChange={handleChange}
        id="toggle-switch"
        className={cn(
          'shadow-none bg-base-input data-[state=checked]:bg-base-primary [&>span]:bg-base-background',
          sizeClasses[size as Size],
          description && '-mt-[1px]'
        )}
      />

      <div className="flex flex-col w-full">
        {label && (
          <UILabel className="text-p-s text-base-foreground text-left">
            {label}
          </UILabel>
        )}
        {description && (
          <span className="text-p-s text-base-mutedForeground mt-1.5 text-left">
            {description}
          </span>
        )}
      </div>
    </div>
  );
};

export default Switch;
