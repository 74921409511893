import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { UIButton } from 'components/ui';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from 'components/ui';

interface Action {
  label: string;
  handler: (data: any) => void;
  icon?: React.ElementType;
}

interface ActionCellProps {
  rowData: any;
  actions: Action[];
  menuLabel?: string;
}

const ActionCell: FC<ActionCellProps> = ({ rowData, actions, menuLabel }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <UIButton variant="ghost">
        <FontAwesomeIcon
          icon="fa-regular fa-ellipsis-vertical"
          className="text-black"
        />
      </UIButton>
    </DropdownMenuTrigger>
    <DropdownMenuContent
      side="left"
      align="start"
      sideOffset={2}
      className="bg-base-popover border-base-border rounded-md min-w-fit"
    >
      {menuLabel && (
        <DropdownMenuLabel className="border-b border-base-muted-foreground">
          {menuLabel}
        </DropdownMenuLabel>
      )}
      {actions.map((action: Action, index: number) => (
        <DropdownMenuItem
          key={index}
          onClick={() => action.handler(rowData)}
          className="text-base-popoverForeground text-p-s hover:bg-base-accent cursor-pointer hover:rounded-md"
        >
          {action.icon && <action.icon className="mr-2" />}
          {action.label}
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  </DropdownMenu>
);

export default ActionCell;
