import type { DateRange } from '@mui/x-date-pickers-pro';
import { CellContext, type ColumnDef, type Row } from '@tanstack/react-table';
import {
  type ExtendedDateRange,
  type FormikProps,
  ScheduleTimeFrame,
  TimeframeDateRange,
  type TimeFrameObject
} from 'common/contracts';
import { DATE_TIME_FORMAT_UTC } from 'constants/constants';
import {
  EAppearanceStatus,
  EPendingAppearanceStatus,
  OfferType
} from 'constants/enums';
import dayjs, { type Dayjs } from 'dayjs';
import { statusUtils } from 'utils/scheduleUtils';

import AcDateTimePicker from 'components/AcDateTimePicker/AcDateTimePicker';
import Chip, { type ChipStatus } from 'components/Chip/Chip';
import ActionCell from 'components/DataTableComponent/components/ActionCell';
import DataTable from 'components/DataTableComponent/DataTable';
import Input from 'components/Input/Input';
import RichText from 'components/RichText/RichText';

interface ScheduledOfferProps {
  formikProps: FormikProps;
  editRangeDatesValues: ExtendedDateRange | null;
  setEditRangeDatesValues: (value: ExtendedDateRange | null) => void;
  intervalCron: string;
  appearancesTableData: ScheduleTimeFrame[];
  notes: string;
  setNotes: (value: string) => void;
  dateRange: DateRange<Dayjs>;
  setDateRange: (value: DateRange<Dayjs>) => void;
  resetScheduleValues: () => void;
}

const statusMapping: Record<string, ChipStatus> = {
  [EAppearanceStatus.RUNNING]: 'success',
  [EAppearanceStatus.UPCOMING]: 'warning',
  [EAppearanceStatus.ENDED]: 'secondary',
  [EPendingAppearanceStatus.PENDING_SAVE]: 'secondary'
};

const ScheduledOffer: React.FC<ScheduledOfferProps> = ({
  formikProps,
  editRangeDatesValues,
  setEditRangeDatesValues,
  intervalCron,
  appearancesTableData,
  notes,
  setNotes,
  dateRange,
  setDateRange,
  resetScheduleValues
}) => {
  const { values, handleChange, handleBlur, setFieldValue, touched, errors } =
    formikProps;

  const handleApply = (range: TimeframeDateRange, notes?: string) => {
    const [fromUtcDate, toUtcDate] = range;

    if (fromUtcDate && toUtcDate) {
      const newTimeFrame: ScheduleTimeFrame = {
        startTime: new Date(fromUtcDate.toISOString()), // UTC start date
        endTime: new Date(toUtcDate.toISOString()), // UTC end date
        notes
      };

      if (editRangeDatesValues) {
        const [editStartTime, editEndTime] = editRangeDatesValues;

        const updatedTimeFrames = values?.schedule?.timeFrames.map(
          (timeFrame: ScheduleTimeFrame) => {
            const currentStartTimestamp = new Date(
              timeFrame.startTime
            ).getTime();
            const currentEndTimestamp = new Date(timeFrame.endTime).getTime();

            return currentStartTimestamp ===
              new Date(editStartTime).getTime() &&
              currentEndTimestamp === new Date(editEndTime).getTime()
              ? newTimeFrame
              : timeFrame;
          }
        );
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: updatedTimeFrames,
          ...(intervalCron &&
            values?.schedule?.permanent && { interval: intervalCron })
        });
      } else {
        setEditRangeDatesValues(null);
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: [...values?.schedule?.timeFrames, newTimeFrame],
          ...(intervalCron &&
            values?.schedule?.permanent && { interval: intervalCron })
        });
      }
    } else {
      console.error('Invalid date range provided: Start or End date is null.');
    }
  };

  const removeTimeframe = (startTime: string, endTime: string) => {
    const newTimeFrames = formikProps?.values?.schedule?.timeFrames?.filter(
      (timeFrame: TimeFrameObject) =>
        new Date(timeFrame.startTime).getTime() !==
          new Date(startTime).getTime() &&
        new Date(timeFrame.endTime).getTime() !== new Date(endTime).getTime()
    );

    resetScheduleValues();
    formikProps.setFieldValue('schedule', {
      permanent: !newTimeFrames.length
        ? false
        : formikProps.values?.schedule.permanent,
      timeFrames: newTimeFrames
    });
  };

  const columns: ColumnDef<ScheduleTimeFrame>[] = [
    {
      accessorKey: 'startTime',
      header: 'Start',
      cell: (cell: CellContext<ScheduleTimeFrame, unknown>) =>
        dayjs(cell.getValue() as string)
          .utc()
          .format(DATE_TIME_FORMAT_UTC)
    },
    {
      accessorKey: 'endTime',
      header: 'End',
      cell: (cell: CellContext<ScheduleTimeFrame, unknown>) =>
        dayjs(cell.getValue() as string)
          .utc()
          .format(DATE_TIME_FORMAT_UTC)
    },
    {
      accessorKey: 'notes',
      header: 'Notes',
      cell: (cell: CellContext<ScheduleTimeFrame, unknown>) =>
        cell.getValue() as string
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }: { row: Row<ScheduleTimeFrame> }) => {
        const { startTime, endTime, id } = row.original;
        const pendingSaveStatus = id?.startsWith('id-');
        const status = pendingSaveStatus
          ? EPendingAppearanceStatus.PENDING_SAVE
          : statusUtils.determineStatus(new Date(startTime), new Date(endTime));

        return (
          <Chip variant="flat" status={statusMapping[status]}>
            {status}
          </Chip>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: '',
      cell: ({ row }: { row: Row<ScheduleTimeFrame> }) => (
        <ActionCell
          rowData={row.original}
          actions={[
            {
              label: 'Edit',
              handler: () => {
                setEditRangeDatesValues([
                  new Date(row.original.startTime),
                  new Date(row.original.endTime),
                  row.original.notes
                ]);
              }
            },
            {
              label: 'Delete',
              handler: () =>
                removeTimeframe(
                  String(row.original.startTime),
                  String(row.original.endTime)
                )
            }
          ]}
        />
      )
    }
  ];

  const tableConfig = {
    data: appearancesTableData,
    showPagination: false,
    columns
  };

  return (
    <div className="mt-8">
      {values.type === OfferType.SPECIAL_OFFER && (
        <>
          <Input
            label="Availability"
            name="playerAvailability"
            value={values.playerAvailability || ''}
            placeholder="01"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            onBlur={handleBlur}
            isRequired={values.setAsFree}
            type="number"
            className="w-64"
            state={
              touched.playerAvailability && errors.playerAvailability
                ? 'error'
                : 'default'
            }
            description={errors.playerAvailability}
          />
          <div className="my-5 border-t border-b py-4" />
        </>
      )}
      <AcDateTimePicker
        onApply={(dateRange: TimeframeDateRange, notes?: string) =>
          handleApply(dateRange, notes)
        }
        editRangeDatesValues={editRangeDatesValues}
        setEditRangeDatesValues={setEditRangeDatesValues}
        disableFuture={false}
        minDate={dayjs().utc().startOf('day')}
        applyText={editRangeDatesValues ? 'Apply' : 'Add Appearance'}
        notes={notes}
        setNotes={setNotes}
        formikProps={formikProps}
        dateRange={dateRange}
        setDateRange={setDateRange}
        resetScheduleValues={resetScheduleValues}
        useNewDesign={true}
      />
      {appearancesTableData.length > 0 && (
        <div className="rounded-[20px] mt-8 mb-10 w-[528px] relative left-1/2 transform -translate-x-1/2">
          <DataTable {...tableConfig} />
        </div>
      )}
    </div>
  );
};

export default ScheduledOffer;
