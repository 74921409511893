import { useParams } from 'react-router-dom';

import { EBundlesViewModel } from '@appcharge/shared-ui';
import useTheme from 'api/useTheme';
import { AxiosError } from 'axios';
import { OfferDesignTypes } from 'views/OffersUI/OffersUIForm/types';

import useImages from '../api/useImages';
import { BannerAspectRatio, EAssetType } from '../constants/enums';
import { EToasterStatus, showToast } from '../utils/showToast';

export const UploadsTooltips = {
  [EAssetType.PRODUCT]: '1:1 Ratio | PNG, JPEG | At least 128px width',
  [EAssetType.BADGE]: '1:1 Ratio | PNG, JPEG | At least 128px width',
  [EAssetType.BG_BUNDLE]: '1:2 Ratio | PNG, JPEG | 320px width minimum',
  [EAssetType.BG_DESKTOP]: '1920 X 1080 px | PNG, JPEG',
  [EAssetType.BG_MOBILE]: '1:3 Ratio | PNG, JPEG | At least 400px width',
  [EAssetType.BG_POPUP]: '1:1 Ratio | PNG, JPEG | At least 400px width',
  [EAssetType.FAVICON]: '1:1 Ratio | PNG, JPEG | 32px width',
  [EAssetType.LOGO]: '1:1 Ratio | PNG, JPEG | 320px width',
  [EAssetType.PRODUCT_PREFIX]: '1:1 Ratio | PNG, JPEG | At least 32px width',
  [EAssetType.GENERAL]: '1:1 Ratio | PNG, JPEG | 128px width',
  [EAssetType.SECTION]: '1:1 Ratio | PNG, JPEG | 128px width',
  [EAssetType.BANNER]: '1:3 Ratio | PNG, JPEG | At least 400px width',
  [EAssetType.ADD_TO_HOME_BUTTON_IMAGE]:
    '2:1 Ratio | PNG, JPEG | At least 122px width',
  [EAssetType.ADD_TO_HOME_ICON_IMAGE]:
    '1:1 Ratio | PNG, SVG | Max 192px width and height',
  BG_BUNDLE_LINEAR: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_BUNDLE_TWO_IN_ONE: '1:1 Ratio | PNG, JPEG | At least 400px width',
  BG_BUNDLE_THREE_IN_ONE: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_BUNDLE_SIX_IN_ONE: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_SPECIAL_OFFER: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_POPUP_SQUARE: '1:1 Ratio | PNG, JPEG | At least 400px width',
  BG_POPUP_TWO_ON_THREE: '2:3 Ratio | PNG, JPEG | At least 400px width'
};

export const UploadTooltipsDescription =
  'For optimized display, make sure your image is at least';

const OfferDesignTooltipMessage: Record<any, any> = {
  [OfferDesignTypes.SpecialOffer]: UploadsTooltips.BG_SPECIAL_OFFER,
  [OfferDesignTypes.PopUp]: {
    [BannerAspectRatio.SQUARE]: UploadsTooltips.BG_POPUP_SQUARE,
    [BannerAspectRatio.TWO_ON_THREE]: UploadsTooltips.BG_POPUP_TWO_ON_THREE
  },
  [OfferDesignTypes.Bundle]: {
    [EBundlesViewModel.LINEAR]: UploadsTooltips.BG_BUNDLE_LINEAR,
    [EBundlesViewModel.TWO_IN_ONE]: UploadsTooltips.BG_BUNDLE_TWO_IN_ONE,
    [EBundlesViewModel.THREE_IN_ONE]: UploadsTooltips.BG_BUNDLE_THREE_IN_ONE,
    [EBundlesViewModel.SIX_IN_ONE]: UploadsTooltips.BG_BUNDLE_SIX_IN_ONE
  }
};

export const useUploads = () => {
  const { publisherId } = useParams();
  const { addImage } = useImages(publisherId);
  const { getGeneralTheme } = useTheme(publisherId);

  const { popupRatio, bundlesViewModel } = getGeneralTheme?.data || {};

  const uploadImage = async (
    event: React.SyntheticEvent,
    pictureRef: HTMLInputElement,
    pictures: any,
    setFieldValue: Function,
    setCurrentUploadingField: Function,
    currentUploadingField: string
  ) => {
    event.preventDefault();
    const file = pictureRef.files?.[0];
    const formData = new FormData();
    if (!file) {
      return;
    }
    if (file && file.size > 2000000) {
      showToast({
        message: 'File size limit is 2MB',
        status: EToasterStatus.ERROR
      });
      return;
    }
    formData.append('image', file as File);
    formData.append('type', 'Products');
    formData.append('name', file.name);
    addImage.mutate(formData, {
      onSuccess: async (data) => {
        showToast({
          message: `${data!.data.name} has been uploaded`,
          status: EToasterStatus.SUCCESS
        });
        await pictures.refetch();
        setFieldValue(currentUploadingField, data!.data.url);
        setCurrentUploadingField('');
      },
      onError(error) {
        console.log({ error });
        showToast({
          message: 'Asset has failed to upload',
          status: EToasterStatus.ERROR,
          description: (
            (error as AxiosError).response?.data as {
              message: string;
            }
          ).message
        });
      }
    });
  };

  const getAssetUploadMessage = (offerUiType: OfferDesignTypes) => {
    if (offerUiType === OfferDesignTypes.Bundle) {
      return OfferDesignTooltipMessage[offerUiType][bundlesViewModel];
    }
    if (offerUiType === OfferDesignTypes.PopUp) {
      return OfferDesignTooltipMessage[offerUiType][popupRatio || '1 / 1'];
    }
    return OfferDesignTooltipMessage[offerUiType];
  };

  return { uploadImage, getAssetUploadMessage };
};
