import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import useTheme from 'api/useTheme';

import { defaultGeneralTheme } from '../GeneralSettings/defaults';

export const useGetCheckoutInitialValues = () => {
  const location = useLocation();

  const { publisherId } = useParams();

  const [initialValues, setInitialValue] = useState<any>({});

  const { getGeneralTheme: generalTheme, getCheckoutTheme: checkoutTheme } =
    useTheme(publisherId);

  useEffect(() => {
    setInitialValue({
      backgroundImage: checkoutTheme.data?.backgroundImage || '',
      buttonColor: checkoutTheme.data?.buttonColor,
      buttonTextColor: checkoutTheme.data?.buttonTextColor || '',
      mobileBgImage: checkoutTheme.data?.mobileBgImage || '',
      primaryColor: checkoutTheme.data?.primaryColor || '',
      textColor: checkoutTheme.data?.textColor || '',
      logo:
        checkoutTheme.data?.logo ||
        generalTheme?.data?.logo ||
        defaultGeneralTheme.logo,
      publisherId: checkoutTheme.data?.publisherId || '',
      _id: checkoutTheme.data?._id || ''
    });
  }, [checkoutTheme.data, generalTheme.data, location]);

  return { initialValues };
};
