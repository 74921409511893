import { Route } from 'react-router-dom';

import ContentView from '../views/Content/ContentView';
import PagesView from '../views/Pages/PagesView';
import SettingsView from '../views/Settings/SettingsView';

const portalLevelRoutes = (
  <Route path="portal/:portalId/*">
    <Route path="pages/*" element={<PagesView />} />
    <Route path="content/*" element={<ContentView />} />
    <Route path="settings/*" element={<SettingsView />} />
  </Route>
);

export default portalLevelRoutes;
