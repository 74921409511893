import { SyntheticEvent, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import useUsers from 'api/useUsers';
import { ELocalStorageKeys } from 'constants/enums';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';

import PageTopBar from 'components/Topbar/PageTopBar';

import './style.scss';

enum EProfileState {
  PERSONAL = 'personal',
  PASSWORD = 'password'
}

const ProfileView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = location.pathname.split('/')[4];
  const [tab, setTab] = useState(currentTab);
  const { getUsers, updateUser, updatePassword } = useUsers({
    shouldFetchUsersData: true,
    enableFeatureFlags: false
  });

  useEffect(() => {
    setTab(location.pathname.split('/').pop() || '');
  }, []);

  const userId = useSelector(
    ({ auth }: { auth: AuthSliceState }) =>
      auth.userId ||
      localStorageUtil.getAny(ELocalStorageKeys.USER_DETAILS)?.userId
  );
  const { isLoading } = getUsers;

  const tabs = [
    { label: 'Personal Info', value: EProfileState.PERSONAL },
    { label: 'Password', value: EProfileState.PASSWORD }
  ];

  const handleTabChange = (event: SyntheticEvent, newValue: EProfileState) => {
    setTab(newValue);
    const newPath = `/profile/${newValue}`;
    navigate(newPath);
  };

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          height: '60px',
          width: '60px'
        }}
      />
    );

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent={<PageTopBar withTabsDesign={true} headline="Profile" />}
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};

export default ProfileView;
