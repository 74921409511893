import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { EPublisherSolutionModel, Portal, Project } from 'common/contracts';

import { User } from '../../common/contracts';
import { IPermissions } from '../../common/permissions.types';
import { EFeatureFlag, ELocalStorageKeys } from '../../constants/enums';
import { localStorageUtil } from '../../utils/localStorageUtil';

import {
  companyLevelNavItems,
  pageFeatureFlagMap,
  portalLevelNavItems,
  storeLevelNavItems
} from './constants';
import {
  ESideBarContext,
  ESidebarGroupKey,
  SideBarGroup,
  SideBarItem
} from './types';

export const generateProjectNavItems = (projects: Project[]): SideBarItem[] => {
  return projects.map((project: any) => ({
    title: project.publisherName,
    url: `project/${project.publisherId}`,
    icon: getIconByContext(
      ESideBarContext.PROJECT,
      project.projectType === EPublisherSolutionModel.CHECKOUT
    )
  }));
};

export const generatePortalNavItems = (portals: Portal[]): SideBarItem[] => {
  return portals.map((portal: any) => ({
    title: portal.portalName,
    url: `portal/${portal.portalId}/settings`,
    icon: getIconByContext(ESideBarContext.PORTAL)
  }));
};

export const filterGroupsByPermissions = (
  groups: SideBarGroup[],
  permissions: IPermissions,
  currentProjectId?: string
): SideBarGroup[] => {
  if (!groups || groups.length < 1) {
    return [];
  }

  const filteredGroups = groups
    .map((group) => ({
      ...group,
      items: group.items.filter((item) => {
        const requiredPermissions = item.permissions || [];
        const hasNoRequiredPermissions = requiredPermissions.length === 0;

        let hasAllRequiredPermissions = false;
        if (!currentProjectId) {
          // Check for each permission if the user has access to at least in one project
          hasAllRequiredPermissions = Object.values(permissions).some(
            (projectPermissions) => {
              return requiredPermissions.every((permission) =>
                Object.keys(projectPermissions).includes(permission)
              );
            }
          );
        } else {
          const hasLocalizationPermission = Boolean(
            permissions[currentProjectId].offers?.includes('manage')
          );
          hasAllRequiredPermissions = requiredPermissions.every(
            (permission) => {
              return item.title === 'Localization'
                ? hasLocalizationPermission
                : permissions[currentProjectId][permission]?.includes('view') ||
                    permissions[currentProjectId][permission]?.includes(
                      'manage'
                    );
            }
          );
        }

        return hasNoRequiredPermissions || hasAllRequiredPermissions;
      })
    }))
    .filter((group) => group.items.length > 0);

  return filteredGroups;
};

export const getCompanyLevelNavItems = (
  permissions: IPermissions
): SideBarGroup[] => {
  const permissionsFilteredGroups = filterGroupsByPermissions(
    companyLevelNavItems,
    permissions
  );

  const userDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  );

  // Also need to filter the all projects nav items
  const projectsNavItems = userDetails?.projects
    ? generateProjectNavItems(userDetails.projects)
    : [];

  const portalsNavItems = userDetails?.portals
    ? generatePortalNavItems(userDetails.portals)
    : [];

  const projectsGroup: SideBarGroup = {
    title: ESidebarGroupKey.Projects,
    isCollapsable: true,
    items: [...portalsNavItems, ...projectsNavItems]
  };

  return [...permissionsFilteredGroups, projectsGroup];
};

export const getProjectLevelNavItems = (
  permissions: IPermissions,
  projectId: string,
  featureFlags?: Record<EFeatureFlag, boolean>
): SideBarGroup[] => {
  const permissionsFilteredGroups = filterGroupsByPermissions(
    storeLevelNavItems,
    permissions,
    projectId
  );

  if (!featureFlags) {
    return permissionsFilteredGroups;
  }

  const filteredItems = permissionsFilteredGroups
    .map((group) => ({
      ...group,
      items: group.items.filter((item) => {
        const requiredFlag = pageFeatureFlagMap[item.title];
        if (!requiredFlag) {
          return true;
        }
        return !!featureFlags[requiredFlag];
      })
    }))
    .filter((group) => group.items.length > 0);

  return filteredItems;
};

export const getPortalLevelNavItems = (
  permissions: IPermissions
): SideBarGroup[] => {
  // todo: filter by permissions when defined
  return portalLevelNavItems;
};

export const getIconByContext = (
  context: ESideBarContext,
  isCheckout: boolean = false
) => {
  switch (context) {
    case ESideBarContext.PORTAL:
      return (
        <FontAwesomeIcon
          icon="fa-regular fa-table-layout"
          className="p-3 rounded-full bg-indigo-100 text-[12px]"
        />
      );
    case ESideBarContext.PROJECT:
      if (isCheckout) {
        return (
          <FontAwesomeIcon
            icon="fa-regular fa-credit-card"
            className="p-3 rounded-full bg-orange-100 text-[12px]"
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon="fa-regular fa-store"
            className="p-3 rounded-full bg-green-100 text-[12px]"
          />
        );
      }
    default:
      return null;
  }
};
