import { EFontWeights } from '@appcharge/shared-ui';
import {
  EIntervalsRepeat,
  EIntervalsRepeatRequired,
  EOfferStatus
} from 'constants/enums';
import {
  OfferDesignTypeItem,
  OfferDesignTypes
} from 'views/OffersUI/OffersUIForm/types';

export const roundedHoursOptions = [
  {
    content: '00:00',
    key: '00:00',
    value: '00:00',
    label: '00:00'
  },
  {
    content: '01:00',
    key: '01:00',
    value: '01:00',
    label: '01:00'
  },
  {
    content: '02:00',
    key: '02:00',
    value: '02:00',
    label: '02:00'
  },
  {
    content: '03:00',
    key: '03:00',
    value: '03:00',
    label: '03:00'
  },
  {
    content: '04:00',
    key: '04:00',
    value: '04:00',
    label: '04:00'
  },
  {
    content: '05:00',
    key: '05:00',
    value: '05:00',
    label: '05:00'
  },
  {
    content: '06:00',
    key: '06:00',
    value: '06:00',
    label: '06:00'
  },
  {
    content: '07:00',
    key: '07:00',
    value: '07:00',
    label: '07:00'
  },
  {
    content: '08:00',
    key: '08:00',
    value: '08:00',
    label: '08:00'
  },
  {
    content: '09:00',
    key: '09:00',
    value: '09:00',
    label: '09:00'
  },
  {
    content: '10:00',
    key: '10:00',
    value: '10:00',
    label: '10:00'
  },
  {
    content: '11:00',
    key: '11:00',
    value: '11:00',
    label: '11:00'
  },
  {
    content: '12:00',
    key: '12:00',
    value: '12:00',
    label: '12:00'
  },
  {
    content: '13:00',
    key: '13:00',
    value: '13:00',
    label: '13:00'
  },
  {
    content: '14:00',
    key: '14:00',
    value: '14:00',
    label: '14:00'
  },
  {
    content: '15:00',
    key: '15:00',
    value: '15:00',
    label: '15:00'
  },
  {
    content: '16:00',
    key: '16:00',
    value: '16:00',
    label: '16:00'
  },
  {
    content: '17:00',
    key: '17:00',
    value: '17:00',
    label: '17:00'
  },
  {
    content: '18:00',
    key: '18:00',
    value: '18:00',
    label: '18:00'
  },
  {
    content: '19:00',
    key: '19:00',
    value: '19:00',
    label: '19:00'
  },
  {
    content: '20:00',
    key: '20:00',
    value: '20:00',
    label: '20:00'
  },
  {
    content: '21:00',
    key: '21:00',
    value: '21:00',
    label: '21:00'
  },
  {
    content: '22:00',
    key: '22:00',
    value: '22:00',
    label: '22:00'
  },
  {
    content: '23:00',
    key: '23:00',
    value: '23:00',
    label: '23:00'
  }
];

export const intervalsRepeatOptions = [
  {
    content: 'Does not repeat',
    value: EIntervalsRepeat.NONE,
    key: EIntervalsRepeat.NONE,
    label: EIntervalsRepeat.NONE
  },
  {
    content: 'Every day',
    value: EIntervalsRepeat.DAILY,
    key: EIntervalsRepeat.DAILY,
    label: EIntervalsRepeat.DAILY
  },
  {
    content: 'Every week',
    value: EIntervalsRepeat.WEEKLY,
    key: EIntervalsRepeat.WEEKLY,
    label: EIntervalsRepeat.WEEKLY
  },
  {
    content: 'Every month',
    value: EIntervalsRepeat.MONTHLY,
    key: EIntervalsRepeat.MONTHLY,
    label: EIntervalsRepeat.MONTHLY
  }
];

export const intervalsRepeatOptionsRequired = [
  {
    content: 'Every day',
    value: EIntervalsRepeatRequired.DAILY,
    key: EIntervalsRepeatRequired.DAILY,
    label: EIntervalsRepeatRequired.DAILY
  },
  {
    content: 'Every week',
    value: EIntervalsRepeatRequired.WEEKLY,
    key: EIntervalsRepeatRequired.WEEKLY,
    label: EIntervalsRepeatRequired.WEEKLY
  },
  {
    content: 'Every month',
    value: EIntervalsRepeatRequired.MONTHLY,
    key: EIntervalsRepeatRequired.MONTHLY,
    label: EIntervalsRepeatRequired.MONTHLY
  }
];

export const dayInMonthOptions = Array.from({ length: 31 }, (_, i) => ({
  key: i + 1,
  value: i + 1,
  content: i + 1,
  label: `${i + 1}`
}));

export const statusFilterOptions = [
  { label: 'All', value: '' },
  ...Object.values(EOfferStatus)
    .filter((status) => status !== EOfferStatus.PENDING_SAVE)
    .map((status) => ({
      label: status,
      value: status
    }))
];

export const fontWeightOptions = [
  {
    content: 'regular',
    key: EFontWeights.Normal,
    value: EFontWeights.Normal
  },
  {
    content: EFontWeights.Bold,
    key: EFontWeights.Bold,
    value: EFontWeights.Bold
  },
  {
    content: EFontWeights.Bolder,
    key: EFontWeights.Bolder,
    value: EFontWeights.Bolder
  }
];

const separatorOptions = [
  {
    content: 'Comma',
    key: ',',
    value: ','
  },
  {
    content: 'Dot',
    key: '.',
    value: '.'
  },
  {
    content: 'Space',
    key: ' ',
    value: ' '
  }
];

const trueFalseOptions = [
  {
    content: 'Yes',
    key: 'true',
    value: 'true'
  },
  {
    content: 'No',
    key: 'false',
    value: 'false',
    selected: true
  }
];

export const adminSettingsUtils = {
  separatorOptions,
  trueFalseOptions
};

export const initialOfferDesignTypesItems: OfferDesignTypeItem[] = [
  {
    content: 'Special Offer',
    key: OfferDesignTypes.SpecialOffer,
    value: OfferDesignTypes.SpecialOffer
  },
  {
    content: 'Bundle',
    key: OfferDesignTypes.Bundle,
    value: OfferDesignTypes.Bundle
  },
  {
    content: 'Pop Up',
    key: OfferDesignTypes.PopUp,
    value: OfferDesignTypes.PopUp
  },
  {
    content: 'Rolling Offer',
    key: OfferDesignTypes.RollingOffer,
    value: OfferDesignTypes.RollingOffer
  }
];

export const textModelOptions = [
  {
    content: '4 Digits',
    key: 'fourDigits',
    value: 'fourDigits'
  },
  {
    content: '6 Digits',
    key: 'sixDigits',
    value: 'sixDigits'
  },
  {
    content: 'Mobile Only',
    key: 'mobileOnly',
    value: 'mobileOnly'
  }
];
