import React, { FC, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { cn } from '../lib/utils';
import { UILabel } from '../ui';

interface Option {
  name: string;
  url: string;
}

interface AutocCompleteInputProps {
  options: Option[];
  onChange: (option: Option | null) => void;
  currentOption?: Option | null;
  label?: string;
  placeholder?: string;
}

const AutoCompleteInput: FC<AutocCompleteInputProps> = ({
  options,
  onChange,
  currentOption,
  label,
  placeholder = ''
}) => {
  const [inputValue, setInputValue] = useState(currentOption?.name || '');
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  useEffect(() => {
    setInputValue(currentOption?.name || '');
  }, [currentOption]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (option: Option) => {
    setInputValue(option.name);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full text-black" ref={dropdownRef}>
      {label && (
        <UILabel htmlFor="autocomplete-input" className="block mb-2 text-p-s">
          {label}
        </UILabel>
      )}
      <div className="relative">
        {currentOption && (
          <img
            src={currentOption.url}
            alt={currentOption.name}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 w-6 h-6"
          />
        )}
        <input
          ref={inputRef}
          id="autocomplete-input"
          type="text"
          className={cn(
            'h-10 w-full px-4 py-2 border rounded-lg focus:ring-2 focus:outline-none cursor-pointer',
            'bg-base-background text-base-foreground',
            currentOption ? 'pl-12' : ''
          )}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          autoComplete="off"
        />
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          className="absolute right-3 top-3 text-base-foreground cursor-pointer"
          onChange={handleInputChange}
        />
      </div>
      {isOpen && filteredOptions.length > 0 && (
        <ul className="absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-md max-h-40 overflow-auto">
          {filteredOptions.map((option) => (
            <li
              key={option.name}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect(option)}
            >
              <img
                src={option.url}
                alt={option.name}
                className="w-6 h-6 mr-2"
              />
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutoCompleteInput;
