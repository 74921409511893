import { StoreSettingsFormValues } from './generalTheme.types';

export const defaultStoreSettings: StoreSettingsFormValues = {
  productsQuantityFormat: {
    milSeparator: ',',
    fractionalSeparator: '.',
    thousandShorthand: false,
    millionShorthand: false,
    billionShorthand: false
  },
  balanceQuantityFormat: {
    milSeparator: ',',
    fractionalSeparator: '.',
    thousandShorthand: false,
    millionShorthand: false,
    billionShorthand: false
  },
  specialOfferQuantityFormat: {
    milSeparator: ',',
    fractionalSeparator: '.',
    thousandShorthand: false,
    millionShorthand: false,
    billionShorthand: false
  }
};
