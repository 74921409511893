import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import useContentManagement from 'api/portal/useContentManagement';
import { PageContentData } from 'common/contracts';
import dayjs from 'dayjs';
import { EToasterStatus, showToast } from 'utils/showToast';
import * as z from 'zod';

import FormHeader from 'components/FormHeader/FormHeader';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import Input from 'components/Input/Input';
import InputSelectDropdown from 'components/InputSelectDropdown/InputSelectDropdown';
import RichText from 'components/RichText/RichText';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from 'components/ui';

import { usePages } from '../../../api/portal/usePagesApi';
import Switch from '../../../components/Switch/Switch';
import Textarea from '../../../components/Textarea/Textarea';

import {
  CONTENT_FORM_SCHEMA,
  CONTENT_RADIO_BUTTON_OPTIONS,
  DEFAULT_PAGE_OPTION,
  EContentRadioButtonOptions
} from './constants';

type ContentFormProps = {
  dup?: boolean;
};

const ContentForm: FC<ContentFormProps> = ({ dup = false }) => {
  const {
    createPageContent,
    getCreatedContentsData,
    getPageContentById,
    editPageContent
  } = useContentManagement();
  const { getPages } = usePages();
  const currentContentsData = getPageContentById?.data;
  const pagesData = getPages?.data?.pages;
  const navigate = useNavigate();
  const [isPublishDateEnabled, setIsPublishDateEnabled] = useState(
    !Boolean(currentContentsData?.publishDate)
  );

  const initialValues = useMemo(
    () => ({
      title:
        (dup
          ? `${currentContentsData?.title}_duplicated`
          : currentContentsData?.title) ?? '',
      subTitle: currentContentsData?.title ?? '',
      bodyText: currentContentsData?.bodyText ?? '',
      headerImage: currentContentsData?.headerImage ?? 'test',
      thumbnailImage: currentContentsData?.thumbnailImage ?? 'test',
      pages: currentContentsData?.pages ?? [],
      labels: currentContentsData?.labels ?? [],
      state: currentContentsData?.state ?? EContentRadioButtonOptions.HIDDEN,
      slug: currentContentsData?.slug ?? '',
      publishDate: currentContentsData?.publishDate ?? ''
    }),
    [currentContentsData, dup]
  );

  const contentFormConfig = useForm<z.infer<typeof CONTENT_FORM_SCHEMA>>({
    resolver: zodResolver(CONTENT_FORM_SCHEMA),
    defaultValues: initialValues,
    mode: 'onChange'
  });
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isValid, isDirty }
  } = contentFormConfig;

  const parsedPagesOptions = useMemo(() => {
    if (!pagesData || pagesData?.length === 0) return [];
    return pagesData.map((page: any) => ({
      label: page.pageName,
      value: page.id
    }));
  }, [pagesData]);

  const titleValue = watch('title');

  useEffect(() => {
    if (currentContentsData) {
      reset(initialValues);
    }
  }, [currentContentsData, reset]);

  useEffect(() => {
    if (watch('title')) {
      const formattedTitle = titleValue.replace(/\s+/g, '-').toLowerCase();
      setValue('slug', formattedTitle);
    }
  }, [titleValue, watch]);

  const BUTTON_TEXT = currentContentsData && !dup ? 'Update' : 'Save';

  const handleContentFormSubmit = useCallback(
    (data: PageContentData) => {
      return currentContentsData && !dup
        ? handleEditSelectedContent(data)
        : handleCreateContent(data);
    },
    [currentContentsData]
  );

  const handleCreateContent = async (data: PageContentData) => {
    try {
      await createPageContent.mutateAsync(data);
      await getCreatedContentsData.refetch();
      showToast({
        message: 'New content was created successfully',
        status: EToasterStatus.SUCCESS
      });
      navigate('../');
    } catch (error: any) {
      showToast({
        message:
          error?.response?.data?.message ||
          'New content was not created. Please try again',
        status: EToasterStatus.ERROR
      });
    }
  };

  const handleEditSelectedContent = async (data: PageContentData) => {
    try {
      await editPageContent.mutateAsync(data);
      await getCreatedContentsData.refetch();
      showToast({
        message: 'Page content was successfully updated',
        status: EToasterStatus.SUCCESS
      });
      navigate('../');
    } catch (error: any) {
      showToast({
        message:
          error?.response?.data?.message ||
          'Page content was not updated. Please try again',
        status: EToasterStatus.ERROR
      });
    }
  };

  const onCloseForm = () => {
    navigate('../');
  };

  return (
    <div className="bg-white fixed h-screen w-full top-0 left-0 z-[1000] overflow-scroll">
      <FormHeader
        onCloseForm={onCloseForm}
        onSubmitForm={handleSubmit(handleContentFormSubmit)}
        onOptionChange={(option) => {
          setValue('state', option as EContentRadioButtonOptions);
        }}
        selectedOption={watch('state')}
        options={CONTENT_RADIO_BUTTON_OPTIONS}
        title="Create Portal Content"
        isFormValid={!isValid}
        buttonText={BUTTON_TEXT}
      />
      <div className="mt-7 w-[456px] mx-auto">
        <RichText
          title="General"
          description="Create content for your pages"
          className="mb-9"
        />
        <Form {...contentFormConfig}>
          <form>
            <div className="flex flex-col gap-5 mb-9">
              <div className="grid grid-cols-2 gap-5">
                <FormField
                  name="title"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          label="Title"
                          placeholder="e.g., Blog"
                          isRequired={true}
                          state={errors.title?.message ? 'error' : 'default'}
                          description={errors.title?.message}
                          value={value}
                          onChange={onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  name="subTitle"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          label="Sub Title"
                          placeholder="Number"
                          value={value}
                          onChange={onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                name="bodyText"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        label="Body text"
                        placeholder="e.g., Write your blog post here. Share exciting updates, game features, behind-the-scenes insights, or special offers."
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="headerImage"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ImageUpload
                        className="w-full"
                        title="Add header image"
                        description="The image will be scaled to 32px x 32px"
                        buttonText="Upload"
                        isRequired
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                name="thumbnailImage"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <ImageUpload
                      className="w-full"
                      title="Add thumbnail image"
                      description="The image will be scaled to 32px x 32px"
                      buttonText="Upload"
                      isRequired
                    />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-2 gap-5">
                <FormField
                  name="pages"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormItem>
                      <InputSelectDropdown
                        label="Pages"
                        defaultSelected={
                          parsedPagesOptions.find(
                            (option: any) => option.value === value?.[0]
                          ) ?? {
                            label: DEFAULT_PAGE_OPTION,
                            value: DEFAULT_PAGE_OPTION
                          }
                        }
                        options={parsedPagesOptions}
                        onOptionChange={(value: string) => onChange([value])}
                        disabled={parsedPagesOptions?.length === 0}
                      />
                    </FormItem>
                  )}
                />
                <FormField
                  name="labels"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormItem>
                      <FormControl>
                        {/* TODO: Change to new Label component when it will be implemented */}
                        <Input
                          label="Labels"
                          placeholder="Add some label"
                          value={value?.length > 0 ? value.join(', ') : ''}
                          onChange={(e) =>
                            onChange(
                              e.target.value.split(',').map((v) => v.trim())
                            )
                          } //TODO Added temporarily until new Label component will be implemented
                          isRequired={true}
                          description={errors.labels?.message}
                          state={errors.labels?.message ? 'error' : 'default'}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-full">
                <FormField
                  name="slug"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormItem>
                      <Input
                        label="Slug"
                        placeholder="/"
                        value={value}
                        onChange={onChange}
                      />
                    </FormItem>
                  )}
                />
              </div>
              {watch('state') === EContentRadioButtonOptions.HIDDEN && (
                <>
                  <div>
                    <RichText
                      title="Schedule publication date"
                      description="Set a date for your content to go live"
                      className="mb-5"
                    />
                    <Switch
                      className="mb-9"
                      label="Enable scheduled publishing"
                      description="Turn on scheduled publishing for this content"
                      textAlignment="right"
                      onCheckedChange={() => {
                        console.log('change');
                        return setIsPublishDateEnabled(!isPublishDateEnabled);
                      }}
                    />
                    {/*TODO Update for new date picker from design system when it will be created*/}
                    <FormField
                      name="publishDate"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <FormItem>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                timezone="UTC"
                                disablePast
                                format="DD/MM/YYYY HH:mm"
                                value={dayjs(value) || null}
                                onChange={(newValue) => {
                                  setValue(
                                    'publishDate',
                                    newValue
                                      ? newValue.toISOString()
                                      : undefined
                                  );
                                }}
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    height: '48.3px'
                                  },
                                  '.MuiFormHelperText-root': {
                                    marginLeft: 0
                                  }
                                }}
                                disabled={isPublishDateEnabled}
                              />
                            </LocalizationProvider>
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default ContentForm;
