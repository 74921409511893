import { useParams } from 'react-router-dom';

import { Grid, Stack } from '@mui/material';
import useUsers from 'api/useUsers';
import { FormikProps } from 'common/contracts';
import { EFeatureFlag, EIntervalsRepeat } from 'constants/enums';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';

import IntervalOffer from './IntervalOffer';

export interface PermanentOfferProps {
  formikProps: FormikProps;
  repeat: EIntervalsRepeat;
  setRepeat: (repeat: EIntervalsRepeat) => void;
  weekDay: number;
  setWeekDay: (day: number) => void;
  dayInMonth: number;
  setDayInMonth: (day: number) => void;
  publisherId?: string;
  startHour: string;
  setStartHour: (hour: string) => void;
  intervalCron: string;
  setIntervalCron: (cron: string) => void;
}

const PermanentOffer: React.FC<PermanentOfferProps> = ({
  formikProps,
  startHour,
  setStartHour,
  intervalCron,
  setIntervalCron,
  weekDay,
  setWeekDay,
  repeat,
  setRepeat,
  dayInMonth,
  setDayInMonth
}) => {
  const { values, handleChange, handleBlur, touched, errors } = formikProps;
  const { publisherId } = useParams();
  const { fetchFeatureFlags } = useUsers({ publisherId });

  const hasIntervalsFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_INTERVALS];

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: '2rem'
      }}
    >
      <AcCard
        stackContainer={false}
        title="Permanent Offer"
        description="Permanent events won’t have an end date"
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={3}>
            <AcInput
              header="Availability"
              name="playerAvailability"
              value={values.playerAvailability || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              required={values?.setAsFree || values?.schedule?.interval}
              type="number"
              error={
                touched.playerAvailability && Boolean(errors.playerAvailability)
              }
              helperText={
                touched.playerAvailability
                  ? errors.playerAvailability?.toString()
                  : ''
              }
            />
          </Grid>
          {hasIntervalsFeatureFlag && (
            <IntervalOffer
              formikProps={formikProps}
              repeat={repeat}
              setRepeat={setRepeat}
              weekDay={weekDay}
              setWeekDay={setWeekDay}
              dayInMonth={dayInMonth}
              setDayInMonth={setDayInMonth}
              publisherId={publisherId}
              startHour={startHour}
              setStartHour={setStartHour}
              intervalCron={intervalCron}
              setIntervalCron={setIntervalCron}
            />
          )}
        </Grid>
      </AcCard>
    </Stack>
  );
};

export default PermanentOffer;
