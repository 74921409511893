import React from 'react';

import ReactCountryFlag from 'react-country-flag';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import countries from 'i18n-iso-countries';
import locales from 'i18n-locales';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const handleRenderCountriesList = (selectedCountries: any) => {
  if (selectedCountries.length > 2) {
    return `${selectedCountries.slice(0, 2).join(', ')} and ${selectedCountries.length - 2} more`;
  }
  return selectedCountries.join(', ');
};

const countriesList = countries.getNames('en');

export const getCountriesOptions = () => {
  return [
    ...Object.entries(countriesList).map(([key, value]) => ({
      content: value,
      key: key,
      value: value,
      renderFunction: () => (
          <Stack
              className="countryItem"
              direction="row"
              alignItems="center"
              gap={1}
          >
            <ReactCountryFlag
                className="countryFlag"
                countryCode={key}
                svg
            />
            <Typography className="countryName">{value}</Typography>
          </Stack>
      )
    }))
  ];
}

const sortedLocales = locales.sort((locale1, locale2) => {
  const pattern = /^[a-z]{2}-[a-z]{2}-[A-Z]{2}$/;
  const locale1Matches = pattern.test(locale1);
  const locale2Matches = pattern.test(locale2);
  if (locale1Matches && !locale2Matches) return 1;
  if (!locale1Matches && locale2Matches) return -1;
  return locale1.localeCompare(locale2)
})

export const getSupportedLanguageCodes = () => (
    sortedLocales.map((locale) => ({
    content: locale,
    key: locale,
    value: locale,
  }))
)
