import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect
} from 'react';
import { ESideBarContext, SideBarGroup, ESidebarGroupKey } from './types';
import { User } from 'common/contracts';
import { EFeatureFlag } from 'constants/enums';

interface SidebarContextType {
  context: ESideBarContext;
  setContext: (context: ESideBarContext) => void;
  isCheckout: boolean;
  setIsCheckout: (isCheckout: boolean) => void;
  navItems: SideBarGroup[];
  setNavItems: (items: SideBarGroup[]) => void;
  collapsedGroups: Record<string, boolean>;
  toggleGroup: (group: string) => void;
  userDetails: User | null;
  featureFlags?: Record<EFeatureFlag, boolean>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export function SidebarProvider({
  children,
  initialUserDetails,
  initialFeatureFlags
}: {
  children: ReactNode;
  initialUserDetails: User | null;
  initialFeatureFlags?: Record<EFeatureFlag, boolean>;
}) {
  const [context, setContext] = useState<ESideBarContext>(
    ESideBarContext.COMPANY
  );
  const [isCheckout, setIsCheckout] = useState(false);
  const [navItems, setNavItems] = useState<SideBarGroup[]>([]);
  const [collapsedGroups, setCollapsedGroups] = useState(() => {
    // Try to get saved state from session storage
    const savedState = sessionStorage.getItem('sidebarCollapsedGroups');
    if (savedState) {
      try {
        return JSON.parse(savedState);
      } catch (e) {
        console.error('Failed to parse saved sidebar state', e);
      }
    }

    // Return default state if nothing in storage or parsing failed
    return {
      [ESidebarGroupKey.Management]: false,
      [ESidebarGroupKey.Studio]: false,
      [ESidebarGroupKey.Offers]: false,
      [ESidebarGroupKey.Dashboard]: false,
      [ESidebarGroupKey.Projects]: false
    };
  });
  const [isLoading, setIsLoading] = useState(false);

  // Update session storage when state changes
  useEffect(() => {
    sessionStorage.setItem(
      'sidebarCollapsedGroups',
      JSON.stringify(collapsedGroups)
    );
  }, [collapsedGroups]);

  const toggleGroup = (group: string) => {
    setCollapsedGroups((prev: Record<string, boolean>) => ({
      ...prev,
      [group]: !prev[group as keyof typeof prev]
    }));
  };

  return (
    <SidebarContext.Provider
      value={{
        context,
        setContext,
        isCheckout,
        setIsCheckout,
        navItems,
        setNavItems,
        collapsedGroups,
        toggleGroup,
        userDetails: initialUserDetails,
        featureFlags: initialFeatureFlags,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
