import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import { cn } from '../../../components/lib/utils';
import {
  DropdownMenuContent as IUDropdownMenuContent,
  DropdownMenuItem
} from '../../../components/ui/dropdown-menu';

import { DropdownMenuItemsProps } from './constants';
import { useProfileDropdownActions } from './useProfileDropdownActions';

type DropdownMenuContentProps = React.ComponentProps<
  typeof IUDropdownMenuContent
> & {
  dropdownMenuItems: DropdownMenuItemsProps[];
  className?: string;
};

const DropdownMenuContent: FC<DropdownMenuContentProps> = ({
  dropdownMenuItems,
  className,
  ...rest
}) => {
  const actions = useProfileDropdownActions();

  return (
    <IUDropdownMenuContent
      align="end"
      sideOffset={-1}
      className={cn(
        'w-[var(--radix-popper-anchor-width)] rounded-t-none rounded-b-md border-base-border bg-white px-0 pt-1 pb-0 shadow-md',
        className
      )}
      {...rest}
    >
      {dropdownMenuItems.map(({ name, iconType }, i) => (
        <DropdownMenuItem
          key={i}
          className="text-base-popoverForeground cursor-pointer rounded-none px-3 last:border-t border-base-border last:mt-1 last:py-2.5 hover:bg-indigo-50"
          onClick={() => actions[name]()}
        >
          <FontAwesomeIcon
            icon={cn('fa-regular', iconType)}
            className="text-base-sidebarForeground"
          />
          {name}
        </DropdownMenuItem>
      ))}
    </IUDropdownMenuContent>
  );
};

export default DropdownMenuContent;
